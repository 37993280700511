import { IOverrides, override_key, useStore } from "~/models/Root";

export const defaultOverrides: Array<IOverrides> = [
  {
    id: 1,
    key: "Partner_Label",
    value: "Partner",
    type: "Label",
    is_override: false,
  },
  {
    id: 2,
    key: "Partners_Label",
    value: "Partners",
    type: "Label",
    is_override: false,
  },
  {
    id: 3,
    key: "Program_Label",
    value: "Program",
    type: "Label",
    is_override: false,
  },
  {
    id: 4,
    key: "Programs_Label",
    value: "Programs",
    type: "Label",
    is_override: false,
  },
  {
    id: 5,
    key: "Course_Label",
    value: "Course",
    type: "Label",
    is_override: false,
  },
  {
    id: 6,
    key: "Courses_Label",
    value: "Courses",
    type: "Label",
    is_override: false,
  },
  {
    id: 7,
    key: "Module_Label",
    value: "Module",
    type: "Label",
    is_override: false,
  },
  {
    id: 8,
    key: "Modules_Label",
    value: "Modules",
    type: "Label",
    is_override: false,
  },
  {
    id: 9,
    key: "Activity_Label",
    value: "Activity",
    type: "Label",
    is_override: false,
  },
  {
    id: 10,
    key: "Activities_Label",
    value: "Activities",
    type: "Label",
    is_override: false,
  },
];

const useOverrides = () => {
  const { getOverrides } = useStore();

  const ProgramLabel = () => {
    return getOverrides(override_key.PROGRAM);
  };

  const ProgramsLabel = () => {
    return getOverrides(override_key.PROGRAMS);
  };

  const PartnerLabel = () => {
    return getOverrides(override_key.PARTNER);
  };

  const PartnersLabel = () => {
    return getOverrides(override_key.PARTNERS);
  };

  const CourseLabel = () => {
    return getOverrides(override_key.COURSE);
  };

  const CoursesLabel = () => {
    return getOverrides(override_key.COURSES);
  };

  const ModuleLabel = () => {
    return getOverrides(override_key.MODULE);
  };

  const ModulesLabel = () => {
    return getOverrides(override_key.MODULES);
  };

  const ActivityLabel = () => {
    return getOverrides(override_key.ACTIVITY);
  };

  const ActivitiesLabel = () => {
    return getOverrides(override_key.ACTIVITIES);
  };

  return {
    ProgramLabel,
    ProgramsLabel,
    PartnerLabel,
    PartnersLabel,
    CourseLabel,
    CoursesLabel,
    ModuleLabel,
    ModulesLabel,
    ActivityLabel,
    ActivitiesLabel,
  };
};

export default useOverrides;
