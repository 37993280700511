import React, { Fragment } from "react";

import { AlertTriangle, CheckSquare } from "lucide-react";

import { NodeCoverage } from "~/components/explorer/hooks/useNodeCoverage";
import NodeMappingsListTable from "~/components/explorer/node/hover-details/NodeMappingsTable";
import { ScrollArea } from "~/components/ui/scroll-area";
import { useExplorerContext } from "~/context/ExplorerProvider";
import { IMappingListItem, NodeTypes } from "~/models/AlignEditor";

type CoverageDetailsProps = {
  id: number;
  coverage: NodeCoverage;
  type: NodeTypes;
  mappings?: IMappingListItem[];
};

const CoverageDetails = ({
  id,
  coverage,
  mappings = [],
  type,
}: CoverageDetailsProps) => {
  const { selectedList, getTypeLabel } = useExplorerContext();

  const showCoverage = selectedList !== undefined;

  const isProgram = type === NodeTypes.Program;
  const preposition = isProgram ? "under" : "within";

  return (
    <>
      {!(isProgram && !showCoverage) && (
        <h4 className="text font-semibold pt-2">Mappings</h4>
      )}
      {showCoverage && (
        <div className="flex items-center pt-1">
          <CheckSquare className="mr-2 h-4 w-4" />{" "}
          <div className="text-sm">
            <sup>{coverage.numerator}</sup>&frasl;
            <sub>{coverage.denominator}</sub>
          </div>
          <div className="ps-1 text-sm whitespace-pre">
            selected list items covered {preposition} this {getTypeLabel(type)}
          </div>
        </div>
      )}

      {mappings.length > 0 ? (
        <>
          <h4 className="text-sm font-semibold text-idesign-navy-70 pt-2">
            All {getTypeLabel(type)} Level mappings
          </h4>
          <ScrollArea
            className="flex flex-col max-h-[250px] bg-gradient-1 rounded-[10px] border border-idesign-navy-120"
            thumbClassName="bg-gradient-1"
          >
            <NodeMappingsListTable id={id} type={type} />
          </ScrollArea>
        </>
      ) : (
        <NoMappingsWarning type={type} />
      )}
    </>
  );
};

type NoMappingsWarningProps = { type: NodeTypes };

const NoMappingsWarning = ({ type }: NoMappingsWarningProps) => {
  const { getTypeLabel } = useExplorerContext();
  return type !== NodeTypes.Program ? (
    <div className="flex items-center pt-1">
      <AlertTriangle className="mr-2 h-4 w-4" />{" "}
      <span className="text-sm">
        There are no list items mapped to this {getTypeLabel(type)}
      </span>
    </div>
  ) : null;
};

export default CoverageDetails;
