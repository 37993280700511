import * as React from "react";

import * as CheckboxPrimitive from "@radix-ui/react-checkbox";
import { VariantProps, cva } from "class-variance-authority";
import { Check, Minus } from "lucide-react";

import { cn } from "~/lib/utils";

const variants = {
  primary: cn(
    "data-[state=unchecked]:focus-visible:ring-checkbox-offset",
    "data-[state=unchecked]:focus-visible:outline-ring data-[state=unchecked]:focus-visible:outline-1",
    "data-[state=unchecked]:focus-visible:border-input data-[state=unchecked]:border-input",
    "data-[state=unchecked]:disabled:border-disable",
    "data-[state=checked]:bg-checkbox-fill data-[state=checked]:text-checkbox-icon",
    "data-[state=checked]:hover:bg-checkbox-fill-hover",
    "data-[state=checked]:disabled:bg-disable data-[state=checked]:disabled:text-disable-foreground",
    "data-[state=checked]:focus-visible:ring-ring data-[state=checked]:focus-visible:ring-offset-checkbox-offset",
    "data-[state=indeterminate]:bg-checkbox-fill data-[state=indeterminate]:text-checkbox-icon",
    "data-[state=indeterminate]:focus-visible:ring-ring data-[state=indeterminate]:focus-visible:ring-offset-checkbox-offset",
    "data-[state=indeterminate]:disabled:bg-disable data-[state=indeterminate]:disabled:text-disable-foreground",
  ),
};

const checkboxVariants = cva(
  cn(
    "group peer h-5 w-5 shrink-0 rounded-sm border text-lg border-solid",
    "disabled:cursor-not-allowed",
    "focus-visible:outline-none ",
    "data-[state=unchecked]:focus-visible:ring data-[state=unchecked]:focus-visible:ring-offset-none",
    "data-[state=checked]:focus-visible:ring-1 data-[state=checked]:focus-visible:ring-offset-2 data-[state=checked]:border-none",
    "data-[state=indeterminate]:focus-visible:ring-1 data-[state=indeterminate]:focus-visible:ring-offset-2 data-[state=indeterminate]:border-none",
  ),
  {
    variants: {
      variant: variants,
    },
    defaultVariants: {
      variant: "primary",
    },
  },
);

const Checkbox = React.forwardRef<
  React.ElementRef<typeof CheckboxPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof CheckboxPrimitive.Root> &
    VariantProps<typeof checkboxVariants>
>(({ className, variant, ...props }, ref) => (
  <CheckboxPrimitive.Root
    ref={ref}
    className={cn(className, checkboxVariants({ variant }))}
    {...props}
  >
    <CheckboxPrimitive.Indicator
      className={cn("flex items-center justify-center text-current")}
    >
      <Check className="h-4 w-4 hidden group-data-[state=checked]:block" />
      <Minus className="h-4 w-4 hidden group-data-[state=indeterminate]:block" />
    </CheckboxPrimitive.Indicator>
  </CheckboxPrimitive.Root>
));
Checkbox.displayName = CheckboxPrimitive.Root.displayName;

export { Checkbox };
