import React, { useContext } from "react";

import styled from "styled-components";

import AlignButton from "~/components/AlignButton";
import { DialogContext } from "~/components/AlignDialog";
import { colors } from "~/css/shared-styles";
import useOverrides from "~/hooks/useOverrides";

export const ModalStyles = styled.div`
  .modal-container {
    display: flex;
    justify-content: center;
    flex-direction: column;
  }
  .headline {
    margin-top: 0;
    text-align: center;
  }
`;

interface IPartnerCreation {
  createdPartnerName: string;
}

const PartnerCreationModal: React.FC<IPartnerCreation> = (props) => {
  const { createdPartnerName } = props;
  const { closeDialog } = useContext(DialogContext);
  const { PartnerLabel, ProgramsLabel } = useOverrides();
  const partnerLabel = PartnerLabel();
  const programsLabel = ProgramsLabel();

  return (
    <ModalStyles>
      <div className="modal-container">
        <h5 className="headline">
          {createdPartnerName} has been successfuly created. You can add{" "}
          {programsLabel} for this {partnerLabel} in the admin tab.
        </h5>
        <AlignButton
          type="button"
          label="Close"
          onClick={() => {
            closeDialog();
          }}
          alignRight={true}
          style={{
            margin: "auto",
            marginTop: "0.625rem",
            background: colors.formButtonBlue,
            color: "white",
            width: "fit-content",
            textAlign: "center",
            paddingLeft: "0.938rem",
            paddingRight: "0.938rem",
          }}
        />
      </div>
    </ModalStyles>
  );
};

export default PartnerCreationModal;
