import React, { useEffect, useRef, useState } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";

import { isProduction } from "~/api/ApiPaths";
import App from "~/App";
import { DialogContext } from "~/components/AlignDialog";
import RequireProtectedPath from "~/components/RequireProtectedPath";
import ProgramEditorProvider from "~/context/ProgramEditorProvider";
import {
  Provider as MobXProvider,
  alignEditorStore,
} from "~/models/AlignEditor";
import {
  Provider as RootProvider,
  rootStore,
  initPersistence,
} from "~/models/Root";
import { PERMISSION } from "~/models/UserManager";
import AdminRoute from "~/routes/AdminRoute";
import DashboardRoute from "~/routes/DashboardRoute";
import DataGridEditorRoute from "~/routes/DataGridEditorRoute";
import ExplorerRoute from "~/routes/ExplorerRoute";
import ListManagerRoute from "~/routes/ListManagerRoute";
import LoginRoute from "~/routes/LoginRoute";
import RegisterRoute from "~/routes/RegisterRoute";
import ReportRoute from "~/routes/ReportRoute";
import ResetPasswordRoute from "~/routes/ResetPasswordRoute";

const Gate = () => {
  const [isStateLoaded, setIsStateLoaded] = useState(false);
  const initOnce = useRef(true);
  const [isOpen, setIsOpen] = useState(false);
  const [dialogContent, setDialogContent] = useState(<></>);
  const [isDialogModal, setIsDialogModal] = useState(false);

  useEffect(() => {
    if (initOnce.current) {
      initOnce.current = false;
      initPersistence().then(() => {
        setIsStateLoaded(true);
      });
    }
  }, []);

  useEffect(() => {
    if (isOpen === false) {
      //console.log('dialog closed and resetting its state.')
      setDialogContent(<></>);
      setIsDialogModal(false);
    }
  }, [isOpen]);

  if (!isStateLoaded) {
    return null;
  }

  const openDialog = (content?: JSX.Element) => {
    if (content !== undefined) {
      setDialogContent(content);
    }
    setIsOpen(true);
  };

  const closeDialog = () => {
    setIsOpen(false);
  };

  return (
    <RootProvider value={rootStore}>
      <MobXProvider value={alignEditorStore}>
        <DialogContext.Provider
          value={{
            isOpen: isOpen,
            dialogContent: dialogContent,
            setDialogContent: setDialogContent,
            openDialog: openDialog,
            closeDialog: closeDialog,
            setIsDialogModal: setIsDialogModal,
            isDialogModal: isDialogModal,
          }}
        >
          <BrowserRouter>
            <Routes>
              <Route path="/" element={<App />}>
                <Route
                  element={
                    <RequireProtectedPath
                      allowedRoles={[
                        PERMISSION.MANAGE_PROGRAMS,
                        PERMISSION.MANAGE_CURRICULUM,
                        PERMISSION.MANAGE_CURRICULUM_MAPPINGS,
                        PERMISSION.VIEW_PROGRAM_DETAILS,
                      ]}
                    />
                  }
                >
                  <Route
                    path="/programs"
                    element={
                      <ProgramEditorProvider>
                        <DataGridEditorRoute />
                      </ProgramEditorProvider>
                    }
                  />
                </Route>

                {!isProduction() && (
                  <Route path="/explorer" element={<ExplorerRoute />} />
                )}

                <Route
                  element={
                    <RequireProtectedPath
                      allowedRoles={[
                        PERMISSION.MANAGE_GLOBAL_LISTS,
                        PERMISSION.MANAGE_PARTNER_LISTS,
                        PERMISSION.MANAGE_PROGRAM_LISTS,
                        PERMISSION.VIEW_LISTS,
                      ]}
                    />
                  }
                >
                  <Route path="/lists" element={<ListManagerRoute />} />
                </Route>
                <Route path="/reports" element={<ReportRoute />} />
                <Route path="/dashboard" element={<DashboardRoute />} />
                <Route
                  element={
                    <RequireProtectedPath
                      allowedRoles={[
                        PERMISSION.MANAGE_USERS,
                        PERMISSION.MANAGE_PARTNERS,
                        PERMISSION.MANAGE_PROGRAMS,
                      ]}
                    />
                  }
                >
                  <Route path="/admin" element={<AdminRoute />} />
                </Route>
              </Route>
              <Route path="/login" element={<LoginRoute />} />
              <Route path="/register" element={<RegisterRoute />} />
              <Route path="/forgotpassword" element={<ResetPasswordRoute />} />
              <Route path="*" element={<Navigate to="/login" replace />} />
            </Routes>
          </BrowserRouter>
        </DialogContext.Provider>
      </MobXProvider>
    </RootProvider>
  );
};

export default Gate;
