import React from "react";

export const NavbarLogo = () => {
  return (
    <svg
      role="img"
      width="165"
      height="37"
      viewBox="0 0 165 37"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>iDesign 'Align' Word Logo</title>
      <path
        d="M74.9789 33.0155H70.2737C69.7486 33.0155 69.32 32.8934 68.9928 32.6525C68.6623 32.4099 68.4178 32.0987 68.256 31.7206L66.2779 26.2863H54.607L52.6272 31.7206C52.4929 32.0468 52.2571 32.3446 51.9213 32.614C51.5856 32.8834 51.1604 33.0172 50.6491 33.0172H45.9043L57.353 4.54431H63.5508L74.9789 33.0172V33.0155ZM64.8007 22.2039L61.6312 13.4904C61.4419 13.032 61.2439 12.4966 61.0356 11.8809C60.8272 11.2668 60.6275 10.5993 60.4399 9.87984C60.2505 10.6127 60.0525 11.2886 59.8442 11.9093C59.6359 12.5317 59.4362 13.0705 59.2468 13.5289L56.098 22.2023H64.8007V22.2039Z"
        fill="#002E40"
      />
      <path
        d="M95.0704 28.2273V33.0157H77.6855V4.54285H83.7439V28.2273H95.0721H95.0704Z"
        fill="#002E40"
      />
      <path
        d="M124.315 18.5134H134.371V30.2687C132.879 31.3278 131.284 32.1024 129.597 32.5943C127.907 33.0845 126.12 33.3305 124.234 33.3305C121.824 33.3305 119.645 32.9674 117.694 32.2413C115.742 31.5151 114.072 30.5046 112.688 29.2096C111.3 27.9146 110.231 26.377 109.477 24.5985C108.723 22.8183 108.346 20.8759 108.346 18.7711C108.346 16.6663 108.706 14.6837 109.427 12.9035C110.147 11.125 111.174 9.59411 112.505 8.31252C113.838 7.02924 115.449 6.0304 117.341 5.31096C119.233 4.59153 121.349 4.23181 123.692 4.23181C124.902 4.23181 126.034 4.32718 127.084 4.51624C128.134 4.7053 129.103 4.96798 129.992 5.30093C130.88 5.63387 131.694 6.0304 132.435 6.48716C133.175 6.94559 133.848 7.44919 134.454 7.99797L132.696 10.6264C132.426 11.0447 132.076 11.3007 131.648 11.391C131.217 11.483 130.744 11.3776 130.233 11.0782C129.749 10.7887 129.274 10.5311 128.809 10.3035C128.344 10.0743 127.85 9.87519 127.326 9.70454C126.801 9.53388 126.228 9.40505 125.61 9.31303C124.99 9.22268 124.278 9.17584 123.47 9.17584C122.11 9.17584 120.877 9.40505 119.775 9.86181C118.672 10.3202 117.732 10.9677 116.959 11.8043C116.184 12.6408 115.589 13.6497 115.172 14.8259C114.754 16.0038 114.547 17.3188 114.547 18.7711C114.547 20.3405 114.776 21.7442 115.234 22.979C115.692 24.2154 116.337 25.2594 117.171 26.1093C118.006 26.9609 119.006 27.6084 120.17 28.0518C121.335 28.4969 122.63 28.7194 124.057 28.7194C125.026 28.7194 125.891 28.624 126.652 28.435C127.411 28.2459 128.156 27.9882 128.883 27.6603V22.8719H125.572C125.181 22.8719 124.875 22.7715 124.653 22.5674C124.431 22.3633 124.321 22.1056 124.321 21.7927V18.5151L124.315 18.5134Z"
        fill="#002E40"
      />
      <path
        d="M164.228 4.54285V33.0157H161.098C160.628 33.0157 160.23 32.9405 159.907 32.7899C159.583 32.641 159.268 32.3816 158.958 32.0152L144.36 13.9608C144.402 14.4192 144.431 14.8709 144.452 15.3143C144.471 15.7593 144.483 16.1709 144.483 16.5507V33.0141H139.132V4.54285H142.322C142.577 4.54285 142.797 4.55121 142.978 4.57296C143.159 4.59137 143.324 4.63821 143.472 4.71016C143.62 4.78377 143.761 4.87747 143.897 4.99458C144.032 5.1117 144.18 5.26897 144.341 5.4664L159.041 23.5978C158.987 23.1009 158.946 22.6207 158.92 22.1556C158.893 21.6922 158.879 21.2505 158.879 20.8305V4.54452H164.23L164.228 4.54285Z"
        fill="#002E40"
      />
      <path
        d="M101.772 9.95548C103.413 9.95548 104.744 8.70453 104.744 7.1614C104.744 5.61827 103.413 4.36731 101.772 4.36731C100.131 4.36731 98.8008 5.61827 98.8008 7.1614C98.8008 8.70453 100.131 9.95548 101.772 9.95548Z"
        fill="#002E40"
      />
      <path
        d="M99.0332 19.5106C98.9505 13.8003 104.313 13.6832 104.659 13.6815H104.678V32.8837H99.0332V19.8687C99.0332 19.7566 99.0349 19.6394 99.0332 19.5089V19.5106Z"
        fill="#002E40"
      />
      <path
        d="M32.5084 20.2953C32.8475 20.2953 33.123 20.563 33.123 20.8926C33.123 21.2222 32.8475 21.4899 32.5084 21.4899H26.4053C26.4862 21.3042 26.531 21.1017 26.531 20.8926C26.531 20.6834 26.4879 20.481 26.4053 20.2953H32.5084Z"
        fill="#002E40"
      />
      <path
        d="M11.8299 15.5839C11.749 15.7696 11.7043 15.972 11.7043 16.1812C11.7043 16.3903 11.7473 16.5927 11.8299 16.7785H9.36803C9.02888 16.7785 8.75342 16.5108 8.75342 16.1812C8.75342 15.8516 9.02888 15.5839 9.36803 15.5839H11.8299Z"
        fill="#002E40"
      />
      <path
        d="M11.8289 25.0034C11.7481 25.1889 11.7034 25.3911 11.7034 25.6C11.7034 25.8089 11.7464 26.0111 11.8289 26.1966H11.3234C10.9846 26.1966 10.7095 25.9292 10.7095 25.6C10.7095 25.2708 10.9846 25.0034 11.3234 25.0034H11.8289Z"
        fill="#002E40"
      />
      <path
        d="M19.1073 0.61792C9.02213 0.61792 0.846191 8.5635 0.846191 18.3645C0.846191 28.1656 9.02213 36.1112 19.1073 36.1112C29.1925 36.1112 37.3685 28.1656 37.3685 18.3645C37.3685 8.5635 29.1925 0.61792 19.1073 0.61792ZM19.1039 5.61047C19.765 5.61047 20.3073 6.13582 20.3125 6.78499C20.3125 7.43248 19.7719 7.95951 19.1039 7.95951C18.4376 7.96453 17.8953 7.4375 17.8953 6.78499C17.8902 6.13749 18.4307 5.61549 19.1039 5.61047ZM20.3159 11.4797C20.3159 12.1272 19.7753 12.6542 19.1073 12.6542C18.4394 12.6542 17.8988 12.1322 17.8988 11.4797C17.8936 10.8372 18.4359 10.3052 19.1022 10.3052C19.7684 10.3052 20.3107 10.8306 20.3142 11.4797H20.3159ZM8.1355 16.1795C8.1355 15.5203 8.68642 14.9849 9.36473 14.9849H24.9539C25.6323 14.9849 26.1832 15.5203 26.1832 16.1795C26.1832 16.8387 25.6323 17.3741 24.9539 17.3741H9.36473C8.68642 17.3741 8.1355 16.8387 8.1355 16.1795ZM19.1263 31.4332C18.46 31.4332 17.9177 30.9112 17.9177 30.2587C17.9177 29.6061 18.4583 29.0892 19.1211 29.0841C19.7925 29.0841 20.3331 29.6095 20.3331 30.2587C20.3331 30.9078 19.7925 31.4332 19.1246 31.4332H19.1263ZM24.9539 26.7953H11.3205C10.6422 26.7953 10.0913 26.2599 10.0913 25.6007C10.0913 24.9415 10.6422 24.4061 11.3205 24.4061H18.9317C18.9937 24.3961 19.0574 24.3894 19.1228 24.3894C19.1883 24.3894 19.2502 24.3961 19.3122 24.4061H22.9982H24.9539C25.6323 24.4061 26.1832 24.9415 26.1832 25.6007C26.1832 26.2599 25.6323 26.7953 24.9539 26.7953ZM32.5049 22.0855H13.2762C12.5979 22.0855 12.047 21.5501 12.047 20.8909C12.047 20.2317 12.5979 19.6963 13.2762 19.6963H19.0798C19.0918 19.6963 19.1056 19.6947 19.1177 19.6947C19.1297 19.6947 19.1435 19.6947 19.1555 19.6963H32.5049C33.1832 19.6963 33.7342 20.2317 33.7342 20.8909C33.7342 21.5501 33.1832 22.0855 32.5049 22.0855Z"
        fill="#002E40"
      />
      <path
        d="M19.1192 19.6963C19.1072 19.6963 19.0935 19.6963 19.0815 19.6974H19.1569C19.1449 19.6974 19.1312 19.6963 19.1192 19.6963Z"
        fill="#002E40"
      />
      <path
        d="M19.1246 24.3911C19.0592 24.3911 18.9955 24.3983 18.9336 24.409H19.3155C19.2536 24.3983 19.1899 24.3911 19.1263 24.3911H19.1246Z"
        fill="#002E40"
      />
    </svg>
  );
};
