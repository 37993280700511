import React from "react";

import { Calendar } from "lucide-react";
import { observer } from "mobx-react-lite";

import { NodeCoverage } from "~/components/explorer/hooks/useNodeCoverage";
import CoverageDetails from "~/components/explorer/node/hover-details/CoverageDetails";
import { IModule, NodeTypes } from "~/models/AlignEditor";

type NodeModuleDetailsProps = {
  module: IModule;
  coverage: NodeCoverage;
};

const NodeModuleDetails = observer(
  ({ module, coverage }: NodeModuleDetailsProps) => {
    // const { mutation } = useEditCurriculumItem();
    // const { getProgram, saveModuleChanges } = useStore();

    // const handleEditModule = (
    //   moduleUpdates: ModuleEditable,
    //   moduleOriginal: IModule,
    // ) => {
    //   const module = {
    //     ...moduleOriginal,
    //     ...moduleUpdates,
    //   };
    //   saveModuleChanges(module, module.course_id);
    //   mutation.mutate(getProgram()?.getCurriculumNodesForDatabasePost());
    // };

    const mappings = [...(module.mappings ?? [])];

    return (
      <div className="flex justify-between space-x-4 w-full">
        <div className="space-y-1 w-full">
          <div className="flex justify-between w-full">
            <h4 className="self-center font-bold">{module.display_name}</h4>

            {/* <ModuleDialog
              module={module}
              onSubmit={(data) => handleEditModule(data, module)}
            >
              <Button size="xs">
                <div className="flex gap-1 items-center">
                  <EditIcon size="15" />
                  Edit
                </div>
              </Button>
            </ModuleDialog> */}
          </div>

          <p className="text-sm">{module.description}</p>

          <div className="flex items-center pt-1">
            <Calendar className="mr-2 h-4 w-4 opacity-70" />{" "}
            <span className="text-sm text-muted-foreground">
              Week {module.week_number}
            </span>
          </div>

          <CoverageDetails
            id={module.id}
            coverage={coverage}
            mappings={mappings}
            type={NodeTypes.Module}
          />
        </div>
      </div>
    );
  },
);

export { NodeModuleDetails };
