import React from "react";

import CourseActivityTypeDetail from "~/components/Reports/CourseActivityTypeDetail";
import * as ReportModels from "~/components/Reports/ReportModels";
import { Course, List } from "~/components/Reports/ReportModels";
import {
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TableHeader,
  TableRow,
} from "~/components/ui/table";
import useOverrides from "~/hooks/useOverrides";

class ActivitySummary {
  activity_type = "";
  frequency = 0;
  avg_time_allotted = 0;
  avg_time_allotted_display = "";
  tot_minutes_allotted = 0;
  tot_minutes_allotted_display = "";
  modality = "";
  grading_stakes = "";
  mapping_count = 0;
  lo_percent = "";
}

const CourseActivityDetails: React.FC<{
  course: Course;
  list: List;
  expandable: boolean | true;
}> = ({ course, list, expandable }) => {
  const courseMinutes = ReportModels.getTotalTotMinutes(course);
  const courseMinutesDisplay = ReportModels.formatMinutes(courseMinutes);
  const modulesCount = course.modules?.length | 0;
  const activities = course.modules?.flatMap((x) => x.activities) || [];
  const mappings = activities.flatMap((a) =>
    a.mappings.filter((m) => m.list_id === list.id),
  );
  const { CourseLabel, ActivityLabel, ModulesLabel, ActivitiesLabel } =
    useOverrides();
  const courseLabel = CourseLabel();
  const activityLabel = ActivityLabel();
  const modulesLabel = ModulesLabel();
  const activitiesLabel = ActivitiesLabel();

  const distinct_activityTypes = Array.from(
    new Set(activities.map((a) => a.activity_type)),
  );
  const activitySummaries: ActivitySummary[] = distinct_activityTypes.map(
    (activity_type) => {
      const activitiesOfType = activities.filter(
        (a) => a.activity_type === activity_type,
      );
      const frequency = activitiesOfType.length;
      const tot_minutes = activitiesOfType.reduce(
        (acc, curr) => acc + curr.tot_minutes,
        0,
      );
      const avg_time_allotted = tot_minutes / frequency;
      const modality = activitiesOfType[0].modality; // assuming there is only one modality per activity_type
      const grading_stakes = activitiesOfType[0].grading_stakes; // assuming there is only one grading_stakes per activity_type
      const mapping_count = activitiesOfType.flatMap((a) =>
        a.mappings.filter((m) => m.list_id === list.id),
      ).length;
      const lo_percent =
        Math.round((mapping_count / mappings.length) * 100).toFixed(1) + "%";

      return {
        activity_type: activity_type,
        frequency: frequency,
        avg_time_allotted: avg_time_allotted,
        avg_time_allotted_display:
          ReportModels.formatMinutes(avg_time_allotted),
        tot_minutes_allotted: tot_minutes,
        tot_minutes_allotted_display: ReportModels.formatMinutes(tot_minutes),
        modality: modality,
        grading_stakes: grading_stakes,
        mapping_count: mapping_count,
        lo_percent: lo_percent,
      };
    },
  );

  return (
    <>
      {course === null || list === null ? (
        <p>No {courseLabel?.toLowerCase()} data</p>
      ) : (
        <div>
          <h5 className="font-bold">Summary of {activitiesLabel}</h5>
          <div className="mb-2">
            There are {mappings.length} learning objectives in this{" "}
            {courseLabel?.toLowerCase()}. The {courseLabel?.toLowerCase()} is
            designed as {modulesCount} {modulesLabel?.toLowerCase()}. The
            allotted time for a student to complete this{" "}
            {courseLabel?.toLowerCase()} is {courseMinutesDisplay}.
          </div>
          <div className="h-fit border border-idesign-navy-120 rounded-lg">
            <Table className="bg-white overflow-hidden rounded-lg border-collapse border-spacing-0">
              <TableHeader>
                <TableRow className="bg-gradient-1 border-b border-idesign-navy-120">
                  <TableHead className="text-white font-bold">
                    {activityLabel} Type
                  </TableHead>
                  <TableHead className="center text-white font-bold border-x border-idesign-navy-120">
                    Frequency
                  </TableHead>
                  <TableHead className="center text-white font-bold">
                    LOs Covered
                  </TableHead>
                  <TableHead className="center text-white font-bold border-x border-idesign-navy-120">
                    Avg Time Allotted
                  </TableHead>
                  <TableHead className="center text-white font-bold ">
                    Total Time Allotted
                  </TableHead>
                  <TableHead className="center text-white font-bold border-x border-idesign-navy-120">
                    Modality
                  </TableHead>
                  <TableHead className="center text-white font-bold">
                    Grading Stakes
                  </TableHead>
                </TableRow>
              </TableHeader>
              <TableBody>
                {activitySummaries.map((item) => (
                  <TableRow
                    key={item.activity_type}
                    className="border-y border-idesign-navy-120 hover:bg-ocean-10"
                  >
                    <TableCell>{item.activity_type}</TableCell>
                    <TableCell className="px-2 py-4 center border-x border-idesign-navy-120">
                      {item.frequency}
                    </TableCell>
                    <TableCell className="center">{item.lo_percent}</TableCell>
                    <TableCell className="center border-x border-idesign-navy-120">
                      {item.avg_time_allotted_display}
                    </TableCell>
                    <TableCell className="center">
                      {item.tot_minutes_allotted_display}
                    </TableCell>
                    <TableCell className="center border-x border-idesign-navy-120">
                      {item.modality}
                    </TableCell>
                    <TableCell className="center">
                      {item.grading_stakes}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
              <TableFooter className="border-t-8 border-ocean-120 hover:bg-ocean-10">
                <TableRow>
                  <TableCell className="pl-5 py-2">TOTAL</TableCell>
                  <TableCell className="center border-x border-idesign-navy-120">
                    {activities.length}
                  </TableCell>
                  <TableCell className="center border-x border-idesign-navy-120">
                    &nbsp;
                  </TableCell>
                  <TableCell>&nbsp;</TableCell>
                  <TableCell className="center border-x border-idesign-navy-120">
                    {courseMinutesDisplay}
                  </TableCell>
                  <TableCell className="center border-x border-idesign-navy-120">
                    &nbsp;
                  </TableCell>
                  <TableCell>&nbsp;</TableCell>
                </TableRow>
              </TableFooter>
            </Table>
          </div>
          <div className="mt-8">
            <h5 className="font-bold">
              List of {courseLabel} {activitiesLabel}
            </h5>
            {distinct_activityTypes.map((activity_type) => (
              <div key={activity_type} className="mb-4">
                <CourseActivityTypeDetail
                  course={course}
                  list_id={list.id}
                  activity_type={activity_type}
                  key={activity_type}
                />
              </div>
            ))}
          </div>
        </div>
      )}
    </>
  );
};

export default CourseActivityDetails;
