import { useEffect, useState } from "react";
import { API_PATHS, ApiUrls } from "~/api/ApiPaths";
import useAxios from "~/hooks/useAxios";
import { useStore as useRootStore } from "~/models/Root";

const enum LOG_TYPES {
  PAGE_VIEW = "Page View",
  CONTENT_VIEW = "Content View",
  SESSION_EXTENSION = "Session Extension",
}

const useLogSessionActivity = () => {
  const {
    getAppUser,
    setSessionExpirationDialog,
    getSessionExpirationTimer,
    setSessionExpirationTimer,
  } = useRootStore();
  const [startFetch, setStartFetch] = useState(false);
  let sessionTimer = getSessionExpirationTimer();
  const appUser = getAppUser();
  const { authToken, requestUrl, fetch, payload, data } = useAxios({
    method: "POST",
    initialValue: null,
  });

  const logPageView = (relativePath: string) => {
    logActivity(LOG_TYPES.PAGE_VIEW, relativePath);
  };

  const logContentView = (relativePath: string, details: string) => {
    logActivity(LOG_TYPES.CONTENT_VIEW, relativePath, details);
  };

  const logSessionExtension = () => {
    logActivity(LOG_TYPES.SESSION_EXTENSION, "");
  };

  const logActivity = (
    activityType: string,
    relativePath: string,
    details: string | null = null,
  ) => {
    setSessionTimer();
    requestUrl.current = `${API_PATHS.LOG_SESSION_ACTIVITY}`;
    authToken.current = appUser.authtoken;
    payload.current = {
      activityType: activityType,
      relativeUri: relativePath,
      details: details ? { content: details } : null,
    };
    fetch();
  };

  const setSessionTimer = () => {
    if (sessionTimer) {
      // console.log("CLEAR");
      clearTimeout(sessionTimer);
      setSessionExpirationTimer(null);
    }
    // console.log("Setting Frontend Timer");
    sessionTimer = window.setTimeout(
      () => {
        // console.log("Frontend Timer Expired");
        setStartFetch(true);
        checkSessionExpiration();
      },
      45 * 60 * 1000,
    );
    setSessionExpirationTimer(sessionTimer);
  };

  const checkSessionExpiration = () => {
    requestUrl.current = ApiUrls.sessions.getSessionExpiration;
    authToken.current = appUser.authtoken;
    fetch("GET");
  };

  useEffect(() => {
    if (data) {
      if (data.issuccess && data.message === "Session Details" && startFetch) {
        // console.log("Retrieved Session Timer: ", data.entity);
        setStartFetch(false);
        const now = new Date().getTime();
        const limit = new Date(data.entity.last_activity);
        limit.setMinutes(limit.getMinutes() + 40);
        if (now > limit.getTime()) {
          setSessionExpirationDialog(true);
        }
      }
    }
  }, [data, startFetch, setSessionExpirationDialog]);

  return { logPageView, logContentView, logSessionExtension };
};

export default useLogSessionActivity;
