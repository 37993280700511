import React, { useRef } from "react";
import { useNavigate } from "react-router-dom";

import { API_PATHS } from "../api/ApiPaths";
import useAxios from "../hooks/useAxios";
import useLogSessionActivity from "../hooks/useLogSessionActivity";
import { useStore as useRootStore } from "../models/Root";
import { Button } from "./ui/button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from "./ui/dialog";

const InactivityDialog: React.FC = () => {
  const { logSessionExtension } = useLogSessionActivity();
  const navigate = useNavigate();

  const { authToken, requestUrl, fetch } = useAxios({
    method: "GET",
    initialValue: null,
  });
  const {
    getAuthToken,
    setAuthToken,
    resetAppUser,
    setLoggedIn,
    getSessionExpirationDialog,
    setSessionExpirationDialog,
  } = useRootStore();

  const toggleSessionExpirationDialog = () => {
    setSessionExpirationDialog(!getSessionExpirationDialog());
    if (logoutPressed.current) {
      logoutPressed.current = false;
    } else {
      logSessionExtension();
    }
  };
  const logoutPressed = useRef(false);
  const logout = () => {
    authToken.current = getAuthToken();
    requestUrl.current = API_PATHS.LOGOUT;
    fetch("POST");
    setAuthToken("");
    resetAppUser();
    setLoggedIn(false);
    navigate("/login", { replace: true });
  };

  return (
    <Dialog
      open={getSessionExpirationDialog()}
      onOpenChange={toggleSessionExpirationDialog}
    >
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Your Session Is Expiring</DialogTitle>
          <DialogDescription>
            Your session is about to expire due to inactivity. Would you like to
            continue?
          </DialogDescription>
          <div className="flex flex-row justify-center items-center pt-4">
            <Button
              className="mr-4"
              variant="blue"
              onClick={() => {
                toggleSessionExpirationDialog();
              }}
            >
              Continue Session
            </Button>
            <Button
              variant="grey"
              onClick={() => {
                logoutPressed.current = true;
                toggleSessionExpirationDialog();
                logout();
              }}
            >
              Log Out
            </Button>
          </div>
        </DialogHeader>
      </DialogContent>
    </Dialog>
  );
};

export default InactivityDialog;
