export interface IReportsProps {
  programData: ProgramData | null;
}
export interface ICourseProps {
  course: Course;
}

export class ProgramData {
  id = 0;
  agency_id = 0;
  partner_id = 0;
  display_name = "";
  code = "";
  name = "";
  total_credits = 0;
  total_tot_minutes = 0;
  mapped_list_ids: number[] = [];
  mapped_lists: List[] = [];
  courses: Course[] = [];
  mappings: MappingItem[] = [];

  //getMappingsCount(list_id: number, listitem_id: number): number {
  //    return this.courses.reduce((acc, curr) => acc + curr.getMappingsCount(list_id, listitem_id), 0)
  //        + this.modules.reduce((acc, curr) => acc + curr.getMappingsCount(list_id, listitem_id), 0)
  //        + this.activities.reduce((acc, curr) => acc + curr.getMappingsCount(list_id, listitem_id), 0);
  //}

  //getCourseMappingCountbyListId(course_id: number, list_id: number): number {
  //    const course = this.courses.find(x => x.id === course_id);
  //    if (course === undefined) return 0;
  //    const courseMappingsCount = this.mappings.filter(x => x.list_id === list_id && x.c_item_type === 'Course' && x.c_item_id === course_id).length;
  //    const moduleIds = course.modules.map(x => x.id);
  //    const moduleMappingsCount = this.mappings.filter(x => x.list_id === list_id && x.c_item_type === 'Module' && moduleIds.includes(x.c_item_id)).length;
  //    const activityIds = course.modules.map(x => x.activities.map(y => y.id)).flat();
  //    const activityMappingsCount = this.mappings.filter(x => x.list_id === list_id && x.c_item_type === 'Activity' && activityIds.includes(x.c_item_id)).length;

  //    return courseMappingsCount + moduleMappingsCount + activityMappingsCount;
  //}
}

export class CurriculumItemBase {
  program_id = 0;
  id = 0;
  code = "";
  name = "";
  display_name = "";
  description = "";
  display_order = 0;
  mappings: MappingItem[] = [];

  // getMappingsCount(list_id: number, listitem_id: number): number {
  //     return this.mappings?.reduce((acc, curr) =>
  //         curr.list_id === list_id && curr.listitem_id === listitem_id
  //             ? acc + 1
  //             : acc + 0
  //         , 0) || 0;
  // }
}

export class Course extends CurriculumItemBase {
  level = "";
  credits = 0;
  target_tot_minutes = 9000;
  modules: Module[] = [];

  // get tot_minutes(): number {
  //     return this.modules?.reduce((acc, curr) => acc + curr.activities.reduce((sum, item) => sum + item.tot_minutes, 0), 0) || 0;
  // }

  // get tot_minutes_display(): string {
  //     return getDisplayTimeFromMinutes(this.tot_minutes);
  // }

  /**
   * Returns an array of MappingItem objects that are associated with the specified list ID and all of its child items.
   * @param list_id The ID of the list to retrieve mappings for.
   * @returns An array of MappingItem objects that are associated with the specified list ID and all of its child items.
   */
  // getTreeMappingsByListId(list_id: number): MappingItem[] {

  //     if (list_id === undefined)
  //         return new Array<MappingItem>();

  //     const courseMappings = this.mappings.filter(x => x.list_id === list_id && x.c_item_type === 'Course' && x.c_item_id === this.id);
  //     const moduleMappings = this.modules?.map(x => x.mappings.filter(y => y.list_id === list_id && y.c_item_type === 'Module')).flat() || [];
  //     const activityMappings = this.modules?.map(x => x.activities.map(y => y.mappings.filter(z => z.list_id === list_id && z.c_item_type === 'Activity'))).flat().flat() || [];

  //     return courseMappings.concat(moduleMappings, activityMappings) || [];
  // }

  /**
   * Returns an array of MappingItem objects that are associated with the specified list ID, list item ID, and all of its child items.
   * @param list_id The ID of the list to retrieve mappings for.
   * @param listitem_id The ID of the list item to retrieve mappings for.
   * @returns An array of MappingItem objects that are associated with the specified list ID, list item ID, and all of its child items.
   */
  // getTreeMappingsByListItemId(list_id: number, listitem_id: number): MappingItem[] {

  //     if (list_id === undefined || listitem_id === undefined)
  //         return new Array<MappingItem>();

  //     const courseMappings = this.mappings.filter(x => x.list_id === list_id && x.listitem_id === listitem_id && x.c_item_type === 'Course' && x.c_item_id === this.id);
  //     const moduleMappings = this.modules?.map(x => x.mappings.filter(y => y.list_id === list_id && y.listitem_id === listitem_id && y.c_item_type === 'Module')).flat() || [];
  //     const activityMappings = this.modules?.map(x => x.activities.map(y => y.mappings.filter(z => z.list_id === list_id && z.listitem_id === listitem_id && z.c_item_type === 'Activity'))).flat().flat() || [];

  //     return courseMappings.concat(moduleMappings, activityMappings) || [];
  // }
}

export class Module extends CurriculumItemBase {
  course_id = 0;
  week_number = 0;
  activities: Activity[] = [];
}

export class Activity extends CurriculumItemBase {
  course_id = 0;
  module_id = 0;

  tot_minutes = 0;
  activity_type = "";
  is_assessment = false;
  modality = "";
  collaboration = "";
  engagement_types: string[] = [];
  experience_type = "";
  blooms = "";
  grading_points = 0;
  grading_stakes = "";
}

export class MappingItem {
  id = 0;
  list_id = 0;
  listitem_id = 0;
  list_name = "";
  listitem_display_name = "";
  c_item_type = "";
  c_item_id = 0;
}

export class List {
  id = 0;
  name = "";
  list_type = "";
  is_global_list = false;
  is_active = false;

  items: ListItem[] = [];
  items_are_visible = false;
}

export class ListItem {
  id = 0;
  list_id = 0;
  display_name = "";
  code = "";
  parent_id = 0;
  is_heading = false;
  is_mappable = false;
  mapping_restrictions: string[] = [];
}

export function formatMinutes(minutes: number): string {
  if (minutes < 60) {
    return `${Math.round(minutes)}m`;
  }
  const hours = Math.floor(minutes / 60);
  const mins = Math.round(minutes % 60);
  return `${hours}h ${mins}m`;
}

export function formatHours(hours: number): string {
  const wholeHours = Math.floor(hours);
  const minutes = Math.round((hours - wholeHours) * 60);
  return `${wholeHours}h ${minutes}m`;
}

export function getTotalTotMinutes(course: Course) {
  return (
    course.modules?.reduce(
      (acc, curr) =>
        acc + curr.activities.reduce((sum, item) => sum + item.tot_minutes, 0),
      0,
    ) || 0
  );
}

export function getTreeMappings(course: Course): MappingItem[] {
  if (course === undefined) return [];

  const courseMappings =
    course.mappings?.filter(
      (x) => x.c_item_type === "Course" && x.c_item_id === course.id,
    ) || [];
  const moduleMappings =
    course.modules
      ?.map((x) => x.mappings?.filter((y) => y.c_item_type === "Module"))
      .flat() || [];
  const activityMappings =
    course.modules
      ?.map((x) =>
        x.activities.map(
          (y) => y.mappings?.filter((z) => z.c_item_type === "Activity"),
        ),
      )
      .flat()
      .flat() || [];

  return courseMappings.concat(moduleMappings, activityMappings) || [];
}

export function getTreeMappingsByListId(
  course: Course,
  list_id: number,
): MappingItem[] {
  if (course === undefined || list_id === undefined) return [];

  const courseMappings =
    course.mappings?.filter(
      (x) =>
        x.list_id === list_id &&
        x.c_item_type === "Course" &&
        x.c_item_id === course.id,
    ) || [];
  const moduleMappings =
    course.modules
      ?.map(
        (x) =>
          x.mappings?.filter(
            (y) => y.list_id === list_id && y.c_item_type === "Module",
          ),
      )
      .flat() || [];
  const activityMappings =
    course.modules
      ?.map((x) =>
        x.activities.map(
          (y) =>
            y.mappings?.filter(
              (z) => z.list_id === list_id && z.c_item_type === "Activity",
            ),
        ),
      )
      .flat()
      .flat() || [];

  return courseMappings.concat(moduleMappings, activityMappings) || [];
}

export function getTreeMappingsByListItemId(
  course: Course,
  list_id: number,
  listitem_id: number,
): MappingItem[] {
  if (
    course === undefined ||
    list_id === undefined ||
    listitem_id === undefined
  )
    return [];

  const courseMappings =
    course.mappings?.filter(
      (x) =>
        x.list_id === list_id &&
        x.listitem_id === listitem_id &&
        x.c_item_type === "Course" &&
        x.c_item_id === course.id,
    ) || [];
  const moduleMappings =
    course.modules
      ?.map(
        (x) =>
          x.mappings?.filter(
            (y) =>
              y.list_id === list_id &&
              y.listitem_id === listitem_id &&
              y.c_item_type === "Module",
          ),
      )
      .flat() || [];
  const activityMappings =
    course.modules
      ?.map((x) =>
        x.activities.map(
          (y) =>
            y.mappings?.filter(
              (z) =>
                z.list_id === list_id &&
                z.listitem_id === listitem_id &&
                z.c_item_type === "Activity",
            ),
        ),
      )
      .flat()
      .flat() || [];

  return courseMappings.concat(moduleMappings, activityMappings) || [];
}
