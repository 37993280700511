import React from "react";

import { Search } from "lucide-react";

import { ColumnFilterWithHighlightingConfig } from "~/components/program/mapping/utils/filtersWithHighlighting";
import { Skeleton } from "~/components/ui/skeleton";
import { Input } from "~/components/ui-rework/input";
import { useMappingSheetContext } from "~/context/MappingSheetProvider";

export const MappingSearch = () => {
  const { filterColumn: column } = useMappingSheetContext();

  if (!column) return <Skeleton className="w-[13.25rem] h-10 rounded-[18px]" />;

  const filterValue = column.getFilterValue() as
    | ColumnFilterWithHighlightingConfig
    | undefined;
  return (
    <>
      <div>
        <DebouncedInput
          type="text"
          value={filterValue?.term ?? ""}
          onChange={(value) =>
            column.setFilterValue((updater: any) => {
              return {
                ...updater,
                term: value,
              } as ColumnFilterWithHighlightingConfig;
            })
          }
        />
      </div>
    </>
  );
};

type DebouncedInputProps = {
  value: string | number;
  onChange: (value: string | number) => void;
  debounce?: number;
} & Omit<React.InputHTMLAttributes<HTMLInputElement>, "onChange">;

const DebouncedInput = ({
  value: initialValue,
  onChange,
  debounce = 500,
  ...props
}: DebouncedInputProps) => {
  const [value, setValue] = React.useState(initialValue);

  React.useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  React.useEffect(() => {
    const timeout = setTimeout(() => {
      onChange(value);
    }, debounce);

    return () => clearTimeout(timeout);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  return (
    <Input
      {...props}
      value={value}
      className="max-w-[13.25rem] text-base"
      placeholder="Search mappings..."
      onChange={(e) => setValue(e.target.value)}
      icon={<Search />}
      iconPosition="left"
    />
  );
};
