import React from "react";
import { Handle, NodeProps, Position } from "reactflow";

import { useNodeCoverage } from "~/components/explorer/hooks/useNodeCoverage";
import { NodeActivityDetails } from "~/components/explorer/node/hover-details/NodeActivityDetails";
import NodeIcon from "~/components/explorer/node/NodeIcon";
import {
  HoverCard,
  HoverCardContent,
  HoverCardPortal,
  HoverCardTrigger,
} from "~/components/ui/hover-card";
import { Progress } from "~/components/ui/progress";
import { useExplorerContext } from "~/context/ExplorerProvider";
import { cn } from "~/lib/utils";
import { IActivity, NodeData, NodeTypes } from "~/models/AlignEditor";

const ActivitiesNode = ({ data }: NodeProps) => {
  const { activities } = data as NodeData;

  return (
    <div
      className="grid border-l-neutral-90 border-l"
      style={{
        gridAutoColumns: "203px",
        gridTemplateRows: "1fr 1fr",
        gridAutoFlow: "column",
        gridGap: "8px",
        paddingLeft: "8px",
      }}
    >
      <Handle
        type="target"
        position={Position.Left}
        isConnectable={false}
        className="!left-[1px] invisible"
      />
      {activities?.map((activity: IActivity) => {
        return (
          <div
            key={`${activity.id}-${activity.name}`}
            className={cn(
              "rounded rounded-bl-[12px] rounded-tr-[12px] rounded-br-[1px] rounded-tl-[1px] backdrop-blur-[1px]",
              "bg-explorer-node",
              "shadow-explorer-node",
              "w-[203px] h-[40px]",
            )}
          >
            <ActivityNode activity={activity} />
          </div>
        );
      })}
    </div>
  );
};

type ActivityNodeProps = {
  activity: IActivity;
};

const ActivityNode = ({ activity }: ActivityNodeProps) => {
  const { selectedList, boundaryContainer } = useExplorerContext();
  const coverage = useNodeCoverage([...(activity?.mappings ?? [])]);

  const showProgressBar = selectedList !== undefined;
  const value = (coverage.numerator / coverage.denominator) * 100;

  return (
    <HoverCard>
      <HoverCardTrigger asChild>
        <div
          className="h-full grid p-1.5 pt-[5px] gap-x-1.5 gap-y-1"
          style={{
            gridTemplateAreas: `
                      'icon name'
                      'icon progress'
                    `,
            gridTemplateRows: "1fr 10px",
            gridTemplateColumns: "29px 1fr",
          }}
        >
          <NodeIcon type={NodeTypes.Activity} />
          <h6
            className={cn("my-auto text-[8px] font-bold", {
              truncate: showProgressBar,
              "line-clamp-2 col-start-2 row-span-2": !showProgressBar,
            })}
            style={showProgressBar ? { gridArea: "name" } : undefined}
          >
            {activity.display_name}
          </h6>
          {showProgressBar && (
            <div className="flex items-center" style={{ gridArea: "progress" }}>
              <Progress
                className="h-1.5 shadow-explorer-node-progress"
                value={value}
              />
              <span className="text-[8px] leading-none w-6 text-end mb-px">
                {Math.round(value)}%
              </span>
            </div>
          )}
        </div>
      </HoverCardTrigger>
      <HoverCardPortal>
        <HoverCardContent
          avoidCollisions
          collisionBoundary={[boundaryContainer]}
          className="light bg-gradient-one border-ocean-70 text-foreground backdrop-blur-[5px] w-[360px] z-50 overflow-x-hidden overflow-y-auto hover-card-shadow"
          style={{
            maxHeight: "calc(var(--radix-popper-available-height) - 1rem)",
          }}
        >
          <NodeActivityDetails activity={activity} coverage={coverage} />
        </HoverCardContent>
      </HoverCardPortal>
    </HoverCard>
  );
};

export default ActivitiesNode;
