import React, { ReactNode } from "react";
import { useForm } from "react-hook-form";

import { Button } from "~/components/ui-rework/button";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogFooter,
  DialogTitle,
  DialogTrigger,
} from "~/components/ui-rework/dialog";
import { Input } from "~/components/ui-rework/input";
import { Label } from "~/components/ui-rework/label";
import { useProgramEditorContext } from "~/context/ProgramEditorProvider";
import { IModule, ModuleEditable } from "~/models/AlignEditor";

type ModuleDialogProps = {
  module?: IModule;
  children: ReactNode;
  onSubmit: (data: ModuleEditable) => void;
};

const ModuleDialog = ({ module, children, onSubmit }: ModuleDialogProps) => {
  const {
    register,
    formState: { errors, isDirty, isValid },
    handleSubmit,
    reset,
  } = useForm({
    mode: "onChange",
    defaultValues: {
      code: module?.code,
      name: module?.name,
      display_name: module?.display_name,
      week_number: module?.week_number ?? 0,
    },
  });

  const handleOpenChange = (open: boolean) => {
    if (open) {
      reset({
        code: module?.code,
        name: module?.name,
        display_name: module?.display_name,
        week_number: module?.week_number ?? 0,
      });
    }
  };

  const actionLabel = module ? "Edit" : "Add";
  const { moduleLabel } = useProgramEditorContext();
  const title = `${actionLabel} ${moduleLabel}`;

  return (
    <Dialog onOpenChange={handleOpenChange}>
      <DialogTrigger asChild>{children}</DialogTrigger>
      <DialogContent className="light">
        <form onSubmit={handleSubmit((data) => onSubmit(data))}>
          <DialogTitle className="mt-1">{title}</DialogTitle>
          <div className="grid gap-4 py-4">
            <div className="flex flex-col gap-2">
              <Label htmlFor="code" className="font-bold mb-0">
                Code
              </Label>
              <Input
                id="code"
                className="bg-transparent"
                placeholder={`${moduleLabel} Code`}
                {...register("code", { required: "Code is required" })}
                aria-describedby={
                  errors.code?.message ? "code-error" : undefined
                }
              />
              {errors.code?.message && (
                <span
                  id="code-error"
                  className="text-sm font-medium text-error-50"
                >
                  {errors.code.message}
                </span>
              )}
            </div>

            <div className="flex flex-col gap-2">
              <Label htmlFor="name" className="font-bold mb-0">
                Name
              </Label>
              <Input
                id="name"
                className="bg-transparent"
                placeholder={`${moduleLabel} Name`}
                {...register("name", { required: "Name is required" })}
                aria-describedby={
                  errors.name?.message ? "name-error" : undefined
                }
              />
              {errors.name?.message && (
                <span
                  id="name-error"
                  className="text-sm font-medium text-error-50"
                >
                  {errors.name.message}
                </span>
              )}
            </div>

            <div className="flex flex-col gap-2">
              <Label htmlFor="display_name" className="font-bold mb-0">
                Display Name
              </Label>
              <Input
                id="display_name"
                className="bg-transparent"
                placeholder={`${moduleLabel} Display Name`}
                {...register("display_name", {
                  required: "Display Name is required",
                })}
                aria-describedby={
                  errors.display_name?.message
                    ? "display_name-error"
                    : undefined
                }
              />
              {errors.display_name?.message && (
                <span
                  id="display_name-error"
                  className="text-sm font-medium text-error-50"
                >
                  {errors.display_name.message}
                </span>
              )}
            </div>

            <div className="flex flex-col gap-2">
              <Label htmlFor="week_number" className="font-bold mb-0">
                Week
              </Label>
              <Input
                id="week_number"
                className="bg-transparent"
                type="number"
                min="0"
                {...register("week_number", {
                  required: "Week is required",
                  min: 0,
                  valueAsNumber: true,
                })}
                aria-describedby={
                  errors.week_number?.message ? "week_number-error" : undefined
                }
              />
              {errors.week_number?.message && (
                <span
                  id="week_number-error"
                  className="text-sm font-medium text-error-50"
                >
                  {errors.week_number.message}
                </span>
              )}
            </div>
          </div>
          <DialogFooter>
            <DialogClose asChild>
              <Button
                type="submit"
                disabled={!isDirty || !isValid}
                className="font-normal"
              >
                {module ? "Save Changes" : title}
              </Button>
            </DialogClose>
          </DialogFooter>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default ModuleDialog;
