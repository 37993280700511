import React, { useEffect, useState } from "react";

import {
  Bar,
  BarChart,
  Cell,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from "recharts";

import * as ReportModels from "~/components/Reports/ReportModels";
import * as ToteModels from "~/components/Reports/ToteModels";
import ToteSummaryDetailsVisuals from "~/components/Reports/ToteSummaryDetailsVisuals";
import useOverrides from "~/hooks/useOverrides";
import { cn } from "~/lib/utils";

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../ui/table";

interface IModuleSummary {
  module_code: string;
  tot_minutes: number;
  tot_minutes_display: string;
  tot_percent: number;
  label: string;
}

const CustomizedLabel = (props: any) => {
  const { x, y, width, viewBox, value } = props;
  const middleY = y + viewBox.height / 2;
  if (value > 0) {
    return (
      <g>
        <text
          x={x + width + 5}
          y={middleY}
          fill="#002E40"
          textAnchor="start"
          dominantBaseline="middle"
          fontSize={12}
        >
          {ReportModels.formatHours(value)}
        </text>
      </g>
    );
  }
  return null;
};

function getModuleSummaries(
  course: ReportModels.Course,
  totalMinutes: number,
): IModuleSummary[] {
  const summaries: IModuleSummary[] = [];
  course.modules.forEach((module) => {
    const tot_minutes = module.activities.reduce(
      (a: number, b: ReportModels.Activity) => a + (b.tot_minutes || 0),
      0,
    );
    const percent = Math.round((tot_minutes / totalMinutes) * 100);
    summaries.push({
      module_code: module.code,
      tot_minutes: tot_minutes,
      tot_minutes_display: ReportModels.formatMinutes(tot_minutes),
      tot_percent: percent,
      label: `${module.code}`,
    });
  });

  return summaries;
}

function getUnitsByExpTypes(course: ReportModels.Course): IUnitByExpTypes[] {
  const result: IUnitByExpTypes[] = new Array<IUnitByExpTypes>();

  course.modules.forEach((module) => {
    result.push({
      unit: module.code,
      exp_types: activityExperienceTypes.map((expType) => {
        const tot_minutes = module.activities
          ?.filter((x) => x.experience_type === expType)
          .reduce(
            (a: number, b: ReportModels.Activity) => a + (b.tot_minutes || 0),
            0,
          );
        const tot_hours = tot_minutes / 60;
        if (tot_hours % 1 === 0) {
          return tot_hours.toString() + "h";
        }
        return tot_hours.toFixed(2) + "h";
      }),
    });
  });

  return result;
}

const activityExperienceTypes: string[] = [
  "Assimilative",
  "Finding and Handling Info",
  "Communication",
  "Productive",
  "Experiential",
  "Interactive/Adaptive",
];
interface IUnitByExpTypes {
  unit: string;
  exp_types: string[];
}

export class CompModel {
  constructor(course: ReportModels.Course) {
    const courseActivities = course?.modules?.flatMap((x) => x.activities);
    const activitySummaries =
      ToteModels.getActivityTypesSummary(courseActivities);
    const categorySummaries =
      ToteModels.getCategorySummaries(activitySummaries);
    const totalMinutes =
      courseActivities?.reduce(
        (a: number, b: ReportModels.Activity) => a + (b.tot_minutes || 0),
        0,
      ) || 0;
    const moduleSummary = getModuleSummaries(course, totalMinutes);

    this.totalMinutes = totalMinutes;
    this.totalMinutesDisplay = ReportModels.formatMinutes(totalMinutes);
    this.activitySummaries = activitySummaries;
    this.categorySummaries = categorySummaries;
    this.moduleSummaries = moduleSummary;
    this.unitHoursByExpType = getUnitsByExpTypes(course);
  }

  totalMinutes = 0;
  totalMinutesDisplay = "";
  activitySummaries: ToteModels.ITotSummary[] = [];
  categorySummaries: ToteModels.ITotSummary[] = [];
  moduleSummaries: IModuleSummary[] = new Array<IModuleSummary>();
  unitHoursByExpType: IUnitByExpTypes[] = new Array<IUnitByExpTypes>();
}

const ToteCourseDetails: React.FC<ReportModels.ICourseProps> = ({ course }) => {
  const [Model, setModel] = React.useState<CompModel | null>(null);
  const { ActivityLabel, ActivitiesLabel, ModuleLabel } = useOverrides();
  const activityLabel = ActivityLabel();
  const moduleLabel = ModuleLabel();
  const activitiesLabel = ActivitiesLabel();
  const [moduleBarReady, setModuleBarReady] = useState(false);
  const [moduleData, setModuleData] = useState<any>([]);
  const maxValue = Math.max(
    ...moduleData.map((x: { tot_hours: any }) => x.tot_hours),
  );

  const activityProfileKey = [
    {
      label: "Assimilative",
      value: "Read, Watch, Listen, Think about, Access, Observe, Review",
    },
    {
      label: "Finding and Handling Info",
      value:
        "List, Analyze, Collate, Plot, Find, Discover, Access, Use, Gather, Order, Classify, Select, Assess, Manipulate",
    },
    {
      label: "Communication",
      value:
        "Communicate, Debate, Discuss, Argue, Share, Report, Collaborate, Present, Describe, Question",
    },
    {
      label: "Productive",
      value:
        "List, Create, Build, Make, Design, Construct, Contribute, Complete, Produce, Write, Draw, Refine, Compose, Synthesize, Remix",
    },
    {
      label: "Experiential",
      value:
        "Practice, Apply, Mimic, Experience, Explore, Investigate, Perform, Engage",
    },
    {
      label: "Interactive/Adaptive",
      value: "Explore, Experiment, Trial, Improve, Model, Simulate",
    },
  ];

  useEffect(() => {
    if (course && Model === null) {
      const model = new CompModel(course);
      setModel(model);
    }
  }, [course, Model]);

  useEffect(() => {
    if (Model !== null && !moduleBarReady) {
      const moduleData = Model?.moduleSummaries.map((item) => {
        return {
          name: item.label,
          tot_minutes: item.tot_minutes,
          tot_minutes_display: item.tot_minutes_display,
          tot_percent: item.tot_percent,
          tot_hours: item.tot_minutes / 60,
          module_num: item.module_code.split(" ")[1],
        };
      });
      setModuleBarReady(true);
      setModuleData(moduleData);
    }
  }, [Model, moduleBarReady]);

  return (
    <div className="light">
      <div>
        <h4 className="font-bold mb-4">{activityLabel} Types & Categories</h4>
        <div className="flex-splitrow">
          <div className="splitrow-left w-full h-fit border border-idesign-navy-120 rounded-lg ">
            <Table className="bg-cerulean-10 w-full overflow-hidden rounded-lg border-collapse border-spacing-0">
              <TableHeader>
                <TableRow className="bg-gradient-1 border-b border-idesign-navy-120">
                  <TableHead className="p-3 text-white border-b border-idesign-navy-120">
                    Type / Category
                  </TableHead>
                  <TableHead className="p-3 text-white border border-t-0 border-idesign-navy-120 text-center">
                    Total Time
                  </TableHead>
                  <TableHead className="p-3 text-white border-b border-idesign-navy-120 text-center">
                    Percent
                  </TableHead>
                </TableRow>
              </TableHeader>
              <TableBody>
                {Model?.totalMinutes !== 0 ? (
                  Model?.categorySummaries.map((category) => {
                    return (
                      <React.Fragment key={category.category}>
                        <TableRow
                          key={category.category}
                          className="bg-white border-b border-idesign-navy-120"
                        >
                          <TableCell className="p-3 whitespace-nowrap font-bold">
                            {category.category}
                          </TableCell>
                          <TableCell className="border-x border-idesign-navy-120 p-3 font-bold text-center">
                            {category.tot_minutes_display}
                          </TableCell>
                          <TableCell className="p-3 font-bold text-center">
                            {category.tot_percent}%
                          </TableCell>
                        </TableRow>
                        {Model.activitySummaries
                          .filter((x) => x.category === category.category)
                          .map((activity) => {
                            return (
                              <TableRow
                                key={activity.type}
                                className="border-b border-idesign-navy-120"
                              >
                                <TableCell
                                  className="p-3 whitespace-nowrap"
                                  headers={"type-category " + category.category}
                                >
                                  {activity.type}
                                </TableCell>
                                <TableCell className="border-x border-idesign-navy-120 p-3 text-center">
                                  {activity.tot_minutes_display}
                                </TableCell>
                                <TableCell className="p-3 text-center">
                                  {activity.tot_percent}%
                                </TableCell>
                              </TableRow>
                            );
                          })}
                      </React.Fragment>
                    );
                  })
                ) : (
                  <TableRow>
                    <TableCell className="p-3" colSpan={3}>
                      No data available.
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </div>

          {/*Activity Types Chart*/}
          <div className="mr-10">
            {Model?.totalMinutes !== 0 && (
              <ToteSummaryDetailsVisuals data={Model} />
            )}
          </div>
        </div>
      </div>

      <div className="mt-6">
        <h4 className="font-bold mb-4">
          {moduleLabel} Summary - {course.code}
        </h4>
        <div className="flex-splitrow">
          <div className="splitrow-left w-full h-fit border border-idesign-navy-120 rounded-lg ">
            <Table className="bg-cerulean-10 w-full overflow-hidden rounded-lg border-collapse border-spacing-0">
              <TableHeader>
                <TableRow className="bg-gradient-1 border-b border-idesign-navy-120">
                  <TableHead className="p-3 text-white border-b border-idesign-navy-120">
                    {moduleLabel}
                  </TableHead>
                  <TableHead className="p-3 text-white border border-t-0 border-idesign-navy-120 text-center">
                    Total Time
                  </TableHead>
                  <TableHead className="p-3 text-white border-b border-idesign-navy-120 text-center">
                    Percent
                  </TableHead>
                </TableRow>
              </TableHeader>
              <TableBody>
                {Model?.moduleSummaries.length !== 0 ? (
                  Model?.moduleSummaries.map((item) => {
                    return (
                      <TableRow
                        key={item.module_code}
                        className=" bg-white border-b border-idesign-navy-120"
                      >
                        {/* going to split this here in case module is overriden.  */}
                        <TableCell className="p-3 whitespace-nowrap">{`${moduleLabel} ${
                          item.module_code.split(" ")[1]
                        }`}</TableCell>
                        <TableCell className="border-x border-idesign-navy-120 p-3 text-center">
                          {item.tot_minutes_display}
                        </TableCell>
                        <TableCell className="p-3 text-center">
                          {item.tot_percent}%
                        </TableCell>
                      </TableRow>
                    );
                  })
                ) : (
                  <TableRow>
                    <TableCell colSpan={3}>
                      No {activitiesLabel} found.
                    </TableCell>
                  </TableRow>
                )}
                <TableRow className="!border-t-8 border-ocean-120 font-bold">
                  <TableCell className="p-3">TOTAL</TableCell>
                  <TableCell className="border-x border-idesign-navy-120 p-3 text-center">
                    {Model?.totalMinutesDisplay}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </div>

          {/*Module Summary Chart*/}
          {Model?.moduleSummaries.length !== 0 && moduleBarReady && (
            <ResponsiveContainer width="100%" height={575}>
              <BarChart
                width={575}
                height={600}
                data={moduleData}
                layout="vertical"
                margin={{ bottom: 20, right: 80 }}
                maxBarSize={100}
              >
                <XAxis
                  type="number"
                  fontSize={12}
                  stroke="#002E40"
                  label={{
                    value: "Hours",
                    angle: 0,
                    position: "bottom",
                    offset: 0,
                    fontSize: 12,
                    fontWeight: 700,
                    fill: "#002E40",
                  }}
                />
                <YAxis
                  type="category"
                  dataKey="module_num"
                  tickLine={false}
                  interval={0}
                  fontSize={12}
                  stroke="#002E40"
                  label={{
                    value: "Module",
                    angle: -90,
                    position: "insideLeft",
                    offset: 25,
                    fontSize: 12,
                    fontWeight: 700,
                    fill: "#002E40",
                  }}
                />
                <defs>
                  <linearGradient
                    id="bar-gradient"
                    x1="0%"
                    y1="0%"
                    x2="100%"
                    y2="0%"
                  >
                    <stop offset="0%" stopColor="hsla(196,100%,10%,0.95)" />
                    <stop offset="100%" stopColor="hsla(197,52%,21%,0.95)" />
                  </linearGradient>
                </defs>
                <Bar dataKey="tot_hours" label={<CustomizedLabel/>}>
                  {moduleData.map((index: any) => (
                    <Cell key={`cell-${index}`} fill="url(#bar-gradient" />
                  ))}
                </Bar>
              </BarChart>
            </ResponsiveContainer>
          )}
        </div>
      </div>

      <div className="mt-6">
        <h4 className="font-bold mb-2">
          {activityLabel} Profiles - {course.code}
        </h4>
        <h4 className="mb-6">
          **{activityLabel} profiles key displayed below shows definitions for
          these categories.
        </h4>
        <div className="border border-idesign-navy-120 rounded-lg mr-10">
          <Table className="bg-cerulean-10 w-full overflow-hidden rounded-lg border-collapse border-spacing-0">
            <TableHeader>
              <TableRow className="bg-gradient-1 border-b border-idesign-navy-120">
                <TableHead className="p-3 text-white border-b border-idesign-navy-120">
                  {moduleLabel}
                </TableHead>
                {activityExperienceTypes.map((type) => (
                  <TableHead
                    className="p-3 text-white border border-t-0 border-idesign-navy-120 text-center"
                    key={type}
                  >
                    {type}
                  </TableHead>
                ))}
              </TableRow>
            </TableHeader>
            <TableBody>
              {Model?.unitHoursByExpType.length !== 0 ? (
                Model?.unitHoursByExpType.map((item, idx) => {
                  return (
                    <TableRow
                      key={idx}
                      className=" bg-white border-b border-idesign-navy-120"
                    >
                      <TableCell className="p-3 whitespace-nowrap">
                        {item.unit}
                      </TableCell>
                      {item.exp_types.map((expType, index) => (
                        <TableCell
                          className={cn(
                            "border-x border-idesign-navy-120 p-3 font-bold text-center",
                            index === item.exp_types.length - 1 && "border-r-0",
                          )}
                          key={index}
                        >
                          {expType}
                        </TableCell>
                      ))}
                    </TableRow>
                  );
                })
              ) : (
                <TableRow>
                  <TableCell colSpan={activityExperienceTypes.length + 1}>
                    No {activitiesLabel} found.
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </div>
        <h4 className="my-4">**{activityLabel} Profiles Key/Legend</h4>
        <div className="w-5/6 border border-idesign-navy-120 rounded-lg ">
          <Table className="bg-white w-full overflow-hidden rounded-lg border-collapse border-spacing-0">
            <TableHeader>
              <TableRow className="bg-gradient-1 border-b border-idesign-navy-120">
                <TableHead className="p-3 text-white border-b border-idesign-navy-120">
                  Category
                </TableHead>
                <TableHead className="p-3 text-white border border-t-0 border-idesign-navy-120">
                  Process Outcomes (learners will..)
                </TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {activityProfileKey.map((item, idx) => {
                return (
                  <TableRow
                    className="bg-white border-b border-idesign-navy-120"
                    key={idx + item.label}
                  >
                    <TableCell>{item.label}</TableCell>
                    <TableCell className="border-l border-idesign-navy-120 p-3">
                      {item.value}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </div>
      </div>
    </div>
  );
};

export default ToteCourseDetails;
