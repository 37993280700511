import * as React from "react";

import * as ScrollAreaPrimitive from "@radix-ui/react-scroll-area";

import { cn } from "~/lib/utils";

const ScrollArea = React.forwardRef<
  React.ElementRef<typeof ScrollAreaPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof ScrollAreaPrimitive.Root> & { focusable?: boolean }
>(({ className, children, focusable = false, ...props }, ref) => {
  const [focused, setFocused] = React.useState(false);
  return (
    <ScrollAreaPrimitive.Root
      ref={ref}
      className={cn(
        "relative overflow-hidden",
        className,
      )}
      type={focused ? "always" : "hover"}
      {...props}
    >
      <ScrollAreaPrimitive.Viewport
        onFocus={() => setFocused(true)}
        onBlur={() => setFocused(false)}
        tabIndex={focusable ? 0 : undefined}
        className={cn(
          "h-full w-full",
          "focus:ring-0 focus:outline-ring focus:outline focus:outline-[3px] focus:outline-offset-4 focus:rounded-sm",
        )}
      >
        {children}
      </ScrollAreaPrimitive.Viewport>
      <ScrollBar/>
      <ScrollAreaPrimitive.Corner />
    </ScrollAreaPrimitive.Root>
  );
});
ScrollArea.displayName = ScrollAreaPrimitive.Root.displayName;

const ScrollBar = React.forwardRef<
  React.ElementRef<typeof ScrollAreaPrimitive.ScrollAreaScrollbar>,
  React.ComponentPropsWithoutRef<
    typeof ScrollAreaPrimitive.ScrollAreaScrollbar
  >
>(({ className, orientation = "vertical", ...props }, ref) => {
  return (
    <ScrollAreaPrimitive.ScrollAreaScrollbar
      ref={ref}
      orientation={orientation}
      className={cn(
        "flex touch-none select-none transition-colors bg-none m-2",
        orientation === "vertical" && "w-3 border-l border-l-transparent",
        orientation === "horizontal" &&
          "h-3 flex-col border-t border-t-transparent",
        className,
      )}
      {...props}
    >
      <div
        className={cn(
          "absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 rounded-md bg-scrollbar-track",
          orientation === "vertical" ? "w-[3.5px] h-full" : "h-[3.5px] w-full",
        )}
      />
      <ScrollAreaPrimitive.ScrollAreaThumb
        className={cn("relative flex-1 rounded-full bg-scrollbar-thumb")}
      />
    </ScrollAreaPrimitive.ScrollAreaScrollbar>
  );
});
ScrollBar.displayName = ScrollAreaPrimitive.ScrollAreaScrollbar.displayName;

export { ScrollArea, ScrollBar };
