import React, { useEffect, useRef, useState } from "react";

import {
  ColumnDef,
  ColumnFiltersState,
  SortingState,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";

import { API_PATHS } from "~/api/ApiPaths";
import { Button } from "~/components/ui/button";
import { Input } from "~/components/ui/input";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "~/components/ui/table";
import useAxios from "~/hooks/useAxios";
import useOverrides from "~/hooks/useOverrides";
import { useStore } from "~/models/Root";
import { IProgramData } from "~/models/UserManager";

interface IUserPartnerDetails {
  programDetailsData: any;
}

const ProgramList: React.FC<IUserPartnerDetails> = (props) => {
  const { programDetailsData } = props;
  const displayReady = useRef(false);
  const [programData, setProgramData] = useState<IProgramData[]>([]);
  const { data, authToken, error, requestUrl, fetch } = useAxios({
    method: "GET",
    initialValue: null,
  });
  const { getAuthToken } = useStore();
  const programsFetched = useRef(false);
  const programsSet = useRef(false);
  const { ProgramLabel } = useOverrides();
  const programLabel = ProgramLabel();
  const [sorting, setSorting] = React.useState<SortingState>([]);
  const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>(
    [],
  );
  const [tableBeginningSize, setTableBeginningSize] = useState(0);
  const [previousRowSize, setPreviousRowSize] = useState(0);

  useEffect(() => {
    if (data?.issuccess && !programsSet.current && programsFetched.current) {
      const allProgramsArray: IProgramData[] = [];
      data.entity.forEach((item: IProgramData) => {
        allProgramsArray.push({
          id: item.id,
          partner_id: item.partner_id,
          display_name: item.display_name,
          code: item.code,
          name: item.name,
          total_credits: item.total_credits,
          total_tot_minutes: item.total_tot_minutes,
        });
      });
      setProgramData(allProgramsArray);
      programsSet.current = true;
      displayReady.current = false;
    } else if (!programsFetched.current) {
      programsFetched.current = true;
      displayReady.current = false;
      requestUrl.current = `${
        API_PATHS.GET_PROGRAMS
      }/${+programDetailsData.id}`;
      authToken.current = getAuthToken();
      fetch();
    } else if (error) {
      console.log(error);
    }
  }, [
    data,
    error,
    authToken,
    requestUrl,
    fetch,
    getAuthToken,
    programDetailsData,
    programsFetched,
    programsSet,
    displayReady,
  ]);

  const columns: ColumnDef<IProgramData>[] = [
    {
      accessorKey: "code",
      header: ({ column }) => {
        return (
          <Button
            className={`${
              column.getIsSorted() === "asc" ? "text-primary" : ""
            } p-0 w-full items-center justify-start`}
            variant="ghost"
            onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
          >
            {programLabel} Code
            <span aria-hidden="true" className="material-icons text-base">
              unfold_more
            </span>
          </Button>
        );
      },
    },
    {
      accessorKey: "name",
      header: ({ column }) => {
        return (
          <Button
            className={
              column.getIsSorted() === "asc" ? "text-primary p-0" : "p-0"
            }
            variant="ghost"
            onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
          >
            {programLabel} Name
            <span aria-hidden="true" className="material-icons text-base">
              unfold_more
            </span>
          </Button>
        );
      },
    },
    {
      accessorKey: "total_credits",
      header: ({ column }) => {
        return (
          <Button
            className={
              column.getIsSorted() === "asc" ? "text-primary p-0" : "p-0"
            }
            variant="ghost"
            onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
          >
            Total Credits
            <span aria-hidden="true" className="material-icons text-base">
              unfold_more
            </span>
          </Button>
        );
      },
    },
    {
      accessorKey: "total_tot_minutes",
      header: ({ column }) => {
        return (
          <Button
            className={
              column.getIsSorted() === "asc" ? "text-primary p-0" : "p-0"
            }
            variant="ghost"
            onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
          >
            Total Minutes
            <span aria-hidden="true" className="material-icons text-base">
              unfold_more
            </span>
          </Button>
        );
      },
    },
  ];

  const table = useReactTable({
    data: programData,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    getFilteredRowModel: getFilteredRowModel(),
    state: {
      sorting,
      columnFilters,
    },
  });

  return (
    //Div styling used here to keep grid from overflowing
    <div className="border rounded-md p-1">
      <div className="h-full w-full ">
        <Table className="w-full">
          <TableHeader>
            {table.getHeaderGroups().map((headerGroup) => (
              <TableRow key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  return (
                    <TableHead key={header.id}>
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                            header.column.columnDef.header,
                            header.getContext(),
                          )}
                    </TableHead>
                  );
                })}
              </TableRow>
            ))}
            <TableRow>
              <TableHead>
                <Input
                  placeholder="Filter Program Codes..."
                  value={
                    (table.getColumn("code")?.getFilterValue() as string) ?? ""
                  }
                  onChange={(event) =>
                    table.getColumn("code")?.setFilterValue(event.target.value)
                  }
                  className="m-0 border-0 p-0"
                />
              </TableHead>
              <TableHead>
                <Input
                  placeholder="Filter programs..."
                  value={
                    (table.getColumn("name")?.getFilterValue() as string) ?? ""
                  }
                  onChange={(event) =>
                    table.getColumn("name")?.setFilterValue(event.target.value)
                  }
                  className="m-0 border-0 p-0"
                />
              </TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {table.getRowModel().rows?.length ? (
              table.getRowModel().rows.map((row) => (
                <TableRow
                  key={row.id}
                  data-state={row.getIsSelected() && "selected"}
                >
                  {row.getVisibleCells().map((cell) => (
                    <TableCell key={cell.id} className="py-[0.5rem]">
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext(),
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell
                  colSpan={columns.length}
                  className="h-24 text-center"
                >
                  No results.
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
        <div className="border-t flex flex-row items-center justify-between w-full py-2">
          <div className="flex-1 text-sm text-muted-foreground px-2">
            Items {tableBeginningSize} -{" "}
            {tableBeginningSize + table.getRowModel().rows.length} of{" "}
            {table.getFilteredRowModel().rows.length} total.
          </div>
          <div className="space-x-2 m-2">
            <Button
              className="rounded-md"
              size="sm"
              variant="outline"
              onClick={() => {
                table.previousPage();
                setTableBeginningSize(tableBeginningSize - previousRowSize);
              }}
              disabled={!table.getCanPreviousPage()}
            >
              Previous
            </Button>
            <Button
              className="rounded-md"
              size="sm"
              variant="outline"
              onClick={() => {
                table.nextPage();
                setTableBeginningSize(
                  tableBeginningSize + table.getRowModel().rows.length,
                );
                setPreviousRowSize(table.getRowModel().rows.length);
              }}
              disabled={!table.getCanNextPage()}
            >
              Next
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProgramList;
