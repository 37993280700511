import React from "react";

import { OctagonAlertIcon, Trash2Icon } from "lucide-react";
import { observer } from "mobx-react-lite";

import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "~/components/ui-rework/alert-dialog";
import { IMappingListItem } from "~/models/AlignEditor";

type DeleteMappingDialogProps = {
  mapping: IMappingListItem;
  onDelete: (id: number) => void;
};

const DeleteMappingDialog = observer(
  ({ mapping, onDelete }: DeleteMappingDialogProps) => {
    return (
      <AlertDialog>
        <div className="flex">
          <AlertDialogTrigger asChild>
            <button
              style={{ width: "16.5px" }}
              title="Delete Mapping"
              aria-label="Delete Mapping"
            >
              <Trash2Icon size="16px" className="text-destructive" />
            </button>
          </AlertDialogTrigger>
        </div>
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle className="flex items-center gap-2">
              <OctagonAlertIcon size="24px" />
              Delete Mapping
            </AlertDialogTitle>
            <AlertDialogDescription>
              {mapping.listitem_display_name} will be permanently deleted. Are
              you sure?
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogCancel>Cancel</AlertDialogCancel>
            <AlertDialogAction onClick={() => onDelete(mapping.id ?? 0)}>
              Delete
            </AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    );
  },
);

export { DeleteMappingDialog };
