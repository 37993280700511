import * as React from "react";

import { cva, type VariantProps } from "class-variance-authority";

import { cn } from "~/lib/utils";

const variants = {
  default: cn(
    "border-transparent bg-cerulean-10 text-idesign-navy-100 hover:bg-cerulean-20 hover:shadow-cerulean-10/60",
  ),
  error:
    "border-transparent bg-system-error-10 text-system-error-100 hover:bg-system-error-20 hover:shadow-system-error-10/60",
  success:
    "border-transparent bg-system-success-10 text-system-success-100 hover:bg-system-success-20 hover:shadow-system-success-10/60",
  warning:
    "border-transparent bg-system-warning-10 text-system-warning-150 hover:bg-system-warning-20 hover:shadow-system-warning-10/60",
};

const badgeVariants = cva(
  cn(
    "inline-flex items-center justify-center gap-1 rounded-full border px-2 py-0.5 text-xs font-semibold transition-colors focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2",
    "hover:shadow hover:shadow-x-0 hover:-shadow-y-0.5 hover:shadow-blur-0.5",
  ),
  {
    variants: {
      variant: variants,
    },
    defaultVariants: {
      variant: "default",
    },
  },
);

export const BADGE_VARIANT_KEYS = Object.keys(variants) as Array<
  keyof typeof variants
>;
export interface BadgeProps
  extends React.HTMLAttributes<HTMLDivElement>,
    VariantProps<typeof badgeVariants> {}

function Badge({ className, variant, ...props }: BadgeProps) {
  return (
    <div className={cn(badgeVariants({ variant }), className)} {...props} />
  );
}

export { Badge, badgeVariants };
