import React, { CSSProperties } from "react";

import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { Row, flexRender } from "@tanstack/react-table";

import { TableCell, TableRow } from "~/components/ui/table";
import { cn } from "~/lib/utils";
import { IListManagerListItem } from "~/models/ListManager";

const DraggableRow = ({ row }: { row: Row<IListManagerListItem> }) => {
  const { transform, transition, setNodeRef, isDragging } = useSortable({
    id: row.original.id,
  });

  const style: CSSProperties = {
    transform: CSS.Transform.toString(transform), //let dnd-kit do its thing
    transition: transition,
    opacity: isDragging ? 0.8 : 1,
    zIndex: isDragging ? 1 : 0,
    position: "relative",
  };

  return (
    // connect row ref to dnd-kit, apply important styles
    <TableRow
      ref={setNodeRef}
      style={style}
      className={cn(
        "bg-cerulean-10 hover:!bg-ocean-10",
        row.original.is_heading && "!bg-white ",
      )}
    >
      <DraggableRowCells row={row} />
    </TableRow>
  );
};

/**
 * @dnd-kit has some performance issues on large lists in its current build and we
 * are unsure when the maintainer will address them. This component is memoized to
 * prevent unnecessary re-renders of the row cells while dragging.
 */
const DraggableRowCells = React.memo(
  ({ row }: { row: Row<IListManagerListItem> }) => {
    return (
      <>
        {row.getVisibleCells().map((cell) => (
          <TableCell
            key={cell.id}
            className={cn(
              "py-2 border border-idesign-navy-120 border-b-0",
              "last:border-r-0 first:border-l-0",
            )}
          >
            {flexRender(cell.column.columnDef.cell, cell.getContext())}
          </TableCell>
        ))}
      </>
    );
  },
);

export { DraggableRow };
