import React, { ReactNode } from "react";
import { useForm } from "react-hook-form";

import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";

import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "~/components/ui/form";
import { SelectMultiple } from "~/components/ui/select-multiple";
import { Button } from "~/components/ui-rework/button";
import { Checkbox } from "~/components/ui-rework/checkbox";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogFooter,
  DialogTitle,
  DialogTrigger,
} from "~/components/ui-rework/dialog";
import { Input } from "~/components/ui-rework/input";
import { FormSelect } from "~/components/ui-rework/select";
import { useProgramEditorContext } from "~/context/ProgramEditorProvider";
import { ActivityEditable, IActivity } from "~/models/AlignEditor";

const formSchema = z.object({
  code: z.string().min(1, { message: "Code is required" }),
  name: z.string().min(1, { message: "Name is required" }),
  display_name: z.string().min(1, { message: "Display Name is required" }),
  tot_minutes: z.coerce
    .number()
    .min(0, { message: "Total Minutes is required" }),
  activity_type: z.string().nullable(),
  is_assessment: z.boolean(),
  modality: z.string().nullable().optional(),
  collaboration: z.string().nullable().optional(),
  engagement_types: z.array(z.string()).nullable().optional(),
  experience_type: z.string().nullable().optional(),
  blooms: z.array(z.string()).nullable().optional(),
  grading_points: z.coerce.number().min(0, { message: "Points is required" }),
  grading_stakes: z.string().nullable().optional(),
});

const activityTypeOptions = [
  { label: "Assignment", value: "Assignment" },
  { label: "Class Meeting", value: "Class Meeting" },
  { label: "Discussion", value: "Discussion" },
  { label: "Exam", value: "Exam" },
  { label: "Multimedia", value: "Multimedia" },
  { label: "Other", value: "Other" },
  { label: "Project", value: "Project" },
  { label: "Quiz", value: "Quiz" },
  { label: "Reading", value: "Reading" },
];

const modalityOptions = [
  { label: "Blended", value: "Blended" },
  { label: "In-Person", value: "In-Person" },
  { label: "Online - Asynchronous", value: "Online - Asynchronous" },
  { label: "Online - Synchronous", value: "Online - Synchronous" },
];

const collaborationOptions = [
  { label: "Group", value: "Group" },
  { label: "Individual", value: "Individual" },
];

const engagementTypeOptions = [
  { label: "Student-Content", value: "Student-Content" },
  { label: "Student-Instructor", value: "Student-Instructor" },
  { label: "Student-Student", value: "Student-Student" },
];

const experienceTypeOptions = [
  { label: "Assimilative", value: "Assimilative" },
  { label: "Communication", value: "Communication" },
  { label: "Experiential", value: "Experiential" },
  { label: "Finding and Handling Info", value: "Finding and Handling Info" },
  { label: "Interactive/Adaptive", value: "Interactive/Adaptive" },
  { label: "Productive", value: "Productive" },
];

const bloomsOptions = [
  { label: "Analyze", value: "Analyze" },
  { label: "Apply", value: "Apply" },
  { label: "Create", value: "Create" },
  { label: "Evaluate", value: "Evaluate" },
  { label: "Remember", value: "Remember" },
  { label: "Understand", value: "Understand" },
  { label: "Factual", value: "Factual" },
  { label: "Conceptual", value: "Conceptual" },
  { label: "Procedural", value: "Procedural" },
  { label: "Metacognitive", value: "Metacognitive" },
];

const gradingStakesOptions = [
  { label: "High Stakes", value: "High Stakes" },
  { label: "Medium Stakes", value: "Medium Stakes" },
  { label: "Low Stakes", value: "Low Stakes" },
  { label: "None", value: "None" },
];

const parseOptions = (
  options: { label: string; value: string }[],
  values?: string | null,
) => {
  const optionValues = options.map((option) => option.value);
  return values?.split(", ").filter((value) => optionValues.includes(value));
};

type ActivityDialogProps = {
  activity?: IActivity;
  children: ReactNode;
  onSubmit: (data: ActivityEditable) => void;
};

const ActivityDialog = ({
  activity,
  children,
  onSubmit,
}: ActivityDialogProps) => {
  const form = useForm<z.infer<typeof formSchema>>({
    mode: "onChange",
    resolver: zodResolver(formSchema),
    defaultValues: {
      code: activity?.code ?? "",
      name: activity?.name ?? "",
      display_name: activity?.display_name ?? "",
      tot_minutes: activity?.tot_minutes ?? 0,
      activity_type: activity?.activity_type ?? null,
      is_assessment: activity?.is_assessment,
      modality: activity?.modality ?? null,
      collaboration: activity?.collaboration ?? null,
      engagement_types:
        parseOptions(engagementTypeOptions, activity?.engagement_types) ?? [],
      experience_type: activity?.experience_type ?? null,
      blooms: parseOptions(bloomsOptions, activity?.blooms) ?? [],
      grading_points: activity?.grading_points ?? 0,
      grading_stakes: activity?.grading_stakes ?? null,
    },
  });

  const handleOpenChange = (open: boolean) => {
    if (open) {
      form.reset({
        code: activity?.code ?? "",
        name: activity?.name ?? "",
        display_name: activity?.display_name ?? "",
        tot_minutes: activity?.tot_minutes ?? 0,
        activity_type: activity?.activity_type ?? null,
        is_assessment: activity?.is_assessment,
        modality: activity?.modality ?? null,
        collaboration: activity?.collaboration ?? null,
        engagement_types: parseOptions(
          engagementTypeOptions,
          activity?.engagement_types,
        ),
        experience_type: activity?.experience_type ?? null,
        blooms: parseOptions(bloomsOptions, activity?.blooms),
        grading_points: activity?.grading_points ?? 0,
        grading_stakes: activity?.grading_stakes ?? null,
      });
    }
  };

  const actionLabel = activity ? "Edit" : "Add";
  const { activityLabel } = useProgramEditorContext();
  const title = `${actionLabel} ${activityLabel}`;

  return (
    <Dialog onOpenChange={handleOpenChange}>
      <DialogTrigger asChild>{children}</DialogTrigger>
      <DialogContent className="light max-w-xl">
        <Form {...form}>
          <form onSubmit={form.handleSubmit((data) => onSubmit(data))}>
            <DialogTitle className="mt-1">{title}</DialogTitle>

            <div className="grid grid-cols-2 gap-3 py-3">
              <FormField
                control={form.control}
                name="code"
                render={({ field }) => (
                  <FormItem className="col-span-2">
                    <FormLabel>Code</FormLabel>
                    <FormControl>
                      <Input
                        {...field}
                        className="bg-transparent"
                        placeholder={`${activityLabel} Code`}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="name"
                render={({ field }) => (
                  <FormItem className="col-span-2">
                    <FormLabel>Name</FormLabel>
                    <FormControl>
                      <Input
                        {...field}
                        className="bg-transparent"
                        placeholder={`${activityLabel} Name`}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="display_name"
                render={({ field }) => (
                  <FormItem className="col-span-2">
                    <FormLabel>Display Name</FormLabel>
                    <FormControl>
                      <Input
                        {...field}
                        className="bg-transparent"
                        placeholder={`${activityLabel} Display Name`}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="tot_minutes"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Total Minutes</FormLabel>
                    <FormControl>
                      <Input
                        {...field}
                        min={0}
                        type="number"
                        className="bg-transparent"
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name={"activity_type"}
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Type</FormLabel>
                    <FormSelect
                      testid="activity-type"
                      handleChange={field.onChange}
                      value={field.value}
                      options={activityTypeOptions}
                    />
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name={"is_assessment"}
                render={({ field }) => (
                  <FormItem className="flex flex-row items-center space-x-3 space-y-0 col-span-2">
                    <FormControl>
                      <Checkbox
                        checked={field.value}
                        onCheckedChange={field.onChange}
                        data-testid="activity-assessment"
                      />
                    </FormControl>
                    <FormLabel>Is Assessment?</FormLabel>
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name={"modality"}
                render={({ field }) => (
                  <FormItem className="col-span-1">
                    <FormLabel>Modality</FormLabel>
                    <FormSelect
                      testid="activity-modality"
                      handleChange={field.onChange}
                      value={field.value ?? null}
                      options={modalityOptions}
                    />
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name={"collaboration"}
                render={({ field }) => (
                  <FormItem className="col-span-1">
                    <FormLabel>Collaboration</FormLabel>
                    <FormSelect
                      testid="activity-collaboration"
                      handleChange={field.onChange}
                      value={field.value ?? null}
                      options={collaborationOptions}
                    />
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name={"experience_type"}
                render={({ field }) => (
                  <FormItem className="col-span-2">
                    <FormLabel>Experience Type</FormLabel>
                    <FormSelect
                      testid="activity-experience"
                      handleChange={field.onChange}
                      value={field.value ?? null}
                      options={experienceTypeOptions}
                    />
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name={"engagement_types"}
                render={({ field }) => (
                  <FormItem className="col-span-2">
                    <FormLabel>Engagement Types</FormLabel>
                    <FormControl>
                      <SelectMultiple
                        testid="activity-engagement"
                        clearable
                        limit={2}
                        options={engagementTypeOptions}
                        selectPlaceholder="Select up to two..."
                        searchPlaceholder="Select up to two..."
                        values={field.value ?? undefined}
                        onValuesChange={field.onChange}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name={"blooms"}
                render={({ field }) => (
                  <FormItem className="col-span-2">
                    <FormLabel>Blooms</FormLabel>
                    <FormControl>
                      <SelectMultiple
                        testid="activity-blooms"
                        clearable
                        limit={2}
                        options={bloomsOptions}
                        selectPlaceholder="Select up to two..."
                        searchPlaceholder="Select up to two..."
                        values={field.value ?? undefined}
                        onValuesChange={field.onChange}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="grading_points"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Points</FormLabel>
                    <FormControl>
                      <Input
                        {...field}
                        min={0}
                        type="number"
                        className="bg-transparent"
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name={"grading_stakes"}
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Stakes</FormLabel>
                    <FormSelect
                      testid="activity-stakes"
                      handleChange={field.onChange}
                      value={field.value}
                      options={gradingStakesOptions}
                    />
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>

            <DialogFooter>
              <DialogClose asChild>
                <Button
                  type="submit"
                  disabled={!form.formState.isDirty || !form.formState.isValid}
                  className="font-normal"
                >
                  {activity ? "Save Changes" : title}
                </Button>
              </DialogClose>
            </DialogFooter>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  );
};

export default ActivityDialog;
