import React, { useEffect, useState } from "react";

import { observer } from "mobx-react-lite";
import {
  Bar,
  BarChart,
  Cell,
  LabelList,
  Legend,
  Pie,
  PieChart,
  ResponsiveContainer,
  Sector,
  XAxis,
  YAxis,
} from "recharts";

import { formatHours } from "~/components/Reports/ReportModels";
import { TotModel } from "~/components/Reports/ToteSummary";
import { Checkbox } from "~/components/ui-rework/checkbox";
import { Label } from "~/components/ui-rework/label";
import { colors } from "~/css/shared-styles";
import useOverrides from "~/hooks/useOverrides";

const renderLegend = (props: any) => {
  const { payload } = props;
  const assignment = payload.filter(
    (item: any) => item.payload.category === "Assignments/Assessments",
  );
  const blended = payload.filter(
    (item: any) => item.payload.category === "Blended Learning",
  );
  const learning = payload.filter(
    (item: any) => item.payload.category === "Learning Activities",
  );
  return (
    <ul className="flex flex-col gap-2 text-idesign-navy-120">
      <li key={`item-0`} className="flex items-center">
        <svg className="h-3 w-3">
          <rect className="h-3 w-3" fill={"url(#assignment-gradient)"} />
        </svg>
        <span className="pl-2 text-sm">
          Assignment/Assessments -{" "}
          {assignment.length > 0 ? assignment[0].payload.tot_percent : 0}%
        </span>
      </li>
      <li key={`item-1`} className="flex items-center">
        <svg className="h-3 w-3">
          <rect className="h-3 w-3" fill={"url(#blended-gradient)"} />
        </svg>
        <span className="pl-2 text-sm">
          Blended Learning -{" "}
          {blended.length > 0 ? blended[0].payload.tot_percent : 0}%
        </span>
      </li>
      <li key={`item-2`} className="flex items-center">
        <svg className="h-3 w-3">
          <rect className="h-3 w-3" fill={"url(#learning-gradient)"} />
        </svg>
        <span className="pl-2 text-sm">
          Learning Activities -{" "}
          {learning.length > 0 ? learning[0].payload.tot_percent : 0}%
        </span>
      </li>
    </ul>
  );
};

export const renderPieLabel = (props: {
  cx: any;
  cy: any;
  midAngle: any;
  innerRadius: any;
  outerRadius: any;
  startAngle: any;
  endAngle: any;
  fill: any;
  payload: any;
}) => {
  const RADIAN = Math.PI / 180;
  const {
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    startAngle,
    endAngle,
    fill,
    payload,
  } = props;
  const sin = Math.sin(-RADIAN * midAngle);
  const cos = Math.cos(-RADIAN * midAngle);
  const sx = cx + (outerRadius + 10) * cos;
  const sy = cy + (outerRadius + 10) * sin;
  const mx = cx + (outerRadius + 15) * cos;
  const my = cy + (outerRadius + 20) * sin;
  const ex = mx + (cos >= 0 ? 1 : -1) * 22;
  const ey = my;
  const textAnchor = cos >= 0 ? "start" : "end";

  return (
    <>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
      />
      <Sector
        cx={cx}
        cy={cy}
        startAngle={startAngle}
        endAngle={endAngle}
        innerRadius={outerRadius + 6}
        outerRadius={outerRadius + 10}
        fill={fill}
      />
      <path
        d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`}
        stroke={fill}
        fill="none"
      />
      <text
        x={ex + (cos >= 0 ? 1 : -1) * 12}
        y={ey}
        textAnchor={textAnchor}
        fill="colors.ideign-navy-100"
        fontSize={14}
      >
        {`${payload.category}`}
      </text>
      <text
        x={ex + (cos >= 0 ? 1 : -1) * 12}
        y={ey}
        dy={18}
        textAnchor={textAnchor}
        fill="colors.ideign-navy-100"
        fontSize={14}
      >
        {`${payload.tot_minutes_display}`}
      </text>
    </>
  );
};

export const renderActiveShape = (props: {
  cx: any;
  cy: any;
  midAngle: any;
  innerRadius: any;
  outerRadius: any;
  startAngle: any;
  endAngle: any;
  fill: any;
  payload: any;
}) => {
  const { cx, cy, payload } = props;
  return (
    <g>
      <text x={cx} y={cy} dy={8} textAnchor="middle" fill="#333">
        {payload.tot_percent}%
      </text>
      {renderPieLabel(props)}
    </g>
  );
};

export const showAllLabel = (props: any) => {
  return <g>{renderPieLabel(props)}</g>;
};

export const CustomizedLabel = (props: any, showAll: boolean) => {
  const { x, y, width, viewBox, value } = props;
  const middleY = y + viewBox.height / 2;
  if (value > 0) {
    return (
      <g>
        <text
          x={x + width + 5}
          y={middleY}
          fill="#002E40"
          textAnchor="start"
          dominantBaseline="middle"
          fontSize={12}
        >
          {formatHours(value)}
        </text>
      </g>
    );
  }
  return null;
};

export const getGradient = (category: string) => {
  switch (category) {
    case "Assignments/Assessments":
      return "url(#assignment-gradient)";
    case "Blended Learning":
      return "url(#blended-gradient)";
    case "Learning Activities":
      return "url(#learning-gradient)";
    default:
      return colors.formButtonBlue;
  }
};

const ToteSummaryVisuals: React.FC<{ data: TotModel | null }> = observer(
  ({ data }) => {
    const [index, setIndex] = useState(0);
    const [activeCategory, setActiveCategory] = useState("");
    const [barDataReady, setBarDataReady] = useState(false);
    const [barData, setBarData] = useState<any>([]);
    const [previousCategory, setPreviousCategory] = useState("");
    const [previousHover, setPreviousHover] = useState();
    const [previousIndex, setPreviousIndex] = useState(0);
    const [firstLoad, setFirstLoad] = useState(true);
    const { ActivityLabel } = useOverrides();
    const activityLabel = ActivityLabel();

    const pieHover = (data: any, index: any) => {
      setPreviousCategory(activeCategory);
      setPreviousHover(data);
      setActiveCategory(data.category);
      setIndex(index);
      setShowAll(false);
    };

    useEffect(() => {
      if (firstLoad) {
        setFirstLoad(false);
        setActiveCategory("Assignments/Assessments");
        setPreviousCategory("Assignments/Assessments");
        setIndex(0);
      }
    }, [data, firstLoad]);

    useEffect(() => {
      if (activeCategory !== "") {
        const barData = data?.activityTypesSummary
          .filter((item) =>
            activeCategory === "ALL" ? true : item.category === activeCategory,
          )
          .map((item: any) => {
            const color = getGradient(item.category);
            return {
              name: item.type,
              tot_minutes: item.tot_minutes,
              tot_minutes_display: item.tot_minutes_display,
              tot_percent: item.tot_percent,
              tot_hours: item.tot_minutes / 60,
              color: color,
            };
          });
        setBarData(barData);
        setBarDataReady(true);
      }
    }, [activeCategory, data]);

    const [showAll, setShowAll] = React.useState(false);
    const checkboxChange = (event: any) => {
      if (event) {
        setShowAll(event);
        setActiveCategory("ALL");
        setPreviousIndex(index);
        setIndex(-1);
      } else if (event === false && previousHover !== undefined) {
        setShowAll(event);
        setActiveCategory(previousCategory);
        pieHover(previousHover, index);
        setIndex(previousIndex);
      } else {
        setShowAll(event);
        setActiveCategory(previousCategory);
        setIndex(previousIndex);
      }
    };

    return (
      <div className="pl-1 pr-1 w-full">
        <h6 className="mt-0 ml-7">Aggregate Category Breakdown</h6>
        <ResponsiveContainer width="100%" height={280}>
          <PieChart
            margin={{ top: 0, right: 25, bottom: 0, left: 25 }}
            role="img"
            aria-label="Pie graph that describes the the program broken down into each category. The categories include: Assignments or Assessments, Blended Learning, and Learning Activities."
          >
            {/* linear gradient definition */}
            <defs>
              <linearGradient id="blended-gradient">
                <stop offset="4%" stopColor="#DD5900" />
                <stop offset="92%" stopColor="#C30000" />
              </linearGradient>
              <linearGradient id="learning-gradient">
                <stop stopColor="#6E55A2" />
                <stop offset="1" stopColor="#47346F" />
              </linearGradient>
              <linearGradient id="assignment-gradient">
                <stop stopColor="#006185" />
                <stop offset="1" stopColor="#0288BA" />
              </linearGradient>
            </defs>
            {/* linear gradient ends */}

            <Legend
              content={renderLegend}
              layout="vertical"
              align="right"
              verticalAlign="middle"
            />
            {data && (
              <Pie
                label={showAll ? showAllLabel : undefined}
                tabIndex={0}
                focusable
                paddingAngle={
                  data?.categorySummary.filter((item) => item.tot_minutes > 0)
                    .length > 1
                    ? 5
                    : 0
                }
                activeIndex={index}
                activeShape={renderActiveShape}
                data={data?.categorySummary.filter(
                  (item) => item.tot_minutes !== 0,
                )}
                cx="50%"
                cy="50%"
                innerRadius={60}
                outerRadius={80}
                fill={colors.formButtonBlue}
                dataKey="tot_percent"
                onMouseEnter={showAll ? undefined : pieHover}
                onFocus={showAll ? undefined : pieHover}
              >
                {data?.categorySummary
                  .filter((item) => item.tot_minutes > 0)
                  .map((entry, index) => (
                    <Cell
                      key={`cell-${index}`}
                      fill={getGradient(entry.category)}
                    />
                  ))}
              </Pie>
            )}
          </PieChart>
        </ResponsiveContainer>
        {barDataReady && (
          <>
            <h6 className="mt-0 ml-7">
              Aggregate {activityLabel} Type for{" "}
              {showAll ? "All Categories" : activeCategory}
            </h6>

            <div className="flex items-center space-x-2">
              <Checkbox
                id="showAll"
                className="ml-14 mt-0"
                checked={showAll}
                onCheckedChange={checkboxChange}
              />
              <Label htmlFor="showAll" className="mb-0">
                Show All Activity Types (Expand Below)
              </Label>
            </div>
            <ResponsiveContainer
              width={"100%"}
              height={415}
            >
              <BarChart
                height={380}
                data={barData}
                layout="vertical"
                margin={{ bottom: 20, left: 75, top: 20, right: 75 }}
              >
                <XAxis type="number" fontSize={12} stroke="#002E40" label={{
                  value: "Hours",
                  angle: 0,
                  position: "bottom",
                  offset: 0,
                  fontSize: 12,
                  fontWeight: 700,
                  fill: "#002E40",
                }}/>
                <YAxis
                  type="category"
                  dataKey="name"
                  tickLine={false}
                  interval={0}
                  fontSize={12}
                  stroke="#002E40"
                  label={{
                    value: "Activity Type",
                    angle: -90,
                    position: "insideLeft",
                    offset: -40,
                    fontSize: 12,
                    fontWeight: 700,
                    fill: "#002E40",
                  }}
                />
                <Bar dataKey="tot_hours">
                  <LabelList
                    content={<CustomizedLabel showAll={showAll} />}
                    position="right"
                  />
                  {barData.map((entry: any, index: any) => (
                    <Cell key={`cell-${index}`} fill={entry.color} />
                  ))}
                </Bar>
              </BarChart>
            </ResponsiveContainer>
          </>
        )}
      </div>
    );
  },
);

export default ToteSummaryVisuals;
