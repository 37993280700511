import { useContext, useEffect } from "react";

import { useQuery, useQueryClient } from "@tanstack/react-query";
import axios from "axios";

import { ApiUrls } from "~/api/ApiPaths";
import { AxiosContext } from "~/api/AxiosInstance";
import { useExplorerContext } from "~/context/ExplorerProvider";
import { useStore as useRootStore } from "~/models/Root";

export const useExplorerListDetailsQuery = () => {
  const axiosInstance = useContext(AxiosContext) ?? axios;
  const { getAuthToken, selectedProgramId } = useRootStore();

  const { selectedList, setListDetails } = useExplorerContext();
  const selectedListId = parseInt(selectedList ?? "-1");

  const queryFn = (): Promise<any> =>
    axiosInstance
      .request({
        method: "GET",
        url: ApiUrls.lists.getListDetails(selectedListId),
        headers: { Authorization: getAuthToken() },
      })
      .then((response) => response.data);

  const query = useQuery({
    queryKey: [
      "explorerListDetails",
      ApiUrls.lists.getListDetails(selectedListId),
    ],
    enabled: selectedProgramId !== 0 && selectedListId !== -1,
    queryFn,
  });

  const { data, isSuccess } = query;

  const queryClient = useQueryClient();

  useEffect(() => {
    queryClient.invalidateQueries({ queryKey: ["explorerListDetails"] });
  }, [selectedList, queryClient]);

  useEffect(() => {
    if (isSuccess) {
      setListDetails(data.entity);
    }
  }, [data, isSuccess, setListDetails]);
  return query;
};
