import React, { Fragment } from "react";

import { observer } from "mobx-react-lite";
import styled from "styled-components";

import CourseActivity from "~/components/Reports/CourseActivity";
import * as ReportModels from "~/components/Reports/ReportModels";
import { ScrollArea } from "~/components/ui/scroll-area";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "~/components/ui-rework/accordion";
import { Dropdown } from "~/components/ui-rework/dropdown";
import { Select } from "~/components/ui-rework/select";
import EmptyStateGraphic from "~/images/Reports-Program-Course-Activities-Modules.svg";
import { cn } from "~/lib/utils";
import { useStore } from "~/models/Root";

const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const CourseActivities: React.FC<{
  programData: ReportModels.ProgramData | null;
  forPrint?: boolean | false;
}> = observer(({ programData, forPrint }) => {
  const { getDropdownListReports, setTargetListReports, getTargetListReports } =
    useStore();

  const listItems = getDropdownListReports();
  const targetListVar = getTargetListReports();

  const handleDropdownChange = (newValue: string) => {
    const list =
      programData?.mapped_lists.find(
        (item: ReportModels.List) => item.id === parseInt(newValue),
      ) || null;
    if (list) {
      setTargetListReports(list);
    }
  };

  const handleSelectChange = (fieldname: string, newValue: string) => {
    const list =
      programData?.mapped_lists.find(
        (item: ReportModels.List) => item.id === parseInt(newValue),
      ) || null;
    if (list) {
      setTargetListReports(list);
    }
  };

  return (
    <>
      {!targetListVar && listItems.length > 0 ? (
        <div className="h-full flex flex-row gap-9 px-24 justify-center items-center">
          <img
            alt="Course Modules Reports Graphic"
            src={EmptyStateGraphic}
            className="w-[632px] h-[632px]"
          />
          <div className="flex flex-col gap-4 justify-center max-w-fit">
            <h3 className="text-lg font-bold text-idesign-navy-100">
              Select a List
            </h3>
            <p className="text-base font-normal text-idesign-navy-100 w-[415px]">
              Select a list to see a breakdown of the course activities
            </p>
            <div className="w-[85%]">
              <Select
                fieldName="list-loader-reports"
                options={listItems}
                placeholder="Select a list"
                testid="course-modules-list-dropdown"
                value={undefined}
                handleChange={handleSelectChange}
              />
            </div>
          </div>
        </div>
      ) : (
        <ScrollArea className="w-full h-full" thumbClassName="bg-gradient-1">
          <div className="py-2">
            <div className="w-fit pl-1">
              {!forPrint && (
                <>
                  {targetListVar && listItems.length > 0 && (
                    <Dropdown
                      options={listItems}
                      placeholder="Select a list"
                      testid="course-modules-list-dropdown"
                      value={targetListVar?.id.toString() || undefined}
                      handleChange={handleDropdownChange}
                      variant="outline"
                    />
                  )}
                </>
              )}
            </div>
            <FlexColumn>
              {targetListVar && targetListVar.id > 0 && (
                <div
                  className={cn(
                    "flex flex-col gap-2 pr-4",
                    forPrint
                      ? "mt-4"
                      : listItems.length === 0
                      ? "mt-0"
                      : "mt-4",
                  )}
                >
                  {programData?.courses.map((course) => (
                    <Fragment key={course.id}>
                      {forPrint === true ? (
                        <div style={{ margin: "10px 0px" }}>
                          <CourseActivity
                            course={course}
                            list={targetListVar}
                            expandable={false}
                          />
                        </div>
                      ) : (
                        <Accordion type="single" collapsible variant="primary">
                          <AccordionItem value={course.display_name}>
                            <AccordionTrigger>
                              {course.display_name}
                            </AccordionTrigger>
                            <AccordionContent className="m-4">
                              <CourseActivity
                                course={course}
                                list={targetListVar}
                                expandable={true}
                              />
                            </AccordionContent>
                          </AccordionItem>
                        </Accordion>
                      )}
                    </Fragment>
                  ))}
                </div>
              )}
            </FlexColumn>
          </div>
        </ScrollArea>
      )}
    </>
  );
});

export default CourseActivities;
