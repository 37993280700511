import { useEffect, useRef, useState } from "react";

import { ApiUrls, IData } from "~/api/ApiPaths";
import { useProgramEditorContext } from "~/context/ProgramEditorProvider";
import useAxios from "~/hooks/useAxios";
import { useStore } from "~/models/AlignEditor";
import { useStore as useRootStore } from "~/models/Root";

type AvailableList = {
  id: number;
  name: string;
  list_type: string;
  is_global_list: boolean;
  is_active: boolean;
  agency_id: number;
  partner_id: number;
  program_id: number;
  course_id: number;
  created_at: number;
  created_by_id: number;
  updated_at: number | null;
  updated_by_id: number;
};

type ListsMenu = {
  label: string;
  value: string;
};

export const useAvailableListsAPI = () => {
  const { getAuthToken, appUser } = useRootStore();
  const { getCurrentProgramAvailableListIds } = useStore();
  const { partnerId } = useProgramEditorContext();

  const [availableLists, setAvailableLists] = useState<ListsMenu[]>([]);

  const { data, error, fetch, requestUrl, authToken } = useAxios({
    method: "GET",
    initialValue: null,
  });
  const apiFetched = useRef(false);

  useEffect(() => {
    if (!apiFetched.current) {
      apiFetched.current = true;
      const agency_id = appUser.agency_id;
      const partner_id = appUser.selected_partner_id;
      //console.log('Drawer => agency_id', agency_id, 'partner_id', partner_id)
      //[Todo] for now course_id is always 0 because it is not implemented at the endpoint yet we need to change this when it is implemented
      //we are also passing a 0to program_id because we want to get all lists for the partner and agency
      //and there is now way to set a list to a given program at the moment, and the endpoint to accept program_id is not implemented yet.
      requestUrl.current = ApiUrls.lists.getListsList(agency_id, partner_id);
      authToken.current = getAuthToken();
      fetch();
    }
  });

  useEffect(() => {
    let dataAvailableListItems: AvailableList[] = [];
    const availableListItems: ListsMenu[] = [];
    const programAvailableListIds = getCurrentProgramAvailableListIds();
    if (data) {
      const Data = data as IData;
      if (Data.issuccess) {
        dataAvailableListItems = data.entity.filter((list: AvailableList) => {
          return (
            programAvailableListIds.includes(list.id) && list.is_active === true
          );
        });
        dataAvailableListItems.forEach((listItem) => {
          availableListItems.push({
            value: listItem.id.toString(),
            label: listItem.name,
          });
        });
        setAvailableLists(availableListItems);
      }
    }
  }, [data, setAvailableLists, partnerId, getCurrentProgramAvailableListIds]);

  return { availableLists, error };
};
