import React, { useEffect, useState } from "react";

import { Form, Formik } from "formik";
import * as Yup from "yup";

import { ApiUrls } from "~/api/ApiPaths";
import { AddFormStyle } from "~/components/admin/CreateUserDrawer";
import PartnerDetails, {
  IPartnerDetailsForm,
} from "~/components/admin/PartnerDetails";
import { Button } from "~/components/ui/button";
import useAxios from "~/hooks/useAxios";
import useOverrides from "~/hooks/useOverrides";
import { useStore } from "~/models/Root";

interface IPartnerDetailsDrawer {
  partnerDetailsData: any | null;
}

interface IUpdatePartnerObj {
  partner_type: string;
  short_name: string;
  name: string;
  is_active: boolean;
  partner_id: number;
}

const PartnerDetailsDrawer: React.FC<IPartnerDetailsDrawer> = (props) => {
  const { partnerDetailsData } = props;
  const { data, authToken, error, requestUrl, payload, fetch } = useAxios({
    method: "POST",
    initialValue: null,
  });
  const { getAuthToken } = useStore();
  const { PartnerLabel } = useOverrides();
  const partnerLabel = PartnerLabel();
  const defaultValues = {
    displayName: "" || partnerDetailsData?.display_name,
    shortName: "" || partnerDetailsData?.short_name,
  };

  const [formValues, setFormValues] =
    useState<IPartnerDetailsForm>(defaultValues);
  const [revertFormValues, setRevertFormValues] =
    useState<IPartnerDetailsForm>(defaultValues);
  const [newFormValues, setNewFormValues] =
    useState<IPartnerDetailsForm>(defaultValues);
  const [editMode, setEditMode] = useState(false);

  const updatePartner = (partner: IUpdatePartnerObj) => {
    payload.current = {
      partner_type: partner.partner_type,
      short_name: partner.short_name,
      name: partner.name,
      is_active: partner.is_active,
      partner_id: partner.partner_id,
    };
    authToken.current = getAuthToken();
    requestUrl.current = ApiUrls.partners.updatePartner;
    fetch();
  };

  useEffect(() => {
    if (data?.issuccess) {
      setFormValues(newFormValues);
    } else if (error) {
      console.log(error);
    }
  }, [data, error, newFormValues, setFormValues]);

  return (
    <>
      <Formik
        initialValues={defaultValues}
        validationSchema={Yup.object({
          displayName: Yup.string()
            .min(2, "Too Short!")
            .max(50, "Too Long!")
            .required("Name Required"),
          shortName: Yup.string()
            .min(2, "Too Short!")
            .max(10, "Too Long!")
            .required("Name Required"),
        })}
        onSubmit={async (values, { setSubmitting }) => {
          setEditMode(false);
          //update the user with the new values
          const updatePartnerObj: IUpdatePartnerObj = {
            partner_type: partnerDetailsData?.partner_type,
            short_name: values.shortName,
            name: values.displayName,
            is_active: true,
            partner_id: partnerDetailsData?.id,
          };
          updatePartner(updatePartnerObj);
          //update the form values for reverting on cancel changes
          const revertObject: IPartnerDetailsForm = {
            displayName: values.displayName,
            shortName: values.shortName,
          };
          setNewFormValues(revertObject);
        }}
      >
        <AddFormStyle>
          <Form className="add-form">
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <Button
                type="button"
                variant="secondary"
                className="h-fit hover:bg-gray-200 border rounded-full"
                onClick={() => {
                  setEditMode(true);
                  setRevertFormValues(formValues);
                }}
                testid="admin-edit-partner"
              >
                Edit {partnerLabel}
              </Button>
            </div>
            <PartnerDetails
              partnerDetailsData={partnerDetailsData}
              editMode={editMode}
              setEditMode={setEditMode}
              revertFormValues={revertFormValues}
            />
          </Form>
        </AddFormStyle>
      </Formik>
    </>
  );
};

export default PartnerDetailsDrawer;
