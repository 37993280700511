import React, { forwardRef, useState } from "react";

import { Check, ChevronDown } from "lucide-react";

import { Badge } from "~/components/ui/badge";
import { Button } from "~/components/ui/button";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "~/components/ui/popover";
import { ScrollArea } from "~/components/ui/scroll-area";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from "~/components/ui-rework/command";
import { cn } from "~/lib/utils";

type SelectMultipleOption = {
  value: string;
  label: React.ReactNode;
};

type SelectMultipleProps = {
  className?: string | undefined;
  clearable?: boolean;
  disabled?: boolean;
  emptyText?: string;
  limit?: number;
  maxHeight?: string;
  options: SelectMultipleOption[];
  searchPlaceholder?: string;
  selectPlaceholder?: string;
  values?: string[];
  onValuesChange?: (value: string[]) => void;
  buttonMaxHeight?: string;
  testid?: string;
};

const handleMultipleSelect = (
  option: SelectMultipleOption,
  clearable = false,
  values?: string[],
  onValuesChange?: (value: string[]) => void,
) => {
  if (values?.includes(option.value)) {
    if (!clearable && values.length === 1) return false;
    onValuesChange?.(values.filter((value) => value !== option.value));
  } else {
    onValuesChange?.([...(values ?? []), option.value]);
  }
};

const SelectMultiple = forwardRef(
  (
    {
      className,
      clearable,
      disabled,
      emptyText,
      limit,
      maxHeight,
      options,
      searchPlaceholder,
      selectPlaceholder,
      testid,
      values,
      onValuesChange,
      buttonMaxHeight,
    }: SelectMultipleProps,
    ref: React.ForwardedRef<HTMLInputElement>,
  ) => {
    const [open, setOpen] = useState(false);

    const hasValue = values && values.length > 0;

    const selectedOptions =
      values
        ?.map((value) => options.find((opt) => opt.value === value))
        .filter(
          (option): option is SelectMultipleOption => option !== undefined,
        ) ?? [];

    const isLimitReached = !!(limit && values?.length === limit);

    return (
      <Popover open={open} onOpenChange={setOpen}>
        <PopoverTrigger asChild>
          <Button
            role="combobox"
            variant="outline"
            aria-expanded={open}
            data-testid={testid}
            disabled={disabled}
            className={cn(
              "h-auto min-h-10 w-full justify-between hover:bg-secondary/20 active:scale-100 !ring-offset-select-ring",
              "flex items-center rounded-[10px] bg-transparent border border-idesign-navy-100 p-2.5 ring-offset-background",
              "data-[placeholder]:text-muted-foreground",
              "hover:border-ring",
              "focus:outline-none focus:ring-1 focus:ring-ring focus:ring-offset-4",
              "focus-visible:!outline-none focus-visible:!ring-1 focus-visible:!ring-ring focus-visible:ring-offset-4",
              "data-[state=open]:!outline-none data-[state=open]:!ring-1 data-[state=open]:!ring-ring data-[state=open]:ring-offset-4",
              "focus:shadow focus:shadow-spread-1.5 focus:shadow-[rgb(0, 18, 26)]",
              "disabled:cursor-not-allowed disabled:opacity-50",
              className,
            )}
          >
            <ScrollArea thumbClassName="bg-gradient-1">
              <span
                className="flex flex-wrap gap-x-2 gap-y-1 text-left mr-2.5"
                style={maxHeight ? { maxHeight: maxHeight } : {}}
              >
                {hasValue ? (
                  selectedOptions.map((option) => (
                    <Badge
                      className="dark bg-gradient-1 text-white rounded-xl"
                      key={option.value}
                      variant="multiselect"
                    >
                      {option.label}
                    </Badge>
                  ))
                ) : (
                  <span className="text-muted-foreground text-base">
                    {selectPlaceholder ?? "Select multiple..."}
                  </span>
                )}
              </span>
            </ScrollArea>
            <ChevronDown
              className={cn(
                "ml-1 shrink-0 rotate-0 transition-transform text-neutral-100",
                open && "rotate-180",
              )}
            />
          </Button>
        </PopoverTrigger>
        <PopoverContent
          align="start"
          className="p-0 w-[var(--radix-popper-anchor-width)] dark border-0"
          scrollFix
        >
          <Command className="dark bg-popover-gradient">
            <CommandInput
              className="text-white placeholder:text-gray-200 stroke-gray-200 border-ocean-70"
              ref={ref}
              placeholder={searchPlaceholder ?? "Search for an option"}
            />
            <CommandList>
              <CommandEmpty>{emptyText ?? "No results found"}</CommandEmpty>
              <CommandGroup>
                <ScrollArea>
                  <div className="max-h-60 p-[3px]">
                    {options.map((option) => (
                      <CommandItem
                        key={option.value}
                        value={option.value.toLowerCase().trim()}
                        onSelect={(selectedValue) => {
                          const option = options.find(
                            (option) =>
                              option.value.toLowerCase().trim() ===
                              selectedValue,
                          );

                          if (!option) return null;

                          handleMultipleSelect(
                            option,
                            clearable,
                            values,
                            onValuesChange,
                          );
                        }}
                        disabled={
                          isLimitReached && !values?.includes(option.value)
                        }
                      >
                        <Check
                          className={cn("mr-2 h-4 w-4 opacity-0", {
                            "opacity-100": values?.includes(option.value),
                          })}
                        />
                        {option.label}
                      </CommandItem>
                    ))}
                  </div>
                </ScrollArea>
              </CommandGroup>
            </CommandList>
          </Command>
        </PopoverContent>
      </Popover>
    );
  },
);

export { SelectMultiple };
