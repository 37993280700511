import React, { useEffect, useState } from "react";

import { ProgressBar } from "@progress/kendo-react-progressbars";
import { observer } from "mobx-react-lite";
import styled from "styled-components";

import * as ReportModels from "~/components/Reports/ReportModels";
import ToteCourseDetails from "~/components/Reports/ToteCourseDetails";
import * as ToteModels from "~/components/Reports/ToteModels";
import ToteSummaryVisuals from "~/components/Reports/ToteSummaryVisuals";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "~/components/ui/table";
import { Button } from "~/components/ui-rework/button";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "~/components/ui-rework/dialog";
import { ScrollArea } from "~/components/ui-rework/scroll-area";
import { colors } from "~/css/shared-styles";
import useOverrides from "~/hooks/useOverrides";
import { cn } from "~/lib/utils";

interface ICourseTot {
  code: string;
  tot_percent: number;
  tot_minutes: number;
  tot_minutes_display: string;
  course: ReportModels.Course;
}

function getCourseTotals(programData: ReportModels.ProgramData): ICourseTot[] {
  const courseTotals: ICourseTot[] = [];

  if (programData === null) return courseTotals;

  for (let i = 0; i < (programData?.courses?.length | 0); i++) {
    const courseMinutes = ReportModels.getTotalTotMinutes(
      programData.courses[i],
    );
    const courseMinutesDisplay = ReportModels.formatMinutes(courseMinutes);
    const totPercent = Math.round((courseMinutes / 9000) * 100); //this target time will have to be passed in down the road

    const courseTot: ICourseTot = {
      code: programData.courses[i].code,
      tot_percent: totPercent,
      tot_minutes: courseMinutes,
      tot_minutes_display: courseMinutesDisplay,
      course: programData.courses[i],
    };

    courseTotals.push(courseTot);
  }

  return courseTotals;
}

const CompStyles = styled.div`
  .close-button {
    cursor: pointer;
    font-weight: normal;
    font-size: 2rem;
    color: ${colors.inactiveGrey};
  }

  .k-progressbar .k-selected {
    background-color: #002e40;
  }
`;

const TableStyles = styled.div`
  table tbody tr td:first-child,
  table tbody tr td:last-child {
    min-width: 80px;
    width: 20%;
  }
`;

export class TotModel {
  courseTots: ICourseTot[] = [];
  activityTypesSummary: ToteModels.ITotSummary[] = [];
  categorySummary: ToteModels.ITotSummary[] = [];
}

const ToteSummary: React.FC<ReportModels.IReportsProps> = observer(
  ({ programData }) => {
    const [Model, setTotModel] = React.useState<TotModel | null>(null);
    const [modalVisible, setModalVisible] = React.useState(false);
    const [targetCourse, setTargetCourse] =
      React.useState<ReportModels.Course | null>(null);
    const { CourseLabel } = useOverrides();
    const courseLabel = CourseLabel();
    const [categoryFound, setCategoryFound] = useState(false);
    const [activityFound, setActivityFound] = useState(false);

    useEffect(() => {
      if (programData && Model === null) {
        const model = new TotModel();

        const activities =
          programData?.courses?.flatMap(
            (course) => course.modules?.flatMap((x) => x.activities),
          ) || [];

        model.courseTots = getCourseTotals(programData);
        model.activityTypesSummary =
          ToteModels.getActivityTypesSummary(activities);
        model.categorySummary = ToteModels.getCategorySummaries(
          model.activityTypesSummary,
        );

        setTotModel(model);
      }
    }, [programData, Model]);

    useEffect(() => {
      if (targetCourse !== null) setModalVisible(true);
      else setModalVisible(false);
    }, [targetCourse]);

    useEffect(() => {
      const categories = Model?.categorySummary;
      categories?.forEach((category) => {
        if (category.tot_minutes !== 0) {
          setCategoryFound(true);
        }
      });
    }, [Model?.categorySummary]);

    useEffect(() => {
      const activityByType = Model?.activityTypesSummary;
      activityByType?.forEach((activity) => {
        if (activity.tot_minutes !== 0) {
          setActivityFound(true);
        }
      });
    }, [Model?.activityTypesSummary]);

    return (
      <CompStyles>
        <div className="flex-row w-full">
          <div className="w-full h-fit border border-idesign-navy-120 rounded-lg ">
            <TableStyles>
              <Table className="bg-white w-full overflow-hidden rounded-lg border-collapse border-spacing-0">
                <TableHeader>
                  <TableRow className="bg-gradient-1 border-b border-idesign-navy-120">
                    <TableHead className="text-white font-bold text-lg ">
                      {courseLabel}
                    </TableHead>
                    <TableHead className="text-white font-bold text-lg border-x border-idesign-navy-120">
                      Estimated Time on Task
                    </TableHead>
                    <TableHead className="text-white font-bold text-lg">
                      Hours/Minutes
                    </TableHead>
                  </TableRow>
                </TableHeader>
                <TableBody>
                  {Model?.courseTots?.map((course, idx) => (
                    <TableRow key={idx}>
                      <TableCell
                        className={cn(
                          "p-2 border-b border-idesign-navy-120",
                          idx === Model.courseTots.length - 1 && "border-b-0",
                        )}
                      >
                        <Button
                          variant="ghost"
                          onClick={() => setTargetCourse(course.course)}
                          style={{
                            lineHeight: 1,
                            height: "fit-content",
                          }}
                          aria-haspopup="dialog"
                        >
                          {course.code}
                        </Button>
                      </TableCell>
                      <TableCell
                        className={cn(
                          "p-2 border border-idesign-navy-120",
                          idx === Model.courseTots.length - 1 && "border-b-0",
                        )}
                      >
                        <ProgressBar
                          tabIndex={-1}
                          value={course.tot_minutes}
                          max={Math.max(
                            ...Model.courseTots.map((o) => o.tot_minutes),
                          )}
                          labelVisible={false}
                        />
                      </TableCell>
                      <TableCell
                        className={cn(
                          "p-2 text-center center border-b border-idesign-navy-120",
                          idx === Model.courseTots.length - 1 && "border-b-0",
                        )}
                      >
                        {course.tot_minutes_display}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableStyles>
          </div>
          {(categoryFound || activityFound) && (
            <ToteSummaryVisuals data={Model} />
          )}
        </div>

        {targetCourse && (
          <div className="light">
            <Dialog
              open={modalVisible}
              onOpenChange={(open) => {
                if (!open) {
                  setTargetCourse(null);
                }
              }}
            >
              <DialogContent className="light max-w-[95%] h-full backdrop-blur-md">
                <DialogHeader>
                  <DialogTitle>{targetCourse.display_name}</DialogTitle>
                </DialogHeader>
                <ScrollArea className="h-full">
                  <ToteCourseDetails
                    key={targetCourse.code}
                    course={targetCourse}
                  />
                </ScrollArea>
              </DialogContent>
            </Dialog>
          </div>
        )}
      </CompStyles>
    );
  },
);

export default ToteSummary;
