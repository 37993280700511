import React, { useState } from "react";
import { useLocation, Outlet, Navigate } from "react-router-dom";

import { observer } from "mobx-react-lite";

import InvalidRoleAccess from "~/components/InvalidRoleAccess";
import { useStore as useRootStore } from "~/models/Root";

interface IRequireProtectedPath {
  allowedRoles: string[];
}

const RequireProtectedPath: React.FC<IRequireProtectedPath> = observer(
  (props) => {
    const { allowedRoles } = props;
    const { getAppUser, getLoggedIn } = useRootStore();
    const [user] = useState(getAppUser);
    const [isLoggedIn] = useState(getLoggedIn());
    const location = useLocation();

    const isUserRoleValid = () => {
      let isAllowed = false;
      allowedRoles.forEach((role) => {
        if (user.basic_permissions.includes(role)) {
          isAllowed = true;
        }
      });
      return isAllowed;
    };

    return isLoggedIn ? (
      isUserRoleValid() ? (
        <Outlet />
      ) : (
        <InvalidRoleAccess />
      )
    ) : (
      <Navigate to="/login" state={{ from: location }} replace />
    );
  },
);

export default RequireProtectedPath;
