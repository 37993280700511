import React, { useEffect, useContext } from "react";

import { useQuery, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import { observer } from "mobx-react-lite";
import styled from "styled-components";

import { API_PATHS, IData } from "~/api/ApiPaths";
import { AxiosContext } from "~/api/AxiosInstance";
import AlignLoadingIndicator from "~/components/AlignLoadingIndicator";
import Explorer from "~/components/explorer/Explorer";
import ExplorerLegend from "~/components/explorer/ExplorerLegend";
import FilterBadges from "~/components/explorer/filters/FilterBadges";
import FilterPopover from "~/components/explorer/filters/FilterPopover";
import ExplorerProvider, {
  useExplorerContext,
} from "~/context/ExplorerProvider";
import { useStore } from "~/models/AlignEditor";
import { useStore as useRootStore } from "~/models/Root";

const FlowMapWrapper = styled.div`
  flex: 1;
  display: flex;
  height: 100%;
  width: 98%;
  justify-content: center;
  margin: auto;
`;

const ExplorerRoute: React.FC = observer((props) => {
  const { initProgramTreeNode, isStoreReady, resetStoreIsReady } = useStore();
  const { getAuthToken, selectedProgramId } = useRootStore();

  const axiosInstance = useContext(AxiosContext) ?? axios;

  const queryFn = (): Promise<any> =>
    axiosInstance
      .request({
        method: "GET",
        url: `${API_PATHS.PROGRAM_DETAILS}/${selectedProgramId}`,
        headers: { Authorization: getAuthToken() },
      })
      .then((response) => response.data);

  const { data, isSuccess, error, isLoading } = useQuery({
    queryKey: [
      "explorerData",
      `${API_PATHS.PROGRAM_DETAILS}/${selectedProgramId}`,
    ],
    enabled: selectedProgramId !== 0,
    queryFn,
  });

  const queryClient = useQueryClient();

  useEffect(() => {
    queryClient.invalidateQueries({ queryKey: ["explorerData"] });
    resetStoreIsReady();
  }, [selectedProgramId, queryClient, resetStoreIsReady]);

  useEffect(() => {
    if (data && isSuccess && !isStoreReady) {
      const Data = data as IData;

      initProgramTreeNode([Data.entity]);
    }
  }, [data, initProgramTreeNode, isStoreReady, isSuccess]);

  if (isLoading) return <AlignLoadingIndicator />;

  return (
    <FlowMapWrapper className="relative bg-background">
      {isStoreReady && (
        <>
          <ExplorerProvider>
            <ExplorerRouteContent />
          </ExplorerProvider>
        </>
      )}
      {error && <div>{String(error)}</div>}
    </FlowMapWrapper>
  );
});

const ExplorerRouteContent = () => {
  const { selectedProgramId } = useRootStore();
  const { setBoundaryContainer } = useExplorerContext();

  return (
    <div className="h-full w-full" ref={setBoundaryContainer}>
      <div className="absolute top-4 left-4 z-10 flex gap-4">
        <FilterPopover />
        <FilterBadges />
      </div>
      <ExplorerLegend />
      <Explorer programId={selectedProgramId} />
    </div>
  );
};

export default ExplorerRoute;
