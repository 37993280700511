export const colors = {
  lightGrey: "#bbbbbb",
  loginGrey: "#ebebeb",
  secondaryGrey: "#ccd5d9",
  linkBlue: "#00a5e1",
  selectedBlue: "rgb(0 165 225 / 20%)",
  neutral100: "#002E40",
  white: "#ffffff",
  formButtonBlue: "#017FAE",
  formButtonGrey: "#E6EAEC",
  inactiveGrey: "#99ABB3",
  activeTabBlue: "#00A5E1",
  inactiveTabGrey: "#4D6D79",
  primary10: "#e6f6fc",
  info10: "#e6f2f7",
  headerLighBlue: "#f0f4ff",
  altRow: "#f8f9fe",
  error10: "#FBEAE7",
  error40: "#E9836F",
  error60: "#DB310F",
};

export const chartColors = [
  "#7cb9e8",
  "#00308f",
  "#00ffff",
  "#0066b2",
  "#0000ff",
  "#a3c1ad",
  "#6495ed",
  "#1034a6",
  "#6f00ff",
  "#b0c4de",
  "#f0f4ff",
];

// this file should be used to keep a single point of reference for style rules so it becomes easy to update in the future

export const mappingEditingStyle = `&.editing-active {
    position: relative;
    &::after {
        height: 100%;
        width: 100%;
        content:"";
        position: absolute;
        background-color: #00a5e14d;
        top: 0;
        left: 0;
    }
    .editing-banner {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
        display: flex;
        align-items: center;
        .status-label {
            color: black;
            padding: 1rem;
            margin-left: 1rem;        
            text-align: left;
            font-size: 2rem;
            display: flex;
            border-style: solid;
            border-width: 1px;
            border-color: #99abb3;
            border-radius: 6px;
            background-color: #e6eaec;
            box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.15);
        }
    }
}`;
