import React, { useEffect, useRef } from "react";

import { useFormikContext } from "formik";

import AlignButton from "~/components/AlignButton";
import AlignFormikTextInput from "~/components/AlignFormikTextInput";
import { IRegisterForm } from "~/components/login/Register";
import { colors } from "~/css/shared-styles";

interface IRegisterFirstPage {
  setShowNextPage: React.Dispatch<React.SetStateAction<boolean>>;
}

/**
 * @description component for registering for a new account, includes form
 * for inputting name, email, title . validation for all these fields
 */
const RegisterFirstPage: React.FC<IRegisterFirstPage> = (props) => {
  const { setShowNextPage } = props;
  const { errors, values, setFieldTouched, touched } =
    useFormikContext<IRegisterForm>();
  const readyForSubmit = useRef(false);

  useEffect(() => {
    if (Object.keys(touched).length !== 0) {
      if (
        Object.keys(errors).length === 0 &&
        values.firstName !== "" &&
        values.lastName !== "" &&
        values.title !== ""
      ) {
        readyForSubmit.current = true;
      } else {
        readyForSubmit.current = false;
      }
    }
  }, [errors, touched, values]);

  return (
    <>
      <h3>Set up your profile</h3>
      <AlignFormikTextInput
        label="First Name"
        name="firstName"
        placeholder="First Name"
        materialIconName="person_outline"
        iconAlignment="left"
      />
      <AlignFormikTextInput
        label="Last Name"
        name="lastName"
        placeholder="Last Name"
        materialIconName="person_outline"
        iconAlignment="left"
      />
      <AlignFormikTextInput
        label="Email"
        name="email"
        type="email"
        isReadOnly={true}
        placeholder="Email@example.com"
        materialIconName="mail_outline"
        iconAlignment="left"
      />
      <AlignFormikTextInput
        label="Title"
        name="title"
        placeholder="Title"
        materialIconName="people_outline"
        iconAlignment="left"
      />
      <div className="button-holder">
        <AlignButton
          label="Continue"
          type="button"
          onClick={() => {
            setFieldTouched("firstName", true, true);
            setFieldTouched("lastName", true, true);
            setFieldTouched("email", true, true);
            setFieldTouched("title", true, true);
            if (readyForSubmit.current) {
              setShowNextPage(true);
            }
          }}
          style={{
            marginBottom: "1.5rem",
            background: colors.formButtonBlue,
            color: "white",
            width: "fit-content",
            textAlign: "center",
          }}
        />
      </div>
    </>
  );
};

export default RegisterFirstPage;
