import React, { ReactNode } from "react";
import { useForm } from "react-hook-form";

import { Button } from "~/components/ui-rework/button";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogFooter,
  DialogTitle,
  DialogTrigger,
} from "~/components/ui-rework/dialog";
import { Input } from "~/components/ui-rework/input";
import { Label } from "~/components/ui-rework/label";
import { useProgramEditorContext } from "~/context/ProgramEditorProvider";
import { IProgramTreeNode, ProgramEditable } from "~/models/AlignEditor";

type ProgramDialogProps = {
  program: IProgramTreeNode;
  children: ReactNode;
  onSubmit: (data: ProgramEditable) => void;
};

const ProgramDialog = ({ program, children, onSubmit }: ProgramDialogProps) => {
  const {
    register,
    formState: { errors, isDirty, isValid },
    handleSubmit,
    reset,
  } = useForm({
    mode: "onChange",
    defaultValues: {
      code: program.code,
      name: program.name,
      display_name: program.display_name,
    },
  });

  const handleOpenChange = (open: boolean) => {
    if (open)
      reset({
        code: program.code,
        name: program.name,
        display_name: program.display_name,
      });
  };

  const { programLabel } = useProgramEditorContext();

  return (
    <Dialog onOpenChange={handleOpenChange}>
      <DialogTrigger asChild>{children}</DialogTrigger>
      <DialogContent>
        <form onSubmit={handleSubmit((data) => onSubmit(data))}>
          <DialogTitle className="mt-1">{`Edit ${programLabel}`}</DialogTitle>
          <div className="grid gap-4 py-4">
            <div className="flex flex-col gap-2">
              <Label htmlFor="code" className="font-bold mb-0">
                Code
              </Label>
              <Input
                id="code"
                className="bg-transparent"
                placeholder={`${programLabel} Code`}
                {...register("code", { required: "Code is required" })}
                aria-describedby={
                  errors.code?.message ? "code-error" : undefined
                }
              />
              {errors.code?.message && (
                <span
                  id="code-error"
                  className="text-sm font-medium text-error-50"
                >
                  {errors.code.message}
                </span>
              )}
            </div>

            <div className="flex flex-col gap-2">
              <Label htmlFor="name" className="font-bold mb-0">
                Name
              </Label>
              <Input
                id="name"
                className="bg-transparent"
                placeholder={`${programLabel} Name`}
                {...register("name", { required: "Name is required" })}
                aria-describedby={
                  errors.name?.message ? "name-error" : undefined
                }
              />
              {errors.name?.message && (
                <span
                  id="name-error"
                  className="text-sm font-medium text-error-50"
                >
                  {errors.name.message}
                </span>
              )}
            </div>

            <div className="flex flex-col gap-2">
              <Label htmlFor="display_name" className="font-bold mb-0">
                Display Name
              </Label>
              <Input
                id="display_name"
                className="bg-transparent"
                placeholder={`${programLabel} Display Name`}
                {...register("display_name", {
                  required: "Display Name is required",
                })}
                aria-describedby={
                  errors.display_name?.message
                    ? "display_name-error"
                    : undefined
                }
              />
              {errors.display_name?.message && (
                <span
                  id="display_name-error"
                  className="text-sm font-medium text-error-50"
                >
                  {errors.display_name.message}
                </span>
              )}
            </div>
          </div>
          <DialogFooter>
            <DialogClose asChild>
              <Button
                className="font-normal"
                type="submit"
                disabled={!isDirty || !isValid}
              >
                Save Changes
              </Button>
            </DialogClose>
          </DialogFooter>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default ProgramDialog;
