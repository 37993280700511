import React from "react";
import { Link as RouterLink } from "react-router-dom";

import { ArrowRight, ChevronRight } from "lucide-react";

import { sizes } from "~/components/ui-rework/label";
import { cn } from "~/lib/utils";

const linkSizes = {
  m: sizes.m,
  s: sizes.s,
};

type LinkProps = {
  to: string;
  mode?: "dark" | "light";
  children: React.ReactNode;
  size: keyof typeof linkSizes;
  onClick?: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
  className?: string;
  inline?: boolean;
  disabled?: boolean;
};

export const LINK_SIZE_KEYS = Object.keys(linkSizes) as Array<
  keyof typeof linkSizes
>;

const Link = ({
  to,
  children,
  size,
  onClick,
  className,
  disabled = false,
  inline = false,
}: LinkProps) => {
  return (
    <RouterLink
      to={to}
      className={cn(
        linkSizes[size],
        "text-link group block underline p-2 rounded-lg",
        "hover:no-underline",
        "active:text-link-active",
        "focus:outline-none focus:ring focus:ring-ring",
        className,
      )}
      onClick={disabled ? (e) => e.preventDefault() : onClick}
    >
      <div className="flex items-center">
        <span>{children}</span>
        {!inline && (
          <div className="relative inline-block">
            <span
              className={`absolute inset-0 flex items-center justify-center ${
                !disabled
                  ? "transition-opacity duration-200 opacity-100 group-hover:opacity-0 group-focus:opacity-0"
                  : ""
              }`}
            >
              <ChevronRight
                size={size === "s" ? 16 : 24}
                className="stroke-link-icon"
              />
            </span>
            <span
              className={
                !disabled
                  ? "transition-opacity duration-200 opacity-0 group-hover:opacity-100 group-focus:opacity-100"
                  : "opacity-0"
              }
            >
              <ArrowRight
                size={size === "s" ? 16 : 24}
                className="stroke-link-icon"
              />
            </span>
          </div>
        )}
      </div>
    </RouterLink>
  );
};

export default Link;
