import React, { useEffect, useRef, useState } from "react";

import {
  ColumnDef,
  ColumnFiltersState,
  SortingState,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";

import { API_PATHS } from "~/api/ApiPaths";
import { Button } from "~/components/ui/button";
import { Input } from "~/components/ui/input";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "~/components/ui/table";
import useAxios from "~/hooks/useAxios";
import useOverrides from "~/hooks/useOverrides";
import { useStore } from "~/models/Root";
import { IUserRoles } from "~/models/UserManager";

interface IUserPartnerDetails {
  userDetailsData: any | null;
}

const UserPartnerDetails: React.FC<IUserPartnerDetails> = (props) => {
  const { userDetailsData } = props;
  const displayReady = useRef(false);
  const [rolesData, setRolesData] = useState<IUserRoles[]>([]);
  const { data, authToken, error, requestUrl, fetch } = useAxios({
    method: "GET",
    initialValue: null,
  });
  const { getAuthToken } = useStore();
  const partnersFetched = useRef(false);
  const partnersSet = useRef(false);
  const { PartnerLabel, ProgramLabel } = useOverrides();
  const partnerLabel = PartnerLabel();
  const programLabel = ProgramLabel();
  const [sorting, setSorting] = React.useState<SortingState>([]);
  const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>(
    [],
  );
  const [tableBeginningSize, setTableBeginningSize] = useState(0);
  const [previousRowSize, setPreviousRowSize] = useState(0);

  useEffect(() => {
    if (data?.issuccess && !partnersSet.current && partnersFetched.current) {
      // console.log(data.entity)
      const allPartnersArray: IUserRoles[] = [];
      data.entity.forEach((item: IUserRoles) => {
        allPartnersArray.push({
          role_name: item.role_name,
          agency_name: item.agency_name,
          partner_name: item.partner_name,
          program_name: item.program_name,
        });
      });
      setRolesData(allPartnersArray);
      partnersSet.current = true;
      displayReady.current = false;
    } else if (!partnersFetched.current) {
      // fetch partner data
      partnersFetched.current = true;
      displayReady.current = false;
      requestUrl.current = `${API_PATHS.GET_USER_ROLES}?app_users_id=${userDetailsData?.id}`;
      authToken.current = getAuthToken();
      fetch();
    } else if (error) {
      console.log(error);
    }
  }, [
    data,
    error,
    authToken,
    requestUrl,
    fetch,
    getAuthToken,
    userDetailsData?.id,
    partnersFetched,
    partnersSet,
    displayReady,
  ]);

  const columns: ColumnDef<IUserRoles>[] = [
    {
      accessorKey: "partner_name",
      header: ({ column }) => {
        return (
          <Button
            className={`${
              column.getIsSorted() === "asc" ? "text-primary" : ""
            } p-0 w-full items-center justify-start`}
            variant="ghost"
            onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
          >
            {partnerLabel}
            <span aria-hidden="true" className="material-icons text-base">
              unfold_more
            </span>
          </Button>
        );
      },
    },
    {
      accessorKey: "program_name",
      header: ({ column }) => {
        return (
          <Button
            className={
              column.getIsSorted() === "asc" ? "text-primary p-0" : "p-0"
            }
            variant="ghost"
            onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
          >
            {programLabel}
            <span aria-hidden="true" className="material-icons text-base">
              unfold_more
            </span>
          </Button>
        );
      },
    },
    {
      accessorKey: "role_name",
      header: "User Type",
    },
  ];

  const table = useReactTable({
    data: rolesData,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    getFilteredRowModel: getFilteredRowModel(),
    state: {
      sorting,
      columnFilters,
    },
  });

  return (
    <div className="h-full w-full border rounded-md p-1">
      <Table className="w-full overflow-visible">
        <TableHeader>
          {table.getHeaderGroups().map((headerGroup) => (
            <TableRow key={headerGroup.id}>
              {headerGroup.headers.map((header) => {
                return (
                  <TableHead key={header.id}>
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                          header.column.columnDef.header,
                          header.getContext(),
                        )}
                  </TableHead>
                );
              })}
            </TableRow>
          ))}
          <TableRow>
            <TableHead>
              <Input
                placeholder="Filter partners..."
                value={
                  (table
                    .getColumn("partner_name")
                    ?.getFilterValue() as string) ?? ""
                }
                onChange={(event) =>
                  table
                    .getColumn("partner_name")
                    ?.setFilterValue(event.target.value)
                }
                className="m-0 border-0 p-0"
              />
            </TableHead>
            <TableHead>
              <Input
                placeholder="Filter programs..."
                value={
                  (table
                    .getColumn("program_name")
                    ?.getFilterValue() as string) ?? ""
                }
                onChange={(event) =>
                  table
                    .getColumn("program_name")
                    ?.setFilterValue(event.target.value)
                }
                className="m-0 border-0 p-0"
              />
            </TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {table.getRowModel().rows?.length ? (
            table.getRowModel().rows.map((row) => (
              <TableRow
                key={row.id}
                data-state={row.getIsSelected() && "selected"}
              >
                {row.getVisibleCells().map((cell) => (
                  <TableCell key={cell.id} className="py-[0.5rem]">
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </TableCell>
                ))}
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell colSpan={columns.length} className="h-24 text-center">
                No results.
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
      <div className="border-t flex flex-row items-center justify-between w-full py-2">
        <div className="flex-1 text-sm text-muted-foreground px-2">
          Items {tableBeginningSize} -{" "}
          {tableBeginningSize + table.getRowModel().rows.length} of{" "}
          {table.getFilteredRowModel().rows.length} total.
        </div>
        <div className="space-x-2 m-2">
          <Button
            className="rounded-md"
            size="sm"
            variant="outline"
            onClick={() => {
              table.previousPage();
              setTableBeginningSize(tableBeginningSize - previousRowSize);
            }}
            disabled={!table.getCanPreviousPage()}
          >
            Previous
          </Button>
          <Button
            className="rounded-md"
            size="sm"
            variant="outline"
            onClick={() => {
              table.nextPage();
              setTableBeginningSize(
                tableBeginningSize + table.getRowModel().rows.length,
              );
              setPreviousRowSize(table.getRowModel().rows.length);
            }}
            disabled={!table.getCanNextPage()}
          >
            Next
          </Button>
        </div>
      </div>
    </div>
  );
};

export default UserPartnerDetails;
