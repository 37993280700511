/* eslint-disable @typescript-eslint/no-empty-interface */
import { toJS } from "mobx";
import {
  Instance,
  SnapshotIn,
  applySnapshot,
  cast,
  getSnapshot,
  resolveIdentifier,
  types,
} from "mobx-state-tree";

export enum ListTypes {
  "LO" = "Learning Objectives",
  "CC" = "Core Competencies",
  "AB" = "Advanced Behaviors",
  "WS" = "Work Skills",
  "GL" = "Global List",
}

export const Mapping_Restrictions: string[] = [
  "Program",
  "Course",
  "Module",
  "Activity",
];

export type ListItemEditable = {
  code?: string;
  name: string;
  display_name: string;
  mapping_restrictions?: string[];
  is_heading?: boolean;
  is_mappable?: boolean;
};

//dropDown interface for lists manager list menu
export interface IAvailableList {
  id: number;
  name: string;
  list_type: string;
  is_global_list: boolean;
  is_active: boolean;
  agency_id: number;
  partner_id: number;
  program_id: number;
  course_id: number;
  created_at: number;
  created_by_id: number;
  updated_at: number | null;
  updated_by_id: number;
  description: string;
  mapped_programs: object[];
}

export const listManagerItem = types
  .model("ListManagerItem", {
    id: types.identifierNumber,
    created_at: types.number,
    list_id: types.number,
    display_name: types.string,
    code: types.maybeNull(types.string),
    name: types.optional(types.string, ""),
    parent_id: types.optional(types.number, -1),
    is_heading: types.optional(types.boolean, false),
    is_mappable: types.optional(types.boolean, true),
    mapping_restrictions: types.optional(types.array(types.string), []),
    display_order: types.optional(types.number, 0),
    transaction_type: types.optional(types.string, ""),
    move: types.optional(types.boolean, false),
    is_selected: types.optional(types.boolean, false),
    isChanged: types.optional(types.boolean, false),
    delete: types.optional(types.boolean, false),
    isNewItem: types.optional(types.boolean, false),
  })
  .actions((self) => ({
    selectItem() {
      self.is_selected = true;
    },
    unSelectItem() {
      console.log("unselecting item?", self);
      self.is_selected = false;
    },
    markForDelete() {
      self.delete = true;
    },
    markIsNewItem() {
      self.isNewItem = true;
    },
    markIsChanged() {
      self.isChanged = true;
    },
  }));

export type ListManagerListItem = Instance<typeof listManagerItem>;
export interface IListManagerListItem
  extends SnapshotIn<typeof listManagerItem> {}
export interface IListManagerListItemsForApi
  extends Omit<
    IListManagerListItem,
    "move" | "is_selected" | "is_changed" | "delete" | "isNewItem"
  > {}

const listManagerList = types
  .model("ListManagerList", {
    id: types.identifierNumber,
    name: types.string,
    list_type: types.optional(types.string, ""),
    is_global_list: types.optional(types.boolean, false),
    is_active: types.optional(types.boolean, false),
    agency_id: types.optional(types.number, 0),
    partner_id: types.optional(types.number, 0),
    program_id: types.optional(types.number, 0),
    course_id: types.optional(types.number, 0),
    created_at: types.number,
    created_by_id: types.optional(types.number, 0),
    updated_at: types.maybeNull(types.number),
    updated_by_id: types.optional(types.number, 0),
    items: types.optional(types.array(listManagerItem), []),
    expanded: types.optional(types.boolean, false),
  })
  .actions((self) => ({
    toggleItemSelection(itemId: string) {
      const foundItem = resolveIdentifier(listManagerItem, self.items, itemId);
      if (foundItem) {
        foundItem.is_selected = !foundItem.is_selected;
      }
    },
    unselectAllItems() {
      self.items.forEach((item) => {
        item.is_selected = false;
      });
    },
  }));

export type ListManagerListInstance = Instance<typeof listManagerList>;
export interface IListManagerList extends SnapshotIn<typeof listManagerList> {}

// eslint-disable-next-line
type ListManagerInput = {
  [key: string]: any;
} & ListManagerListInstance;

let lastSnapshot: any | null = null;

const ValidationError = types.model("ValidationError", {
  fieldName: types.string,
  action: types.string,
});

export type ValidationErrorInstance = Instance<typeof ValidationError>;
export interface IValidationError extends SnapshotIn<typeof ValidationError> {}

const ListManager = types
  .model("ListManager", {
    list: types.optional(types.array(listManagerList), []),
    areListHeaderChanged: types.optional(types.boolean, false),
    areListItemsChanged: types.optional(types.boolean, false),
    shouldReloadManagerView: types.optional(types.boolean, false),
    validationErrors: types.optional(types.array(ValidationError), []),
  })
  .views((self) => ({
    get areHeaderChanged() {
      return self.areListHeaderChanged;
    },
    get areItemsChanged() {
      return self.areListItemsChanged;
    },
    get shouldReloadView() {
      return self.shouldReloadManagerView;
    },
    get areValidationErrors() {
      return self.validationErrors.length > 0 ? true : false;
    },
  }))
  .actions((self) => ({
    resetList() {
      //console.log('resetting list')
      self.list = cast([]);
    },
    initListManagerData(data: IListManagerList) {
      applySnapshot(self.list, [data]);
      return getSnapshot(self.list)[0].id;
    },
    saveUpdatedListItems(updatedItems: IListManagerListItem[]) {
      //we save the updated items to the model in case something goes south with the post
      // we could theoretically retry the post with the items in the model.
      self.list[0].items = cast([]);
      applySnapshot(self.list[0].items, updatedItems);
    },
    getCurrentSnapshot() {
      // console.log(
      //   "getting current snapshot from list manager",
      //   getSnapshot(self.list),
      // );
      return getSnapshot(self.list);
    },
    getListItems() {
      return getSnapshot(self.list[0].items);
    },
    getListItemsInstance() {
      return self.list.length > 0 && self.list[0] ? self.list[0].items : [];
    },
    updateValue(fieldName: string, newValue: any) {
      switch (fieldName) {
        case "organization_id":
          break;
        case "name":
          self.list[0].name = newValue;
          this.setListHeaderChanged(true);
          break;
        case "list_type":
          self.list[0].list_type = newValue;
          this.setListHeaderChanged(true);
          break;
        case "is_active":
          self.list[0].is_active = newValue;
          this.setListHeaderChanged(true);
          break;
        default:
      }
    },
    setLastSnapshot() {
      lastSnapshot = getSnapshot(self.list);
    },
    revertChanges() {
      if (lastSnapshot) {
        applySnapshot(self.list, lastSnapshot);
        lastSnapshot = null;
      }
      self.validationErrors = cast([]);
      this.setListHeaderChanged(false);
      this.setListItemsChanged(false);
      return this.getCurrentSnapshot();
    },
    setListHeaderChanged(value: boolean) {
      self.areListHeaderChanged = value;
    },
    setListItemsChanged(value: boolean) {
      //console.log('setting list items changed to ', value)
      self.areListItemsChanged = value;
    },
    transformListItemForPost(item: any) {
      delete (item as any).isNewItem;
      delete (item as any).isChanged;
      delete (item as any).is_selected;
      delete (item as any).move;
      delete (item as any).delete;
      return item;
    },
    getItemsForDatabasePost(transaction_type: "Create" | "Update" | "Delete") {
      const currentModelItems = toJS(self.list[0].items);
      //console.log('current model items', currentModelItems)
      let itemsToPost: IListManagerListItemsForApi[] = [];
      if (transaction_type === "Create") {
        itemsToPost = currentModelItems.filter((item) => {
          return item.isNewItem;
        });
        itemsToPost = itemsToPost.map((item) => {
          const transItem = this.transformListItemForPost(item);
          transItem.mapping_restrictions = [];
          transItem.transaction_type = "Create";
          transItem.id = 0;
          return transItem as IListManagerListItemsForApi;
        });
      }
      if (transaction_type === "Update") {
        itemsToPost = currentModelItems.filter((item) => {
          return item.isChanged;
        });
        //console.log('items to post', itemsToPost, currentModelItems)
        itemsToPost = itemsToPost.map((item) => {
          const transItem = this.transformListItemForPost(item);
          transItem.transaction_type = "Update";
          return transItem as IListManagerListItemsForApi;
        });
      }
      if (transaction_type === "Delete") {
        itemsToPost = currentModelItems.filter((item) => {
          return item.delete;
        });
        itemsToPost = itemsToPost.map((item) => {
          const transItem = this.transformListItemForPost(item);
          transItem.transaction_type = "Delete";
          return transItem as IListManagerListItemsForApi;
        });
      }

      return itemsToPost as IListManagerListItemsForApi[];
    },
    setShouldReloadManagerView(value: boolean) {
      self.shouldReloadManagerView = value;
    },
    handleValidation(fieldName: string, action: "add" | "remove") {
      const foundError = self.validationErrors.find((err) => {
        return err.fieldName === fieldName;
      });
      if (!foundError && action === "add") {
        self.validationErrors.push({ fieldName, action });
        console.log("adding error", self.validationErrors);
      } else {
        const foundError = self.validationErrors.findIndex((err) => {
          return err.fieldName === fieldName;
        });
        if (foundError !== -1 && action === "remove") {
          self.validationErrors.splice(foundError, 1);
          console.log("removing error", self.validationErrors);
        }
      }
    },
  }));

export type ListManagerInstance = Instance<typeof ListManager>;
export interface IListManager extends SnapshotIn<typeof ListManager> {}

export default ListManager;
