import React, { Fragment, useMemo, useState } from "react";

import {
  ColumnDef,
  ExpandedState,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { ChevronDown, ChevronUp, FilePenLineIcon } from "lucide-react";
import { observer } from "mobx-react-lite";

import ActivityDialog from "~/components/program/dialogs/ActivityDialog";
import { DeleteCurriculumNode } from "~/components/program/dialogs/DeleteNodeDialog";
import MappingTable from "~/components/program/tables/MappingTable";
import {
  Table,
  TableHeader,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
} from "~/components/ui/table";
import { Button } from "~/components/ui-rework/button";
import { cn } from "~/lib/utils";
import {
  useStore,
  IActivity,
  NodeTypes,
  ActivityEditable,
} from "~/models/AlignEditor";

const GRADING_STAKES = new Map<string, string>([
  ["High Stakes", "High"],
  ["Medium Stakes", "Medium"],
  ["Low Stakes", "Low"],
  ["None", "None"],
]);

type ActivityTableProps = {
  className?: string;
  courseId: number;
  moduleId: number;
};

const ActivityTable = observer(
  ({ className, courseId, moduleId }: ActivityTableProps) => {
    const columns = useMemo<ColumnDef<IActivity>[]>(
      () => [
        {
          id: "expand",
          header: "",
          cell: ({ row }) => (
            <div className="flex">
              <button
                {...{
                  onClick: () => row.toggleExpanded(!row.getIsExpanded()),
                  style: { cursor: "pointer" },
                }}
                data-testid="activity-expand"
              >
                {row.getIsExpanded() ? (
                  <ChevronUp size="16px" />
                ) : (
                  <ChevronDown size="16px" />
                )}
              </button>
            </div>
          ),
        },
        {
          id: "edit",
          header: "",
          cell: ({ row }) => (
            <div className="flex">
              <ActivityDialog
                activity={row.original}
                onSubmit={(data) => handleEditActivity(data, row.original)}
              >
                <button
                  data-testid="program-edit-activity"
                  title="Edit Activity"
                  aria-label="Edit Activity"
                >
                  <FilePenLineIcon className="stroke-ocean-120" size="16px" />
                </button>
              </ActivityDialog>
            </div>
          ),
        },
        {
          id: "code",
          header: "Code",
          cell: ({ row }) => <div>{row.original.code}</div>,
        },
        {
          id: "name",
          header: "Name",
          cell: ({ row }) => <div>{row.original.name}</div>,
        },
        {
          id: "display_name",
          header: "Display Name",
          cell: ({ row }) => <div>{row.original.display_name}</div>,
        },
        {
          id: "tot_minutes",
          header: () => <div className="text-end">Total Minutes</div>,
          cell: ({ row }) => (
            <div className="text-end">{row.original.tot_minutes}</div>
          ),
        },
        {
          id: "activity_type",
          header: "Type",
          cell: ({ row }) => <div>{row.original.activity_type}</div>,
        },
        {
          id: "is_assessment",
          header: () => <div className="text-center">Assessment</div>,
          cell: ({ row }) => (
            <div className="text-center">
              {row.original.is_assessment ? "Yes" : "No"}
            </div>
          ),
        },
        {
          id: "modality",
          header: "Modality",
          cell: ({ row }) => <div>{row.original.modality}</div>,
        },
        {
          id: "collaboration",
          header: "Collaboration",
          cell: ({ row }) => <div>{row.original.collaboration}</div>,
        },
        {
          id: "experience_type",
          header: "Experience Type",
          cell: ({ row }) => <div>{row.original.experience_type}</div>,
        },
        {
          id: "engagement_types",
          header: "Engagement Types",
          cell: ({ row }) => <div>{row.original.engagement_types}</div>,
        },
        {
          id: "blooms",
          header: "Blooms",
          cell: ({ row }) => <div>{row.original.blooms}</div>,
        },
        {
          id: "grading_points",
          header: () => <div className="text-end">Points</div>,
          cell: ({ row }) => (
            <div className="text-end">{row.original.grading_points}</div>
          ),
        },
        {
          id: "grading_stakes",
          header: "Stakes",
          cell: ({ row }) => (
            <div>
              {GRADING_STAKES.get(row.original.grading_stakes ?? "") ?? "None"}
            </div>
          ),
        },
        {
          id: "delete",
          header: "",
          cell: ({ row }) => (
            <DeleteCurriculumNode
              id={row.original.id}
              type={NodeTypes.Activity}
              display_name={row.original.display_name}
              onDelete={deleteActivity}
            />
          ),
        },
      ],
      [], // eslint-disable-line react-hooks/exhaustive-deps
    );

    const {
      getProgram,
      getModuleActivities,
      saveActivityChanges,
      deleteCurriculumNode,
    } = useStore();

    const [data, setData] = useState<IActivity[]>(
      getModuleActivities(courseId, moduleId),
    );

    const handleAddActivity = (activity: ActivityEditable) => {
      getProgram()?.addActivity(activity, courseId, moduleId);
      setData(getModuleActivities(courseId, moduleId));
    };

    const handleEditActivity = (
      activityUpdates: ActivityEditable,
      activityOriginal: IActivity,
    ) => {
      const activity = {
        ...activityOriginal,
        ...activityUpdates,
        engagement_types: activityUpdates.engagement_types?.join(", "),
        blooms: activityUpdates.blooms?.join(", "),
        grading_stakes: activityUpdates.grading_stakes ?? undefined,
      };
      setData(saveActivityChanges(activity, courseId, moduleId));
    };

    const deleteActivity = (activityId: number) => {
      deleteCurriculumNode(activityId, NodeTypes.Activity);
      setData(getModuleActivities(courseId, moduleId));
    };

    const [expanded, setExpanded] = useState<ExpandedState>({});

    const table = useReactTable({
      data,
      columns,
      state: {
        expanded,
      },
      onExpandedChange: setExpanded,
      getCoreRowModel: getCoreRowModel(),
    });

    return (
      <>
        <div className="bg-gradient-1 text-white font-semibold flex gap-2 items-center w-full px-3 py-2 border-t border-l border-idesign-navy-120">
          <span className="text-base">Activities</span>
          <ActivityDialog onSubmit={handleAddActivity}>
            <Button
              className="dark text-sm py-1 px-2 font-normal"
              variant="outline"
              testid="program-add-activity"
            >
              Add Activity
            </Button>
          </ActivityDialog>
        </div>
        {data.length > 0 && (
          <div className="w-full flex text-[0px]">
            <Table
              className={cn(
                "max-w-fit overflow-x-auto table-fixed",
                "border border-r-0 border-idesign-navy-120",
                className,
              )}
            >
              <colgroup>
                <col style={{ width: "33px" }} />
                <col style={{ width: "33px" }} />
                <col style={{ width: "120px" }} />
                <col style={{ width: "33%", minWidth: "200px" }} />
                <col style={{ width: "66%", minWidth: "200px" }} />
                <col style={{ width: "104px" }} />
                <col style={{ width: "104px" }} />
                <col style={{ width: "96px" }} />
                <col style={{ width: "156px" }} />
                <col style={{ width: "104px" }} />
                <col style={{ width: "172px" }} />
                <col style={{ width: "134px" }} />
                <col style={{ width: "110px" }} />
                <col style={{ width: "70px" }} />
                <col style={{ width: "68px" }} />
                <col style={{ width: "33px" }} />
              </colgroup>
              <TableHeader className="bg-gradient-1">
                {table.getHeaderGroups().map((headerGroup) => (
                  <TableRow
                    key={headerGroup.id}
                    className="hover:bg-transparent"
                  >
                    {headerGroup.headers.map((header) => {
                      return (
                        <TableHead
                          className={cn(
                            "text-white font-bold border-x border-idesign-navy-120 p-2 py-4",
                            "last:border-r-0 first:border-x-0 [&:nth-child(2)]:border-x-0",
                          )}
                          key={header.id}
                        >
                          {header.isPlaceholder
                            ? null
                            : flexRender(
                                header.column.columnDef.header,
                                header.getContext(),
                              )}
                        </TableHead>
                      );
                    })}
                  </TableRow>
                ))}
              </TableHeader>
              <TableBody>
                {table.getRowModel().rows?.length ? (
                  table.getRowModel().rows.map((row) => (
                    <Fragment key={row.id}>
                      <TableRow
                        key={row.id + "_original"}
                        className={cn(
                          "hover:bg-gray-200 border-b-0",
                          "[&_td:last-child]:border-r-0 [&_td:first-child]:border-l-0",
                          {
                            "bg-muted": row.index % 2 !== 0,
                            "bg-white": row.index % 2 === 0,
                          },
                        )}
                      >
                        {row.getVisibleCells().map((cell) => (
                          <TableCell
                            key={cell.id}
                            className="p-2 border-x border-idesign-navy-120"
                          >
                            {flexRender(
                              cell.column.columnDef.cell,
                              cell.getContext(),
                            )}
                          </TableCell>
                        ))}
                      </TableRow>
                      {row.getIsExpanded() ? (
                        <tr
                          key={row.id + "_expanded"}
                          className={cn({
                            "bg-muted": row.index % 2 !== 0,
                            "bg-white": row.index % 2 === 0,
                          })}
                        >
                          <TableCell
                            colSpan={columns.length}
                            className={cn("pl-8 pr-0 py-0", {
                              // "border-b border-idesign-navy-120":
                              //   row.index !== table.getRowModel().rows.length - 1,
                            })}
                          >
                            <MappingTable
                              className={cn({
                                "border-b border-idesign-navy-120":
                                  row.index !==
                                  table.getRowModel().rows.length - 1,
                              })}
                              nodeId={row.original.id}
                              nodeType={NodeTypes.Activity}
                            />
                          </TableCell>
                        </tr>
                      ) : null}
                    </Fragment>
                  ))
                ) : (
                  <TableRow>
                    <TableCell
                      colSpan={columns.length}
                      className="h-24 text-center"
                    >
                      No results.
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </div>
        )}
      </>
    );
  },
);

export default ActivityTable;
