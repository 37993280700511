import React, { Fragment } from "react";

import { observer } from "mobx-react-lite";

import CourseModule from "~/components/Reports/CourseModule";
import * as ReportModels from "~/components/Reports/ReportModels";
import { ScrollArea } from "~/components/ui/scroll-area";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "~/components/ui-rework/accordion";
import { Dropdown } from "~/components/ui-rework/dropdown";
import { Select } from "~/components/ui-rework/select";
import EmptyStateGraphic from "~/images/Reports-Program-Course-Activities-Modules.svg";
import { useStore } from "~/models/Root";

const CourseModules: React.FC<{
  programData: ReportModels.ProgramData | null;
  forPrint?: boolean;
}> = observer(({ programData, forPrint = false }) => {
  const { getDropdownListReports, setTargetListReports, getTargetListReports } =
    useStore();
  const listItems = getDropdownListReports();
  const targetListVar = getTargetListReports();

  const handleDropdownChange = (newValue: string) => {
    const list =
      programData?.mapped_lists.find(
        (item: ReportModels.List) => item.id === parseInt(newValue),
      ) || null;
    if (list) {
      setTargetListReports(list);
    }
  };

  const handleSelectChange = (fieldname: string, newValue: string) => {
    const list =
      programData?.mapped_lists.find(
        (item: ReportModels.List) => item.id === parseInt(newValue),
      ) || null;
    if (list) {
      setTargetListReports(list);
    }
  };

  return (
    <>
      {!targetListVar && listItems.length > 0 ? (
        <div className="h-full flex flex-row gap-9 px-24 justify-center items-center">
          <img
            alt="Course Modules Reports Graphic"
            src={EmptyStateGraphic}
            className="w-[632px] h-[632px]"
          />
          <div className="flex flex-col gap-4 justify-center max-w-fit">
            <h3 className="text-lg font-bold text-idesign-navy-100">
              Select a List
            </h3>
            <p className="text-base font-normal text-idesign-navy-100 w-[415px]">
              Select a list to see a breakdown of the course modules
            </p>
            <div className="w-[85%]">
              <Select
                fieldName="list-loader-reports"
                options={listItems}
                placeholder="Select a list"
                testid="course-modules-list-dropdown"
                value={undefined}
                handleChange={handleSelectChange}
              />
            </div>
          </div>
        </div>
      ) : (
      <ScrollArea className="w-full h-full" thumbClassName="bg-gradient-1">
        <div className="py-2">
          <div className="w-fit pl-1">
            {!forPrint && (
              <>
                {listItems.length > 0 && targetListVar && (
                  <Dropdown
                    options={listItems}
                    placeholder="Select a list"
                    testid="course-modules-list-dropdown"
                    value={targetListVar?.id.toString() || undefined}
                    handleChange={handleDropdownChange}
                    variant="outline"
                  />
                )}
              </>
            )}
          </div>
          <div className="flex-col w-full pr-4">
            {targetListVar && targetListVar.id > 0 && (
              <div
                className={
                  forPrint ? "mt-4" : listItems.length === 0 ? "mt-0" : "mt-4"
                }
              >
                {programData?.courses.map((course) => (
                  <Fragment key={course.code}>
                    {forPrint === true ? (
                      <div className="mt-1">
                        <CourseModule
                          course={course}
                          list={targetListVar}
                          expandable={false}
                        />
                      </div>
                    ) : (
                      <Accordion
                        type="single"
                        collapsible
                        variant="primary"
                        className="mb-2"
                      >
                        <AccordionItem value={course.display_name}>
                          <AccordionTrigger>
                            {course.display_name}
                          </AccordionTrigger>
                          <AccordionContent className="mx-4 w-full">
                            <CourseModule
                              course={course}
                              list={targetListVar}
                              expandable={true}
                            />
                          </AccordionContent>
                        </AccordionItem>
                      </Accordion>
                    )}
                  </Fragment>
                ))}
              </div>
            )}
          </div>
        </div>
      </ScrollArea>
      )}
    </>
  );
});

export default CourseModules;
