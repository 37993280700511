import React from "react";

import { Laptop, Sparkles } from "lucide-react";

export const MappingAssistantLoading = () => {
  return (
    <div className="flex flex-col items-center justify-center light pt-24">
      <div className="flex justify-center items-center gap-6 pb-11">
        <div className="flex justify-center items-center rounded-full h-[72px] w-[72px] bg-ocean-100">
          <Sparkles size={38} className="stroke-white" />
        </div>
        <span
          style={{ animationDelay: "0ms" }}
          className="animate-blink h-4 w-4 rounded-full bg-ocean-100"
        />
        <span
          style={{ animationDelay: "150ms" }}
          className="animate-blink h-4 w-4 rounded-full bg-ocean-100"
        />
        <span
          style={{ animationDelay: "300ms" }}
          className="animate-blink h-4 w-4 rounded-full bg-ocean-100"
        />
        <span
          style={{ animationDelay: "450ms" }}
          className="animate-blink h-4 w-4 rounded-full bg-ocean-100"
        />
        <div className="flex justify-center items-center rounded-full h-[72px] w-[72px] bg-ocean-30">
          <Laptop size={38} className="stroke-white" />
        </div>
      </div>
      <span className="text-idesign-navy-100 text-lg font-bold pb-5">
        Results are on the way!
      </span>
      <span className="text-idesign-navy-100">
        Mapping Assistant is fetching suggestions for you.
      </span>
    </div>
  );
};
