import React from "react";

import CourseModuleDetail from "~/components/Reports/CourseModuleDetail";
import * as ReportModels from "~/components/Reports/ReportModels";
import { Course, List } from "~/components/Reports/ReportModels";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "~/components/ui-rework/accordion";

interface IExpandableModule {
  is_expanded: boolean;
  module: ReportModels.Module;
  module_id: number;
  module_display_name: string;
  mappings: ReportModels.MappingItem[];
  activities: ReportModels.Activity[];
  activity_count: number;
  activities_tot_minutes_display: string;
}

export class CompModel {
  constructor(course: ReportModels.Course, list_id: number) {
    this.course_display_name = course.display_name;
    this.credits = course.credits;
    this.module_count = course.modules?.length || 0;

    this.modules =
      course.modules?.map((module) => {
        return {
          is_expanded: false,
          module: module,
          module_id: module.id,
          module_display_name: module.display_name,
          mappings:
            module.mappings.filter((mapping) => mapping.list_id === list_id) ||
            [],
          activities: module.activities || [],
          activity_count: module.activities?.length || 0,
          activities_tot_minutes_display: ReportModels.formatMinutes(
            module.activities?.reduce(
              (acc, activity) => acc + activity.tot_minutes,
              0,
            ) || 0,
          ),
        } as IExpandableModule;
      }) || [];
  }

  course_display_name = "";
  credits = 0;
  module_count = 0;

  modules: IExpandableModule[] = [];
}

const CourseModule: React.FC<{
  course: Course;
  list: List;
  expandable: boolean;
  listItemId?: number;
}> = ({ course, list, expandable = true, listItemId }) => {
  const getTitle = function (
    //checks if module is mapped or not and returns the title accordingly
    list: ReportModels.List,
    modules: ReportModels.Module,
    module: IExpandableModule,
  ): string {
    if (listItemId) {
      return `${module.module_display_name}`;
    }
    const moduleMappings =
      modules.mappings?.filter((x) => x.list_id === list.id) || [];
    const activityMappings =
      modules.activities?.flatMap((a) =>
        a.mappings.filter((x) => x.list_id === list.id),
      ) || [];
    const mappings = moduleMappings.concat(activityMappings.flat());
    if (mappings.length > 0) {
      return `${module.module_display_name} - (Mapped)`;
    } else {
      return `${module.module_display_name} - (Not Mapped)`;
    }
  };

  // normally it might be best to seperate this into two functions,
  // but since it is iterating using forEach for the same data I decided to keep it together
  const createModelAndIdentifyActivities = function (): [CompModel, number[]] {
    const Model = new CompModel(course, list?.id);
    if (!listItemId) {
      return [Model, []];
    }
    const newModuleArray: IExpandableModule[] = [];
    const activitiesToShow = new Array<number>();
    Model.modules.forEach((module) => {
      let listItemFound = false;
      module.activities.forEach((activity) => {
        activity.mappings.forEach((mapping) => {
          if (mapping.listitem_id === listItemId) {
            listItemFound = true;
            activitiesToShow.push(mapping.c_item_id);
          }
        });
      });
      if (listItemFound) {
        newModuleArray.push(module);
      }
    });
    Model.modules = newModuleArray;
    return [Model, activitiesToShow];
  };

  // useStates for the function above
  const [Model] = React.useState(createModelAndIdentifyActivities()[0]);
  const [activitiesToShow] = React.useState(
    createModelAndIdentifyActivities()[1],
  );

  return (
    <>
      <div>
        {expandable && (
          <>
            {Model.modules.map((x) => {
              const title = getTitle(list, x.module, x);
              return (
                <Accordion
                  type="single"
                  key={x.module_id}
                  collapsible
                  variant="secondary"
                  // defaultExpanded={listItemId ? true : false}
                  className="mb-2 mr-8" 
                >
                  <AccordionItem value={title}>
                    <AccordionTrigger>{title}</AccordionTrigger>
                    <AccordionContent>
                      <CourseModuleDetail
                        key={x.module_id}
                        module={x.module}
                        list_id={list.id}
                        fromAlignmentGrid={listItemId ? true : false}
                        activityIDDisplay={activitiesToShow}
                      />
                    </AccordionContent>
                  </AccordionItem>
                </Accordion>
              );
            })}
          </>
        )}

        {!expandable && (
          <>
            {Model.modules.map((x) => (
              <CourseModuleDetail
                key={x.module_id}
                module={x.module}
                list_id={list.id}
                fromAlignmentGrid={listItemId ? true : false}
              />
            ))}
          </>
        )}
      </div>
    </>
  );
};

export default CourseModule;
