import React, { useEffect, useRef, useState } from "react";

import { observer } from "mobx-react-lite";

import { ApiUrls, IData } from "~/api/ApiPaths";
import ListEditor from "~/components/lists/ListEditor/ListEditor";
import ListEditorProvider from "~/context/ListEditorProvider";
import useAxios from "~/hooks/useAxios";
import { useStore } from "~/models/AlignEditor";
import { useStore as useRootStore } from "~/models/Root";

interface IListManagerLoaderProps {
  listId: number;
  setSelectedListId: React.Dispatch<React.SetStateAction<number | null>>;
}

const ListManagerLoader = observer(
  ({ listId, setSelectedListId }: IListManagerLoaderProps) => {
    const {
      initListManager,
      listManagerReady,
      resetListManager,
      listManagerInstance,
    } = useStore();
    const shouldReloadManagerView =
      listManagerInstance().shouldReloadManagerView;
    const { data, error, fetch, requestUrl, authToken } = useAxios({
      method: "GET",
      initialValue: null,
    });
    const [currentListId, setCurrentListId] = useState<number>(-1);
    const apiFetched = useRef(false);
    const { getAuthToken } = useRootStore();

    useEffect(() => {
      if (listManagerInstance().shouldReloadManagerView) {
        //console.log('should reload manager view detected')
        listManagerInstance().setShouldReloadManagerView(false);
        apiFetched.current = false;
        setCurrentListId(-1);
      }
    }, [listManagerInstance, shouldReloadManagerView, setCurrentListId]);

    useEffect(() => {
      if (apiFetched.current && listId !== -1 && listId !== currentListId) {
        apiFetched.current = false;
      }
    }, [listId, currentListId]);

    useEffect(() => {
      if (!apiFetched.current && listId !== -1 && listId !== currentListId) {
        //console.log('how many times here??', currentListId, listId)
        apiFetched.current = true;
        resetListManager();
        requestUrl.current = `${ApiUrls.lists.getListDetails(listId)}`;
        authToken.current = getAuthToken();
        fetch();
      }
    }, [
      listId,
      resetListManager,
      requestUrl,
      fetch,
      currentListId,
      getAuthToken,
      authToken,
    ]);

    useEffect(() => {
      const Data = data as IData;
      if (data) {
        //console.log('data?', data)
        if (Data.issuccess) {
          setCurrentListId(initListManager(Data.entity));
        }
      }
    }, [data, initListManager, setCurrentListId]);

    return (
      <div className="flex flex-col w-full px-5">
        {apiFetched.current && listManagerReady && (
          <>
            <ListEditorProvider>
              <ListEditor
                listId={currentListId}
                setSelectedListId={setSelectedListId}
              />
            </ListEditorProvider>
          </>
        )}
        {error !== "" && <div>{JSON.stringify(error)}</div>}
      </div>
    );
  },
);

export default ListManagerLoader;
