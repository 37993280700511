import React from "react";

import { observer } from "mobx-react-lite";

import { Check, Edit2 } from "lucide-react";
import { Button } from "~/components/ui-rework/button";
import { useStore } from "~/models/AlignEditor";

interface IAlignEditModeButton {
  readOnlyMode: boolean;
  setReadOnlyMode: React.Dispatch<React.SetStateAction<boolean>>;
  enterEditMode: () => void;
  cancelChanges: () => void;
  saveChanges: () => void;
  onClickCallback?: () => void;
  variant?:
    | "primary"
    | "ghost"
    | "outline"
}

const AlignEditModeButton = observer(
  ({
    readOnlyMode,
    setReadOnlyMode,
    enterEditMode,
    cancelChanges,
    saveChanges,
    onClickCallback,
    variant = "primary",
  }: IAlignEditModeButton) => {
    const { listManagerInstance } = useStore();
    const disabled = listManagerInstance().areValidationErrors;
    return (
      <div className="flex justify-end gap-4">
        {!readOnlyMode && disabled && (
          <span className="font-semibold inline-flex italic items-center text-[#DB310F] text-[0.8rem]">
            <span
              className="material-icons text-[1.3rem] mr-[0.2rem]"
              aria-hidden="true"
            >
              warning
            </span>
            <span>Required values.</span>
          </span>
        )}
        {readOnlyMode && (
          <Button
            variant={variant}
            className="min-w-[5rem]"
            onClick={() => {
              setReadOnlyMode(!readOnlyMode);
              enterEditMode();
              if (onClickCallback) {
                onClickCallback();
              }
            }}
            testid="lists-edit"
          >
            <Edit2 size={24} className="mr-2"/>
            Edit
          </Button>
        )}
        {!readOnlyMode && (
          <div className="flex gap-2">
            <Button
              variant="outline"
              onClick={cancelChanges}
            >
              Cancel
            </Button>
            <Button
              variant="primary"
              onClick={saveChanges}
              disabled={disabled}
              testid="lists-save"
            >
              <Check size={24} className="mr-2"/>
              Save
            </Button>
          </div>
        )}
      </div>
    );
  },
);

export default AlignEditModeButton;
