import React from "react";

import { useFormikContext } from "formik";

import AlignFormikTextInput from "~/components/AlignFormikTextInput";
import { Button } from "~/components/ui/button";

interface IUserDetails {
  userDetailsData: any | null;
  revertFormValues: IUserDetailsForm;
  editMode: boolean;
  setEditMode: React.Dispatch<React.SetStateAction<boolean>>;
}

export interface IUserDetailsForm {
  firstName: string;
  lastName: string;
  email: string;
  title: string;
}

const UserDetails: React.FC<IUserDetails> = (props) => {
  const { userDetailsData, revertFormValues, editMode, setEditMode } = props;
  const { setFieldValue, submitForm } = useFormikContext();

  const revertChanges = () => {
    setFieldValue("firstName", revertFormValues.firstName);
    setFieldValue("lastName", revertFormValues.lastName);
    setFieldValue("email", revertFormValues.email);
    setFieldValue("title", revertFormValues.title);
  };

  return (
    <>
      <AlignFormikTextInput
        label="First Name"
        name="firstName"
        placeholder="First Name"
        materialIconName="person_outline"
        iconAlignment="left"
        disabled={editMode ? false : true}
      />
      <AlignFormikTextInput
        label="Last Name"
        name="lastName"
        placeholder="Last Name"
        materialIconName="person_outline"
        iconAlignment="left"
        disabled={editMode ? false : true}
      />
      <AlignFormikTextInput
        label="Email address"
        name="email"
        type="email"
        placeholder="Email address"
        materialIconName="mail_outline"
        iconAlignment="left"
        disabled={editMode ? false : true}
      />
      <AlignFormikTextInput
        label="Title"
        name="title"
        placeholder="Title"
        materialIconName="people_outline"
        iconAlignment="left"
        disabled={editMode ? false : true}
      />
      <span className="font-bold">User Type:</span>
      <br />
      {userDetailsData?.user_type}
      {editMode && (
        <div className="flex flex-row justify-end mb-2">
          <Button
            type="button"
            variant="destructive"
            className="hover:bg-red-800 rounded-full mt-3 mr-3"
            onClick={() => {
              setEditMode(false);
              revertChanges();
            }}
          >
            Cancel Changes
          </Button>
          <Button
            variant="blue"
            className="mt-3"
            type="submit"
            onClick={() => {
              submitForm();
            }}
            testid="user-save-changes"
          >
            Save Changes
          </Button>
        </div>
      )}
    </>
  );
};

export default UserDetails;
