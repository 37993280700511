import React, { useEffect, useRef, useState } from "react";

import { Reveal } from "@progress/kendo-react-animation";
import {
  ExpansionPanel,
  ExpansionPanelActionEvent,
  ExpansionPanelContent,
} from "@progress/kendo-react-layout";
import styled from "styled-components";

import { ApiUrls } from "~/api/ApiPaths";
import AlignLoadingIndicator from "~/components/AlignLoadingIndicator";
import { ModalStyles } from "~/components/PartnerCreationModal";
import SessionActivity from "~/components/SessionActivity";
import useAxios from "~/hooks/useAxios";
import { useStore } from "~/models/Root";

const SessionStyles = styled.div`
  .k-expander-content {
    padding: 0;
  }
`;

interface ILastLoginDialog {
  userDetails: any;
}

export interface IUserSession {
  activity: [];
  created_at: number;
  duration_seconds: number;
  id: number;
  last_activity: number;
  login_type: string;
  session_date: string;
  session_start: number;
  user_id: number;
}

const LastLoginDialog: React.FC<ILastLoginDialog> = (props) => {
  const { userDetails } = props;
  const { data, authToken, error, requestUrl, fetch } = useAxios({
    method: "GET",
    initialValue: null,
  });
  const dataFetched = useRef(false);
  const { getAuthToken } = useStore();
  const [expandedSession, setExpandedSession] = useState(0);
  const [userSessions, setUserSessions] = useState<IUserSession[]>();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (data?.issuccess && !dataFetched.current) {
      dataFetched.current = true;
      const sessionData: IUserSession[] = [];
      data.entity.sessions.forEach((item: IUserSession) => {
        sessionData.push({
          activity: item.activity,
          created_at: item.created_at,
          duration_seconds: item.duration_seconds,
          id: item.id,
          last_activity: item.last_activity,
          login_type: item.login_type,
          session_date: item.session_date,
          session_start: item.session_start,
          user_id: item.user_id,
        });
      });
      sessionData.reverse();
      setUserSessions(sessionData);
      setLoading(false);
    } else if (!dataFetched.current) {
      // fetch user data
      requestUrl.current = ApiUrls.sessions.getUserSessions(userDetails.id);
      authToken.current = getAuthToken();
      fetch();
    } else if (error) {
      console.log(error);
    }
  }, [data, error, authToken, requestUrl, fetch, getAuthToken, userDetails]);

  return (
    <ModalStyles>
      <div className="modal-container">
        {loading ? (
          <AlignLoadingIndicator />
        ) : (
          userSessions?.map((session) => (
            <>
              <ExpansionPanel
                key={session.id}
                title={`${session.session_date}`}
                subtitle={
                  session.duration_seconds > 60
                    ? `${Math.floor(session.duration_seconds / 60)} minutes`
                    : `${session.duration_seconds} seconds`
                }
                expanded={expandedSession === session.id}
                tabIndex={0}
                onAction={(event: ExpansionPanelActionEvent) => {
                  setExpandedSession(event.expanded ? 0 : session.id);
                }}
              >
                <Reveal>
                  {expandedSession === session.id && (
                    <SessionStyles>
                      <ExpansionPanelContent>
                        <div key={session.id} className="content">
                          <SessionActivity
                            key={session.id}
                            sessionDetails={session.activity}
                          />
                        </div>
                      </ExpansionPanelContent>
                    </SessionStyles>
                  )}
                </Reveal>
              </ExpansionPanel>
            </>
          ))
        )}
      </div>
    </ModalStyles>
  );
};

export default LastLoginDialog;
