import React, {
  createContext,
  useContext,
  useState,
  SetStateAction,
} from "react";

import { Column } from "@tanstack/react-table";

import { ListDetailsTree } from "~/components/program/mapping/hooks/useListDetailsTreeQuery";
import { SelectOption } from "~/components/ui/select";
import { ItemRow, NodeTypes } from "~/models/AlignEditor";

type MappingSheetContextType = {
  selectedList?: SelectOption;
  setSelectedList: React.Dispatch<SetStateAction<SelectOption | undefined>>;
  parentItemId?: number;
  setParentItemId: React.Dispatch<SetStateAction<number | undefined>>;
  listDetailsTree?: ListDetailsTree;
  setListDetailsTree: React.Dispatch<
    SetStateAction<ListDetailsTree | undefined>
  >;
  lastSaved?: number;
  setLastSaved: React.Dispatch<SetStateAction<number | undefined>>;
  filterColumn?: Column<any, unknown>;
  setFilterColumn: React.Dispatch<
    SetStateAction<Column<any, unknown> | undefined>
  >;
  listItemHierarchy?: ItemRow[];
  setListItemHierarchy: React.Dispatch<SetStateAction<ItemRow[] | undefined>>;
  parentItemOptions?: SelectOption[];
  setParentItemOptions: React.Dispatch<
    SetStateAction<SelectOption[] | undefined>
  >;
  nodeId: number;
  nodeType: NodeTypes;
  selectedListId: number;
};

export const MappingSheetContext = createContext<MappingSheetContextType>({
  selectedList: undefined,
  setSelectedList: () => undefined,
  parentItemId: undefined,
  setParentItemId: () => undefined,
  listDetailsTree: undefined,
  setListDetailsTree: () => undefined,
  lastSaved: undefined,
  setLastSaved: () => undefined,
  filterColumn: undefined,
  setFilterColumn: () => undefined,
  listItemHierarchy: undefined,
  setListItemHierarchy: () => undefined,
  parentItemOptions: undefined,
  setParentItemOptions: () => undefined,
  nodeId: -1,
  nodeType: NodeTypes.Program,
  selectedListId: -1,
});

type MappingSheetProviderProps = {
  nodeId: number;
  nodeType: NodeTypes;
} & { children: React.ReactNode };

const MappingSheetProvider = ({
  nodeId,
  nodeType,
  children,
}: MappingSheetProviderProps) => {
  const [selectedList, setSelectedList] = useState<SelectOption>();
  const [parentItemId, setParentItemId] = useState<number>();
  const [listDetailsTree, setListDetailsTree] = useState<ListDetailsTree>();
  const [lastSaved, setLastSaved] = useState<number>();
  const [filterColumn, setFilterColumn] = useState<Column<any, unknown>>();
  const [listItemHierarchy, setListItemHierarchy] = useState<ItemRow[]>();
  const [parentItemOptions, setParentItemOptions] = useState<SelectOption[]>();

  const selectedListId = parseInt(selectedList?.value ?? "-1");

  return (
    <MappingSheetContext.Provider
      value={{
        selectedList,
        setSelectedList,
        parentItemId,
        setParentItemId,
        listDetailsTree,
        setListDetailsTree,
        lastSaved,
        setLastSaved,
        filterColumn,
        setFilterColumn,
        listItemHierarchy,
        setListItemHierarchy,
        parentItemOptions,
        setParentItemOptions,
        nodeId,
        nodeType,
        selectedListId,
      }}
    >
      {children}
    </MappingSheetContext.Provider>
  );
};

export const useMappingSheetContext = () => {
  const context = useContext(MappingSheetContext);

  if (!context) {
    throw new Error(
      "useMappingSheetContext must be used within a MappingSheetProvider",
    );
  }

  return context;
};

export default MappingSheetProvider;
