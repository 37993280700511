import React, { Suspense, useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";

import { zodResolver } from "@hookform/resolvers/zod";
import { observer } from "mobx-react-lite";
import { z } from "zod";


import PasswordStrengthBar from "react-password-strength-bar";
import { ApiUrls } from "~/api/ApiPaths";
import { AppWrapper } from "~/components/login/Login";
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "~/components/ui-rework/card";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "~/components/ui/form";
import { useToast } from "~/components/ui/use-toast";
import useAxios from "~/hooks/useAxios";
import { default as align } from "~/images/align-logo-updated.svg";
import loginImage from "~/images/login-background_light.svg";
import studio from "~/images/studio-1.svg";
import { useStore as useRootStore } from "~/models/Root";
import { Button } from "../ui-rework/button";
import { Input } from "../ui-rework/input";
import Link from "../ui-rework/link";

/**
 * @description component for resetting password
 */
const ResetPassword: React.FC = observer(() => {
  const resetToken = useRef(useLocation().search.split("=")[1] || "");
  const [displayReady, setDisplayReady] = useState(false);
  const { data, error, requestUrl, fetch, payload } = useAxios({
    method: "GET",
    initialValue: null,
  });
  const navigate = useNavigate();
  const { toast } = useToast();
  const [userResetEmail, setUserResetEmail] = useState("");
  const [updatedPasswordCall, setUpdatedPasswordCall] = useState(false);
  const [updateSuccessful, setUpdateSuccessful] = useState(false);
  const fetchOnce = useRef(true);
  const [resetRecordId, setResetRecordId] = useState(0);
  const { setAuthToken, resetAppUser } = useRootStore();

 //Password Requirements
 const formSchema = z
  .object({
    password: z
      .string()
      .min(8, { message: "Password must be at least 8 characters" })
      .regex(/[0-9]/, { message: "Password must include a number" })
      .regex(/[a-z]/, {
        message: "Password must include a lowercase character",
      })
      .regex(/[A-Z]/, {
        message: "Password must include an uppercase character",
      })
      .regex(/[^\w]/, { message: "Password must include a symbol" }),
    confirmPassword: z.string().min(1, { message: "Password is required" }),
  })
  .refine((data) => data.password === data.confirmPassword, {
    message: "Passwords don't match",
    path: ["confirmPassword"],
  });

  const form = useForm<z.infer<typeof formSchema>>({
    mode: "onChange",
    resolver: zodResolver(formSchema),
    defaultValues: {
      password: "",
      confirmPassword: "",
    },
  });

  function onSubmit(values: z.infer<typeof formSchema>) {
    updatePassword(values.password, userResetEmail);
  }

  useEffect(() => {
    if (fetchOnce.current) {
      setAuthToken("");
      resetAppUser();
      fetchOnce.current = false;
      requestUrl.current = `${ApiUrls.account.validateResetPassword(
        resetToken.current,
      )}`;
      fetch();
    }
  }, [fetchOnce, requestUrl, fetch, setAuthToken, resetAppUser]);

  useEffect(() => {
    if (data) {
      if (data.issuccess && !displayReady && !fetchOnce.current) {
        setDisplayReady(true);
        setUserResetEmail(data.entity.email);
        setResetRecordId(data.entity.reset_id);
      }
    } else if (error) {
      toast({
        variant: "destructive",
        title: "Uh oh! Something went wrong",
        description:
          "Your reset password link is invalid or expired. Please request a new one.",
      });
      navigate("/login", { replace: true });
    }
  }, [
    data,
    error,
    displayReady,
    fetchOnce,
    toast,
    navigate,
    setDisplayReady,
    setUserResetEmail,
  ]);

  const updatePassword = (password: string, email: string) => {
    setUpdatedPasswordCall(true);
    requestUrl.current = `${ApiUrls.account.updatePassword}`;
    payload.current = {
      password: password,
      email: email,
      token: resetToken.current,
    };
    fetch("POST");
  };

  useEffect(() => {
    if (data && updatedPasswordCall) {
      if (data.issuccess) {
        toast({
          variant: "success",
          title: "Password Updated",
          description:
            "Check your email for confirmation of your updated password or contact support.",
        });
        setUpdateSuccessful(true);
      } else if (!data.issuccess) {
        toast({
          variant: "destructive",
          title: "Uh oh! Something went wrong",
          description:
            "Your password could not be updated. Please try again or contact support.",
        });
      }
    } else if (error && updatedPasswordCall) {
      toast({
        variant: "destructive",
        title: "Uh oh! Something went wrong",
        description:
          "Your password could not be updated. Please try again or contact support.",
      });
    }
  }, [data, error, updatedPasswordCall, toast, setUpdateSuccessful]);

  return (
    <AppWrapper className="light">
      <div
        className="fixed inset-0 bg-cover bg-center z-[-1]"
        style={{ backgroundImage: `url(${loginImage})` }}
      ></div>
      <div className="absolute flex flex-row w-full justify-between p-6">
        <img src={studio} alt="Studio Main Logo" />
        <img src={align} alt="Align Main Logo" />
      </div>
      <div className="grid h-full place-items-center">
        {!updateSuccessful ? (
          <div>
            <Card className="w-[415px]">
              <CardHeader>
                <CardTitle>Reset Password</CardTitle>
                <CardDescription>
                  Resetting password for <b>{userResetEmail}.</b>
                </CardDescription>
                <CardDescription className="mt-4">
                  Do not reuse previous passwords.
                </CardDescription>
              </CardHeader>
              <CardContent>
                <Form {...form}>
                  <form onSubmit={form.handleSubmit((data) => onSubmit(data))}>
                    <FormField
                      control={form.control}
                      name="password"
                      render={({ field }) => (
                        <FormItem className="w-[345px] mb-2">
                          <FormLabel htmlFor="password">New Password</FormLabel>
                          <FormControl>
                            <Input
                              type="password"
                              className="bg-transparent"
                              placeholder="Password"
                              {...field}
                            />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                    <Suspense
                      fallback={<div>Loading password strength meter...</div>}
                    >
                      <PasswordStrengthBar
                        style={{ width: "345px" }}
                        password={form.getValues().password}
                      />
                    </Suspense>
                    <div className="pl-6 mb-4 mt-[-22px]">
                      <ul className="list-disc text-foreground">
                        <li className="text-sm w-1/2">&#x2265; 8 characters</li>
                        <li className="text-sm w-1/2">Uppercase letter</li>
                        <li className="text-sm w-1/2">Special character</li>
                        <li className="text-sm w-1/2">Lowercase letter</li>
                        <li className="text-sm w-1/2">Number</li>
                      </ul>
                    </div>
                    <FormField
                      control={form.control}
                      name="confirmPassword"
                      render={({ field }) => (
                        <FormItem className="w-[345px] mb-4">
                          <FormLabel htmlFor="confirmPassword">
                            Confirm Password
                          </FormLabel>
                          <FormControl>
                            <Input
                              type="password"
                              className="bg-transparent"
                              placeholder="Password"
                              {...field}
                            />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                    <div className="w-full flex flex-row justify-end gap-4">
                      {/* <Button
                        type="button"
                        variant="outline"
                      >
                        Cancel
                      </Button> */}
                      <Button variant="primary" type="submit">
                        Reset Password
                      </Button>
                    </div>
                  </form>
                </Form>
              </CardContent>
            </Card>
          </div>
        ) : (
          <div>
            <Card className="w-[415px]">
              <CardHeader>
                <CardTitle>Password Reset!</CardTitle>
                <CardDescription>
                  You can now log in with your new password.
                </CardDescription>
              </CardHeader>
              <CardContent>
                <div className="flex justify-end">
                  <Button
                    variant="primary"
                    onClick={() => {
                      navigate("/login", { replace: true });
                    }}
                  >
                    Return to Login
                  </Button>
                </div>
              </CardContent>
            </Card>
            <div className="flex flex-col justify-center items-center pt-6">
              <Link to="" size="m">
                Contact Support
              </Link>
            </div>
          </div>
        )}
      </div>
    </AppWrapper>
  );
});

export default ResetPassword;
