//single file for all paths to the API
import { AlignListItemTypes } from "~/components/AlignListGrid";
import { IProgramTreeNode, NodeTypes } from "~/models/AlignEditor";
import { IAvailableList, IListManagerList } from "~/models/ListManager";

// a map holding the api base url and api group for dev, staging, and production
const apiBaseUrlMap = {
  dev: {
    baseUrl: "https://xtot-nirm-3igr.n7.xano.io",
    apiGroup: "/api:yfjWj4_4",
  },
  staging: {
    baseUrl: "https://xtot-nirm-3igr.n7.xano.io",
    apiGroup: "/api:fnj2zf3I",
  },
  production: {
    baseUrl: "https://xtot-nirm-3igr.n7.xano.io",
    apiGroup: "/api:qRJ_WoMX",
  },
};

const setEnvironment = () => {
  const currentUrl = window.location.href;
  if (currentUrl.includes("localhost")) {
    return apiBaseUrlMap.dev;
  } else if (currentUrl.includes("studio-dev")) {
    return apiBaseUrlMap.dev;
  } else if (currentUrl.includes("studio-staging")) {
    return apiBaseUrlMap.staging;
  } else if (currentUrl.includes("studio-app")) {
    return apiBaseUrlMap.production;
  } else {
    return apiBaseUrlMap.dev;
  }
};

export const isProduction = () => {
  return window.location.href.includes("studio-app");
};

//app base url
export const BASE_URL = Object.freeze({
  URL: setEnvironment().baseUrl,
});

export const API_BASE_URL = BASE_URL.URL;
const API_GROUP = setEnvironment().apiGroup;

export const API_PATHS = Object.freeze({
  // Auth:
  IS_LOGGED_IN: `${API_GROUP}/auth/isloggedin`,
  LOGIN: `${API_GROUP}/auth/login`,
  LOGOUT: `${API_GROUP}/auth/logout`,

  // Lists:
  // CREATE_LIST: `${API_GROUP}/lists/createList`, NOT USED YET?
  LIST_BY_ID: `${API_GROUP}/lists/getListDetails`,
  // GET_LIST_ITEMS: `${API_GROUP}/lists/getListItems`, NOT USED YET?
  LISTS_HEADER_DATA: `${API_GROUP}/lists/getListsList`,
  LIST_TYPES: `${API_GROUP}/lists/getListTypes`,
  UPDATE_LISTS: `${API_GROUP}/lists/updateList`,
  UPDATE_LIST_ITEMS: `${API_GROUP}/lists/updateListItems`,

  // Nav:
  GET_NAV_NODES: `${API_GROUP}/nav/getNavNodes`,

  // Partners:
  CREATE_PARTNER: `${API_GROUP}/partners/createPartner`,
  // GET_PARTNER_LIST: `${API_GROUP}/partners/getPartnerList`, NOT USED YET?
  // UPDATE_PARTNER: `${API_GROUP}/partners/updatePartner`,

  // Programs:
  CREATE_PROGRAM: `${API_GROUP}/programs/createProgram`,
  PROGRAM_DETAILS: `${API_GROUP}/programs/getProgramDetails`,
  GET_PROGRAMS: `${API_GROUP}/programs/getProgramList`,
  //updateCurriculumItems
  //updateCurriculumMappings
  //updateProgram

  // Reports:
  REPORTS_GET_PROGRAMDETAILS: `${API_GROUP}/reports/getProgramDetailsAsTree`,

  // Sessions:
  LOG_SESSION_ACTIVITY: `${API_GROUP}/sessions/logsessionactivity`,

  // Settings:
  GET_LABELS: `${API_GROUP}/settings/getLabels`,
  GET_PARTNER_LOGO: `${API_GROUP}/settings/getPartnerLogo`,

  // Site Invites:
  CREATE_SITE_INVITE: `${API_GROUP}/siteinvite/createInvite`,
  GET_REGISTRATION: `${API_GROUP}/siteinvite/getregistration`,
  REGISTER: `${API_GROUP}/siteinvite/register`,
  SEND_SITE_INVITE: `${API_GROUP}/siteinvite/sendInvite`,

  // User Permissions:
  ASSIGN_ROLE: `${API_GROUP}/user_permissions/assignRole`,
  GET_PARTNER_LIST: `${API_GROUP}/user_permissions/getPartnerListByPermission`,
  GET_PROGRAMS_BY_PERMISSION: `${API_GROUP}/user_permissions/getProgramListByPermission`,
  // GET_USER_PERMISSIONS: `${API_GROUP}/user_permissions/getUserPermissions`,  // Not used?
  GET_USER_ROLES: `${API_GROUP}/user_permissions/getUserRoles`,

  // Users:
  CREATE_USER: `${API_GROUP}/users/createUser`,
  GET_USER_LIST: `${API_GROUP}/users/getUserList`,
  UPDATE_USER: `${API_GROUP}/users/updateUser`,
});
//[TODO] some paths have a ":vX" that indicates a different branch/version of that endpoint at
// some point those paths need to go back to their 'live' versions an those paths will need to
// be updated by removing the ":vX" part.

type genericEntity = AlignListItemTypes &
  IAvailableList &
  IListManagerList &
  IProgramTreeNode;

//Adding interface here because it is used in multiple places that access the API
export interface IData {
  issuccess: boolean;
  entity: genericEntity;
  message: string;
}

// Ideally, we could have something like this:
export const ApiUrls = Object.freeze({
  account: {
    // GET
    validateResetPassword: (token: string) =>
      `${API_BASE_URL}${API_GROUP}/account/validateresetpassword?token=${token}`,

    // POST
    resetPasswordRequest: `${API_BASE_URL}${API_GROUP}/account/resetpasswordrequest`,
    updatePassword: `${API_BASE_URL}${API_GROUP}/account/updatepassword`,
  },
  auth: {
    // GET
    getIsLoggedIn: `${API_BASE_URL}${API_GROUP}/auth/isloggedin`,

    // POST
    login: `${API_BASE_URL}${API_GROUP}/auth/login`,
    logout: `${API_BASE_URL}${API_GROUP}/auth/logout`,
  },
  lists: {
    // GET
    getListDetails: (list_id: number) =>
      `${API_BASE_URL}${API_GROUP}/lists/getListDetails/${list_id}`,
    getListDetailsTree: (list_id: number) =>
      `${API_BASE_URL}${API_GROUP}/lists/getListDetailsTree/${list_id}`,
    getListsListItems: (list_id: number) =>
      `${API_BASE_URL}${API_GROUP}/lists/getListItems?list_id=${list_id}`,
    getListsList: (agency_id = 0, partner_id = 0) =>
      `${API_BASE_URL}${API_GROUP}/lists/getListsList?agency_id=${agency_id}&partner_id=${partner_id}`,
    getListTypes: `${API_BASE_URL}${API_GROUP}/lists/getListTypes`,

    // POST
    createList: `${API_BASE_URL}${API_GROUP}/lists/createList`,
    updateList: `${API_BASE_URL}${API_GROUP}/lists/updateList`,
    updateListItems: `${API_BASE_URL}${API_GROUP}/lists/updateListItems`,
  },
  listtolist: {
    // GET
    getRelationshipsByProgram: (program_id: number) =>
      `${API_BASE_URL}${API_GROUP}/listtolist/getRelationshipsByProgram/${program_id}`,
    getRelationshipDetails: (program_id: number, relationship_id: string) =>
      `${API_BASE_URL}${API_GROUP}/listtolist/getRelationshipDetails/${program_id}/${relationship_id}`,
  },
  mapping: {
    // GET
    getListMappingsForCurriculumItem: (id: number, type: NodeTypes) =>
      `${API_BASE_URL}${API_GROUP}/mapping/getListMappingsForCurriculumItem?c_item_type=${type}&c_item_id=${id}`,
    getAIListItemSuggestionsForActivity: `${API_BASE_URL}${API_GROUP}/mapping/getAIListItemSuggestionsForActivity`,
  },
  nav: {
    // GET
    getNavNodes: `${API_BASE_URL}${API_GROUP}/nav/getNavNodes`,
  },
  partners: {
    // GET
    getPartnerList: (agency_id = 0) =>
      `${API_BASE_URL}${API_GROUP}/partners/getPartnerList?agency_id=${agency_id}`,

    // POST
    createPartner: `${API_BASE_URL}${API_GROUP}/partners/createPartner`,
    updatePartner: `${API_BASE_URL}${API_GROUP}/partners/updatePartner`,
  },
  programs: {
    // GET
    getProgramDetails: (program_id: number) =>
      `${API_BASE_URL}${API_GROUP}/programs/getProgramDetails/${program_id}`,
    getProgramList: (partner_id = 0) =>
      `${API_BASE_URL}${API_GROUP}/programs/getProgramList/${partner_id}`,

    // POST
    createProgram: `${API_BASE_URL}${API_GROUP}/programs/createProgram`,
    updateCurriculumItems: `${API_BASE_URL}${API_GROUP}/programs/updateCurriculumItems2`,
    updateCurriculumMappings: `${API_BASE_URL}${API_GROUP}/programs/updateCurriculumMappings`,
    updateProgram: `${API_BASE_URL}${API_GROUP}/programs/updateProgram`,
  },
  reports: {
    // GET
    getProgramDetailsAsTree: (program_id: number) =>
      `${API_BASE_URL}${API_GROUP}/reports/getProgramDetailsAsTree/${program_id}`,
  },
  sessions: {
    //GET
    getUserSessions: (app_users_id: number) =>
      `${API_BASE_URL}${API_GROUP}/sessions/getUserSessions?app_users_id=${app_users_id}`,
    getSessionExpiration: `${API_BASE_URL}${API_GROUP}/sessions/getsessionexpiration`,

    // POST
    logSessionActivity: `${API_BASE_URL}${API_GROUP}/sessions/logsessionactivity`,
  },
  settings: {
    // GET
    getLabels: `${API_BASE_URL}${API_GROUP}/settings/getLabels`,
    getPartnerLogo: `${API_BASE_URL}${API_GROUP}/settings/getPartnerLogo`,
  },
  siteinvite: {
    // GET
    getregistration: (token: string) =>
      `${API_BASE_URL}${API_GROUP}/siteinvite/getregistration?token=${token}`,

    // POST
    createInvite: `${API_BASE_URL}${API_GROUP}/siteinvite/createInvite`,
    register: `${API_BASE_URL}${API_GROUP}/siteinvite/register`,
    sendInvite: `${API_BASE_URL}${API_GROUP}/siteinvite/sendInvite`,
  },
  user_permissions: {
    // GET
    getPartnerListByPermission: (permission: string) =>
      `${API_BASE_URL}${API_GROUP}/user_permissions/getPartnerListByPermission?permission=${permission}`,
    getProgramListByPermission: (permission: string) =>
      `${API_BASE_URL}${API_GROUP}/user_permissions/getProgramListByPermission?permission=${permission}`,
    getUserPermissions: `${API_BASE_URL}${API_GROUP}/user_permissions/getUserPermissions`,
    getUserRoles: `${API_BASE_URL}${API_GROUP}/user_permissions/getUserRoles`,

    // POST
    assignRole: `${API_BASE_URL}${API_GROUP}/user_permissions/assignRole`,
  },
  users: {
    // GET
    getUserList: (agency_id = 0) =>
      `${API_BASE_URL}${API_GROUP}/users/getUserList?agency_id=${agency_id}`,

    // POST
    createUser: `${API_BASE_URL}${API_GROUP}/users/createUser`,
    updateUser: `${API_BASE_URL}${API_GROUP}/users/updateUser`,
  },
});
