import { useExplorerContext } from "~/context/ExplorerProvider";
import { NodeMapping } from "~/models/AlignEditor";

export type NodeCoverage = {
  numerator: number;
  denominator: number;
};

export const useNodeCoverage = (mappings: NodeMapping[]) => {
  const {
    selectedList,
    listDetails,
    selectedListItemParent,
    selectedListItems,
  } = useExplorerContext();
  const selectedListId = parseInt(selectedList ?? "-1");
  const selectedListHeaderId = parseInt(selectedListItemParent ?? "-1");

  if (!listDetails || selectedListId === -1) {
    return { numerator: 0, denominator: 1 };
  }

  let items = listDetails.items ?? [];

  if (selectedListHeaderId !== -1) {
    items = items.filter((item) => item.parent_id === selectedListHeaderId);
  }

  if (selectedListItems.length > 0) {
    items = items.filter((item) =>
      selectedListItems.includes(item.id.toString()),
    );
  }

  const denominatorList = items
    .filter((item) => item.is_mappable)
    .map((item) => item.id);

  // denominatorList =
  //   (selectedListItems ?? []).length > 0
  //     ? selectedListItems?.map((item) => parseInt(item)) ?? []
  //     : listDetails?.items?.map((item) => item.id) ?? [];

  // if (selectedListHeaderId !== -1) {
  //   denominatorList =
  //     listDetails?.items
  //       ?.filter((item) => item.parent_id === selectedListHeaderId)
  //       .map((item) => item.id) ?? [];
  // }

  if (denominatorList.length === 0) {
    return { numerator: 0, denominator: 1 };
  }

  const set = new Set<number>();
  mappings
    .filter((m) => m.list_id === selectedListId)
    .filter((m) => denominatorList.includes(m.listitem_id))
    .forEach((m) => set.add(m.listitem_id));

  const numerator = set.size;
  const denominator = denominatorList.length;

  return { numerator, denominator };
};
