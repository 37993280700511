import React, { useEffect, useState } from "react";

import { observer } from "mobx-react-lite";
import {
  Bar,
  BarChart,
  Cell,
  LabelList,
  Legend,
  Pie,
  PieChart,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from "recharts";

import { CompModel } from "~/components/Reports/ToteCourseDetails";
import {
  CustomizedLabel,
  getGradient,
  renderActiveShape,
  showAllLabel,
} from "~/components/Reports/ToteSummaryVisuals";
import { colors } from "~/css/shared-styles";
import useOverrides from "~/hooks/useOverrides";

import { Checkbox } from "../ui-rework/checkbox";
import { Label } from "../ui-rework/label";

export const getColor = (label: string | undefined) => {
  const COLORS = [
    `${colors.formButtonBlue}`,
    `${colors.lightGrey}`,
    `${colors.activeTabBlue}`,
  ];
  switch (label) {
    case "Assignments/Assessments":
      return COLORS[0];
    case "Blended Learning":
      return COLORS[1];
    case "Learning Activities":
      return COLORS[2];
  }
};

const renderLegend = (props: any) => {
  const { payload } = props;
  const assignment = payload.filter(
    (item: any) => item.payload.category === "Assignments/Assessments",
  );
  const blended = payload.filter(
    (item: any) => item.payload.category === "Blended Learning",
  );
  const learning = payload.filter(
    (item: any) => item.payload.category === "Learning Activities",
  );
  return (
    <ul className="flex flex-row gap-4 justify-center text-idesign-navy-120">
      <li key={`item-0`} className="flex items-center">
        <svg className="h-3 w-3">
          <rect className="h-3 w-3" fill={"url(#assignment-gradient)"} />
        </svg>
        <span className="pl-2 text-sm">
          Assignment/Assessments -{" "}
          {assignment.length > 0 ? assignment[0].payload.tot_percent : 0}%
        </span>
      </li>
      <li key={`item-1`} className="flex items-center">
        <svg className="h-3 w-3">
          <rect className="h-3 w-3" fill={"url(#blended-gradient)"} />
        </svg>
        <span className="pl-2 text-sm">
          Blended Learning -{" "}
          {blended.length > 0 ? blended[0].payload.tot_percent : 0}%
        </span>
      </li>
      <li key={`item-2`} className="flex items-center">
        <svg className="h-3 w-3">
          <rect className="h-3 w-3" fill={"url(#learning-gradient)"} />
        </svg>
        <span className="pl-2 text-sm">
          Learning Activities -{" "}
          {learning.length > 0 ? learning[0].payload.tot_percent : 0}%
        </span>
      </li>
    </ul>
  );
};

const ToteSummaryDetailsVisuals: React.FC<{ data: CompModel | null }> =
  observer(({ data }) => {
    const [index, setIndex] = useState(0);
    const [activeCategory, setActiveCategory] = useState("");
    const [barDataReady, setBarDataReady] = useState(false);
    const [barData, setBarData] = useState<any>([]);
    const [previousCategory, setPreviousCategory] = useState("");
    const [previousHover, setPreviousHover] = useState();
    const [previousIndex, setPreviousIndex] = useState(0);
    const [firstLoad, setFirstLoad] = useState(true);
    const { ActivityLabel } = useOverrides();
    const activityLabel = ActivityLabel();

    const pieHover = (data: any, index: any) => {
      setPreviousCategory(activeCategory);
      setPreviousHover(data);
      setActiveCategory(data.category);
      setIndex(index);
      setShowAll(false);
    };

    useEffect(() => {
      if (firstLoad) {
        setFirstLoad(false);
        setActiveCategory("Assignments/Assessments");
        setPreviousCategory("Assignments/Assessments");
        setIndex(0);
      }
    }, [data, firstLoad]);

    useEffect(() => {
      if (activeCategory !== "") {
        const barData = data?.activitySummaries
          .filter((item) =>
            activeCategory === "ALL" ? true : item.category === activeCategory,
          )
          .map((item: any) => {
            const color = getGradient(item.category);
            return {
              name: item.type,
              tot_minutes: item.tot_minutes,
              tot_minutes_display: item.tot_minutes_display,
              tot_percent: item.tot_percent,
              tot_hours: item.tot_minutes / 60,
              color: color,
            };
          });
        setBarData(barData);
        setBarDataReady(true);
      }
    }, [activeCategory, data]);

    const [showAll, setShowAll] = React.useState(false);
    const checkboxChange = (event: any) => {
      if (event) {
        setShowAll(event);
        setActiveCategory("ALL");
        setPreviousIndex(index);
        setIndex(-1);
      } else if (event === false && previousHover !== undefined) {
        setShowAll(event);
        setActiveCategory(previousCategory);
        pieHover(previousHover, index);
        setIndex(previousIndex);
      } else {
        setShowAll(event);
        setActiveCategory(previousCategory);
        setIndex(previousIndex);
      }
    };

    return (
      <div className="w-full pl-1 pr-1 relative">
        <h6 className="m-0 absolute text-sm font-bold">Category Breakdown</h6>
        <ResponsiveContainer width="100%" height={325}>
          <PieChart>
            <Legend
              content={renderLegend}
              verticalAlign="bottom"
              align="center"
            />
            {data && (
              <Pie
                label={showAll ? showAllLabel : undefined}
                tabIndex={0}
                focusable
                paddingAngle={
                  data?.categorySummaries.filter((item) => item.tot_minutes > 0)
                    .length > 1
                    ? 5
                    : 0
                }
                activeIndex={index}
                activeShape={renderActiveShape}
                data={data?.categorySummaries.filter(
                  (item) => item.tot_minutes !== 0,
                )}
                cx="50%"
                cy="50%"
                innerRadius={60}
                outerRadius={80}
                fill={colors.formButtonBlue}
                dataKey="tot_percent"
                onMouseEnter={showAll ? undefined : pieHover}
                onFocus={pieHover}
              >
                {data?.categorySummaries
                  .filter((item) => item.tot_minutes > 0)
                  .map((entry, index) => (
                    <Cell
                      key={`cell-${index}`}
                      fill={getGradient(entry.category)}
                    />
                  ))}
              </Pie>
            )}
          </PieChart>
        </ResponsiveContainer>
        {barDataReady && (
          <>
            <h6 className="text-sm font-bold mt-2">
              {activityLabel} Type for{" "}
              {showAll ? "All Categories" : activeCategory}
            </h6>
            <div className="flex items-center space-x-2">
              <Checkbox
                id="showAll-2"
                className="ml-6 mt-0"
                checked={showAll}
                onCheckedChange={checkboxChange}
              />
              <Label htmlFor="showAll-2" className="mb-0 text-sm">
                Show All Activity Types (Expand Below)
              </Label>
            </div>
            <ResponsiveContainer width={"100%"} height={430}>
              <BarChart
                width={600}
                height={380}
                data={barData}
                layout="vertical"
                margin={{ bottom: 20, left: 50, top: 20, right: 40 }}
              >
                <XAxis
                  type="number"
                  fontSize={12}
                  stroke="#002E40"
                  label={{
                    value: "Hours",
                    angle: 0,
                    position: "bottom",
                    offset: 0,
                    fontSize: 12,
                    fontWeight: 700,
                    fill: "#002E40",
                  }}
                />
                <YAxis
                  type="category"
                  dataKey="name"
                  tickLine={false}
                  interval={0}
                  fontSize={12}
                  stroke="#002E40"
                  label={{
                    value: "Activity Type",
                    angle: -90,
                    position: "insideLeft",
                    offset: -40,
                    fontSize: 12,
                    fontWeight: 700,
                    fill: "#002E40",
                  }}
                />
                <Bar dataKey="tot_hours">
                  <LabelList
                    content={<CustomizedLabel showAll={showAll} />}
                    position="right"
                  />
                  {barData.map((entry: any, index: any) => (
                    <Cell key={`cell-${index}`} fill={entry.color} />
                  ))}
                </Bar>
              </BarChart>
            </ResponsiveContainer>
          </>
        )}
      </div>
    );
  });

export default ToteSummaryDetailsVisuals;
