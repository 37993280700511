import React, { createContext, useContext } from "react";

import * as TabsPrimitive from "@radix-ui/react-tabs";
import { cva, VariantProps } from "class-variance-authority";

import { cn } from "~/lib/utils";

const tabListVariants = cva(cn("w-full items-center justify-center"), {
  variants: {
    variant: {
      secondary: "rounded-full shadow-tabs bg-tab-background-gradient",
      tertiary: "focus:outline-none bg-none",
    },
  },
  defaultVariants: {
    variant: "secondary",
  },
});

const tabVariants = cva(
  cn(
    "text-foreground px-4 py-2 shadow-none data-[state=inactive]:shadow-none whitespace-nowrap flex-grow",
    "rounded-full text-base font-normal ring-offset-background transition-all",
    "focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2",
    "disabled:pointer-events-none",
  ),
  {
    variants: {
      variant: {
        secondary: cn(
          "data-[disabled]:bg-transparent data-[disabled]:text-tab-disabled-foreground data-[disabled]:shadow-none",
          "data-[state=inactive]:hover:bg-tab-hover-background",
          "data-[state=active]:bg-tab-active-background data-[state=active]:text-tab-active-foreground",
        ),
        tertiary: cn(
          "rounded-none rounded-t-lg border-b-2 border-tab-border",
          "data-[state=active]:bg-transparent data-[state=active]:shadow-none data-[state=active]:z-40",
          "data-[state=inactive]:hover:bg-tab-hover-background",
          "data-[disabled]:text-tab-disabled-foreground data-[disabled]:border-tab-disabled-foreground",
          "focus-visible:border-transparent focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-0",
          "data-[state=active]:after:block data-[disabled]:after:hidden",
          "after:hidden after:absolute focus-visible:data-[state=active]:after:hidden after:bottom-[-2.5px] after:left-1/2 after:transform after:-translate-x-1/2 after:w-2 after:h-2 after:bg-tab-circle-indicator after:rounded-full",
        ),
      },
    },
    defaultVariants: {
      variant: "secondary",
    },
  },
);

const Tabs = React.forwardRef<
  React.ElementRef<typeof TabsPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof TabsPrimitive.Root> &
    VariantProps<typeof tabVariants>
>(({ variant, children, ...props }, ref) => {
  return (
    <TabsProvider variant={variant}>
      <TabsPrimitive.Root ref={ref} {...props}>
        {children}
      </TabsPrimitive.Root>
    </TabsProvider>
  );
});

const TabsList = React.forwardRef<
  React.ElementRef<typeof TabsPrimitive.List>,
  React.ComponentPropsWithoutRef<typeof TabsPrimitive.List>
>(({ className, ...props }, ref) => {
  const { variant } = useTabsContext();
  return (
    <TabsPrimitive.List
      loop
      ref={ref}
      className={cn(tabListVariants({ variant }), className)}
      {...props}
    />
  );
});
TabsList.displayName = TabsPrimitive.List.displayName;

const TabsTrigger = React.forwardRef<
  React.ElementRef<typeof TabsPrimitive.Trigger>,
  React.ComponentPropsWithoutRef<typeof TabsPrimitive.Trigger>
>(({ className, ...props }, ref) => {
  const { variant } = useTabsContext();
  return (
    <div className="relative inline-flex items-center flex-grow">
      <TabsPrimitive.Trigger
        ref={ref}
        className={cn(tabVariants({ variant }), className)}
        {...props}
      />
    </div>
  );
});
TabsTrigger.displayName = TabsPrimitive.Trigger.displayName;

const TabsContent = React.forwardRef<
  React.ElementRef<typeof TabsPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof TabsPrimitive.Content>
>(({ className, ...props }, ref) => (
  <TabsPrimitive.Content
    ref={ref}
    className={cn(
      "pt-2 ring-offset-background focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2",
      className,
    )}
    {...props}
  />
));
TabsContent.displayName = TabsPrimitive.Content.displayName;

type TabsContextType = VariantProps<typeof tabVariants>;
const TabsContext = createContext<TabsContextType>({ variant: undefined });
type TabsProviderProps = VariantProps<typeof tabVariants> & {
  children: React.ReactNode;
};
const TabsProvider = ({ variant, children }: TabsProviderProps) => (
  <TabsContext.Provider value={{ variant }}>{children}</TabsContext.Provider>
);
const useTabsContext = () => {
  const context = useContext(TabsContext);
  if (!context) {
    throw new Error("useTabsContext must be used within a TabsProvider");
  }
  return context;
};

export { Tabs, TabsContent, TabsList, TabsTrigger };
