import React, { createContext, useContext } from "react";

import * as AccordionPrimitive from "@radix-ui/react-accordion";
import { cva, VariantProps } from "class-variance-authority";
import { ChevronDown } from "lucide-react";

import { cn } from "~/lib/utils";

const accordionTrigger = {
  primary: cn("bg-accordion-background border border-accordion-border"),
  secondary: cn("bg-none border-none"),
};

const AccordionVariants = cva(cn(), {
  variants: {
    variant: accordionTrigger,
  },
  defaultVariants: {
    variant: "primary",
  },
});

const Accordion = React.forwardRef<
  React.ElementRef<typeof AccordionPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof AccordionPrimitive.Root> &
    VariantProps<typeof AccordionVariants>
>(({ variant, children, ...props }, ref) => {
  return (
    <AccordionProvider variant={variant}>
      <AccordionPrimitive.Root ref={ref} {...props}>
        {children}
      </AccordionPrimitive.Root>
    </AccordionProvider>
  );
});

const AccordionItem = React.forwardRef<
  React.ElementRef<typeof AccordionPrimitive.Item>,
  React.ComponentPropsWithoutRef<typeof AccordionPrimitive.Item>
>(({ className, ...props }, ref) => (
  <AccordionPrimitive.Item ref={ref} className={cn(className)} {...props} />
));
AccordionItem.displayName = "AccordionItem";

const AccordionTrigger = React.forwardRef<
  React.ElementRef<typeof AccordionPrimitive.Trigger>,
  React.ComponentPropsWithoutRef<typeof AccordionPrimitive.Trigger>
>(({ className, children, ...props }, ref) => {
  const { variant } = useAccordionContext();
  return (
    <AccordionPrimitive.Header className="flex">
      <AccordionPrimitive.Trigger
        ref={ref}
        className={cn(
          "rounded-lg p-2 w-full flex justify-start items-center py-4 text-base text-accordion-foreground font-bold transition-all [&[data-state=open]>svg]:rotate-180",
          "hover:bg-accordion-background-hover",
          "focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-offset-2",
          "focus-visible:ring-button-primary-ring ring-offset-button-primary-ring-offset focus-visible:ring-offset-4 focus-visible:shadow-lg",
          className,
          AccordionVariants({ variant }),
        )}
        {...props}
      >
        <ChevronDown
          className="shrink-0 transition-transform duration-200 mr-4"
          size={24}
        />
        {children}
      </AccordionPrimitive.Trigger>
    </AccordionPrimitive.Header>
  );
});
AccordionTrigger.displayName = AccordionPrimitive.Trigger.displayName;

const AccordionContent = React.forwardRef<
  React.ElementRef<typeof AccordionPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof AccordionPrimitive.Content>
>(({ className, children, ...props }, ref) => (
  <AccordionPrimitive.Content
    ref={ref}
    className="overflow-hidden text-sm text-foreground transition-all data-[state=closed]:animate-accordion-up data-[state=open]:animate-accordion-down"
    {...props}
  >
    <div className={cn("pb-4 pt-0", className)}>{children}</div>
  </AccordionPrimitive.Content>
));

AccordionContent.displayName = AccordionPrimitive.Content.displayName;

type AccordionContextType = VariantProps<typeof AccordionVariants>;
const AccordionContext = createContext<AccordionContextType>({
  variant: undefined,
});
type AccordionProviderProps = VariantProps<typeof AccordionVariants> & {
  children: React.ReactNode;
};
const AccordionProvider = ({ variant, children }: AccordionProviderProps) => (
  <AccordionContext.Provider value={{ variant }}>
    {children}
  </AccordionContext.Provider>
);
const useAccordionContext = () => {
  const context = useContext(AccordionContext);
  if (!context) {
    throw new Error(
      "useAccordionContext must be used within a AccordionProvider",
    );
  }
  return context;
};

export { Accordion, AccordionContent, AccordionItem, AccordionTrigger };
