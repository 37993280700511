import React, { useCallback, useEffect, useRef } from "react";
import ReactFlow, {
  applyEdgeChanges,
  applyNodeChanges,
  ReactFlowProvider,
  MiniMap,
  Background,
  OnNodesChange,
  OnEdgesChange,
  Node,
  Edge,
  Controls,
} from "reactflow";

import { observer } from "mobx-react-lite";

import useAnimatedNodes from "~/components/explorer/hooks/useAnimatedNodes";
import useExpandCollapse from "~/components/explorer/hooks/useExpandCollapse";
import ActivitiesNode from "~/components/explorer/node/ActivitiesNode";
import CurriculumNode from "~/components/explorer/node/CurriculumNode";
import "reactflow/dist/style.css";
import { useExplorerContext } from "~/context/ExplorerProvider";
import { useStore } from "~/models/AlignEditor";

const proOptions = { account: "paid-pro", hideAttribution: true };

const nodeTypes = {
  curriculum: CurriculumNode,
  activities: ActivitiesNode,
};

type ExpandCollapseExampleProps = {
  treeWidth?: number;
  treeHeight?: number;
  animationDuration?: number;
  programId: number;
  initialNodes: Node[];
  initialEdges: Edge[];
};

const ReactFlowExplorer = observer(
  ({
    treeWidth = 48,
    treeHeight = 269,
    animationDuration = 100,
  }: ExpandCollapseExampleProps) => {
    const { nodes, setNodes, edges, setEdges } = useExplorerContext();

    const { nodes: visibleNodes, edges: visibleEdges } = useExpandCollapse(
      nodes,
      edges,
      { treeWidth, treeHeight },
    );
    const { nodes: animatedNodes } = useAnimatedNodes(visibleNodes, {
      animationDuration,
    });

    const onNodesChange: OnNodesChange = useCallback(
      (changes) => setNodes((nds) => applyNodeChanges(changes, nds)),
      [setNodes],
    );
    const onEdgesChange: OnEdgesChange = useCallback(
      (changes) => setEdges((eds) => applyEdgeChanges(changes, eds)),
      [setEdges],
    );

    // const onNodeClick: NodeMouseHandler = useCallback(
    //   (_, node) => {
    //     setNodes((nds) =>
    //       nds.map((n) => {
    //         if (n.id === node.id) {
    //           return {
    //             ...n,
    //             data: { ...n.data, expanded: !n.data.expanded },
    //           };
    //         }

    //         return n;
    //       }),
    //     );
    //   },
    //   [setNodes],
    // );

    return (
      <ReactFlow
        fitView
        nodes={animatedNodes}
        edges={visibleEdges}
        onNodesChange={onNodesChange}
        onEdgesChange={onEdgesChange}
        // onNodeClick={onNodeClick}
        proOptions={proOptions}
        nodeTypes={nodeTypes}
        nodesDraggable={false}
        nodesConnectable={false}
        className="bg-[#f1f3fa]"
        zoomOnDoubleClick={false}
        elementsSelectable={true}
        edgesFocusable={false}
        maxZoom={3}
      >
        <Background />
        <MiniMap />
        <Controls className="rounded overflow-hidden !left-[10rem] !bottom-4 !m-0" />
      </ReactFlow>
    );
  },
);

type ExplorerProps = {
  programId: number;
};

const Explorer = ({ programId }: ExplorerProps) => {
  const areNodesReady = useRef(false);
  const { getFlowNodes: getFlowNodes2 } = useStore();

  const { nodes, setNodes, edges, setEdges } = useExplorerContext();

  useEffect(() => {
    if (nodes.length === 0 && !areNodesReady.current) {
      areNodesReady.current = true;
      const { nodes: flowNodes, edges: flowEdges } = getFlowNodes2(programId);
      setNodes(flowNodes as Node[]);
      setEdges(flowEdges);
    }
  }, [nodes, edges, setEdges, setNodes, programId, getFlowNodes2]);

  return areNodesReady.current ? (
    <ReactFlowProvider>
      <ReactFlowExplorer
        programId={programId}
        initialNodes={nodes}
        initialEdges={edges}
      />
    </ReactFlowProvider>
  ) : (
    <div>Loading...</div>
  );
};

export default Explorer;
