import React, { memo } from "react";

import { NodeCoverage } from "~/components/explorer/hooks/useNodeCoverage";
import { NodeCourseDetails } from "~/components/explorer/node/hover-details/NodeCourseDetails";
import { NodeModuleDetails } from "~/components/explorer/node/hover-details/NodeModuleDetails";
import { NodeProgramDetails } from "~/components/explorer/node/hover-details/NodeProgramDetails";
import {
  NodeTypes,
  ICourse,
  IModule,
  IProgramTreeNode,
} from "~/models/AlignEditor";

type HoverDetailsProps = {
  detail: any;
  type: NodeTypes;
  coverage: NodeCoverage;
};
const HoverDetails = memo(
  ({ detail, type, coverage: nodeCoverage }: HoverDetailsProps) => {
    switch (type) {
      case NodeTypes.Program:
        return (
          <NodeProgramDetails
            program={detail as IProgramTreeNode}
            coverage={nodeCoverage}
          />
        );
      case NodeTypes.Course:
        return (
          <NodeCourseDetails
            course={detail as ICourse}
            coverage={nodeCoverage}
          />
        );
      case NodeTypes.Module:
        return (
          <NodeModuleDetails
            module={detail as IModule}
            coverage={nodeCoverage}
          />
        );
      default:
        return <div>Default</div>;
    }
  },
);

export default HoverDetails;
