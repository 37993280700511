import React, { useEffect, useRef, useState } from "react";

import {
  ColumnDef,
  ColumnFiltersState,
  SortingState,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";

import { API_PATHS } from "~/api/ApiPaths";
import UserDetailsDrawer from "~/components/admin/UserDetailsDrawer";
import LastLoginDialog from "~/components/LastLoginDialog";
import { Button } from "~/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "~/components/ui/dialog";
import { Input } from "~/components/ui/input";
import {
  Sheet,
  SheetContent,
  SheetHeader,
  SheetTitle,
} from "~/components/ui/sheet";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "~/components/ui/table";
import { toast } from "~/components/ui/use-toast";
import useAxios from "~/hooks/useAxios";
import { IAppUser, useStore } from "~/models/Root";
import { getReadableDate } from "~/utilities/DateFormat";

interface UserListTableProps {
  triggerRefresh: boolean;
  setTriggerRefresh: React.Dispatch<React.SetStateAction<boolean>>;
}

const UserListTable: React.FC<UserListTableProps> = (props) => {
  const { triggerRefresh, setTriggerRefresh } = props;
  const displayReady = useRef(false);
  const [usersData, setUsersData] = useState<IAppUser[]>([]);
  const { data, authToken, error, requestUrl, fetch } = useAxios({
    method: "GET",
    initialValue: null,
  });
  const { getAuthToken } = useStore();
  const usersFetched = useRef(false);
  const usersSet = useRef(false);
  const finishedFetchingAll = useRef(false);
  const [currentData, setCurrentData] = useState([]);
  const [refetch, setRefetch] = useState(false);
  const [sorting, setSorting] = React.useState<SortingState>([]);
  const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>(
    [],
  );
  const [tableBeginningSize, setTableBeginningSize] = useState(0);
  const [previousRowSize, setPreviousRowSize] = useState(0);
  const [dialogVisible, SetDialogVisible] = useState(false);
  const [dialogDetails, setDialogDetails] = useState<IAppUser | null>(null);
  const [isOpen, setIsOpen] = useState(false);
  const [drawerData, setDrawerData] = useState<IAppUser | null>(null);

  useEffect(() => {
    if (data && currentData !== data && !usersSet.current) {
      // Set user data
      setCurrentData(data);
      const allUsersList: IAppUser[] = [];
      data.entity.forEach((item: IAppUser) => {
        allUsersList.push({
          id: item.id,
          email: item.email,
          first_name: item.first_name,
          last_name: item.last_name,
          name: item.name,
          password: "",
          agency_id: item.agency_id,
          partner_id: item.partner_id,
          account_status: item.account_status,
          user_type: item.user_type,
          title: item.title,
          last_login: item.last_login,
        });
      });
      usersSet.current = true;
      setUsersData(allUsersList);
      finishedFetchingAll.current = true;
      setRefetch(false);
      displayReady.current = false;
    } else if (!usersFetched.current || refetch) {
      // fetch user data
      usersFetched.current = true;
      displayReady.current = false;
      requestUrl.current = `${API_PATHS.GET_USER_LIST}`;
      authToken.current = getAuthToken();
      fetch();
    } else if (error) {
      console.log(error);
    }
  }, [
    data,
    error,
    authToken,
    requestUrl,
    fetch,
    getAuthToken,
    currentData,
    refetch,
  ]);

  const updateToast = () => {
    toast({
      variant: "success",
      title: "User List Updated",
      description:
        "If you made an update to a user, that change has been reflected in the user list.",
    });
  };

  const triggleRefetch = () => {
    displayReady.current = false;
    usersFetched.current = false;
    usersSet.current = false;
    setRefetch(true);
  };

  useEffect(() => {
    if (triggerRefresh) {
      triggleRefetch();
      setTriggerRefresh(false);
    }
  }, [triggerRefresh, setTriggerRefresh]);

  const toggleDrawer = () => {
    setIsOpen(!isOpen);
    if (isOpen) {
      triggleRefetch();
      updateToast();
    }
  };

  const columns: ColumnDef<IAppUser>[] = [
    {
      accessorKey: "name",
      header: ({ column }) => {
        return (
          <Button
            className={`${
              column.getIsSorted() === "asc" ? "text-primary" : ""
            } p-0 w-full items-center justify-start`}
            variant="ghost"
            onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
          >
            Name
            <span aria-hidden="true" className="material-icons text-base">
              unfold_more
            </span>
          </Button>
        );
      },
    },
    {
      accessorKey: "email",
      header: ({ column }) => {
        return (
          <Button
            className={
              column.getIsSorted() === "asc" ? "text-primary p-0" : "p-0"
            }
            variant="ghost"
            onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
          >
            Email
            <span aria-hidden="true" className="material-icons text-base">
              unfold_more
            </span>
          </Button>
        );
      },
    },
    {
      accessorKey: "user_type",
      header: "User Type",
    },
    {
      accessorKey: "account_status",
      header: "Account Status",
    },
    {
      accessorKey: "last_login",
      header: "Last Login",
      cell: ({ row }) => {
        const data = row.original;
        if (data?.last_login && data?.last_login > 0) {
          const date_display = getReadableDate(data.last_login);
          return (
            <>
              <Button
                size="sm"
                variant="ghost"
                onClick={() => {
                  SetDialogVisible(true);
                  setDialogDetails(row.original);
                }}
              >
                <span className="underline text-align-blue">
                  {date_display}
                </span>
              </Button>
            </>
          );
        }
      },
    },
    {
      id: "actions",
      header: "Actions",
      cell: ({ row }) => {
        const data = row.original;
        return (
          <Button
            size="sm"
            variant="outline"
            className="rounded-md"
            onClick={() => {
              setDrawerData(data);
              toggleDrawer();
            }}
            testid="admin-user-details"
          >
            User Details
          </Button>
        );
      },
    },
  ];

  const table = useReactTable({
    data: usersData,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    getFilteredRowModel: getFilteredRowModel(),
    state: {
      sorting,
      columnFilters,
    },
  });

  return (
    <>
      <div className="h-full w-full">
        <Table className="w-full">
          <TableHeader>
            {table.getHeaderGroups().map((headerGroup) => (
              <TableRow key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  return (
                    <TableHead key={header.id}>
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                            header.column.columnDef.header,
                            header.getContext(),
                          )}
                    </TableHead>
                  );
                })}
              </TableRow>
            ))}
            <TableRow>
              <TableHead>
                <Input
                  placeholder="Filter names..."
                  value={
                    (table.getColumn("name")?.getFilterValue() as string) ?? ""
                  }
                  onChange={(event) =>
                    table.getColumn("name")?.setFilterValue(event.target.value)
                  }
                  className="m-0 border-0 p-0"
                  data-testid="admin-user-filter"
                />
              </TableHead>
              <TableHead>
                <Input
                  placeholder="Filter emails..."
                  value={
                    (table.getColumn("email")?.getFilterValue() as string) ?? ""
                  }
                  onChange={(event) =>
                    table.getColumn("email")?.setFilterValue(event.target.value)
                  }
                  className="m-0 border-0 p-0"
                />
              </TableHead>
              <TableHead>
                <Input
                  placeholder="Filter user type..."
                  value={
                    (table
                      .getColumn("user_type")
                      ?.getFilterValue() as string) ?? ""
                  }
                  onChange={(event) =>
                    table
                      .getColumn("user_type")
                      ?.setFilterValue(event.target.value)
                  }
                  className="m-0 border-0 p-0"
                />
              </TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {table.getRowModel().rows?.length ? (
              table.getRowModel().rows.map((row) => (
                <TableRow
                  key={row.id}
                  data-state={row.getIsSelected() && "selected"}
                >
                  {row.getVisibleCells().map((cell) => (
                    <TableCell key={cell.id}>
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext(),
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell
                  colSpan={columns.length}
                  className="h-24 text-center"
                >
                  No results.
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
        <div className="border-t flex flex-row items-center justify-between w-full py-2">
          <div className="flex-1 text-sm text-muted-foreground px-2">
            Items {tableBeginningSize} -{" "}
            {tableBeginningSize + table.getRowModel().rows.length} of{" "}
            {table.getFilteredRowModel().rows.length} total.
          </div>
          <div className="space-x-2 m-2">
            <Button
              className="rounded-md"
              size="sm"
              variant="outline"
              onClick={() => {
                table.previousPage();
                setTableBeginningSize(tableBeginningSize - previousRowSize);
              }}
              disabled={!table.getCanPreviousPage()}
            >
              Previous
            </Button>
            <Button
              className="rounded-md"
              size="sm"
              variant="outline"
              onClick={() => {
                table.nextPage();
                setTableBeginningSize(
                  tableBeginningSize + table.getRowModel().rows.length,
                );
                setPreviousRowSize(table.getRowModel().rows.length);
              }}
              disabled={!table.getCanNextPage()}
            >
              Next
            </Button>
          </div>
        </div>
      </div>
      {dialogDetails && dialogVisible && (
        <Dialog open={dialogVisible} onOpenChange={SetDialogVisible}>
          <DialogContent className="max-w-[80vw] overflow-y-auto">
            <DialogHeader>
              <DialogTitle>
                <h1 className="text-lg font-semibold mb-0">
                  {dialogDetails.first_name} {dialogDetails.last_name} Session
                  Details
                </h1>
              </DialogTitle>
            </DialogHeader>
            <LastLoginDialog userDetails={dialogDetails} />
          </DialogContent>
        </Dialog>
      )}
      {isOpen && (
        <Sheet open={isOpen} onOpenChange={toggleDrawer}>
          <SheetContent className="!w-1/2 !min-w-[400px] p-0">
            <div className="mx-auto w-full max-w-m h-full">
              <SheetHeader className="flex flex-row justify-items-center items-center justify-between">
                <SheetTitle className="p-4 mx-2 justify-items-center align-text-center">
                  {" "}
                  {drawerData?.first_name} {drawerData?.last_name}
                </SheetTitle>
              </SheetHeader>
              <UserDetailsDrawer userDetailsData={drawerData} />
            </div>
          </SheetContent>
        </Sheet>
      )}
    </>
  );
};

export default UserListTable;
