import { SelectOption } from "~/components/ui/select";
import { ItemRow } from "~/models/AlignEditor";

export const getParentItemOptions = (items: ItemRow[]) => {
  // console.time("getParentItemOptions");
  const result: SelectOption[] = [];

  const traverse = (rows: ItemRow[]) => {
    for (const row of rows) {
      if (row.items && row.items.length > 0) {
        let label = row.display_name ?? "";
        if (label.length === 0) {
          if ((row.code ?? "").length > 0) label += `${row.code} - `;
          label += `${row.name}`;
        }
        result.push({ value: row.id.toString(), label });
        traverse(row.items);
      }
    }
  };

  traverse(items);
  // console.timeEnd("getParentItemOptions");
  return result;
};
