import React, { useContext, Suspense, lazy } from "react";

import { useFormikContext } from "formik";
import { observer } from "mobx-react-lite";

import AlignButton from "~/components/AlignButton";
import { DialogContext } from "~/components/AlignDialog";
import AlignFormikCheckbox from "~/components/AlignFormikCheckbox";
import AlignFormikTextInput from "~/components/AlignFormikTextInput";
import { IRegisterForm } from "~/components/login/Register";
import PolicyModal from "~/components/PolicyModal";
import TermsModal from "~/components/TermsModal";
import { colors } from "~/css/shared-styles";

interface IRegisterSecondPage {
  setShowNextPage: React.Dispatch<React.SetStateAction<boolean>>;
}

const PasswordStrengthBar = lazy(
  () => import("~/components/PasswordStrengthMeter"),
);

/**
 * @description component for registering for a new account, includes form
 * for creating password and policy/terms modal
 */
const RegisterSecondPage: React.FC<IRegisterSecondPage> = observer((props) => {
  const { setShowNextPage } = props;
  const { openDialog } = useContext(DialogContext);
  const { values } = useFormikContext<IRegisterForm>();

  return (
    <>
      <h3>Create password</h3>
      <AlignFormikTextInput
        label="Password"
        name="password"
        placeholder="Password"
        materialIconName="lock_outline"
        iconAlignment="left"
        type="password"
      />
      <Suspense fallback={<div>Loading password strength meter...</div>}>
        <PasswordStrengthBar password={values.password} />
      </Suspense>
      <div>
        <p className="requirements">Must be at least 8 characters.</p>
        <p className="requirements">Must contain:</p>
        <ul className="list-requirements">
          <li className="requirements">at least one uppercase letter</li>
          <li className="requirements">at least one lowercase letter</li>
          <li className="requirements">at least one number</li>
          <li className="requirements">at least one special character</li>
        </ul>
      </div>
      <AlignFormikTextInput
        label="Confirm Password"
        name="confirmPassword"
        placeholder="Confirm Password"
        materialIconName="key"
        iconAlignment="left"
        type="password"
      />
      <AlignFormikCheckbox name="acceptedTerms">
        <span className="checkbox-text">
          I agree to the
          <button
            type="button"
            className="policy"
            onClick={() => {
              openDialog(<TermsModal />);
            }}
          >
            Terms of Service
          </button>
          and
          <button
            type="button"
            className="policy"
            onClick={() => {
              openDialog(<PolicyModal />);
            }}
          >
            Privacy Policy
          </button>
        </span>
      </AlignFormikCheckbox>
      <div className="button-holder-double">
        <AlignButton
          label="Back"
          type="button"
          onClick={() => {
            setShowNextPage(false);
          }}
          style={{
            marginBottom: "1.5rem",
            background: colors.formButtonGrey,
            color: "black",
            width: "75px",
            textAlign: "center",
          }}
        />
        <AlignButton
          label="Create Account"
          type="submit"
          style={{
            marginBottom: "1.5rem",
            background: colors.formButtonBlue,
            color: "white",
            width: "fit-content",
            textAlign: "center",
          }}
        />
      </div>
    </>
  );
});

export default RegisterSecondPage;
