import React, { useEffect, useRef, useState } from "react";

import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";

import { isAxiosError } from "axios";
import { useForm } from "react-hook-form";
import { ApiUrls, IData } from "~/api/ApiPaths";
import { DialogFooter, DialogTrigger } from "~/components/ui-rework/dialog";
import { Input } from "~/components/ui-rework/input";
import { Label } from "~/components/ui-rework/label";
import { Select, SelectOption } from "~/components/ui-rework/select";
import useAxios from "~/hooks/useAxios";
import { useStore } from "~/models/Root";
import { PERMISSION } from "~/models/UserManager";
import { Button } from "./ui-rework/button";
import { Checkbox } from "./ui-rework/checkbox";
import { Form, FormControl, FormField, FormItem, FormMessage } from "./ui/form";
import { Textarea } from "./ui/textarea";

type CreateListFormProps = {
  handleSuccess: (listId?: number) => void;
};

const CreateListForm = ({ handleSuccess }: CreateListFormProps) => {
  const { appUser } = useStore();
  const {
    data: list_types,
    error: dataError,
    requestUrl,
    fetch,
    authToken,
  } = useAxios({ method: "GET", initialValue: null });
  const {
    data: createListData,
    error: createListError,
    fetch: createList,
    requestUrl: createListUrl,
    authToken: createListAuthToken,
    payload: createListPayload,
  } = useAxios({ method: "POST", initialValue: null });
  const [availableListTypes, setAvailableListTypes] = useState<SelectOption[]>(
    [],
  );
  const fetchListTypes = useRef(true);
  const createdList = useRef(false);
  const [listType, setListType] = useState<string | undefined>(undefined);

  useEffect(() => {
    if (fetchListTypes.current) {
      fetchListTypes.current = false;
      requestUrl.current = `${ApiUrls.lists.getListTypes}`;
      authToken.current = appUser.authtoken;
      fetch();
    }
  }, [fetch, requestUrl, authToken, appUser.authtoken]);

  useEffect(() => {
    //list_types drop down menu items
    if (!fetchListTypes.current && list_types !== null && list_types.length) {
      const tempListTypes: SelectOption[] = [];
      list_types.forEach((type: string) => {
        tempListTypes.push({ label: type, value: type });
      });
      setAvailableListTypes(tempListTypes);
    }
  }, [list_types]);

  useEffect(() => {
    if (createListData && !createdList.current) {
      createdList.current = true;
      const Data = createListData as IData;
      if (Data && Data.issuccess) {
        handleSuccess(Data.entity.id);
      }
    }
  }, [createListData, handleSuccess]);

  const handleChange = (_fieldName: string, newValue: string) => {
    setListType(newValue);
    form.setValue("listType", newValue);
    form.trigger("listType");
  };

  const formSchema = z.object({
    listName: z.string().min(1, { message: "List name is required" }),
    description: z.string(),
    isGlobalList: z.boolean(),
    listType: z.string().min(1, { message: "List type is required" }),
  });

  const defaultValues = {
    listName: "",
    description: "",
    isGlobalList: false,
    listType: "",
  };

  const form = useForm({
    mode: "onChange",
    resolver: zodResolver(formSchema),
    defaultValues: defaultValues,
  });

  function onSubmit(values: z.infer<typeof formSchema>) {
    createListUrl.current = `${ApiUrls.lists.createList}`;
    createListAuthToken.current = appUser.authtoken;
    const agencyID = values.isGlobalList ? 0 : appUser.agency_id;
    const partnerID = values.isGlobalList? 0 : appUser.selected_partner_id;
    createListPayload.current = {
      name: values.listName,
      list_type: values.listType,
      is_global_list: values.isGlobalList,
      is_active: false,
      agency_id: agencyID,
      partner_id: partnerID,
      program_id: 0,
      course_id: 0,
      description: values.description,
    };
    createList();
  }

  return (
    <div className="light">
      <Form {...form}>
        <form onSubmit={form.handleSubmit((data) => onSubmit(data))}>
          <FormField
            control={form.control}
            name="listName"
            render={({ field }) => (
              <FormItem className="mb-6">
                <Label htmlFor="listName">List Name</Label>
                <FormControl>
                  <Input
                    className="bg-transparent"
                    placeholder="Name"
                    {...field}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="listType"
            render={({ field }) => (
              <FormItem className="mb-6">
                <Label htmlFor="listType">List Type</Label>
                <FormControl>
                  <Select
                    fieldName="listType"
                    options={availableListTypes}
                    value={listType}
                    handleChange={handleChange}
                    placeholder={
                      availableListTypes ? "Select..." : "Loading..."
                    }
                    testid="list-type"
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="description"
            render={({ field }) => (
              <FormItem className="mb-6">
                <Label htmlFor="listType">List Description</Label>
                <FormControl>
                  <Textarea
                    placeholder="Description"
                    className="resize-none bg-transparent"
                    {...field}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          {appUser.basic_permissions.includes(
            PERMISSION.MANAGE_GLOBAL_LISTS,
          ) && (
            <FormField
              control={form.control}
              name="isGlobalList"
              render={({ field }) => (
                <FormItem className="mb-6">
                  <FormControl>
                    <div className="flex flex-row items-center space-x-2 mb-6">
                      <Checkbox
                        name="isGlobalList"
                        id="isGlobalList"
                        checked={field.value}
                        onCheckedChange={field.onChange}
                      />
                      <Label
                        htmlFor="isGlobalList"
                        className="m-0 leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
                      >
                        Global List
                      </Label>
                    </div>
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          )}
          <DialogFooter>
            <DialogTrigger asChild>
              <Button
                type="button"
                variant="outline"
              >
                Cancel
              </Button>
            </DialogTrigger>
            <Button type="submit" variant="primary">
              Create
            </Button>
          </DialogFooter>
          {createListError !== "" && (
            <div className="error-content-wrap">
              <h5 className="error-header">
                There was an error creating the new List:
              </h5>
              <div
                className="error body-text m"
                style={{ overflowWrap: "break-word" }}
              >
                {isAxiosError(createListError) && createListError.response
                  ? createListError.response.data.message
                  : createListError.message}
              </div>
            </div>
          )}
        </form>
      </Form>
    </div>
  );
};

export default CreateListForm;
