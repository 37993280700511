import React from "react";

import * as ReportModels from "~/components/Reports/ReportModels";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "~/components/ui/table";
import useOverrides from "~/hooks/useOverrides";

type CourseActivityTypeDetailProps = {
  course: ReportModels.Course;
  list_id: number;
  activity_type?: string;
};

interface IActivityRow {
  id: number;
  display_name: string;
  is_assessment: boolean;
  lo_count: number;
  modality: string;
  grading_stakes: string;
  tot_minutes: number;
  tot_minutes_display: string;
}

class CompModel {
  constructor(
    course: ReportModels.Course,
    list_id: number,
    activityType?: string,
  ) {
    const activity_type = activityType ?? "Other";
    this.activity_type = activity_type;
    this.title = `ACTIVITY: ${activity_type}`.toUpperCase();
    const activityTypePlural =
      this.activity_type.toLowerCase() === "quiz"
        ? "Quizzes"
        : `${activity_type}s`.toUpperCase();
    this.activity_type_plural =
      activityTypePlural.charAt(0) + activityTypePlural.slice(1).toLowerCase();
    this.course_tot_minutes = ReportModels.getTotalTotMinutes(course);
    this.course_los = ReportModels.getTreeMappingsByListId(
      course,
      list_id,
    ).length;

    const targetActivities =
      course.modules?.flatMap(
        (module) =>
          module?.activities?.filter(
            (activity) => activity.activity_type === activity_type,
          ),
      ) || [];
    this.activity_type_tot_minutes = targetActivities.reduce(
      (acc, activity) => acc + activity.tot_minutes,
      0,
    );
    this.activity_type_tot_minutes_display = ReportModels.formatMinutes(
      this.activity_type_tot_minutes,
    );
    this.activity_type_tot_percent = `${Math.round(
      (this.activity_type_tot_minutes / this.course_tot_minutes) * 100,
    ).toFixed(1)}%`;
    this.activity_type_los_count = targetActivities.reduce(
      (acc, activity) =>
        acc + activity.mappings.filter((x) => x.list_id === list_id).length,
      0,
    );
    this.activity_type_lo_percent = `${Math.round(
      (this.activity_type_los_count / this.course_los) * 100,
    ).toFixed(1)}%`;
    this.average_activity_time = Math.round(
      targetActivities.reduce(
        (acc, activity) => acc + activity.tot_minutes,
        0,
      ) / targetActivities.length,
    );
    this.average_activity_time_display = ReportModels.formatMinutes(
      this.average_activity_time,
    );
    this.activity_count = targetActivities.length;

    this.activity_rows = targetActivities.map((activity) => {
      return {
        id: activity.id,
        display_name: activity.display_name,
        is_assessment: activity.is_assessment,
        lo_count:
          activity.mappings?.filter((x) => x.list_id === list_id)?.length || 0,
        modality: activity.modality,
        grading_stakes: activity.grading_stakes,
        tot_minutes: activity.tot_minutes,
        tot_minutes_display: ReportModels.formatMinutes(activity.tot_minutes),
      };
    });
  }

  activity_type = "";
  title = "ACTIVITY: N/A";
  activity_type_plural = "N/A";
  course_tot_minutes = 0;
  course_los = 0;
  activity_type_tot_minutes = 0;
  activity_type_tot_minutes_display = "N/A";
  activity_type_tot_percent = "0.0%";
  activity_type_los_count = 0;
  activity_type_lo_percent = "0.0%";
  average_activity_time = 0;
  average_activity_time_display = "0m";
  activity_count = 0;

  activity_rows: IActivityRow[] = [];
}

const CourseActivityTypeDetail = ({
  course,
  list_id,
  activity_type,
}: CourseActivityTypeDetailProps) => {
  const Model = new CompModel(course, list_id, activity_type);
  const { CourseLabel } = useOverrides();
  const courseLabel = CourseLabel();

  return (
    <div className="light">
      <h6 className="text-idesign-navy-70">{Model.title}</h6>
      <p className="text-foreground">
        {Model.activity_type_plural} make up {Model.activity_type_tot_percent}{" "}
        of the {courseLabel?.toLowerCase()} by time and cover{" "}
        {Model.activity_type_los_count} of the LOs in the{" "}
        {courseLabel?.toLowerCase()}.
      </p>
      <p className="text-foreground">
        There are {Model.activity_count} {Model.activity_type_plural} with an
        average time of {Model.average_activity_time_display} per{" "}
        {Model.activity_type}
      </p>
      <p className="text-foreground">
        The total time allotted for {Model.activity_type_plural} is{" "}
        {Model.activity_type_tot_minutes_display}
      </p>
      <div className="h-fit border border-idesign-navy-120 rounded-lg">
        <Table className="bg-white overflow-hidden rounded-lg border-collapse border-spacing-0">
          <TableHeader>
            <TableRow className="bg-gradient-1 border-b border-idesign-navy-120">
              <TableHead className="text-white" style={{ width: "40%" }}>
                Name
              </TableHead>
              <TableHead className="text-white text-center">
                Assessment
              </TableHead>
              <TableHead className="text-white text-center"># of LOs</TableHead>
              <TableHead className="text-white text-center">Modality</TableHead>
              <TableHead className="text-white text-center">
                Grading Stakes
              </TableHead>
              <TableHead className="text-white text-center">
                Time allotted
              </TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {Model.activity_rows.map((row, index) => (
              <TableRow key={index} className="border-y border-idesign-navy-120">
                <TableCell className="px-2 py-4">{row.display_name}</TableCell>
                <TableCell className="text-center border-x border-idesign-navy-120">
                  {row.is_assessment ? "Yes" : "No"}
                </TableCell>
                <TableCell className="text-center">{row.lo_count}</TableCell>
                <TableCell className="text-center border-x border-idesign-navy-120">
                  {row.modality}
                </TableCell>
                <TableCell className="text-center">
                  {row.grading_stakes}
                </TableCell>
                <TableCell className="text-center border-l border-idesign-navy-120">
                  {row.tot_minutes_display}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
    </div>
  );
};

export default CourseActivityTypeDetail;
