import React, {
  createContext,
  useContext,
  useState,
  SetStateAction,
  useCallback,
} from "react";
import { Node, Edge } from "reactflow";

import { observer } from "mobx-react-lite";

import useOverrides from "~/hooks/useOverrides";
import { NodeTypes } from "~/models/AlignEditor";
import { IListManagerList } from "~/models/ListManager";

type ExplorerContextType = {
  nodes: Node[];
  setNodes: React.Dispatch<SetStateAction<Node[]>>;
  edges: Edge[];
  setEdges: React.Dispatch<SetStateAction<Edge[]>>;
  selectedList: string | undefined;
  setSelectedList: React.Dispatch<SetStateAction<string | undefined>>;
  listDetails: IListManagerList | undefined;
  setListDetails: React.Dispatch<SetStateAction<IListManagerList | undefined>>;
  selectedListItemParent: string | undefined;
  setSelectedListItemParent: React.Dispatch<SetStateAction<string | undefined>>;
  selectedListItems: string[];
  setSelectedListItems: React.Dispatch<SetStateAction<string[]>>;
  boundaryContainer: HTMLElement | null;
  setBoundaryContainer: React.Dispatch<SetStateAction<HTMLElement | null>>;
  getTypeLabel: (type: NodeTypes, plural?: boolean) => string | undefined;
};

export const ExplorerContext = createContext<ExplorerContextType>({
  nodes: [],
  setNodes: () => undefined,
  edges: [],
  setEdges: () => undefined,
  selectedList: undefined,
  setSelectedList: () => undefined,
  listDetails: undefined,
  setListDetails: () => undefined,
  selectedListItemParent: undefined,
  setSelectedListItemParent: () => undefined,
  selectedListItems: [],
  setSelectedListItems: () => [],
  boundaryContainer: null,
  setBoundaryContainer: () => undefined,
  getTypeLabel: () => undefined,
});

type ExplorerProviderProps = {
  children: React.ReactNode;
};

export type ListResponse = {
  id: number;
  name: string;
  list_type: string;
  is_global_list: boolean;
  is_active: boolean;
  agency_id: number;
  partner_id: number;
  program_id: number;
  course_id: number;
  created_at: number;
  created_by_id: number;
  updated_at: number | null;
  updated_by_id: number;
};

const ExplorerProvider = observer(({ children }: ExplorerProviderProps) => {
  const [nodes, setNodes] = useState<Node[]>([]);
  const [edges, setEdges] = useState<Edge[]>([]);
  const [selectedList, setSelectedList] = useState<string | undefined>();
  const [listDetails, setListDetails] = useState<IListManagerList>();
  const [selectedListItemParent, setSelectedListItemParent] =
    useState<string>();
  const [selectedListItems, setSelectedListItems] = useState<string[]>([]);
  const [boundaryContainer, setBoundaryContainer] =
    useState<HTMLElement | null>(null);

  const overrides = useOverrides();

  const getTypeLabel = useCallback(
    (type: NodeTypes, plural = false) => {
      switch (type) {
        case NodeTypes.Program:
          return plural ? overrides.ProgramsLabel() : overrides.ProgramLabel();
        case NodeTypes.Course:
          return plural ? overrides.CoursesLabel() : overrides.CourseLabel();
        case NodeTypes.Module:
          return plural ? overrides.ModulesLabel() : overrides.ModuleLabel();
        case NodeTypes.Activity:
          return plural
            ? overrides.ActivitiesLabel()
            : overrides.ActivityLabel();
        default:
          return "";
      }
    },
    [overrides],
  );

  return (
    <ExplorerContext.Provider
      value={{
        nodes,
        setNodes,
        edges,
        setEdges,
        selectedList,
        setSelectedList,
        listDetails,
        setListDetails,
        selectedListItemParent,
        setSelectedListItemParent,
        selectedListItems,
        setSelectedListItems,
        boundaryContainer,
        setBoundaryContainer,
        getTypeLabel,
      }}
    >
      {children}
    </ExplorerContext.Provider>
  );
});

export const useExplorerContext = () => {
  const context = useContext(ExplorerContext);

  if (!context) {
    throw new Error(
      "useExplorerContext must be used within a ExplorerProvider",
    );
  }

  return context;
};

export default ExplorerProvider;
