import React, { ReactNode } from "react";
import { useForm } from "react-hook-form";

import { Button } from "~/components/ui-rework/button";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogFooter,
  DialogTitle,
  DialogTrigger,
} from "~/components/ui-rework/dialog";
import { Input } from "~/components/ui-rework/input";
import { Label } from "~/components/ui-rework/label";
import { useProgramEditorContext } from "~/context/ProgramEditorProvider";
import { CourseEditable, ICourse } from "~/models/AlignEditor";

type CourseDialogProps = {
  course?: ICourse;
  children: ReactNode;
  onSubmit: (data: CourseEditable) => void;
};

const CourseDialog = ({ course, children, onSubmit }: CourseDialogProps) => {
  const {
    register,
    formState: { errors, isDirty, isValid },
    handleSubmit,
    reset,
  } = useForm({
    mode: "onChange",
    defaultValues: {
      code: course?.code,
      name: course?.name,
      display_name: course?.display_name,
      level: course?.level,
      credits: course?.credits ?? 0,
    },
  });

  const handleOpenChange = (open: boolean) => {
    if (open) {
      reset({
        code: course?.code,
        name: course?.name,
        display_name: course?.display_name,
        level: course?.level,
        credits: course?.credits ?? 0,
      });
    }
  };

  const actionLabel = course ? "Edit" : "Add";
  const { courseLabel } = useProgramEditorContext();
  const title = `${actionLabel} ${courseLabel}`;

  return (
    <Dialog onOpenChange={handleOpenChange}>
      <DialogTrigger asChild>{children}</DialogTrigger>
      <DialogContent>
        <form onSubmit={handleSubmit((data) => onSubmit(data))}>
          <DialogTitle className="mt-1">{title}</DialogTitle>
          <div className="grid gap-4 py-4">
            <div className="flex flex-col gap-2">
              <Label htmlFor="code" className="font-bold mb-0">
                Code
              </Label>
              <Input
                id="code"
                className="bg-transparent"
                placeholder={`${courseLabel} Code`}
                {...register("code", { required: "Code is required" })}
                aria-describedby={
                  errors.code?.message ? "code-error" : undefined
                }
              />
              {errors.code?.message && (
                <span
                  id="code-error"
                  className="text-sm font-medium text-error-50"
                >
                  {errors.code.message}
                </span>
              )}
            </div>

            <div className="flex flex-col gap-2">
              <Label htmlFor="name" className="font-bold mb-0">
                Name
              </Label>
              <Input
                id="name"
                className="bg-transparent"
                placeholder={`${courseLabel} Name`}
                {...register("name", { required: "Name is required" })}
                aria-describedby={
                  errors.name?.message ? "name-error" : undefined
                }
              />
              {errors.name?.message && (
                <span
                  id="name-error"
                  className="text-sm font-medium text-error-50"
                >
                  {errors.name.message}
                </span>
              )}
            </div>

            <div className="flex flex-col gap-2">
              <Label htmlFor="display_name" className="font-bold mb-0">
                Display Name
              </Label>
              <Input
                id="display_name"
                className="bg-transparent"
                placeholder={`${courseLabel} Display Name`}
                {...register("display_name", {
                  required: "Display Name is required",
                })}
                aria-describedby={
                  errors.display_name?.message
                    ? "display_name-error"
                    : undefined
                }
              />
              {errors.display_name?.message && (
                <span
                  id="display_name-error"
                  className="text-sm font-medium text-error-50"
                >
                  {errors.display_name.message}
                </span>
              )}
            </div>

            <div className="flex flex-col gap-2">
              <Label htmlFor="level" className="font-bold mb-0">
                Level
              </Label>
              <Input
                id="level"
                className="bg-transparent"
                placeholder={`${courseLabel} Level`}
                {...register("level", { required: "Level is required" })}
                aria-describedby={
                  errors.level?.message ? "level-error" : undefined
                }
              />
              {errors.level?.message && (
                <span
                  id="level-error"
                  className="text-sm font-medium text-error-50"
                >
                  {errors.level.message}
                </span>
              )}
            </div>

            <div className="flex flex-col gap-2">
              <Label htmlFor="credits" className="font-bold mb-0">
                Credits
              </Label>
              <Input
                id="credits"
                className="bg-transparent"
                placeholder={`${courseLabel} Credits`}
                type="number"
                min="0"
                {...register("credits", {
                  required: "Credits is required",
                  min: 0,
                  valueAsNumber: true,
                })}
                aria-describedby={
                  errors.credits?.message ? "credits-error" : undefined
                }
              />
              {errors.credits?.message && (
                <span
                  id="credits-error"
                  className="text-sm font-medium text-error-50"
                >
                  {errors.credits.message}
                </span>
              )}
            </div>
          </div>
          <DialogFooter>
            <DialogClose asChild>
              <Button
                type="submit"
                disabled={!isDirty || !isValid}
                className="font-normal"
              >
                {course ? "Save Changes" : title}
              </Button>
            </DialogClose>
          </DialogFooter>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default CourseDialog;
