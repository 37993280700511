import React, { useEffect, useRef, useState } from "react";

import {
  ColumnDef,
  ColumnFiltersState,
  SortingState,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";

import { API_PATHS } from "~/api/ApiPaths";
import PartnerDetailsDrawer from "~/components/admin/PartnerDetailsDrawer";
import ProgramDetailsDrawer from "~/components/admin/ProgramDetailsDrawer";
import { Button } from "~/components/ui/button";
import { Input } from "~/components/ui/input";
import {
  Sheet,
  SheetContent,
  SheetHeader,
  SheetTitle,
} from "~/components/ui/sheet";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "~/components/ui/table";
import { toast } from "~/components/ui/use-toast";
import useAxios from "~/hooks/useAxios";
import useOverrides from "~/hooks/useOverrides";
import { useStore } from "~/models/Root";
import { IPartner, IPartnerItem } from "~/models/UserManager";

interface IPartnerListTableProps {
  triggerRefresh: boolean;
  setTriggerRefresh: React.Dispatch<React.SetStateAction<boolean>>;
}

const PartnerListTable: React.FC<IPartnerListTableProps> = (props) => {
  const { triggerRefresh, setTriggerRefresh } = props;
  const displayReady = useRef(false);
  const [partnerData, setPartnerData] = useState<IPartner[]>([]);
  const { data, authToken, error, requestUrl, fetch } = useAxios({
    method: "GET",
    initialValue: null,
  });
  const { getAuthToken } = useStore();
  const partnersFetched = useRef(false);
  const partnersSet = useRef(false);
  const finishedFetchingAll = useRef(false);
  const [currentData, setCurrentData] = useState([]);
  const { PartnerLabel, ProgramsLabel } = useOverrides();
  const partnerLabel = PartnerLabel();
  const programsLabel = ProgramsLabel();
  const [refetch, setRefetch] = useState(false);
  const [sorting, setSorting] = React.useState<SortingState>([]);
  const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>(
    [],
  );
  const [tableBeginningSize, setTableBeginningSize] = useState(0);
  const [previousRowSize, setPreviousRowSize] = useState(0);
  const [openPartnerDetails, setOpenPartnerDetails] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [drawerData, setDrawerData] = useState<IPartner | null>(null);

  useEffect(() => {
    if (
      data &&
      currentData !== data &&
      !partnersSet.current &&
      partnersFetched.current
    ) {
      // Set partner data
      setCurrentData(data);
      const allPartnersArray: IPartner[] = [];
      data.entity.forEach((item: IPartnerItem) => {
        allPartnersArray.push({
          id: item.id,
          partner_type: item.partner_type,
          display_name: item.name,
          short_name: item.short_name,
        });
      });
      setPartnerData(allPartnersArray);
      partnersSet.current = true;
      displayReady.current = false;
      finishedFetchingAll.current = true;
      setRefetch(false);
    } else if (!partnersFetched.current || refetch) {
      // fetch partner data
      partnersFetched.current = true;
      displayReady.current = false;
      requestUrl.current = `${API_PATHS.GET_PARTNER_LIST}?permission=ManageUsers`;
      authToken.current = getAuthToken();
      fetch();
    } else if (error) {
      console.log(error);
    }
  }, [
    data,
    error,
    authToken,
    requestUrl,
    fetch,
    getAuthToken,
    currentData,
    refetch,
  ]);

  const updateToast = () => {
    toast({
      variant: "success",
      title: partnerLabel + " List Updated",
      description:
        "If you made an update to a " +
        partnerLabel +
        ", that change has been reflected in the " +
        partnerLabel +
        " list.",
    });
  };

  const triggleRefetch = () => {
    displayReady.current = false;
    partnersFetched.current = false;
    partnersSet.current = false;
    setRefetch(true);
  };

  useEffect(() => {
    if (triggerRefresh) {
      triggleRefetch();
      setTriggerRefresh(false);
    }
  }, [triggerRefresh, setTriggerRefresh]);

  const toggleDrawer = () => {
    setIsOpen(!isOpen);
    if (isOpen) {
      triggleRefetch();
      updateToast();
    }
  };

  const columns: ColumnDef<IPartner>[] = [
    {
      accessorKey: "display_name",
      header: ({ column }) => {
        return (
          <Button
            className={`${
              column.getIsSorted() === "asc" ? "text-primary" : ""
            } p-0 w-full items-center justify-start`}
            variant="ghost"
            onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
          >
            {partnerLabel}
            <span aria-hidden="true" className="material-icons text-base">
              unfold_more
            </span>
          </Button>
        );
      },
    },
    {
      accessorKey: "partner_type",
      header: ({ column }) => {
        return (
          <Button
            className={
              column.getIsSorted() === "asc" ? "text-primary p-0" : "p-0"
            }
            variant="ghost"
            onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
          >
            {partnerLabel} Type
            <span aria-hidden="true" className="material-icons text-base">
              unfold_more
            </span>
          </Button>
        );
      },
    },
    {
      id: "actions",
      header: "Actions",
      cell: ({ row }) => {
        const data = row.original;
        return (
          <>
            {" "}
            <Button
              className="mr-2 rounded-md"
              size="sm"
              variant="outline"
              onClick={(e) => {
                setIsOpen(true);
                setOpenPartnerDetails(true);
                setDrawerData(data);
              }}
              testid={`admin-details`}
            >
              {partnerLabel} Details
            </Button>
            <Button
              className="rounded-md"
              size="sm"
              variant="outline"
              onClick={(e) => {
                setIsOpen(true);
                setOpenPartnerDetails(false);
                setDrawerData(data);
              }}
              testid={`admin-programs`}
            >
              {programsLabel}
            </Button>
          </>
        );
      },
    },
  ];

  const table = useReactTable({
    data: partnerData,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    getFilteredRowModel: getFilteredRowModel(),
    state: {
      sorting,
      columnFilters,
    },
  });

  return (
    <>
      <div className="h-full w-full">
        <Table className="w-full">
          <TableHeader>
            {table.getHeaderGroups().map((headerGroup) => (
              <TableRow key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  return (
                    <TableHead key={header.id}>
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                            header.column.columnDef.header,
                            header.getContext(),
                          )}
                    </TableHead>
                  );
                })}
              </TableRow>
            ))}
            <TableRow>
              <TableHead>
                <Input
                  placeholder={"Filter " + partnerLabel + "s..."}
                  value={
                    (table
                      .getColumn("display_name")
                      ?.getFilterValue() as string) ?? ""
                  }
                  onChange={(event) =>
                    table
                      .getColumn("display_name")
                      ?.setFilterValue(event.target.value)
                  }
                  className="m-0 border-0 p-0"
                  data-testid="admin-partner-filter"
                />
              </TableHead>
              <TableHead>
                <Input
                  placeholder={"Filter " + partnerLabel + " type..."}
                  value={
                    (table
                      .getColumn("partner_type")
                      ?.getFilterValue() as string) ?? ""
                  }
                  onChange={(event) =>
                    table
                      .getColumn("partner_type")
                      ?.setFilterValue(event.target.value)
                  }
                  className="m-0 border-0 p-0"
                />
              </TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {table.getRowModel().rows?.length ? (
              table.getRowModel().rows.map((row) => (
                <TableRow
                  key={row.id}
                  data-state={row.getIsSelected() && "selected"}
                >
                  {row.getVisibleCells().map((cell) => (
                    <TableCell key={cell.id}>
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext(),
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell
                  colSpan={columns.length}
                  className="h-24 text-center"
                >
                  No results.
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
        <div className="border-t flex flex-row items-center justify-between w-full py-2">
          <div className="flex-1 text-sm text-muted-foreground px-2">
            Items {tableBeginningSize} -{" "}
            {tableBeginningSize + table.getRowModel().rows.length} of{" "}
            {table.getFilteredRowModel().rows.length} total.
          </div>
          <div className="space-x-2 m-2">
            <Button
              className="rounded-md"
              size="sm"
              variant="outline"
              onClick={() => {
                table.previousPage();
                setTableBeginningSize(tableBeginningSize - previousRowSize);
              }}
              disabled={!table.getCanPreviousPage()}
            >
              Previous
            </Button>
            <Button
              className="rounded-md"
              size="sm"
              variant="outline"
              onClick={() => {
                table.nextPage();
                setTableBeginningSize(
                  tableBeginningSize + table.getRowModel().rows.length,
                );
                setPreviousRowSize(table.getRowModel().rows.length);
              }}
              disabled={!table.getCanNextPage()}
            >
              Next
            </Button>
          </div>
        </div>
      </div>
      <Sheet open={isOpen} onOpenChange={toggleDrawer}>
        <SheetContent className="!w-1/2 !min-w-[400px] p-0">
          <div className="mx-auto w-full max-w-m h-full">
            <SheetHeader className="flex flex-row justify-items-center items-center justify-between">
              <SheetTitle className="p-4 mx-2 justify-items-center align-text-center">
                {openPartnerDetails ? partnerLabel + " Details" : programsLabel}
              </SheetTitle>
            </SheetHeader>
            {openPartnerDetails ? (
              <PartnerDetailsDrawer partnerDetailsData={drawerData} />
            ) : (
              <ProgramDetailsDrawer programDetailsData={drawerData} />
            )}
          </div>
        </SheetContent>
      </Sheet>
    </>
  );
};

export default PartnerListTable;
