import React, { ReactNode } from "react";
import { useForm } from "react-hook-form";

import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";

import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "~/components/ui/form";
import { SelectMultiple } from "~/components/ui/select-multiple";
import { Button } from "~/components/ui-rework/button";
import { Checkbox } from "~/components/ui-rework/checkbox";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "~/components/ui-rework/dialog";
import { Input } from "~/components/ui-rework/input";
import {
  IListManagerListItem,
  ListItemEditable,
  Mapping_Restrictions,
} from "~/models/ListManager";

const mappingRestrictionsOptions = Mapping_Restrictions.map((restriction) => ({
  label: restriction,
  value: restriction,
}));

const formSchema = z.object({
  code: z.string().optional(),
  name: z.string().min(1, { message: "Name is required" }),
  display_name: z.string().min(1, { message: "Display Name is required" }),
  mapping_restrictions: z.array(z.string()).optional(),
  is_heading: z.boolean(),
  is_mappable: z.boolean(),
});

type ListItemDialogProps = {
  item?: IListManagerListItem;
  children: ReactNode;
  onSubmit: (data: ListItemEditable) => void;
};

const ListItemDialog = ({ item, children, onSubmit }: ListItemDialogProps) => {
  const form = useForm<z.infer<typeof formSchema>>({
    mode: "onChange",
    resolver: zodResolver(formSchema),
    defaultValues: {
      code: item?.code ?? "",
      name: item?.name ?? "",
      display_name: item?.display_name ?? "",
      mapping_restrictions: item?.mapping_restrictions?.map((s) => s) ?? [],
      is_heading: item?.is_heading ?? false,
      is_mappable: item?.is_mappable ?? true,
    },
  });

  const handleOpenChange = (open: boolean) => {
    if (open) {
      form.reset({
        code: item?.code ?? "",
        name: item?.name ?? "",
        display_name: item?.display_name ?? "",
        mapping_restrictions: item?.mapping_restrictions?.map((s) => s) ?? [],
        is_heading: item?.is_heading ?? false,
        is_mappable: item?.is_mappable ?? true,
      });
    }
  };

  return (
    <Dialog onOpenChange={handleOpenChange}>
      <DialogTrigger asChild>{children}</DialogTrigger>
      <DialogContent>
        <Form {...form}>
          <form onSubmit={form.handleSubmit((data) => onSubmit(data))}>
            <DialogHeader>
              <DialogTitle>{`${item ? "Edit" : "Add"} List Item`}</DialogTitle>
              <DialogDescription>
                {`${
                  item ? "Make changes to the" : "Add a new"
                } list item here. Click Save Changes when
                you're done.`}
              </DialogDescription>
            </DialogHeader>

            <div className="grid gap-4 pb-4">
              <FormField
                control={form.control}
                name="code"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Code</FormLabel>
                    <FormControl>
                      <Input className="bg-transparent" {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="name"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Name</FormLabel>
                    <FormControl>
                      <Input className="bg-transparent" {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="display_name"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Display Name</FormLabel>
                    <FormControl>
                      <Input className="bg-transparent" {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name={"mapping_restrictions"}
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Mapping Restrictions</FormLabel>
                    <FormControl>
                      <SelectMultiple
                        clearable
                        onValuesChange={field.onChange}
                        values={field.value ?? undefined}
                        options={mappingRestrictionsOptions}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name={"is_heading"}
                render={({ field }) => (
                  <FormItem className="flex flex-row items-center space-x-3 space-y-0">
                    <FormControl>
                      <Checkbox
                        checked={field.value}
                        onCheckedChange={field.onChange}
                      />
                    </FormControl>
                    <FormLabel>Is List Heading?</FormLabel>
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name={"is_mappable"}
                render={({ field }) => (
                  <FormItem className="flex flex-row items-center space-x-3 space-y-0">
                    <FormControl>
                      <Checkbox
                        checked={field.value}
                        onCheckedChange={field.onChange}
                      />
                    </FormControl>
                    <FormLabel>Is Mappable?</FormLabel>
                  </FormItem>
                )}
              />
            </div>

            <DialogFooter>
              <DialogClose asChild>
                <Button
                  className="font-normal"
                  disabled={!form.formState.isDirty || !form.formState.isValid}
                  testid="lists-save-changes"
                  type="submit"
                >
                  Save Changes
                </Button>
              </DialogClose>
            </DialogFooter>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  );
};

export default ListItemDialog;
