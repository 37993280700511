import React, { useState } from "react";

import {
  ColumnDef,
  ColumnFiltersState,
  SortingState,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";

import { Button } from "~/components/ui/button";
import { Input } from "~/components/ui/input";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "~/components/ui/table";
import { ISessionDetails } from "~/models/UserManager";

interface ISessionActivityProps {
  sessionDetails: ISessionDetails[];
}

const SessionActivity: React.FC<ISessionActivityProps> = (props) => {
  const { sessionDetails } = props;
  const [sorting, setSorting] = React.useState<SortingState>([]);
  const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>(
    [],
  );
  const [tableBeginningSize, setTableBeginningSize] = useState(0);
  const [previousRowSize, setPreviousRowSize] = useState(0);

  const columns: ColumnDef<ISessionDetails>[] = [
    {
      accessorKey: "activity_type",
      header: "Activity Type",
    },
    {
      accessorKey: "relative_uri",
      header: "Relative Uri",
    },
    {
      header: "Details",
      cell: ({ row }) => {
        const data: any = row.original;
        const reason = data.details.reason;
        const content = data.details.content;
        return (() => {
          switch (data.activity_type) {
            case "Content View":
              return `${content}`;
            case "Logout":
              return `${reason}`;
            default:
              return;
          }
        })();
      },
    },
  ];

  const table = useReactTable({
    data: sessionDetails,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    getFilteredRowModel: getFilteredRowModel(),
    state: {
      sorting,
      columnFilters,
    },
  });

  return (
    <>
      <Table>
        <TableHeader>
          {table.getHeaderGroups().map((headerGroup) => (
            <TableRow key={headerGroup.id}>
              {headerGroup.headers.map((header) => {
                return (
                  <TableHead key={header.id}>
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                          header.column.columnDef.header,
                          header.getContext(),
                        )}
                  </TableHead>
                );
              })}
            </TableRow>
          ))}
          <TableRow>
            <TableHead>
              <Input
                placeholder="Filter activity types..."
                value={
                  (table
                    .getColumn("activity_type")
                    ?.getFilterValue() as string) ?? ""
                }
                onChange={(event) =>
                  table
                    .getColumn("activity_type")
                    ?.setFilterValue(event.target.value)
                }
                className="m-0 border-0 p-0"
              />
            </TableHead>
            <TableHead>
              <Input
                placeholder="Filter relative uris..."
                value={
                  (table
                    .getColumn("relative_uri")
                    ?.getFilterValue() as string) ?? ""
                }
                onChange={(event) =>
                  table
                    .getColumn("relative_uri")
                    ?.setFilterValue(event.target.value)
                }
                className="m-0 border-0 p-0"
              />
            </TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {table.getRowModel().rows?.length ? (
            table.getRowModel().rows.map((row) => (
              <TableRow
                key={row.id}
                data-state={row.getIsSelected() && "selected"}
              >
                {row.getVisibleCells().map((cell) => (
                  <TableCell key={cell.id}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </TableCell>
                ))}
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell colSpan={columns.length} className="h-24 text-center">
                No results.
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
      <div className="border-t flex flex-row items-center justify-between w-full py-2">
        <div className="flex-1 text-sm text-muted-foreground px-2">
          Items {tableBeginningSize} -{" "}
          {tableBeginningSize + table.getRowModel().rows.length} of{" "}
          {table.getFilteredRowModel().rows.length} total.
        </div>
        <div className="space-x-2 m-2">
          <Button
            className="rounded-md"
            size="sm"
            variant="outline"
            onClick={() => {
              table.previousPage();
              setTableBeginningSize(tableBeginningSize - previousRowSize);
            }}
            disabled={!table.getCanPreviousPage()}
          >
            Previous
          </Button>
          <Button
            className="rounded-md"
            size="sm"
            variant="outline"
            onClick={() => {
              table.nextPage();
              setTableBeginningSize(
                tableBeginningSize + table.getRowModel().rows.length,
              );
              setPreviousRowSize(table.getRowModel().rows.length);
            }}
            disabled={!table.getCanNextPage()}
          >
            Next
          </Button>
        </div>
      </div>
    </>
  );
};

export default SessionActivity;
