import React, { useEffect, useState } from "react";

import { Form, Formik } from "formik";
import * as Yup from "yup";

import { API_PATHS } from "~/api/ApiPaths";
import { AddFormStyle } from "~/components/admin/CreateUserDrawer";
import UserDetails, { IUserDetailsForm } from "~/components/admin/UserDetails";
import UserPartnerDetails from "~/components/admin/UserPartnerDetails";
import { Button } from "~/components/ui/button";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "~/components/ui/tabs";
import useAxios from "~/hooks/useAxios";
import useOverrides from "~/hooks/useOverrides";
import { useStore } from "~/models/Root";

interface IUserDetailsDrawer {
  userDetailsData: any | null;
}

interface IUpdateUserObj {
  app_users_id: number;
  first_name: string;
  last_name: string;
  email: string;
  title: string;
}

const UserDetailsDrawer: React.FC<IUserDetailsDrawer> = (props) => {
  const { userDetailsData } = props;
  const { data, authToken, error, requestUrl, payload, fetch } = useAxios({
    method: "POST",
    initialValue: null,
  });
  const { getAuthToken } = useStore();
  const { PartnerLabel, ProgramLabel } = useOverrides();
  const programLabel = ProgramLabel();
  const partnerLabel = PartnerLabel();
  const defaultValues: IUserDetailsForm = {
    firstName: userDetailsData?.first_name || "",
    lastName: userDetailsData?.last_name || "",
    email: userDetailsData?.email || "",
    title: userDetailsData?.title || "",
  };
  const [formValues, setFormValues] = useState<IUserDetailsForm>(defaultValues);
  const [revertFormValues, setRevertFormValues] =
    useState<IUserDetailsForm>(defaultValues);
  const [newFormValues, setNewFormValues] =
    useState<IUserDetailsForm>(defaultValues);
  const [editMode, setEditMode] = useState(false);

  const updateUsers = (user: IUpdateUserObj) => {
    payload.current = {
      app_users_id: user.app_users_id,
      first_name: user.first_name,
      last_name: user.last_name,
      title: user.title,
      email: user.email,
    };
    authToken.current = getAuthToken();
    requestUrl.current = `${API_PATHS.UPDATE_USER}`;
    fetch();
  };

  useEffect(() => {
    if (data?.issuccess) {
      setFormValues(newFormValues);
    } else if (error) {
      console.log(error);
    }
  }, [data, error, newFormValues, setFormValues]);

  return (
    <>
      <Formik
        initialValues={defaultValues}
        validationSchema={Yup.object({
          firstName: Yup.string()
            .min(2, "Too Short!")
            .max(50, "Too Long!")
            .required("First Name Required"),
          lastName: Yup.string()
            .min(2, "Too Short!")
            .max(50, "Too Long!")
            .required("Last Name Required"),
          email: Yup.string().email("Invalid email").required("Email Required"),
          title: Yup.string()
            .min(2, "Too Short!")
            .max(50, "Too Long!")
            .required("Title Required"),
        })}
        onSubmit={async (values, { setSubmitting }) => {
          setEditMode(false);
          //update the user with the new values
          const updateUserObj: IUpdateUserObj = {
            app_users_id: userDetailsData?.id,
            first_name: values.firstName,
            last_name: values.lastName,
            email: values.email,
            title: values.title,
          };
          updateUsers(updateUserObj);
          //update the form values for reverting on cancel changes
          const revertObject: IUserDetailsForm = {
            firstName: values.firstName,
            lastName: values.lastName,
            email: values.email,
            title: values.title,
          };
          setNewFormValues(revertObject);
        }}
      >
        <AddFormStyle>
          <Form className="add-form">
            <div className="flex flex-row justify-between items-center">
              <Tabs defaultValue="partner" className="w-full h-full mx-3">
                <div className="flex flex-row justify-between items-center">
                  <div className="flex flex-row">
                    <TabsList className="grid grid-cols-2 w-[350px] h-fit">
                      <TabsTrigger
                        value="partner"
                        className=" data-[state=active]:text-align-blue"
                      >
                        <span
                          className="material-icons text-2xl w-[30px]"
                          aria-hidden
                        >
                          person_outline
                        </span>
                        <span className="text-base">Details</span>
                      </TabsTrigger>

                      <TabsTrigger
                        value="user"
                        className=" data-[state=active]:text-align-blue"
                        onClick={() => {
                          if (editMode) {
                            setEditMode(false);
                          }
                        }}
                      >
                        <span
                          className="material-icons text-2xl w-[30px]"
                          aria-hidden
                        >
                          business_outline
                        </span>
                        <span className="text-base">
                          {partnerLabel}/{programLabel}
                        </span>
                      </TabsTrigger>
                    </TabsList>
                  </div>
                </div>
                <TabsContent value="partner">
                  <div className="overflow-hidden h-full mt-2 ml-1">
                    <div className="flex flex-row justify-end pt-1 pr-1">
                      <Button
                        type="button"
                        variant="secondary"
                        className="h-fit hover:bg-gray-200 border rounded-full"
                        onClick={() => {
                          setEditMode(true);
                          setRevertFormValues(formValues);
                        }}
                        testid="edit-user"
                      >
                        Edit User
                      </Button>
                    </div>
                    <UserDetails
                      userDetailsData={userDetailsData}
                      revertFormValues={revertFormValues}
                      editMode={editMode}
                      setEditMode={setEditMode}
                    />
                  </div>
                </TabsContent>
                <TabsContent value="user">
                  <div className="overflow-hidden h-full mt-2">
                    <UserPartnerDetails userDetailsData={userDetailsData} />
                  </div>
                </TabsContent>
              </Tabs>
            </div>
          </Form>
        </AddFormStyle>
      </Formik>
    </>
  );
};

export default UserDetailsDrawer;
