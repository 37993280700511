import React, { useEffect } from "react";

import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";

import { Badge } from "~/components/ui/badge";
import { Button } from "~/components/ui/button";
import { ScrollArea } from "~/components/ui/scroll-area";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "~/components/ui/table";
import { cn } from "~/lib/utils";
import { IAvailableList } from "~/models/ListManager";
import { getReadableDate } from "~/utilities/DateFormat";

type MappedProgram = {
  id: number;
  code: string;
};

type ListSelectorProps = {
  lists: IAvailableList[];
  setSelectedListId: React.Dispatch<React.SetStateAction<number | null>>;
  filterFormValues?: any;
};

const ListSelector = ({
  lists,
  setSelectedListId,
  filterFormValues,
}: ListSelectorProps) => {
  const [filteredData, setFilteredData] = React.useState(lists);

  useEffect(() => {
    if (lists) {
      setFilteredData(lists);
    }
  }, [lists]);

  const columns: ColumnDef<IAvailableList>[] = [
    {
      header: "List Name",
      cell: ({ row }) => {
        const name = row.original.name;
        return (
          <Button
            className="group text-left text-button-blue p-0 !no-underline h-full py-3 "
            onClick={() => setSelectedListId(row.original.id)}
            variant="link"
            testid="lists"
          >
            <div className="flex items-center text-wrap min-w-[250px]">
              <span className="group-hover:underline decoration-text-button-blue">
                {name}
              </span>
              <span
                className="material-icons mr-1 text-[1.2rem]"
                aria-hidden="true"
              >
                keyboard_arrow_right
              </span>
            </div>
          </Button>
        );
      },
    },
    {
      accessorKey: "category",
      header: "Category",
      cell: ({ row }) => {
        return row.original.is_global_list ? "Global" : "Local";
      },
    },
    {
      accessorKey: "active",
      header: "Active",
      cell: ({ row }) => {
        return row.original.is_active ? "Yes" : "No";
      },
    },
    {
      accessorKey: "list_type",
      header: "Type",
    },
    {
      accessorKey: "description",
      header: "Description",
    },
    {
      header: "Created",
      cell: ({ row }) => {
        return getReadableDate(row.original.created_at);
      },
    },
    {
      header: "Programs",
      cell: ({ row }) => {
        const values = row.original;
        if (values.mapped_programs) {
          const hasMappedPrograms = values.mapped_programs.length > 0;
          const mappedPrograms: MappedProgram[] =
            values.mapped_programs as MappedProgram[];

          return hasMappedPrograms
            ? mappedPrograms.map((program: MappedProgram) => (
                <Badge key={program.id} variant="multiselect" className="m-0.5">
                  {program.code}
                </Badge>
              ))
            : null;
        } else return null;
      },
    },
  ];

  //a bit ugly with many if statements, but custom filtering because tanstack wont allow for multiple filters
  //on the same column
  React.useEffect(() => {
    if (filterFormValues !== null) {
      const {
        category_partner,
        category_global,
        is_active_yes,
        is_active_no,
        list_type,
        programs,
      } = filterFormValues;
      setFilteredData(
        lists.filter((row) => {
          if (!category_partner && !row.is_global_list) return false;
          if (!category_global && row.is_global_list) return false;
          if (!is_active_yes && row.is_active) return false;
          if (!is_active_no && !row.is_active) return false;

          //go through the list types and check if any of the list types match the filter
          if (list_type.length) {
            let hasMatch = false;
            if (list_type.includes(row.list_type)) {
              hasMatch = true;
            }

            if (!hasMatch) return false;
          }

          //go through the mapped programs and check if any of the programs match the filter
          if (programs.length) {
            let hasMatch = false;
            if (row.mapped_programs.length) {
              row.mapped_programs.forEach((program: any) => {
                if (programs.includes(program.display_name)) {
                  hasMatch = true;
                }
              });
            } else if (programs.includes("No Assigned Program")) {
              hasMatch = true;
            }

            if (!hasMatch) return false;
          }

          return true;
        }),
      );
    }
  }, [filterFormValues, lists]);

  const table = useReactTable({
    data: filteredData,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <div className="px-5 pb-4 overflow-auto w-full">
      <ScrollArea
        thumbClassName="bg-gradient-1"
        className="w-full m-0 p-0 h-full border border-idesign-navy-120 rounded-md"
      >
        <Table className="h-full w-full overflow-scroll" sticky>
          <colgroup>
            <col />
            <col style={{ minWidth: "90px" }} />
            <col style={{ width: "75px" }} />
            <col style={{ width: "170px" }} />
            <col style={{ minWidth: "180px" }} />
            <col style={{ width: "150px" }} />
            <col style={{ minWidth: "50px" }} />
          </colgroup>
          <TableHeader className="sticky top-0 z-10 bg-gradient-1">
            {table.getHeaderGroups().map((headerGroup) => (
              <TableRow key={headerGroup.id} className="hover:bg-transparent">
                {headerGroup.headers.map((header, index, headers) => {
                  const isFirstCell = index === 0;
                  const isLastCell = index === headers.length - 1;
                  return (
                    <TableHead
                      key={header.id}
                      className={cn(
                        "text-white font-bold border-idesign-navy-120 p-2 py-4 bg-transparent",
                        !isFirstCell && "border-l",
                        !isLastCell && "border-r",
                      )}
                    >
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                            header.column.columnDef.header,
                            header.getContext(),
                          )}
                    </TableHead>
                  );
                })}
              </TableRow>
            ))}
          </TableHeader>
          <TableBody>
            {table.getRowModel().rows?.length ? (
              table.getRowModel().rows.map((row, rowIndex, rows) => {
                const isLastRow = rowIndex === rows.length - 1;
                return (
                  <TableRow
                    key={row.id}
                    data-state={row.getIsSelected() && "selected"}
                  >
                    {row.getVisibleCells().map((cell, index, cells) => {
                      const isFirstCell = index === 0;
                      const isLastCell = index === cells.length - 1;
                      return (
                        <TableCell
                          key={cell.id}
                          className={cn(
                            "border-t border-idesign-navy-120 p-2 pl-4 bg-white max-w-[500px] text-idesign-navy-100",
                            !isFirstCell && "border-l",
                            !isLastCell && "border-r",
                          )}
                        >
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext(),
                          )}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })
            ) : (
              <TableRow>
                <TableCell
                  colSpan={columns.length}
                  className="h-24 text-center"
                >
                  No results.
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </ScrollArea>
    </div>
  );
};

export default ListSelector;
