import React, { useContext, useEffect, useRef, useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";

import { observer } from "mobx-react-lite";
import styled from "styled-components";

import "@reach/dialog/styles.css";
import { API_PATHS } from "~/api/ApiPaths";
import AlignDialog, { DialogContext } from "~/components/AlignDialog";
import Navbar from "~/components/Navbar";
import "~/css/App.css";
import useAxios from "~/hooks/useAxios";
import useLogSessionActivity from "~/hooks/useLogSessionActivity";
import { useStore as useRootStore } from "~/models/Root";

import InactivityDialog from "./components/InactivityDialog";

const AppWrapper = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
`;

const App: React.FC = observer(() => {
  const location = useLocation();
  const { data, authToken, error, requestUrl, fetch, resetAll } = useAxios({
    method: "GET",
    initialValue: null,
  });
  const { getAuthToken, setAuthToken, resetAppUser, setLoggedIn } =
    useRootStore();
  const { logPageView } = useLogSessionActivity();
  const [isAuthorized, setIsAuthorized] = useState(false);
  const [requestPath, setRequestPath] = useState("/");
  const isCheckingToken = useRef(false);
  const locationChanged = useRef(true);
  const locationDebounce = useRef("");
  const navigate = useNavigate();
  const { dialogContent } = useContext(DialogContext);

  useEffect(() => {
    //tracks page views for all pages loaded via react router, for register we need to track the page view in the register component.
    if (locationDebounce.current !== location.pathname) {
      locationDebounce.current = location.pathname;
      logPageView(location.pathname);
    }
  }, [location, logPageView]);

  useEffect(() => {
    if (locationChanged.current) {
      locationChanged.current = false;
      if (getAuthToken() !== "" && !isCheckingToken.current) {
        //console.log('check if our session is valid...', getAuthToken())
        resetAll();
        isCheckingToken.current = true;
        requestUrl.current = `${API_PATHS.IS_LOGGED_IN}`;
        authToken.current = getAuthToken();
        fetch();
      } else if (getAuthToken() === "" && !isCheckingToken.current) {
        navigate("/login", { replace: true });
      }
      setRequestPath(location.pathname);
    }
  }, [
    location.pathname,
    authToken,
    requestUrl,
    fetch,
    navigate,
    getAuthToken,
    resetAll,
  ]);

  useEffect(() => {
    if (data) {
      if (data === true && isCheckingToken.current) {
        setIsAuthorized(true);

        //console.log('if current request is not equal then pass the current location path', requestPath, location.pathname)
        if (requestPath !== location.pathname) {
          isCheckingToken.current = false;
        }
        navigate(
          requestPath !== location.pathname ? location.pathname : requestPath,
          { replace: true },
        );
        locationChanged.current = true;
      }
    } else if (data === false || error || null) {
      setAuthToken("");
      setIsAuthorized(false);
      resetAppUser();
      setLoggedIn(false);
      navigate("/login", { replace: true });
    }
    // console.log('environment is set to: ', process.env.REACT_APP_API_BASE_URL)
    // console.log('group is set to: ', process.env.REACT_APP_API_GROUP)
  }, [
    data,
    navigate,
    requestPath,
    setAuthToken,
    setIsAuthorized,
    location.pathname,
    error,
    resetAppUser,
    setLoggedIn,
  ]);

  return (
    <AppWrapper>
      <Navbar />
      <div className="App">{isAuthorized && <Outlet />}</div>
      <AlignDialog children={dialogContent} />
      <InactivityDialog />
    </AppWrapper>
  );
});

export default App;
