import React, { Fragment, useContext, useEffect, useMemo } from "react";

import { useQuery, useQueryClient } from "@tanstack/react-query";
import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  getExpandedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import axios from "axios";

import { ApiUrls } from "~/api/ApiPaths";
import { AxiosContext } from "~/api/AxiosInstance";
import { Skeleton } from "~/components/ui/skeleton";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "~/components/ui/table";
import { NodeTypes } from "~/models/AlignEditor";
import { useStore as useRootStore } from "~/models/Root";

type ListRow = {
  id: number;
  name: string;
  type: string;
  items: ItemRow[];
};

type ItemRow = {
  id: number;
  name: string;
  code?: string;
  display_name?: string;
  items?: ItemRow[];
  is_heading?: boolean;
};

type NodeMappingsListTableProps = {
  id: number;
  type: NodeTypes;
};

const NodeMappingsListTable = React.memo(
  ({ id, type }: NodeMappingsListTableProps) => {
    const { getAuthToken, selectedProgramId } = useRootStore();

    const axiosInstance = useContext(AxiosContext) ?? axios;

    const queryFn = (): Promise<any> =>
      axiosInstance
        .request({
          method: "GET",
          url: ApiUrls.mapping.getListMappingsForCurriculumItem(id, type),
          headers: { Authorization: getAuthToken() },
        })
        .then((response) => response.data);

    const { data, isSuccess } = useQuery({
      queryKey: [
        "curriculumItemMappings",
        ApiUrls.mapping.getListMappingsForCurriculumItem(id, type),
      ],
      enabled: selectedProgramId !== 0,
      queryFn,
    });

    const queryClient = useQueryClient();

    useEffect(() => {
      queryClient.invalidateQueries({ queryKey: ["curriculumItemMappings"] });
    }, [selectedProgramId, queryClient]);

    if (!isSuccess) {
      return (
        <div className="bg-gradient-one">
          <Skeleton className="h-[250px]" />
        </div>
      );
    }

    const listRows: ListRow[] = data.entity;

    return (
      <>
        {listRows.map((row) => {
          return (
            <Fragment key={row.id}>
              <NodeMappingsTable header={row.name} data={row.items} />
            </Fragment>
          );
        })}
      </>
    );
  },
);

type NodeMappingsTableProps = {
  header: string;
  data: ItemRow[];
};

const NodeMappingsTable = React.memo(
  ({ header, data }: NodeMappingsTableProps) => {
    const columns = useMemo<ColumnDef<ItemRow>[]>(
      () => [
        {
          accessorFn: (row) => row.display_name,
          id: "display_name",
          header: () => header,
          cell: ({ row, getValue }) => (
            <div
              style={{
                // Since rows are flattened by default,
                // we can use the row.depth property
                // and paddingLeft to visually indicate the depth
                // of the row
                paddingLeft: `${row.depth}rem`,
              }}
            >
              <div>{getValue<boolean>()}</div>
            </div>
          ),
          footer: (props) => props.column.id,
        },
      ],
      [header],
    );

    const table = useReactTable({
      data,
      columns,
      getSubRows: (row) => row.items,
      getExpandedRowModel: getExpandedRowModel(),
      getCoreRowModel: getCoreRowModel(),
    });

    useEffect(() => {
      table.toggleAllRowsExpanded();
    }, [table]);

    return (
      <div>
        <Table className="w-full">
          <TableHeader className="bg-gradient-1">
            {table.getHeaderGroups().map((headerGroup) => (
              <TableRow key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  return (
                    <TableHead
                      key={header.id}
                      className="bg-gradient-1 text-white font-bold border-b border-idesign-navy-120 py-2"
                    >
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                            header.column.columnDef.header,
                            header.getContext(),
                          )}
                    </TableHead>
                  );
                })}
              </TableRow>
            ))}
          </TableHeader>
          <TableBody>
            {table.getRowModel().rows?.length ? (
              table.getRowModel().rows.map((row) => (
                <TableRow
                  key={row.id}
                  className="border-b last:border-b-0 border-idesign-navy-120"
                  data-state={row.getIsSelected() && "selected"}
                >
                  {row.getVisibleCells().map((cell) => (
                    <TableCell
                      key={cell.id}
                      className="p-2 pl-4 bg-white max-w-[500px]"
                    >
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext(),
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell
                  colSpan={columns.length}
                  className="h-24 text-center"
                >
                  No results.
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>
    );
  },
);

export default NodeMappingsListTable;
