import { MappingSuggestion } from "~/components/program/mapping/types";

export const flattenSuggestions = (data: MappingSuggestion[]) => {
  const result: MappingSuggestion[] = [];

  const traverse = (row: MappingSuggestion) => {
    if (row.reason) result.push(row);
    if (row.items) {
      for (const child of row.items) {
        traverse(child);
      }
    }
  };

  for (const row of data) {
    traverse(row);
  }

  return result;
};
