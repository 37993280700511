import React from "react";

type DividerProps = {
  /** Thickness of divider in px */
  size?: number;
  margin?: number;
  color?: string;
  opacity?: number;
};

const Divider = ({ size, margin, opacity }: DividerProps) => {
  return (
    <hr
      className="h-px my-2 bg-gray-400 border-0"
      style={{
        height: size,
        marginLeft: margin,
        marginRight: margin,
        opacity: opacity,
      }}
    />
  );
};

export default Divider;
