import React, { useEffect, useRef } from "react";
import { useForm } from "react-hook-form";

import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";

import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "~/components/ui/form";
import { Label } from "~/components/ui/label";
import { ScrollArea } from "~/components/ui/scroll-area";
import { SelectMultiple } from "~/components/ui/select-multiple";
import { Button } from "~/components/ui-rework/button";
import { Checkbox } from "~/components/ui-rework/checkbox";
import { SheetTrigger } from "~/components/ui-rework/sheet";

export const formSchema = z.object({
  category_partner: z.boolean(),
  category_global: z.boolean(),
  is_active_yes: z.boolean(),
  is_active_no: z.boolean(),
  list_type: z.array(z.string()),
  programs: z.array(z.string()),
});

const listTypes = [
  { value: "Learning Objectives", label: "Learning Objectives" },
  { value: "Accreditation Standard", label: "Accreditation Standard" },
  { value: "Skills Competencies", label: "Skills Competencies" },
  { value: "Core Competencies", label: "Core Competencies" },
  { value: "Advanced Behaviors", label: "Advanced Behaviors" },
  { value: "Work Skills", label: "Work Skills" },
  { value: "Professional Standards", label: "Professional Standards" },
];

type ListFilterProps = {
  mappedPrograms: any;
  setFormValues: any;
  formValues: any;
  setNumberOfFilters: React.Dispatch<React.SetStateAction<number>>;
};

const ListFilter = ({
  mappedPrograms,
  setFormValues,
  formValues,
  setNumberOfFilters
}: ListFilterProps) => {
  const defaultValues = {
    category_partner: true,
    category_global: true,
    is_active_yes: true,
    is_active_no: true,
    list_type: [],
    programs: [],
  };

  const form = useForm<z.infer<typeof formSchema>>({
    mode: "onChange",
    resolver: zodResolver(formSchema),
    defaultValues: formValues ?? defaultValues,
  });


  function onSubmit(values: z.infer<typeof formSchema>) {
    //Using stringify here so that I can do a deep comparison between the objects 
    if (!values.category_partner && !values.category_global) {
      values.category_partner = true;
      values.category_global = true;
    }
    if (!values.is_active_yes && !values.is_active_no) {
      values.is_active_yes = true;
      values.is_active_no = true;
    }
    setNumberOfFilters(countDifferences(values))
    setFormValues(values);
  }

  function countDifferences(values: z.infer<typeof formSchema>) {
    // Find differences between values and default values
    let count = 0;
    const keys = new Set<keyof typeof values>([
      ...Object.keys(values),
      ...Object.keys(defaultValues),
    ] as (keyof typeof values)[]);
    keys.forEach((key) => {
      if (JSON.stringify(values[key]) !== JSON.stringify(defaultValues[key])) {
        if (key === "list_type") count += values[key].length
        else if (key === "programs") count += values[key].length
        else count++;
      }
    });
    return count;
  }

  const closeButtonRef = useRef<HTMLButtonElement>(null);
  useEffect(() => {
    if (closeButtonRef.current) {
      closeButtonRef.current.focus();
    }
  }, []);

  return (
    <ScrollArea className="h-full" thumbClassName="bg-gradient-1">
      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)} className="px-4">
          <div className="flex flex-col gap-8 mt-6 mb-12">
            <div className="flex flex-col gap-4">
              <Label className="text-base mb-0 font-bold">Category</Label>
              <FormField
                control={form.control}
                name={"category_partner"}
                render={({ field }) => (
                  <FormItem className="flex items-center space-x-3 space-y-0">
                    <FormControl>
                      <Checkbox
                        data-testid={"lists-filter-local"}
                        checked={field.value}
                        onCheckedChange={field.onChange}
                      />
                    </FormControl>
                    <FormLabel className="text-sm !font-normal">
                      Local
                    </FormLabel>
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name={"category_global"}
                render={({ field }) => (
                  <FormItem className="flex items-center space-x-3 space-y-0">
                    <FormControl>
                      <Checkbox
                        checked={field.value}
                        onCheckedChange={field.onChange}
                      />
                    </FormControl>
                    <FormLabel className="text-sm !font-normal">
                      Global
                    </FormLabel>
                  </FormItem>
                )}
              />
            </div>

            <div className="flex flex-col gap-4">
              <Label className="text-base mb-0 font-bold">Active</Label>
              <FormField
                control={form.control}
                name={"is_active_yes"}
                render={({ field }) => (
                  <FormItem className="flex items-center space-x-3 space-y-0">
                    <FormControl>
                      <Checkbox
                        checked={field.value}
                        onCheckedChange={field.onChange}
                      />
                    </FormControl>
                    <FormLabel className="text-sm !font-normal">Yes</FormLabel>
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name={"is_active_no"}
                render={({ field }) => (
                  <FormItem className="flex items-center space-x-3 space-y-0">
                    <FormControl>
                      <Checkbox
                        checked={field.value}
                        onCheckedChange={field.onChange}
                      />
                    </FormControl>
                    <FormLabel className="text-sm !font-normal">No</FormLabel>
                  </FormItem>
                )}
              />
            </div>

            <div className="flex flex-col gap-2">
              <Label className="text-base mb-0 font-bold">List Type</Label>
              <FormField
                control={form.control}
                name={"list_type"}
                render={({ field }) => (
                  <FormItem>
                    <FormControl>
                      <SelectMultiple
                        clearable
                        maxHeight="100px"
                        options={listTypes}
                        buttonMaxHeight="75px"
                        testid="lists-filter-type"
                        values={field.value ?? undefined}
                        onValuesChange={field.onChange}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>

            <div className="flex flex-col gap-2">
              <Label className="text-base mb-0 font-bold">
                Program Selection
              </Label>
              <FormField
                control={form.control}
                name={"programs"}
                render={({ field }) => (
                  <FormItem>
                    <FormControl>
                      <SelectMultiple
                        clearable
                        onValuesChange={field.onChange}
                        values={field.value ?? undefined}
                        options={mappedPrograms}
                        maxHeight="75px"
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>
          </div>
          <div className="flex flex-row gap-4 justify-end flex-wrap pb-6">
            <Button
              type="button"
              variant="ghost"
              onClick={() => {
                form.reset(defaultValues);
              }}
            >
              Reset
            </Button>
            <SheetTrigger asChild>
              <Button
                type="submit"
                disabled={!form.formState.isValid}
                variant="primary"
                testid="lists-apply-filters"
              >
                Apply Filters
              </Button>
            </SheetTrigger>
          </div>
        </form>
      </Form>
    </ScrollArea>
  );
};

export default ListFilter;
