import * as React from "react";

import styled from "styled-components";

import { colors } from "~/css/shared-styles";

const LoadingStyles = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: top;
  width: 100%;

  .loading-indicator {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  .loading-indicator span {
    margin-top: 5px;
    margin-right: 10px;
    color: ${colors.inactiveGrey};
  }

  .loading-indicator label {
    font-weight: bold;
    font-style: italic;
    color: ${colors.inactiveGrey};
  }
`;

type LoadingIndicatorProps = {
  label?: string;
};

const AlignLoadingIndicator = ({
  label = "Loading...",
}: LoadingIndicatorProps) => {
  return (
    <LoadingStyles>
      <div className="loading-indicator">
        <span className="k-icon k-i-loading"></span>
        <label>{label}</label>
      </div>
    </LoadingStyles>
  );
};

export default AlignLoadingIndicator;
