import "@reach/menu-button/styles.css";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import styled from "styled-components";

import CreatePartnerDrawer from "~/components/admin/CreatePartnerDrawer";
import CreateUserDrawer from "~/components/admin/CreateUserDrawer";
import PartnerListTable from "~/components/admin/PartnerListTable";
import UserListTable from "~/components/admin/UserListTable";
import { Button } from "~/components/ui/button";
import {
  Sheet,
  SheetContent,
  SheetHeader,
  SheetTitle,
} from "~/components/ui/sheet";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "~/components/ui/tabs";
import { colors } from "~/css/shared-styles";
import useLogSessionActivity from "~/hooks/useLogSessionActivity";
import useOverrides from "~/hooks/useOverrides";
import { useStore as useRootStore } from "~/models/Root";
import { PERMISSION } from "~/models/UserManager";

const AdminStyles = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  .admin-toolbar-flexbox {
    padding: 1rem;
    padding-bottom: 0;
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
  }
  .scope-selection {
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
  }
  .active {
    font-color: ${colors.linkBlue};
  }
  .not-active {
    font-color: "${colors.inactiveGrey}";
  }
`;

const AdminRoute: React.FC = () => {
  const [isUserView, setIsUserView] = useState(false);
  const { PartnerLabel } = useOverrides();
  const partnerLabel = PartnerLabel();
  const { getAppUser } = useRootStore();
  const appUser = getAppUser();
  const [dashboardLinkValue] = useState(useLocation().state);
  const location = useLocation();
  const { logContentView } = useLogSessionActivity();
  const [isOpen, setIsOpen] = useState(false);
  const [triggerRefresh, setTriggerRefresh] = useState(false);

  useEffect(() => {
    if (dashboardLinkValue?.addUser) {
      setIsUserView(true);
      setIsOpen(true);
    } else if (dashboardLinkValue?.addPartner) {
      setIsUserView(false);
      setIsOpen(true);
    }
  }, [dashboardLinkValue]);

  useEffect(() => {
    if (!isOpen) setTriggerRefresh(true);
  }, [isOpen]);

  return (
    <AdminStyles className="admin-styles">
      <title>Admin - Align App</title>
      <div className="admin-toolbar-flexbox">
        <Tabs defaultValue="partner" className="w-full h-full mx-3">
          <div className="flex flex-row justify-between items-center">
            <div className="flex flex-row">
              <TabsList className="grid grid-cols-2 w-[350px] h-fit">
                {appUser.basic_permissions.includes(
                  PERMISSION.MANAGE_PARTNERS,
                ) && (
                  <TabsTrigger
                    value="partner"
                    className=" data-[state=active]:text-align-blue"
                    onClick={() => {
                      setIsUserView(false);
                      logContentView(
                        location.pathname,
                        `Manage ${partnerLabel}`,
                      );
                    }}
                  >
                    <span className="material-icons text-2xl mr-2" aria-hidden>
                      business
                    </span>
                    <span className="text-base">Manage {partnerLabel}</span>
                  </TabsTrigger>
                )}
                {appUser.basic_permissions.includes(
                  PERMISSION.MANAGE_USERS,
                ) && (
                  <TabsTrigger
                    value="user"
                    className=" data-[state=active]:text-align-blue"
                    onClick={() => {
                      setIsUserView(true);
                      logContentView(location.pathname, `Manage Users`);
                    }}
                    data-testid="admin-manage-users"
                  >
                    <span className="material-icons text-2xl mr-2" aria-hidden>
                      person_outline
                    </span>
                    <span className="text-base">Manage Users</span>
                  </TabsTrigger>
                )}
              </TabsList>
            </div>
            {((isUserView &&
              appUser.basic_permissions.includes(PERMISSION.MANAGE_USERS)) ||
              (!isUserView &&
                appUser.basic_permissions.includes(
                  PERMISSION.MANAGE_PARTNERS,
                ))) && (
              <Button
                className="text-white bg-button-blue hover:bg-align-blue rounded-full"
                onClick={() => {
                  setIsOpen(true);
                }}
                testid="admin-add-new"
              >
                {isUserView ? "Add New User" : "Add New Partner"}
              </Button>
            )}
          </div>
          <TabsContent value="partner">
            <div className="rounded-md border overflow-hidden h-full mt-2">
              <PartnerListTable
                triggerRefresh={triggerRefresh}
                setTriggerRefresh={setTriggerRefresh}
              />
            </div>
          </TabsContent>
          <TabsContent value="user">
            <div className="rounded-md border overflow-hidden h-full mt-2">
              <UserListTable
                triggerRefresh={triggerRefresh}
                setTriggerRefresh={setTriggerRefresh}
              />
            </div>
          </TabsContent>
        </Tabs>
      </div>
      <Sheet open={isOpen} onOpenChange={setIsOpen}>
        <SheetContent className="!w-1/2 !min-w-[400px] p-0">
          <div className="mx-auto w-full max-w-m h-full">
            <SheetHeader className="flex flex-row justify-items-center items-center justify-between">
              <SheetTitle className="p-4 mx-2 justify-items-center align-text-center">
                {isUserView ? "Add New User" : "Add New " + partnerLabel}
              </SheetTitle>
            </SheetHeader>
            {isUserView ? (
              <CreateUserDrawer setIsOpen={setIsOpen} />
            ) : (
              <CreatePartnerDrawer setIsOpen={setIsOpen} />
            )}
          </div>
        </SheetContent>
      </Sheet>
    </AdminStyles>
  );
};

export default AdminRoute;
