import React, { Fragment, useMemo, useState } from "react";

import {
  ColumnDef,
  ExpandedState,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { ChevronDown, ChevronUp, FilePenLineIcon } from "lucide-react";
import { observer } from "mobx-react-lite";

import { DeleteCurriculumNode } from "~/components/program/dialogs/DeleteNodeDialog";
import ModuleDialog from "~/components/program/dialogs/ModuleDialog";
import ActivityTable from "~/components/program/tables/ActivityTable";
import MappingTable from "~/components/program/tables/MappingTable";
import {
  Table,
  TableHeader,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
} from "~/components/ui/table";
import { Button } from "~/components/ui-rework/button";
import { cn } from "~/lib/utils";
import {
  useStore,
  IModule,
  NodeTypes,
  ModuleEditable,
} from "~/models/AlignEditor";

type ModuleTableProps = {
  className?: string;
  courseId: number;
};

const ModuleTable = observer(({ className, courseId }: ModuleTableProps) => {
  const columns = useMemo<ColumnDef<IModule>[]>(
    () => [
      {
        id: "expand",
        header: "",
        cell: ({ row }) => (
          <div className="flex">
            <button
              data-testid="module-expand"
              {...{
                onClick: () => row.toggleExpanded(!row.getIsExpanded()),
                style: { cursor: "pointer" },
              }}
            >
              {row.getIsExpanded() ? (
                <ChevronUp size="16px" />
              ) : (
                <ChevronDown size="16px" />
              )}
            </button>
          </div>
        ),
      },
      {
        id: "edit",
        header: "",
        cell: ({ row }) => (
          <div className="flex">
            <ModuleDialog
              module={row.original}
              onSubmit={(data) => handleEditModule(data, row.original)}
            >
              <button
                data-testid="program-edit-module"
                title="Edit Module"
                aria-label="Edit Module"
              >
                <FilePenLineIcon className="stroke-ocean-120" size="16px" />
              </button>
            </ModuleDialog>
          </div>
        ),
      },
      {
        id: "code",
        header: "Code",
        cell: ({ row }) => <div>{row.original.code}</div>,
      },
      {
        id: "name",
        header: "Name",
        cell: ({ row }) => <div>{row.original.name}</div>,
      },
      {
        id: "display_name",
        header: "Display Name",
        cell: ({ row }) => <div>{row.original.display_name}</div>,
      },
      {
        id: "week_number",
        header: () => <div className="text-end">Week</div>,
        cell: ({ row }) => (
          <div className="text-end">{row.original.week_number}</div>
        ),
      },
      {
        id: "delete",
        header: "",
        cell: ({ row }) => (
          <DeleteCurriculumNode
            id={row.original.id}
            type={NodeTypes.Module}
            display_name={row.original.display_name}
            onDelete={deleteModule}
          />
        ),
      },
    ],
    [], // eslint-disable-line react-hooks/exhaustive-deps
  );

  const {
    getProgram,
    getCourseModules,
    saveModuleChanges,
    deleteCurriculumNode,
  } = useStore();

  const [data, setData] = useState<IModule[]>(getCourseModules(courseId));

  const handleAddModule = (module: ModuleEditable) => {
    getProgram()?.addModule(module, courseId);
    setData(getCourseModules(courseId));
  };

  const handleEditModule = (
    moduleUpdates: ModuleEditable,
    moduleOriginal: IModule,
  ) => {
    const module = { ...moduleOriginal, ...moduleUpdates };
    setData(saveModuleChanges(module, courseId));
  };

  const deleteModule = (moduleId: number) => {
    deleteCurriculumNode(moduleId, NodeTypes.Module);
    setData(getCourseModules(courseId));
  };

  const [expanded, setExpanded] = useState<ExpandedState>({});

  const table = useReactTable({
    data,
    columns,
    state: {
      expanded,
    },
    onExpandedChange: setExpanded,
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <>
      <div className="bg-gradient-1 text-white font-semibold flex gap-2 items-center w-full px-3 py-2 border-t border-l border-idesign-navy-120">
        <span className="text-base">Modules</span>
        <ModuleDialog onSubmit={handleAddModule}>
          <Button
            className="dark text-sm py-1 px-2 font-normal"
            variant="outline"
            testid="program-add-module"
          >
            Add Module
          </Button>
        </ModuleDialog>
      </div>
      {data.length > 0 && (
        <Table
          className={cn(
            "table-fixed border border-r-0 border-idesign-navy-120",
            className,
          )}
        >
          <colgroup>
            <col style={{ width: "33px" }} />
            <col style={{ width: "33px" }} />
            <col style={{ width: "120px" }} />
            <col style={{ width: "33%" }} />
            <col style={{ width: "66%" }} />
            <col style={{ width: "100px" }} />
            <col style={{ width: "33px" }} />
          </colgroup>
          <TableHeader className="bg-gradient-1">
            {table.getHeaderGroups().map((headerGroup) => (
              <TableRow key={headerGroup.id} className="hover:bg-transparent">
                {headerGroup.headers.map((header) => {
                  return (
                    <TableHead
                      className={cn(
                        "text-white font-bold border-x border-idesign-navy-120 p-2 py-4",
                        "last:border-r-0 first:border-x-0 [&:nth-child(2)]:border-x-0",
                      )}
                      key={header.id}
                    >
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                            header.column.columnDef.header,
                            header.getContext(),
                          )}
                    </TableHead>
                  );
                })}
              </TableRow>
            ))}
          </TableHeader>
          <TableBody>
            {table.getRowModel().rows?.length ? (
              table.getRowModel().rows.map((row) => (
                <Fragment key={row.id}>
                  <TableRow
                    key={row.id + "_original"}
                    className={cn(
                      "hover:bg-gray-200 border-b-0",
                      "[&_td:last-child]:border-r-0 [&_td:first-child]:border-l-0",
                      {
                        "bg-muted": row.index % 2 !== 0,
                        "bg-white": row.index % 2 === 0,
                      },
                    )}
                  >
                    {row.getVisibleCells().map((cell) => (
                      <TableCell
                        key={cell.id}
                        className="p-2 border-x border-idesign-navy-120"
                      >
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext(),
                        )}
                      </TableCell>
                    ))}
                  </TableRow>
                  {row.getIsExpanded() ? (
                    <tr
                      key={row.id + "_expanded"}
                      className={cn("", {
                        "bg-muted": row.index % 2 !== 0,
                        "bg-white": row.index % 2 === 0,
                      })}
                    >
                      <TableCell
                        colSpan={columns.length}
                        className="pl-8 pr-0 py-0"
                      >
                        <MappingTable
                          nodeId={row.original.id}
                          nodeType={NodeTypes.Module}
                        />
                        <ActivityTable
                          className={cn({
                            "border-b-0":
                              row.index === table.getRowModel().rows.length - 1,
                          })}
                          courseId={courseId}
                          moduleId={row.original.id}
                        />
                      </TableCell>
                    </tr>
                  ) : null}
                </Fragment>
              ))
            ) : (
              <TableRow>
                <TableCell
                  colSpan={columns.length}
                  className="h-24 text-center"
                >
                  No results.
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      )}
    </>
  );
});

export default ModuleTable;
