import React, { useContext, useEffect, useState } from "react";

import { Form, Formik } from "formik";
import styled from "styled-components";
import * as Yup from "yup";

import { API_PATHS } from "~/api/ApiPaths";
import { DialogContext } from "~/components/AlignDialog";
import AlignFormikTextInput from "~/components/AlignFormikTextInput";
import { AddFormStyle } from "~/components/admin/CreateUserDrawer";
import { Button } from "~/components/ui/button";
import { toast } from "~/components/ui/use-toast";
import useAxios from "~/hooks/useAxios";
import useOverrides from "~/hooks/useOverrides";
import { useStore } from "~/models/Root";

const AddPartnerStyle = styled.div`
  .city-state-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    .city {
      margin-right: 15px;
      width: 50%;
    }
    .state {
      width: 50%;
    }
  }
  .info-description {
    margin-bottom: 0.5rem;
  }
`;

const formSteps: any[] = [
  {
    partnerName: Yup.string()
      .min(2, "Too Short!")
      .max(50, "Too Long!")
      .required("Partner Name is Required"),
    shortName: Yup.string()
      .min(2, "Too Short!")
      .max(50, "Too Long!")
      .required("Short Name is Required"),
  },
];

type CreatePartnerDrawerProps = {
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

//The UI has been created to have much more information like address, website, and division but we dont have a use case for this.
//Regardless this is ready for July 1st and we can revist later to implement this other version once we have validated its usecase.
const CreatePartnerDrawer = ({ setIsOpen }: CreatePartnerDrawerProps) => {
  const { data, payload, requestUrl, authToken, error, fetch } = useAxios({
    method: "POST",
    initialValue: null,
  });
  const { getAppUser, getAuthToken } = useStore();
  const appUserData = getAppUser();
  const { openDialog } = useContext(DialogContext);
  const [createdPartnerName, setCreatedPartnerName] = useState("");
  const { PartnerLabel } = useOverrides();
  const partnerLabel = PartnerLabel();

  useEffect(() => {
    if (data) {
      if (data.issuccess) {
        toast({
          variant: "success",
          title: `${partnerLabel} Created`,
          description: `The ${partnerLabel} ${createdPartnerName} has been created successfully.`,
        });
        setIsOpen(false);
      }
    } else if (error) {
      console.log(error);
    }
  }, [data, error, createdPartnerName, openDialog, setIsOpen, partnerLabel]);

  return (
    <Formik
      initialValues={{
        partnerName: "",
        shortName: "",
      }}
      validationSchema={Yup.object().shape(formSteps[0])}
      onSubmit={async (values, { setSubmitting }) => {
        setCreatedPartnerName(values.partnerName);
        payload.current = {
          agency_id: appUserData.agency_id,
          partner_type: "University",
          short_name: values.shortName,
          name: values.partnerName,
          is_active: true,
        };
        authToken.current = getAuthToken();
        requestUrl.current = `${API_PATHS.CREATE_PARTNER}`;
        fetch();
      }}
    >
      <AddFormStyle>
        <AddPartnerStyle>
          <Form className="add-form">
            <h5 className="info-heading">{partnerLabel} Information</h5>
            <p className="info-description">
              This {partnerLabel} will be created as a University Type{" "}
              {partnerLabel} and activated imediately.
            </p>
            <AlignFormikTextInput
              label={`${partnerLabel} Name`}
              name="partnerName"
              placeholder={`${partnerLabel} Name`}
              materialIconName="domain"
              iconAlignment="left"
            />
            <AlignFormikTextInput
              label="Short Name"
              name="shortName"
              placeholder={`${partnerLabel} Short Name`}
              materialIconName="domain"
              iconAlignment="left"
            />
            {error && (
              <h6>
                Error creating {partnerLabel}. Please check this {partnerLabel}{" "}
                has not already been created.
              </h6>
            )}
            <div className="button-container">
              <Button
                type="submit"
                className="text-white bg-button-blue hover:bg-align-blue rounded-full"
              >
                Add {partnerLabel}
              </Button>
            </div>
          </Form>
        </AddPartnerStyle>
      </AddFormStyle>
    </Formik>
  );
};

export default CreatePartnerDrawer;
