import React, { useCallback } from "react";
import { Handle, NodeProps, Position } from "reactflow";

import { ChevronLeft, ChevronRight } from "lucide-react";

import { useNodeCoverage } from "~/components/explorer/hooks/useNodeCoverage";
import HoverDetails from "~/components/explorer/node/hover-details/HoverDetails";
import NodeIcon from "~/components/explorer/node/NodeIcon";
import { Button } from "~/components/ui/button";
import {
  HoverCard,
  HoverCardContent,
  HoverCardPortal,
  HoverCardTrigger,
} from "~/components/ui/hover-card";
import { Progress } from "~/components/ui/progress";
import { useExplorerContext } from "~/context/ExplorerProvider";
import { cn } from "~/lib/utils";
import { NodeData, NodeMapping, NodeTypes } from "~/models/AlignEditor";

export default function CurriculumNode({ data, id }: NodeProps) {
  const { type, detail, childMappings, expandable } = data as NodeData;

  const { setNodes, selectedList, boundaryContainer } = useExplorerContext();

  const onClickExpand = useCallback(() => {
    setNodes((nds) =>
      nds.map((n) => {
        if (n.id === id) {
          return {
            ...n,
            data: { ...n.data, expanded: !n.data.expanded },
          };
        }

        return n;
      }),
    );
  }, [setNodes, id]);

  const combinedChildMappings = Array.from(
    childMappings?.values() ?? [],
  ).flatMap((array) => array);
  const mappings: NodeMapping[] = [
    ...(detail?.mappings ?? []),
    ...combinedChildMappings,
  ];

  const coverage = useNodeCoverage(mappings);
  const value = (coverage.numerator / coverage.denominator) * 100;

  const showProgressBar = selectedList !== undefined;

  return (
    <div
      className={cn("grid gap-1.5")}
      style={{
        width: "225px",
        height: "40px",
        gridTemplateColumns: "203px 16px",
      }}
    >
      <div
        className={cn(
          "rounded rounded-bl-[12px] rounded-tr-[12px] rounded-br-[1px] rounded-tl-[1px] backdrop-blur-[1px]",
          "bg-explorer-node",
          "shadow-explorer-node",
          "w-[203px] h-[40px]",
        )}
      >
        <Handle
          type="target"
          position={Position.Left}
          isConnectable={false}
          className="!left-2 invisible"
        />
        <HoverCard>
          <HoverCardTrigger asChild>
            <div
              className="h-full grid p-1.5 pt-[5px] gap-x-1.5 gap-y-1"
              style={{
                gridTemplateAreas: `
                  'icon name'
                  'icon progress'
                `,
                gridTemplateRows: "1fr 10px",
                gridTemplateColumns: "29px 1fr",
              }}
            >
              <NodeIcon type={type as NodeTypes} />
              <h6
                className={cn("my-auto text-[8px] font-bold", {
                  truncate: showProgressBar,
                  "line-clamp-2 col-start-2 row-span-2": !showProgressBar,
                })}
                style={showProgressBar ? { gridArea: "name" } : undefined}
              >
                {data.detail?.display_name || data.label}
              </h6>
              {showProgressBar && (
                <div
                  className="flex items-center"
                  style={{ gridArea: "progress" }}
                >
                  <Progress
                    className="h-1.5 shadow-explorer-node-progress"
                    value={value}
                  />
                  <span className="text-[8px] leading-none w-6 text-end mb-px">
                    {Math.round(value)}%
                  </span>
                </div>
              )}
            </div>
          </HoverCardTrigger>
          <HoverCardPortal>
            <HoverCardContent
              avoidCollisions
              collisionBoundary={[boundaryContainer]}
              className="light bg-gradient-one border-ocean-70 text-foreground backdrop-blur-[5px] w-[360px] z-50 overflow-x-hidden overflow-y-auto hover-card-shadow"
              style={{
                maxHeight: "calc(var(--radix-popper-available-height) - 1rem)",
              }}
            >
              <HoverDetails
                detail={data.detail}
                type={type as NodeTypes}
                coverage={coverage}
              />
            </HoverCardContent>
          </HoverCardPortal>
        </HoverCard>
      </div>

      {expandable && (
        <Button
          size="icon_xs"
          className="w-3.5 px-0.5 my-auto h-6"
          onClick={onClickExpand}
          variant="explorer"
        >
          {data.expanded ? (
            <ChevronLeft size="10" />
          ) : (
            <ChevronRight size="10" />
          )}
        </Button>
      )}
      <Handle
        type="source"
        position={Position.Right}
        isConnectable={false}
        className="!right-2 invisible"
      />
    </div>
  );
}
