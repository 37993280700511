import * as React from "react";

import { VariantProps, cva } from "class-variance-authority";

import { cn } from "~/lib/utils";

const inputVariants = cva(
  cn(
    "flex w-full rounded-md border border-input bg-background",
    "ring-offset-background",
    "file:border-0 file:bg-transparent file:text-sm file:font-medium",
    "placeholder:text-muted-foreground",
    "focus:outline-none focus:ring focus:ring-ring focus:ring-offset-[3px]",
    "disabled:cursor-not-allowed disabled:opacity-80",
  ),
  {
    variants: {
      variant: {
        lg: "h-11 px-3 py-2 rounded-[20px]",
        default: "h-10 px-3 py-2 rounded-[18px] text-sm",
        xs: "h-6 text-sm",
        sm: "h-7 px-1 text-sm",
      },
      // rounded: {
      //   default: "rounded-md",
      //   none: "rounded-none",
      //   full: "rounded-full",
      // },
    },
    defaultVariants: {
      variant: "default",
      // rounded: "default",
    },
  },
);

type InputIconProps = {
  icon?: React.ReactNode;
  iconPosition?: "left" | "right";
};

export type InputProps = React.InputHTMLAttributes<HTMLInputElement> &
  VariantProps<typeof inputVariants> &
  InputIconProps;

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  ({ className, type, variant, icon, iconPosition, ...props }, ref) => {
    return (
      // <input
      //   type={type}
      //   className={cn(inputVariants({ variant }), className)}
      //   ref={ref}
      //   {...props}
      // />
      <>
        {icon ? (
          <div className="bg-background">
            <div className="relative inline-block w-full">
              {iconPosition !== "right" && (
                <span
                  className={cn(
                    "absolute left-2.5 top-1/2 -translate-y-1/2 transform text-muted-foreground",
                    {
                      "text-disable-foreground cursor-not-allowed z-10":
                        props.disabled,
                    },
                  )}
                >
                  {icon}
                </span>
              )}
              <input
                type={type}
                className={cn(
                  inputVariants({ variant }),
                  className,
                  iconPosition !== "right" ? "pl-11 pr-2" : "pl-2 pr-11",
                  "bg-transparent relative",
                  { "z-10": !props.disabled },
                )}
                ref={ref}
                {...props}
              />
              {iconPosition === "right" && (
                <span
                  className={cn(
                    "absolute left-auto right-2.5 top-1/2 -translate-y-1/2 transform text-muted-foreground",
                    {
                      "text-disable-foreground cursor-not-allowed z-10":
                        props.disabled,
                    },
                  )}
                >
                  {icon}
                </span>
              )}
            </div>
          </div>
        ) : (
          <input
            type={type}
            className={cn(inputVariants({ variant }), className)}
            ref={ref}
            {...props}
          />
        )}
      </>
    );
  },
);
Input.displayName = "Input";

export { Input };
