import React, { useCallback, useEffect, useRef, useState } from "react";

import { Form, useFormikContext } from "formik";
import { observer } from "mobx-react-lite";
import styled from "styled-components";

import { API_PATHS } from "~/api/ApiPaths";
import AlignButton from "~/components/AlignButton";
import AlignRadioGroup from "~/components/AlignRadioGroup";
import { Select, SelectOption } from "~/components/ui/select";
import { colors } from "~/css/shared-styles";
import useAxios from "~/hooks/useAxios";
import { useStore } from "~/models/Root";
import { IProgramItem } from "~/models/UserManager";

const SetDetailsStyle = styled.div`
  .partner-heading {
    margin-top: 25px;
    margin-bottom: 10px;
  }
  .subheading {
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .radio-button-field {
    display: flex;
    margin-bottom: 4px;
    align-items: center;
    margin-right: 15px;
  }
  .dropdown-header {
    margin-bottom: 5px;
  }
  .inactive-grey {
    color: ${colors.inactiveGrey};
  }
  .partner-container {
    width: 50%;
    margin-bottom: -22px;
    margin-left: 10px;
  }
  .agency-admin-container {
    margin-left: 30px;
    margin-top: 10px;
  }
  .program-selection {
    padding: 30px;
    border: ${colors.inactiveGrey} solid 1px;
    border-radius: 15px;
    margin-bottom: 20px;
    .program-holder {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }
  }
  .save-container {
    margin-top: -33px;
    margin-right: 10px;
    display: flex;
    justify-content: flex-end;
  }
`;

interface ISetUserDetails {
  setUserTypeValue: React.Dispatch<React.SetStateAction<string>>;
  setAgencyAdminValue: React.Dispatch<React.SetStateAction<boolean>>;
  selectedPartnerId: number;
  setSelectedPartnerId: React.Dispatch<React.SetStateAction<number>>;
  selectedProgramId: number;
  setSelectedProgramId: React.Dispatch<React.SetStateAction<number>>;
  errorFound: boolean;
}

interface IPartnerItem {
  short_name: string;
  id: number;
}

const SetUserDetails: React.FC<ISetUserDetails> = observer((props) => {
  const {
    setAgencyAdminValue,
    setUserTypeValue,
    setSelectedPartnerId,
    selectedPartnerId,
    setSelectedProgramId,
    selectedProgramId,
    errorFound,
  } = props;
  const { getAuthToken } = useStore();
  const [partnerItems, setPartnerItems] = useState<SelectOption[]>([]);
  const [programItems, setProgramItems] = useState<SelectOption[]>([]);
  const [allPrograms, setAllPrograms] = useState<any>([]);
  const [isAgencyUser, setIsAgencyUser] = useState<boolean>(true);
  const [isAgencyAdmin, setIsAgencyAdmin] = useState<boolean>(true);
  const [focusedRadioButton1, setFocusedRadioButton1] = useState<string | null>(
    "radio1",
  );
  const [focusedRadioButton2, setFocusedRadioButton2] = useState<string | null>(
    "radio3",
  );
  const { data, authToken, error, requestUrl, fetch } = useAxios({
    method: "GET",
    initialValue: null,
  });
  const fetchPartners = useRef(true);
  const fetchPrograms = useRef(true);
  const [programsFound, setProgramsFound] = useState<boolean>(true);
  const resetFromNow = useRef(false);
  //this key value is used to reset align dropdown, changing key attribute in the component causes refresh to reset buttonß
  const [dropdownProgramKey, setDropdownProgramKey] = useState(+new Date());
  const [dropdownPartnerKey, setDropdownPartnerKey] = useState(+new Date());

  const ResetFormHook = () => {
    const { resetForm } = useFormikContext();
    useEffect(() => {
      if (resetFromNow.current) {
        resetFromNow.current = false;
        resetForm();
      }
    }, [resetForm]);
    return null;
  };

  const handleChange = useCallback(
    (fieldName: string, value: string) => {
      resetFromNow.current = true;
      setSelectedPartnerId(0);
      setSelectedProgramId(0);
      setDropdownPartnerKey(+new Date());
      setDropdownProgramKey(+new Date());
      if (fieldName === "userType") {
        if (value === "true") {
          //user is a agency user
          setIsAgencyUser(true);
          setFocusedRadioButton1("radio1");
          setUserTypeValue("agency");
        } else if (value === "false") {
          //user is a partner user
          setIsAgencyUser(false);
          setFocusedRadioButton1("radio2");
          setUserTypeValue("partner");
        }
      } else if (fieldName === "agencyAdmin") {
        if (value === "true") {
          //user is a agency admin
          setIsAgencyAdmin(true);
          setFocusedRadioButton2("radio3");
          setAgencyAdminValue(true);
        } else if (value === "false") {
          //user is not an agency admin
          setIsAgencyAdmin(false);
          setFocusedRadioButton2("radio4");
          setAgencyAdminValue(false);
        }
      }
    },
    [
      setIsAgencyUser,
      setUserTypeValue,
      setIsAgencyAdmin,
      setAgencyAdminValue,
      setFocusedRadioButton1,
      setFocusedRadioButton2,
      setSelectedPartnerId,
      setSelectedProgramId,
    ],
  );

  //Get list of partners first, then all programs I have permission to, then parse all programs on click of dropdown
  useEffect(() => {
    if (data) {
      if (
        data.issuccess &&
        data.message === "Successfully fetched partner list" &&
        data.entity.length > 0 &&
        partnerItems.length === 0
      ) {
        const partnerListItems: SelectOption[] = [];
        data.entity.forEach((item: IPartnerItem) => {
          if (item.id !== 0) {
            partnerListItems.push({
              value: String(item.id),
              label: item.short_name,
            });
          }
        });
        setPartnerItems(partnerListItems);
        authToken.current = getAuthToken();
        requestUrl.current = `${API_PATHS.GET_PROGRAMS_BY_PERMISSION}?permission=ManageUsers`;
        fetch();
      } else if (
        data.message === "Successfully fetched program list" &&
        fetchPrograms.current
      ) {
        fetchPrograms.current = false;
        setAllPrograms(data.entity);
      }
    } else if (data === null && fetchPartners.current) {
      fetchPartners.current = false;
      authToken.current = getAuthToken();
      requestUrl.current = `${API_PATHS.GET_PARTNER_LIST}?permission=ManageUsers`;
      fetch();
    } else if (error) {
      console.log(error);
    }
  }, [data, error, authToken, requestUrl, fetch, getAuthToken, partnerItems]);

  const handlePartnerChange = (itemName: string, id: string) => {
    if (+id !== selectedPartnerId) {
      setDropdownProgramKey(+new Date());
      setSelectedPartnerId(+id);
      setSelectedProgramId(0);
      //do the filtering
      console.log("FILTER", id, selectedPartnerId);
      const programListItems: SelectOption[] = [];
      allPrograms.forEach((item: IProgramItem) => {
        if (String(item.id) === id) {
          programListItems.push({
            value: String(item.id),
            label: item.display_name,
          });
        }
      });
      setProgramItems(programListItems);
      if (programListItems.length === 0) {
        setProgramsFound(false);
      } else {
        setProgramsFound(true);
      }
    }
  };

  const handleProgramChange = (itemName: string, id: string) => {
    setSelectedProgramId(+id);
  };

  return (
    <SetDetailsStyle>
      <Form className="add-form">
        <h5 className="info-heading">User Type</h5>
        <AlignRadioGroup
          radioGroupFieldName="userType"
          focusedElementId={focusedRadioButton1}
          initialValue={isAgencyUser ? "true" : "false"}
          handleChange={handleChange}
          readOnlyMode={false}
          radioItems={[
            { id: "radio1", value: "true", label: "Agency user" },
            { id: "radio2", value: "false", label: "Partner user" },
          ]}
          zIndex={10}
        />
        {isAgencyUser ? <p className="subheading">Set user as admin?</p> : null}
        <div className="agency-admin-container">
          {isAgencyUser && (
            <AlignRadioGroup
              radioGroupFieldName="agencyAdmin"
              focusedElementId={focusedRadioButton2}
              initialValue={isAgencyAdmin ? "true" : "false"}
              handleChange={handleChange}
              readOnlyMode={false}
              radioItems={[
                { id: "radio3", value: "true", label: "Yes" },
                { id: "radio4", value: "false", label: "No" },
              ]}
              zIndex={10}
            />
          )}
        </div>
        <h5 className="partner-heading">Partners and Programs</h5>
        <p
          className={`dropdown-header ${
            isAgencyAdmin && isAgencyUser ? "inactive-grey" : ""
          }`}
        >
          Select a partner
        </p>
        <div className="partner-container">
          <Select
            variant="drawer"
            fieldName="partner_list"
            handleChange={handlePartnerChange}
            options={partnerItems}
            placeholder={
              isAgencyAdmin && isAgencyUser
                ? "All Partners"
                : "Choose a Partner"
            }
            disabled={isAgencyAdmin && isAgencyUser}
            key={dropdownPartnerKey}
          />
        </div>
        <div className="program-selection">
          <p
            className={`dropdown-header ${
              isAgencyAdmin && isAgencyUser ? "inactive-grey" : ""
            }`}
          >
            Select a program
          </p>
          <div className="program-holder">
            <Select
              fieldName="programs_list"
              handleChange={handleProgramChange}
              options={programItems}
              placeholder={
                isAgencyAdmin && isAgencyUser
                  ? "All Programs"
                  : programsFound
                  ? "Choose a Program"
                  : "No Programs Available"
              }
              disabled={
                (isAgencyAdmin && isAgencyUser) ||
                selectedPartnerId === 0 ||
                !programsFound
              }
              key={dropdownProgramKey}
            />
          </div>
        </div>
        <div className="button-container">
          {isAgencyAdmin && isAgencyUser ? (
            <AlignButton
              label="Send Invite"
              style={{
                backgroundColor: `${colors.formButtonBlue}`,
                color: "white",
                height: "fit-content",
                padding: 1,
                paddingLeft: 9,
                paddingRight: 9,
              }}
              type="submit"
            />
          ) : (
            <AlignButton
              label="Send Invite"
              style={{
                backgroundColor: `${
                  selectedProgramId === 0
                    ? colors.formButtonGrey
                    : colors.formButtonBlue
                }`,
                color: `${
                  selectedProgramId === 0 ? colors.inactiveGrey : "white"
                }`,
                height: "fit-content",
                padding: 1,
                paddingLeft: 9,
                paddingRight: 9,
              }}
              type={selectedProgramId === 0 ? "button" : "submit"}
            />
          )}
        </div>
        {errorFound && (
          <h6>
            Error creating user. Please check this user has not already been
            created.
          </h6>
        )}
        <ResetFormHook />
      </Form>
    </SetDetailsStyle>
  );
});

export default SetUserDetails;
