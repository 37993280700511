import * as React from "react";

import { Slot } from "@radix-ui/react-slot";
import { cva, type VariantProps } from "class-variance-authority";

import { cn } from "~/lib/utils";

const variants = {
  primary: cn(
    "bg-button-primary text-button-primary-foreground",
    "hover:bg-button-primary-hover hover:outline hover:outline-button-primary-hover-outline hover:outline-1",
    "hover:shadow-button-primary-hover-stroke hover:shadow hover:shadow-x-0 hover:-shadow-y-0.5 hover:shadow-blur-0.5",
    "focus-visible:ring-button-primary-ring ring-offset-button-primary-ring-offset focus-visible:ring-offset-4 focus-visible:shadow focus-visible:shadow-lg",
    "active:bg-button-primary-active active:outline-0 active:shadow-none active:ring-0 active:ring-offset-0",
    "disabled:bg-button-primary-disabled disabled:text-button-primary-disabled-foreground",
  ),
  outline: cn(
    "bg-transparent text-button-outline-foreground outline outline-1 outline-button-outline",
    "[&_svg]:stroke-button-outline-icon",
    "hover:bg-button-outline-hover",
    "hover:shadow-button-outline-hover-stroke hover:shadow hover:shadow-x-0 hover:-shadow-y-0.5 hover:shadow-blur-0.5",
    "focus-visible:ring-button-outline-ring ring-offset-button-outline-ring-offset focus-visible:ring-offset-4 focus-visible:shadow focus-visible:shadow-lg focus-visible:outline focus-visible:outline-1 focus-visible:outline-button-outline",
    "active:bg-button-outline-active active:shadow-none",
    "disabled:outline-button-outline-disabled disabled:text-button-outline-disabled-foreground disabled-svg-dark",
  ),
  ghost: cn(
    "bg-transparent text-button-ghost-foreground",
    "hover:bg-button-ghost-hover hover:outline hover:outline-1 hover:outline-button-ghost-hover-outline hover:text-button-ghost-hover-text",
    "focus-visible:ring-button-ghost-ring focus-visible:ring-offset-4 ring-offset-button-outline-ring-offset focus-visible:shadow focus-visible:shadow-two",
    "active:bg-button-ghost-active active:text-button-ghost-active-foreground active:outline-1 active:outline-button-ghost-active-outline active:shadow-none",
    "disabled:outline-0 disabled:text-button-ghost-disabled",
  ),
  // primary: cn(
  //   "bg-cerulean-100 text-idesign-navy-100",
  //   "hover:bg-cerulean-90 hover:outline hover:outline-white hover:outline-1",
  //   "hover:shadow-cerulean-10/60 hover:shadow hover:shadow-x-0 hover:-shadow-y-0.5 hover:shadow-blur-0.5",
  //   "focus-visible:ring-white ring-offset-idesign-navy-90",
  //   "active:bg-cerulean-110 active:outline-0 active:shadow-none",
  //   "disabled:bg-disabled-1 disabled:text-disabled-text-1",
  // ),
  // "light-primary": cn(
  //   "bg-idesign-navy-100 text-white",
  //   "hover:bg-idesign-navy-90",
  //   "hover:shadow hover:shadow-md",
  //   "focus-visible:ring-ocean-100 focus-visible:ring-offset-4 focus-visible:ring-offset-ocean-50 focus-visible:shadow focus-visible:shadow-lg",
  //   "active:bg-idesign-navy-110 active:outline-0 active:ring-0 active:ring-offset-0 active:shadow-none",
  //   "disabled:bg-disabled-4 disabled:text-disabled-text-2",
  // ),
  // outline: cn(
  //   "bg-transparent text-cerulean-100 outline outline-1 outline-white",
  //   "[&_svg]:stroke-white",
  //   "hover:bg-ocean-150",
  //   "hover:shadow-cerulean-10/60 hover:shadow hover:shadow-x-0 hover:-shadow-y-0.5 hover:shadow-blur-0.5",
  //   "focus-visible:ring-white focus-visible:ring-offset-2 ring-offset-white",
  //   "active:bg-ocean-160 active:outline-0 active:shadow-none",
  //   "disabled:outline-disabled-1 disabled:text-disabled-text-1 disabled-svg-dark",
  // ),
  // "light-outline": cn(
  //   "bg-transparent text-idesign-navy-100 outline outline-1 outline-idesign-navy-80",
  //   "hover:bg-ocean-10",
  //   "hover:shadow hover:shadow-md",
  //   "focus-visible:ring-focus-outline-light-end focus-visible:ring-offset-2 focus-visible:ring-offset-focus-outline-light-end focus-visible:shadow focus-visible:shadow-md",
  //   "active:bg-ocean-20 active:outline active:outline-1 active:outline-idesign-navy-80 active:shadow-none",
  //   "disabled:outline-0 disabled:bg-disabled-4 disabled:text-disabled-text-2",
  // ),
  // ghost: cn(
  //   "bg-transparent text-cerulean-100",
  //   "hover:bg-ocean-150 hover:outline hover:outline-1 hover:outline-cerulean-130",
  //   "focus-visible:ring-white focus-visible:ring-offset-2 ring-offset-white",
  //   "active:bg-ocean-160 active:outline-1 active:outline-cerulean-130 active:shadow-none",
  //   "disabled:outline-disabled-1 disabled:text-disabled-text-1 disabled-svg-dark",
  // ),
  // "light-ghost": cn(
  //   "bg-transparent text-ocean-120",
  //   "hover:bg-ocean-20 hover:outline hover:outline-1 hover:outline-ocean-100 hover:text-idesign-navy-100",
  //   "focus-visible:ring-focus-outline-light-end focus-visible:ring-offset-2 focus-visible:ring-offset-focus-outline-light-end focus-visible:shadow focus-visible:shadow-md",
  //   "active:bg-ocean-30 active:text-idesign-navy-100 active:outline active:outline-1 active:outline-ocean-100 active:shadow-none",
  //   "disabled:outline-0 disabled:text-disabled-text-2",
  // ),
};

export const BUTTON_VARIANT_KEYS = Object.keys(variants) as Array<
  keyof typeof variants
>;

const sizes = {
  default: "text-base px-6 py-3 rounded-3xl",
  sm: "text-xs px-[18px] py-2.5 rounded-3xl",
  "icon-only": "text-base p-2 rounded-3xl",
  "icon-only-sm": "p-2 rounded-3xl",
};

export const BUTTON_SIZE_KEYS = Object.keys(sizes) as Array<keyof typeof sizes>;

const buttonVariants = cva(
  cn(
    "inline-flex items-center justify-center whitespace-nowrap rounded-md text-sm font-bold",
    "focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-offset-2",
    "transition-colors duration-200 ease-in-out",
    "disabled:pointer-events-none",
  ),
  {
    variants: {
      variant: variants,
      size: sizes,
    },
    defaultVariants: {
      variant: "primary",
      size: "default",
    },
  },
);

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  asChild?: boolean;
  testid?: string;
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ className, variant, size, asChild = false, testid, ...props }, ref) => {
    const Comp = asChild ? Slot : "button";

    return (
      <Comp
        className={cn(buttonVariants({ variant, size, className }), "")}
        ref={ref}
        data-testid={testid}
        {...props}
      />
    );
  },
);
Button.displayName = "Button";

export { Button, buttonVariants };
