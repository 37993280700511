import React, { useContext, useEffect, useRef, useState } from "react";

import { Formik } from "formik";
import styled from "styled-components";
import * as Yup from "yup";

import { API_PATHS } from "~/api/ApiPaths";
import AlignButton from "~/components/AlignButton";
import { DialogContext } from "~/components/AlignDialog";
import AddUser from "~/components/admin/AddUser";
import SetUserDetails from "~/components/admin/SetUserDetails";
import { toast } from "~/components/ui/use-toast";
import { colors } from "~/css/shared-styles";
import useAxios from "~/hooks/useAxios";
import { useStore } from "~/models/Root";

export const AddFormStyle = styled.div`
  background-color: #f3f5f5;
  width: 100%;
  padding: 1.5rem;
  font-size: 0.875rem;
  height: 100%;
  .add-form {
    padding: 1.5rem;
    background-color: white;
    border-radius: 0.625rem;
  }
  .info-heading {
    margin-top: 0;
    margin-bottom: 1.25rem;
  }
  .button-container {
    align-items: center;
    justify-content: center;
    display: flex;
  }
`;

export const DrawerHeadingStyle = styled.div`
  .drawer-heading {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 1rem;
    align-items: center;
    .drawer-heading-left {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      p {
        margin: 0;
        margin-right: 0.625rem;
      }
    }
  }
`;

// Data shape for create user api request
interface ICreateUser {
  firstName: string;
  lastName: string;
  email: string;
  agencyID: number;
  partnerID: number;
}

const defaultCreateUser: ICreateUser = {
  firstName: "",
  lastName: "",
  email: "",
  agencyID: 0,
  partnerID: 0,
};

const formSteps: any[] = [
  {
    firstName: Yup.string()
      .min(2, "Too Short!")
      .max(50, "Too Long!")
      .required("First Name Required"),
    lastName: Yup.string()
      .min(2, "Too Short!")
      .max(50, "Too Long!")
      .required("Last Name Required"),
    email: Yup.string().email("Invalid email").required("Email Required"),
  },
];

interface ICreateUserDrawer {
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const CreateUserDrawer: React.FC<ICreateUserDrawer> = (props) => {
  const { setIsOpen } = props;
  const { data, payload, requestUrl, authToken, error, fetch } = useAxios({
    method: "POST",
    initialValue: null,
  });
  const { getAppUser, getAuthToken, getEnvironment } = useStore();
  const environmentVariable = getEnvironment();
  const appUserData = getAppUser();
  const [pageNumber, setPageNumber] = useState(1);
  const [userTypeValue, setUserTypeValue] = useState("agency");
  const [agencyAdminValue, setAgencyAdminValue] = useState(true);
  const [selectedPartnerId, setSelectedPartnerId] = useState(0);
  const [selectedProgramId, setSelectedProgramId] = useState(0);
  const [newUserName, setNewUserName] = useState<string | null>(null);
  const { openDialog } = useContext(DialogContext);
  const currentUrl = window.location.href;
  const [newUserID, setNewUserID] = useState(0);
  const inviteCreated = useRef(false);
  const inviteSent = useRef(false);
  const userCreated = useRef(false);
  const roleAssigned = useRef(false);
  const [newUserObject, setNewUserObject] = useState(defaultCreateUser);
  const [errorFound, setErrorFound] = useState(false);

  const createUser = () => {
    authToken.current = getAuthToken();
    payload.current = {
      first_name: newUserObject.firstName,
      last_name: newUserObject.lastName,
      email: newUserObject.email,
      agency_id: newUserObject.agencyID,
      partner_id: newUserObject.partnerID,
    };
    requestUrl.current = `${API_PATHS.CREATE_USER}`;
    userCreated.current = true;
    fetch();
  };

  // after creating the new user, keep the users id for sending site invite
  useEffect(() => {
    if (data) {
      if (data.issuccess && userCreated.current) {
        userCreated.current = false;
        setNewUserName(data.entity.name);
        setNewUserID(data.entity.id);
        inviteCreated.current = true;
        payload.current = {
          app_users_id: data.entity.id,
        };
        requestUrl.current = `${API_PATHS.CREATE_SITE_INVITE}`;
        fetch();
      }
    } else if (error) {
      console.log(error);
      setErrorFound(true);
    }
  }, [data, error, fetch, requestUrl, payload]);

  //receive data back from creating site invite
  useEffect(() => {
    if (data) {
      if (
        data.issuccess &&
        data.message === "Site Invitation created successfully." &&
        inviteCreated.current
      ) {
        // send the invite after its been created
        inviteCreated.current = false;
        payload.current = {
          site_invitation_id: data.entity.id,
          environment: environmentVariable,
        };
        requestUrl.current = `${API_PATHS.SEND_SITE_INVITE}`;
        inviteSent.current = true;
        fetch();
      }
    } else if (error) {
      setErrorFound(true);
      console.log(error);
    }
  }, [data, error, fetch, payload, requestUrl, environmentVariable]);

  //receive data back from sending the site invite
  useEffect(() => {
    if (data) {
      if (data.issuccess && inviteSent.current) {
        //assign roles now
        inviteSent.current = false;
        roleAssigned.current = true;
        let roleId = 0;
        if (agencyAdminValue && userTypeValue === "agency") {
          roleId = 1;
        } else {
          roleId = 2;
        }
        payload.current = {
          app_users_id: newUserID,
          app_role_id: roleId,
          agency_id: newUserObject.agencyID,
          partner_id: newUserObject.partnerID,
          program_id: selectedProgramId,
        };
        requestUrl.current = `${API_PATHS.ASSIGN_ROLE}`;
        fetch();
      }
    } else if (error) {
      console.log(error);
      setErrorFound(true);
    }
  }, [
    data,
    error,
    fetch,
    requestUrl,
    newUserObject,
    agencyAdminValue,
    userTypeValue,
    payload,
    newUserID,
    selectedProgramId,
  ]);

  //receive data back from assigning roles
  useEffect(() => {
    if (data) {
      if (data.issuccess && roleAssigned.current) {
        //send the invite after its been created
        roleAssigned.current = false;
        toast({
          variant: "success",
          title: "User Created",
          description: "The user " + newUserName + " has been created.",
        });
        setPageNumber(1);
        setNewUserName(null);
        setIsOpen(false);
      }
    } else if (error) {
      console.log(error);
      setErrorFound(true);
    }
  }, [data, error, openDialog, newUserName, setIsOpen]);

  return (
    <Formik
      initialValues={{
        firstName: "",
        lastName: "",
        email: "",
      }}
      validationSchema={
        pageNumber === 1
          ? Yup.object().shape(formSteps[0])
          : Yup.object().shape(formSteps[1])
      }
      onSubmit={async (values, { setSubmitting }) => {
        if (pageNumber === 1) {
          const agencyID = appUserData.agency_id;
          const newUser: ICreateUser = {
            firstName: values.firstName,
            lastName: values.lastName,
            email: values.email,
            agencyID: agencyID,
            partnerID: selectedPartnerId,
          };
          setNewUserObject(newUser);
          setPageNumber(2);
        } else if (pageNumber === 2) {
          //Here we will create user, assign roles, create site invite, and send site invite
          // console.log("User Details", agencyAdminValue, userTypeValue)
          if (userTypeValue === "partner") {
            newUserObject.partnerID = +selectedPartnerId;
          }
          //Create User function triggers a series of UseEffects and functions for the rest
          //of the needed api calls
          createUser();
        }
      }}
    >
      <>
        <AddFormStyle>
          {pageNumber === 1 ? (
            <AddUser />
          ) : (
            <SetUserDetails
              setUserTypeValue={setUserTypeValue}
              setAgencyAdminValue={setAgencyAdminValue}
              selectedPartnerId={selectedPartnerId}
              setSelectedPartnerId={setSelectedPartnerId}
              selectedProgramId={selectedProgramId}
              errorFound={errorFound}
              setSelectedProgramId={setSelectedProgramId}
            />
          )}
        </AddFormStyle>
        {currentUrl.includes("localhost") && (
          <div style={{ display: "flex", position: "absolute", bottom: 0 }}>
            <AlignButton
              label="go to previous page (dev only)"
              onClick={() => {
                setPageNumber(1);
              }}
              style={{
                backgroundColor: `${colors.formButtonBlue}`,
                color: "white",
                height: "fit-content",
                padding: 1,
                paddingLeft: 9,
                paddingRight: 9,
              }}
              type="button"
            />
            <AlignButton
              label="go to next page (dev only)"
              onClick={() => {
                setPageNumber(2);
              }}
              style={{
                backgroundColor: `${colors.formButtonBlue}`,
                color: "white",
                height: "fit-content",
                padding: 1,
                paddingLeft: 9,
                paddingRight: 9,
              }}
              type="button"
            />
          </div>
        )}
      </>
    </Formik>
  );
};

export default CreateUserDrawer;
