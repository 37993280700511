import React, { useEffect, useMemo, useState } from "react";

import {
  ColumnDef,
  ColumnFiltersState,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  useReactTable,
  RowSelectionState,
  getExpandedRowModel,
} from "@tanstack/react-table";
import { observer } from "mobx-react-lite";

import AlignLoadingIndicator from "~/components/AlignLoadingIndicator";
import { HighlightableCell } from "~/components/program/mapping/HighlightableCell";
import { columnFilterWithHighlighting } from "~/components/program/mapping/utils/filtersWithHighlighting";
import { ScrollArea } from "~/components/ui/scroll-area";
import { Table, TableBody, TableCell, TableRow } from "~/components/ui/table";
import { Checkbox } from "~/components/ui-rework/checkbox";
import { useMappingSheetContext } from "~/context/MappingSheetProvider";
import { cn } from "~/lib/utils";
import { ItemRow, getTmpId, useStore } from "~/models/AlignEditor";

export const MappingSelectionTable = observer(() => {
  const { getCurrentMappingListId } = useStore();
  const listId = getCurrentMappingListId();
  const { listItemHierarchy } = useMappingSheetContext();

  const data = listItemHierarchy;

  if (!data) return null;

  return <MappingSelectionTableTable data={data} listId={listId} />;
});

type MappingSelectionTableTableProps = {
  data: ItemRow[];
  listId: number;
};

const MappingSelectionTableTable = ({
  data,
  listId,
}: MappingSelectionTableTableProps) => {
  const { setLastSaved, parentItemId } = useMappingSheetContext();

  const columns = useMemo<ColumnDef<ItemRow, any>[]>(
    () => [
      {
        accessorKey: "display_name",
        cell: HighlightableCell,
        filterFn: columnFilterWithHighlighting,
      },
      {
        id: "select",
        cell: ({ row }) => (
          <div className="flex flex-col justify-center items-center light">
            {row.original.is_mappable && (
              <Checkbox
                id={`mapping-checkbox-${row.original.id}`}
                data-testid="mapping-checkbox"
                checked={row.getIsSelected()}
                onCheckedChange={(value) => {
                  row.toggleSelected(!!value);
                  updateNodeMapping(row.original, !value);
                  setLastSaved(Date.now());
                }}
                aria-label="Select row"
              />
            )}
          </div>
        ),
        enableSorting: false,
        enableHiding: false,
      },
    ],
    [], // eslint-disable-line react-hooks/exhaustive-deps
  );

  const {
    getNodeMappedSelectionsList,
    deleteCurriculumNodeMapping,
    getProgram,
  } = useStore();

  const program = getProgram();
  const list = program?.available_lists?.find((l) => l.id === listId);

  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);

  const { setFilterColumn, nodeId, nodeType, parentItemOptions } =
    useMappingSheetContext();

  useEffect(() => {
    if (columnFilters.length === 0) {
      setColumnFilters([
        {
          id: "display_name",
          value: {
            term: "",
            parentItemId: parentItemId,
          },
        },
      ]);
    }
  }, [columnFilters, setColumnFilters, parentItemId]);

  const getRowId = (originalRow: ItemRow, _index: number) =>
    originalRow.id.toString();

  const getNodeRowSelections = () => {
    if (!nodeId) return {};
    const nodeMappings = getNodeMappedSelectionsList(nodeId, nodeType);
    const selections: RowSelectionState = {};
    nodeMappings.forEach((mapping) => (selections[mapping.listitem_id] = true));
    return selections;
  };

  const updateNodeMapping = (listItem: ItemRow, isDelete: boolean) => {
    const program_id = program?.id;
    if (!nodeId || !program_id) return;

    if (!isDelete) {
      program?.addCurriculumNodeMapping(nodeId, nodeType, {
        id: getTmpId(),
        created_at: null,
        program_id: program_id,
        c_item_type: nodeType,
        c_item_id: nodeId,
        list_id: listItem.list_id,
        listitem_id: listItem.id,
        list_name: list?.name ?? "",
        listitem_display_name: listItem.display_name ?? listItem.name,
        isNewItem: true,
      });
    } else {
      const nodeMappings = getNodeMappedSelectionsList(nodeId, nodeType);
      const mapping = nodeMappings
        .filter((m) => m.list_id === listId)
        .find((m) => m.listitem_id === listItem.id);

      if (mapping?.id)
        deleteCurriculumNodeMapping(nodeId, nodeType, mapping.id);
    }
  };

  const [rowSelection, setRowSelection] = useState(getNodeRowSelections());

  const table = useReactTable({
    data,
    columns,
    getSubRows: (row) => row.items,
    getRowId,
    onColumnFiltersChange: setColumnFilters,
    getCoreRowModel: getCoreRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    onRowSelectionChange: setRowSelection,
    enableSubRowSelection: false,
    filterFromLeafRows: true,
    state: {
      columnFilters,
      rowSelection,
      expanded: true,
    },
  });

  useEffect(() => {
    setFilterColumn(table.getColumn("display_name"));
  }, [table, setFilterColumn]);

  return (
    <>
      <div className="flex flex-col flex-1 overflow-auto">
        <div
          className="h-full min-h-0 grid"
          style={{ gridTemplateRows: "auto 1fr auto" }}
        >
          <div className="bg-gradient-1 border border-b-0 border-idesign-navy-120 py-3 rounded-t-md">
            <h3 className="text-base text-white px-4 font-normal">Mappings</h3>
          </div>

          <ScrollArea
            className="w-full h-full min-h-0 rounded-b-md border border-idesign-navy-120 bg-white"
            thumbClassName="bg-gradient-1"
          >
            <div>
              <Table>
                <TableBody>
                  {parentItemOptions !== undefined &&
                  columnFilters.length > 0 &&
                  table.getRowModel().rows?.length ? (
                    table.getRowModel().rows.map((row) => (
                      <TableRow
                        key={row.id}
                        data-state={row.getIsSelected() && "selected"}
                        className={cn(
                          "h-10 border-idesign-navy-120 hover:bg-ocean-10 data-[state=selected]:bg-ocean-10",
                          {
                            "bg-cerulean-10": row.depth > 0,
                          },
                        )}
                      >
                        {row.getVisibleCells().map((cell) => (
                          <TableCell
                            key={cell.id}
                            className={cn({
                              "w-8 !pr-3": cell.column.id === "select",
                              "pl-6": cell.column.id === "display_name",
                            })}
                          >
                            {flexRender(
                              cell.column.columnDef.cell,
                              cell.getContext(),
                            )}
                          </TableCell>
                        ))}
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell
                        colSpan={columns.length}
                        className="h-24 text-center"
                      >
                        {columnFilters.length === 0 ||
                        parentItemOptions === undefined ? (
                          <AlignLoadingIndicator />
                        ) : (
                          "No results."
                        )}
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </div>
          </ScrollArea>
          <div className="flex items-center justify-end space-x-2">
            <div
              className={cn(
                "flex-1 text-base font-bold flex justify-center text-foreground mt-4",
                {
                  invisible: columnFilters.length === 0,
                },
              )}
            >
              {table.getFilteredSelectedRowModel().flatRows.length} of{" "}
              {table.getFilteredRowModel().flatRows.length} row(s) selected.
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
