import React, { useContext, useEffect, useState } from "react";

import { GridCellProps } from "@progress/kendo-react-grid";
import { Formik } from "formik";
import * as Yup from "yup";

import { API_PATHS } from "~/api/ApiPaths";
import AddNewProgram from "~/components/admin/AddNewProgram";
import {
  AddFormStyle,
  DrawerHeadingStyle,
} from "~/components/admin/CreateUserDrawer";
import ProgramList from "~/components/admin/ProgramList";
import { DialogContext } from "~/components/AlignDialog";
import { Button } from "~/components/ui/button";
import { toast } from "~/components/ui/use-toast";
import useAxios from "~/hooks/useAxios";
import useOverrides from "~/hooks/useOverrides";
import { useStore } from "~/models/Root";
import { IProgramItem } from "~/models/UserManager";

interface IProgramDetailsDrawer {
  programDetailsData: GridCellProps | any;
}

const formSteps: any[] = [
  {
    programCode: Yup.string()
      .min(2, "Too Short!")
      .max(10, "Too Long!")
      .required("Program Code Required"),
    programTitle: Yup.string()
      .min(2, "Too Short!")
      .max(50, "Too Long!")
      .required("Program Title Required"),
  },
];

const ProgramDetailsDrawer: React.FC<IProgramDetailsDrawer> = (props) => {
  const { programDetailsData } = props;
  const { data, authToken, error, requestUrl, payload, fetch } = useAxios({
    method: "POST",
    initialValue: null,
  });
  const { getAuthToken } = useStore();
  const { ProgramLabel } = useOverrides();
  const programLabel = ProgramLabel();
  const [isDetailsView, setIsDetailsView] = useState(true);
  const { openDialog } = useContext(DialogContext);
  const [finished, setFinished] = useState(false);

  const createProgram = (programObject: IProgramItem) => {
    requestUrl.current = `${API_PATHS.CREATE_PROGRAM}`;
    authToken.current = getAuthToken();
    payload.current = {
      partner_id: programObject.partner_id,
      display_name: programObject.display_name,
      code: programObject.code,
      name: programObject.name,
      available_list_ids: programObject.available_list_ids,
    };
    fetch();
  };

  useEffect(() => {
    if (data?.issuccess && !finished) {
      setIsDetailsView(true);
      setFinished(true);
      const partnerName = programDetailsData?.short_name;
      toast({
        variant: "success",
        title: "Success",
        description:
          "New " +
          programLabel +
          " has been successfully added to " +
          partnerName,
      });
    } else if (error) {
      console.log(error);
    }
  }, [
    data,
    error,
    openDialog,
    programDetailsData,
    setFinished,
    finished,
    programLabel,
  ]);

  return (
    <AddFormStyle>
      <div className="add-form">
        <DrawerHeadingStyle>
          <div className="flex flex-row justify-between items-center mb-4">
            <div className="flex flex-row justify-between items-center">
              {isDetailsView ? (
                <div className="flex">
                  <p className="font-bold text-black">
                    {programDetailsData?.short_name}'s {programLabel} Details
                  </p>
                </div>
              ) : (
                <div className="flex">
                  <Button
                    className="rounded-md"
                    variant="outline"
                    onClick={() => {
                      setIsDetailsView(true);
                    }}
                  >
                    <span className="material-icons text-xl mr-2">
                      arrow_back
                    </span>
                    Go Back to {programLabel} Details
                  </Button>
                </div>
              )}
            </div>

            {isDetailsView && (
              <Button
                className="text-white bg-button-blue hover:bg-align-blue rounded-full "
                onClick={() => {
                  setIsDetailsView(false);
                }}
                testid="admin-add-program"
              >
                Add New {programLabel}
              </Button>
            )}
          </div>
        </DrawerHeadingStyle>
        <Formik
          initialValues={{
            programCode: "",
            programTitle: "",
          }}
          validationSchema={Yup.object().shape(formSteps[0])}
          onSubmit={async (values, { resetForm }) => {
            const programObject: IProgramItem = {
              id: 0, //needed for interface object however will be assigned an id by the backend
              partner_id: programDetailsData?.id,
              display_name: values.programCode + " - " + values.programTitle,
              code: values.programCode,
              name: values.programTitle,
              available_list_ids: [],
            };
            resetForm();
            createProgram(programObject);
          }}
        >
          {isDetailsView ? (
            <ProgramList programDetailsData={programDetailsData} />
          ) : (
            <AddNewProgram programDetailsData={programDetailsData} />
          )}
        </Formik>
      </div>
    </AddFormStyle>
  );
};

export default ProgramDetailsDrawer;
