import React, { useEffect, useState } from "react";

import { Check, ChevronDown, ChevronRight } from "lucide-react";

import AlignmentMappingDetails from "~/components/Reports/AlignmentMappingDetails";
import * as ReportModels from "~/components/Reports/ReportModels";
import { TableBody, TableCell, TableRow } from "~/components/ui/table";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "~/components/ui-rework/dialog";
import { ScrollArea } from "~/components/ui-rework/scroll-area";
import { cn } from "~/lib/utils";

function getMappingsCountSumByListId(
  course: ReportModels.Course,
  list_id: number,
): string {
  const count = ReportModels.getTreeMappingsByListId(course, list_id)?.length;
  return count > 0 ? count.toString() : "";
}
function getMappingsCountSumByListItemId(
  course: ReportModels.Course,
  list_id: number,
  listitem_id: number,
): string {
  const count = ReportModels.getTreeMappingsByListItemId(
    course,
    list_id,
    listitem_id,
  ).length;
  return count > 0 ? count.toString() : "";
}
function getMappingsByListItemId(
  course: ReportModels.Course,
  list_id: number,
  listitem_id: number,
): ReportModels.MappingItem[] {
  return ReportModels.getTreeMappingsByListItemId(course, list_id, listitem_id);
}

class ModalDetails {
  course: ReportModels.Course = {} as ReportModels.Course;
  mappings: ReportModels.MappingItem[] = [];
  list: ReportModels.List = {} as ReportModels.List;
}

const AlignmentGridSection: React.FC<{
  programData: ReportModels.ProgramData;
  list: ReportModels.List;
  forPrint: boolean | false;
  showNumbers: boolean;
  last: boolean;
}> = ({ programData, list, forPrint, showNumbers, last }) => {
  const [modalVisible, setModalVisible] = React.useState(false);
  const [modalDetails, setModalDetails] = React.useState<ModalDetails | null>(
    null,
  );
  const [isExpanded, setIsExpanded] = React.useState(forPrint);
  const [previousFocus, setPreviousFocus] = useState<HTMLElement | null>(null);

  useEffect(() => {
    if (modalDetails !== null) setModalVisible(true);
    else setModalVisible(false);
  }, [modalDetails]);

  useEffect(() => {
    if (modalVisible === false) {
      setModalDetails(null);
      previousFocus?.focus();
      // console.log("refocus");
    }
  }, [modalVisible, previousFocus]);

  const table = [...document.querySelectorAll("TableRow")].map((TableRow) => [
    ...TableRow.children,
  ]);

  // const handleKeyDown = (
  //   event: React.KeyboardEvent,
  //   course: ReportModels.Course,
  //   mappings: ReportModels.MappingItem[],
  //   list: ReportModels.List,
  // ) => {
  //   // Get the current cell's row and column indices
  //   const currentRow = table.findIndex((row) =>
  //     row.includes(event.currentTarget),
  //   );
  //   const currentCol = table[currentRow]?.indexOf(event.currentTarget);

  //   if (event.key === "Tab") return;
  //   if (event.key === "Enter" && mappings.length > 0) {
  //     setModalDetails({
  //       course: course,
  //       mappings: mappings,
  //       list: list,
  //     });
  //     const newCell = table[currentRow][currentCol] as HTMLElement;
  //     setPreviousFocus(newCell);
  //     return;
  //   }
  //   event.preventDefault(); //prevent arrow keys from moving page up and down

  //   let newRow = currentRow;
  //   let newCol = currentCol;

  //   switch (event.key) {
  //     case "ArrowUp":
  //       newRow = Math.max(0, currentRow - 1);
  //       break;
  //     case "ArrowDown":
  //       newRow = Math.min(table.length - 1, currentRow + 1);
  //       break;
  //     case "ArrowLeft":
  //       newCol = Math.max(0, currentCol - 1);
  //       break;
  //     case "ArrowRight":
  //       newCol = Math.min(table[0].length - 1, currentCol + 1);
  //       break;
  //     default:
  //       break;
  //   }

  //   // Get the new cell and focus it
  //   const newCell = table[newRow][newCol] as HTMLElement;
  //   newCell.focus();
  // };

  return (
    <>
      <TableBody className="border-x border-idesign-navy-120">
        <TableRow
          key={list.id}
          onClick={() => {
            setIsExpanded(!isExpanded);
          }}
          role="row"
          tabIndex={0}
          onKeyDown={(e) => {
            if (e.key === "Enter") setIsExpanded(!isExpanded);
          }}
          className={cn(
            "cursor-pointer border-y border-idesign-navy-120 bg-white  hover:bg-ocean-10",
          )}
        >
          <TableCell
            className="first:border-r-0 first:border-l-0 p-0 max-w-[50%] sticky left-0 border-idesign-navy-120 bg-white hover:bg-ocean-10"
            role="gridcell"
          >
            <div className="flex flex-row items-center w-fit font-bold px-2 py-4 border-r border-idesign-navy-120">
              <div className="mr-2">
                {isExpanded ? (
                  <ChevronDown size={24} />
                ) : (
                  <ChevronRight size={24} />
                )}
              </div>
              {list.name}
            </div>
          </TableCell>
          {programData?.courses.map((course, idx) => (
            <TableCell
              key={idx}
              className={cn(
                "text-center border-x border-idesign-navy-120 font-bold",
                idx === programData.courses.length - 1 && "border-x-0",
                idx === 0 && "border-l-0",
              )}
              role="gridcell"
            >
              {getMappingsCountSumByListId(course, list.id)}
            </TableCell>
          ))}
        </TableRow>
        {list.items.map((item, itemIndex) => (
          <TableRow
            key={itemIndex}
            className={cn(
              "max-w-[50%] bg-cerulean-10 border-y border-idesign-navy-120 hover:bg-ocean-10",
            )}
            style={{
              display: isExpanded ? "" : "none",
            }}
            role="row"
          >
            <TableCell
              className={cn(
                "p-0 relative first:sticky first:left-0 hover:bg-ocean-10 bg-cerulean-10",
                item.is_heading ? "font-bold pl-4" : "pl-7",
              )}
              role="gridcell"
            >
              <div className="px-2 py-4 border-r border-idesign-navy-120">
                {item.is_heading && (
                  <span className="absolute inset-y-0 left-0 w-[8px] bg-ocean-120"></span>
                )}
                <div>{item.display_name}</div>
              </div>
            </TableCell>
            {programData?.courses.map((course, courseIndex) => {
              const count = getMappingsCountSumByListItemId(
                course,
                list.id,
                item.id,
              );
              const mappings = getMappingsByListItemId(
                course,
                list.id,
                item.id,
              );
              const label = showNumbers ? count : "";
              return (
                <TableCell
                  key={courseIndex}
                  className={cn(
                    "border-x border-idesign-navy-120 p-0 m-0",
                    courseIndex === programData.courses.length - 1 &&
                      "border-x-0",
                    courseIndex === 0 && "border-l-0",
                  )}
                  role="gridcell"
                  tabIndex={count !== "" ? 0 : -1}
                  // onKeyDown={(e) => {
                  //   handleKeyDown(e, course, mappings, list);
                  // }}
                >
                  {count !== "" && (
                    <div
                      tabIndex={-1}
                      className="p-0 m-0 h-full w-full cursor-pointer flex items-center justify-center"
                      onClick={() =>
                        setModalDetails({
                          course: course,
                          mappings: mappings,
                          list: list,
                        })
                      }
                      aria-haspopup="dialog"
                    >
                      {!showNumbers && (
                        <Check size={24} className="stroke-ocean-120" />
                      )}
                      {label}
                    </div>
                  )}
                </TableCell>
              );
            })}
          </TableRow>
        ))}
      </TableBody>

      {modalDetails && (
        <Dialog open={modalVisible} onOpenChange={setModalVisible}>
          <DialogContent className="light max-w-[95%] h-full backdrop-blur-md">
            <DialogHeader>
              <DialogTitle>{modalDetails.course.display_name}</DialogTitle>
            </DialogHeader>
            <ScrollArea className="h-full">
              <AlignmentMappingDetails
                key={modalDetails.course.id}
                course={modalDetails.course}
                mappings={modalDetails.mappings}
                list={modalDetails.list}
              />
            </ScrollArea>
          </DialogContent>
        </Dialog>
      )}
    </>
  );
};

export default AlignmentGridSection;
