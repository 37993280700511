import React from "react";

import { OctagonAlertIcon, Trash2Icon } from "lucide-react";
import { observer } from "mobx-react-lite";

import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "~/components/ui-rework/alert-dialog";
import { useProgramEditorContext } from "~/context/ProgramEditorProvider";
import { NodeTypes } from "~/models/AlignEditor";

type DeleteNodeDialogProps = {
  id: number;
  type: NodeTypes;
  display_name: string;
  onDelete: (id: number) => void;
};

const DeleteNodeDialog = observer(
  ({ id, type, display_name, onDelete }: DeleteNodeDialogProps) => {
    const { programLabel, courseLabel, moduleLabel, activityLabel } =
      useProgramEditorContext();

    const getNodeLabel = () => {
      switch (type) {
        case NodeTypes.Course:
          return courseLabel;
        case NodeTypes.Module:
          return moduleLabel;
        case NodeTypes.Activity:
          return activityLabel;
        default:
          return programLabel;
      }
    };

    const nodeLabel = getNodeLabel();

    return (
      <AlertDialog>
        <div className="flex">
          <AlertDialogTrigger asChild>
            <button
              aria-label={`Delete ${nodeLabel}`}
              data-testid={`delete-${type.toLowerCase()}`}
              title={`Delete ${nodeLabel}`}
            >
              <Trash2Icon size="16px" className="text-destructive" />
            </button>
          </AlertDialogTrigger>
        </div>
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle className="flex items-center gap-2">
              <OctagonAlertIcon size="24px" />
              <span>Delete {nodeLabel}</span>
            </AlertDialogTitle>
            <AlertDialogDescription>
              {display_name} will be permanently deleted. Are you sure?
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogCancel>Cancel</AlertDialogCancel>
            <AlertDialogAction onClick={() => onDelete(id)}>
              Delete
            </AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    );
  },
);

export { DeleteNodeDialog as DeleteCurriculumNode };
