/* eslint-disable @typescript-eslint/no-empty-interface */
/* eslint-disable @typescript-eslint/no-this-alias */
import { createContext, useContext } from "react";
import { Edge } from "reactflow";

import { toJS } from "mobx";
import {
  types,
  Instance,
  getSnapshot,
  applySnapshot,
  SnapshotIn,
  resolveIdentifier,
  onSnapshot,
  onPatch,
  IJsonPatch,
  applyPatch,
  cast,
} from "mobx-state-tree";

import ListManager, {
  IListManagerList,
  ListManagerListItem,
} from "~/models/ListManager";

export enum NodeTypes {
  Program = "Program",
  Course = "Course",
  Module = "Module",
  Activity = "Activity",
}

export type ProgramEditable = {
  code?: string;
  name?: string;
  display_name?: string;
};

export type CourseEditable = {
  code?: string;
  name?: string;
  display_name?: string;
  level?: string | null;
  credits?: number;
};

export type ModuleEditable = {
  code?: string;
  name?: string;
  display_name?: string;
  week_number?: number;
};

export type ActivityEditable = {
  code?: string;
  name?: string;
  display_name?: string;
  tot_minutes?: number;
  activity_type: string | null;
  is_assessment?: boolean;
  modality?: string | null;
  collaboration?: string | null;
  engagement_types?: string[] | null;
  experience_type?: string | null;
  blooms?: string[] | null;
  grading_points?: number;
  grading_stakes?: string | null;
};

export type ActivityNodeType = {
  id: number;
  program_id: number;
  course_id: number;
  module_id: number;
  code: string;
  name: string;
  display_name: string;
  description: string | null;
  tot_minutes?: number;
  activity_type: string | null;
  is_assessment?: boolean;
  modality: string | null;
  collaboration: string | null;
  engagement_types: string | null;
  blooms: string | null;
  grading_points?: number;
  grading_stakes: string | null;
  mappings?: IMappingListItem[];
};

export type ItemRow = {
  id: number;
  list_id: number;
  parent_id?: number;
  name: string;
  code?: string | null;
  display_name?: string;
  items?: ItemRow[];
  is_heading?: boolean;
  is_mappable?: boolean;
};

const Color = types
  .model("Colors", {
    id: types.identifier,
    nodeType: types.string,
    color: types.string,
  })
  .actions((self) => ({
    updateColor(color: string) {
      self.color = color;
    },
  }));

export type ColorsInstance = Instance<ListManagerListItem>;
// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IColor extends SnapshotIn<typeof Color> {}

//id generator for temporary ids, prior to posting to the database
export const getTmpId = () => {
  return Math.round(+new Date() + Math.random() * 100);
};

const AvailableListId = types.model("AvailableListIds", {
  list_id: types.maybeNull(types.number),
});

export type AvailableListIdsInstance = Instance<typeof AvailableListId>;
export interface IAvailableListIds extends SnapshotIn<typeof AvailableListId> {}

const MappingList = types.model("MappingList", {
  id: types.number,
  name: types.string,
  list_type: types.string,
  is_global_list: types.boolean,
  is_active: types.boolean,
});

export type MappingListInstance = Instance<typeof MappingList>;
export interface IMappingList extends SnapshotIn<typeof MappingList> {}

const MappingListItem = types
  .model("MappingListItem", {
    id: types.optional(types.number, 0),
    created_at: types.maybeNull(types.number),
    program_id: types.optional(types.number, 0),
    c_item_type: types.optional(types.string, ""),
    c_item_id: types.optional(types.number, 0),
    list_id: types.number,
    listitem_id: types.number,
    list_name: types.string,
    listitem_display_name: types.string,
    is_selected: types.optional(types.boolean, false),
    is_ai_suggested_mapping: types.optional(types.boolean, false),
    is_indirect_mapping: types.optional(types.boolean, false),
    indirect_mapping_id: types.maybeNull(types.string),
    indirect_mapping_root_c_mapping_id: types.optional(types.number, 0),
    created_by_id: types.optional(types.number, 0),
    created_by_name: types.maybeNull(types.string),
    move: types.optional(types.boolean, false),
    delete: types.optional(types.boolean, false),
    isNewItem: types.optional(types.boolean, false),
  })
  .actions((self) => ({
    markForDelete() {
      self.delete = true;
    },
    markIsNewItem() {
      self.isNewItem = true;
    },
  }));

export type MappingListItemInstance = Instance<typeof MappingListItem>;
export interface IMappingListItem extends SnapshotIn<typeof MappingListItem> {}

const Course = types
  .model("Course", {
    id: types.number,
    created_at: types.number,
    program_id: types.optional(types.number, -1),
    code: types.optional(types.string, ""),
    name: types.optional(types.string, ""),
    display_name: types.optional(types.string, ""),
    description: types.maybeNull(types.string),
    display_order: types.optional(types.number, 0),
    level: types.maybeNull(types.string),
    credits: types.optional(types.number, 0),
    mappings: types.optional(types.array(MappingListItem), []),
    expanded: types.optional(types.boolean, false),
    areMappingsInEdit: types.optional(types.boolean, false),
    move: types.optional(types.boolean, false),
    delete: types.optional(types.boolean, false),
    isNewItem: types.optional(types.boolean, false),
    isChanged: types.optional(types.boolean, false),
    isNewestItem: types.optional(types.boolean, false),
  })
  .actions((self) => ({
    toggleExpand() {
      self.expanded = !self.expanded;
      //console.log('toggling at node?', self.expanded)
    },
    toggleAreMappingsInEdit() {
      self.areMappingsInEdit = !self.areMappingsInEdit;
    },
    setField<Key extends keyof typeof self>(
      field: Key,
      value: (typeof self)[Key],
    ): void {
      self[field] = value;
    },
    updateData(data: ICourse) {
      const updateData = data as IUpdateCourse;
      const fields = Object.keys(updateData);
      const selfThis = this;
      fields.forEach((fieldName) => {
        selfThis.setField(
          fieldName as keyof IUpdateCourse,
          data[fieldName as keyof IUpdateCourse],
        );
      });
    },
    markForDelete() {
      self.delete = true;
    },
    markIsNewItem() {
      self.isNewItem = true;
    },
  }));

export type CourseInstance = Instance<typeof Course>;
export interface ICourse extends SnapshotIn<typeof Course> {}
export interface IUpdateCourse
  extends Omit<
    ICourse,
    | "id"
    | "created_at"
    | "expanded"
    | "areMappingsInEdit"
    | "move"
    | "delete"
    | "isNewItem"
    | "isChanged"
    | "isNewestItem"
  > {}

const Module = types
  .model("Module", {
    id: types.number,
    created_at: types.number,
    program_id: types.number,
    course_id: types.number,
    code: types.string,
    name: types.string,
    display_name: types.string,
    description: types.maybeNull(types.string),
    display_order: types.number,
    week_number: types.maybeNull(types.number),
    mappings: types.optional(types.array(MappingListItem), []),
    expanded: types.optional(types.boolean, false),
    areMappingsInEdit: types.optional(types.boolean, false),
    move: types.optional(types.boolean, false),
    delete: types.optional(types.boolean, false),
    isNewItem: types.optional(types.boolean, false),
    isChanged: types.optional(types.boolean, false),
    isNewestItem: types.optional(types.boolean, false),
  })
  .actions((self) => ({
    toggleExpand() {
      self.expanded = !self.expanded;
      //console.log('toggling at node?', self.expanded)
    },
    toggleAreMappingsInEdit() {
      self.areMappingsInEdit = !self.areMappingsInEdit;
    },
    setField<Key extends keyof typeof self>(
      field: Key,
      value: (typeof self)[Key],
    ): void {
      self[field] = value;
    },
    updateData(data: IModule) {
      // here we aim to 'drop' ids and few other attributes from being updated, as we are already at a specific instance.
      const updateData = data as IUpdateModule;
      const fields = Object.keys(updateData);
      const selfThis = this;
      fields.forEach((fieldName) => {
        selfThis.setField(
          fieldName as keyof IUpdateModule,
          data[fieldName as keyof IUpdateModule],
        );
      });
    },
    markForDelete() {
      self.delete = true;
    },
    markIsNewItem() {
      self.isNewItem = true;
    },
  }));

export type ModuleInstance = Instance<typeof Module>;
export interface IModule extends SnapshotIn<typeof Module> {}
export interface IUpdateModule
  extends Omit<
    IModule,
    | "id"
    | "created_at"
    | "expanded"
    | "areMappingsInEdit"
    | "move"
    | "delete"
    | "isNewItem"
    | "isChanged"
    | "isNewestItem"
  > {}

const Activity = types
  .model("Activity", {
    id: types.number,
    created_at: types.number,
    program_id: types.number,
    course_id: types.number,
    module_id: types.number,
    code: types.string,
    name: types.string,
    display_name: types.string,
    description: types.maybeNull(types.string),
    display_order: types.number,
    tot_minutes: types.optional(types.number, 0),
    activity_type: types.maybeNull(types.string),
    is_assessment: types.optional(types.boolean, false),
    modality: types.maybeNull(types.string),
    collaboration: types.maybeNull(types.string),
    engagement_types: types.maybeNull(types.string),
    experience_type: types.maybeNull(types.string),
    blooms: types.maybeNull(types.string),
    grading_points: types.optional(types.number, 0),
    grading_stakes: types.optional(types.string, ""),
    mappings: types.optional(types.array(MappingListItem), []),
    expanded: types.optional(types.boolean, false),
    areMappingsInEdit: types.optional(types.boolean, false),
    move: types.optional(types.boolean, false),
    delete: types.optional(types.boolean, false),
    isNewItem: types.optional(types.boolean, false),
    isChanged: types.optional(types.boolean, false),
    isNewestItem: types.optional(types.boolean, false),
  })
  .actions((self) => ({
    toggleExpand() {
      self.expanded = !self.expanded;
      //console.log('toggling at node?', self.expanded)
    },
    toggleAreMappingsInEdit() {
      self.areMappingsInEdit = !self.areMappingsInEdit;
    },
    setField<Key extends keyof typeof self>(
      field: Key,
      value: (typeof self)[Key],
    ): void {
      self[field] = value;
    },
    updateData(data: IActivity) {
      //console.log('updateData activity ran.', toJS(data))
      const updateDate = data as IUpdateActivity;
      const fields = Object.keys(updateDate);
      const selfThis = this;
      fields.forEach((fieldName) => {
        selfThis.setField(
          fieldName as keyof IUpdateActivity,
          data[fieldName as keyof IUpdateActivity],
        );
      });
    },
    markForDelete() {
      self.delete = true;
    },
    markIsNewItem() {
      self.isNewItem = true;
    },
  }));

export type ActivityInstance = Instance<typeof Activity>;
export interface IActivity extends SnapshotIn<typeof Activity> {}
export interface IUpdateActivity
  extends Omit<
    IActivity,
    | "id"
    | "created_at"
    | "expanded"
    | "areMappingsInEdit"
    | "move"
    | "delete"
    | "isNewItem"
    | "isChanged"
    | "isNewestItem"
  > {}

export type CurriculumNodeTypes = ICourse | IModule | IActivity;

const ValidationError = types.model("ValidationError", {
  fieldName: types.string,
  fieldNodeId: types.number,
});

export type ProgramValidationErrorInstance = Instance<typeof ValidationError>;
export interface IProgramValidationError
  extends SnapshotIn<typeof ValidationError> {}

const ProgramTreeNode = types
  .model("ProgramTreeNode", {
    id: types.identifierNumber,
    partner_id: types.number,
    display_name: types.string,
    code: types.string,
    name: types.string,
    mapped_list_ids: types.maybeNull(types.array(types.number)),
    available_list_ids: types.maybeNull(types.array(types.number)),
    total_credits: types.maybeNull(types.number),
    total_tot_minutes: types.maybeNull(types.number),
    created_at: types.number,
    created_by_id: types.number,
    updated_at: types.number,
    updated_by_id: types.number,
    mapped_lists: types.maybeNull(types.array(MappingList)),
    available_lists: types.maybeNull(types.array(MappingList)),
    courses: types.optional(types.array(Course), []),
    modules: types.optional(types.array(Module), []),
    activities: types.optional(types.array(Activity), []),
    expanded: types.optional(types.boolean, false),
    move: types.optional(types.boolean, false),
    delete: types.optional(types.boolean, false),
    isChanged: types.optional(types.boolean, false),
    validationErrors: types.optional(types.array(ValidationError), []),
  })
  .views((self) => ({
    getCurriculumCourseNodes(programId: number) {
      let courses: ICourse[] = [];
      courses = self.courses.filter((node) => {
        return node.program_id === programId;
      });
      return courses;
    },
    getCurriculumModuleNodes(programId: number, courseId: number) {
      let modules: IModule[] = [];
      modules = self.modules.filter((node) => {
        return node.program_id === programId && node.course_id === courseId;
      });
      return modules;
    },
    getCurriculumActivityNodes(
      programId: number,
      courseId: number,
      moduleId: number,
    ) {
      let activities: IActivity[] = [];
      activities = self.activities.filter((node) => {
        return (
          node.program_id === programId &&
          node.course_id === courseId &&
          node.module_id === moduleId
        );
      });
      return activities;
    },
    get isExpanded() {
      return self.expanded;
    },
    get areValidationErrors() {
      return self.validationErrors.length > 0 ? true : false;
    },
  }))
  .actions((self) => ({
    toggleExpand() {
      self.expanded = !self.expanded;
    },
    addCourse(course: CourseEditable) {
      setProgramTreeNodeNewestItemFalse(
        self.courses.find((courseItem) => courseItem.isNewestItem),
      );

      self.courses.push({
        id: +`${self.courses.length}${getTmpId()}`,
        created_at: +new Date(),
        program_id: self.id,
        code: course.code ?? "",
        name: course.name ?? "",
        display_name: course.display_name ?? "",
        description: null,
        display_order: 0,
        level: course.level ?? "",
        credits: course.credits ?? 0,
        isNewItem: true,
        //used for a onetime only auto-focus event,
        //only the newest item receives focus on creation and only once.
        isNewestItem: true,
      });
    },
    addModule(module: ModuleEditable, courseId: number) {
      setProgramTreeNodeNewestItemFalse(
        self.modules.find((moduleItem) => moduleItem.isNewestItem),
      );
      // create Module
      self.modules.push({
        id: +`${self.modules.length}${getTmpId()}`,
        created_at: +new Date(),
        program_id: self.id,
        course_id: courseId ?? 0,
        code: module.code ?? "",
        name: module.name ?? "",
        display_name: module.display_name ?? "",
        week_number: module.week_number ?? 0,
        description: null,
        display_order: 0,
        isNewItem: true,
        isNewestItem: true,
      });
    },
    addActivity(
      activity: ActivityEditable,
      courseId: number,
      moduleId: number,
    ) {
      setProgramTreeNodeNewestItemFalse(
        self.activities.find((activityItem) => activityItem.isNewestItem),
      );
      //create activity placeholder node.
      self.activities.push({
        id: +`${self.activities.length}${getTmpId()}`,
        created_at: +new Date(),
        program_id: self.id,
        course_id: courseId ?? 0,
        module_id: moduleId ?? 0,
        code: activity.code ?? "",
        name: activity.name ?? "",
        display_name: activity.display_name ?? "",
        tot_minutes: activity.tot_minutes ?? 0,
        activity_type: activity.activity_type ?? null,
        is_assessment: activity.is_assessment ?? false,
        modality: activity.modality ?? null,
        collaboration: activity.collaboration ?? null,
        engagement_types: activity.engagement_types?.join(", ") ?? null,
        blooms: activity.blooms?.join(", ") ?? null,
        grading_points: activity.grading_points ?? 0,
        grading_stakes: activity.grading_stakes ?? "",
        description: null,
        display_order: 0,
        isNewItem: true,
        isNewestItem: true,
      });
    },
    newCurriculumNode(
      NodeType: NodeTypes,
      course_id?: number,
      module_id?: number,
    ) {
      switch (NodeType) {
        case NodeTypes.Course:
          setProgramTreeNodeNewestItemFalse(
            self.courses.find((courseItem) => courseItem.isNewestItem),
          );
          // create course
          self.courses.push({
            id: +`${self.courses.length}${getTmpId()}`,
            created_at: +new Date(),
            program_id: self.id,
            code: "",
            name: "",
            display_name: "",
            description: null,
            display_order: 0,
            level: "",
            credits: 0,
            isNewItem: true,
            //used for a onetime only auto-focus event,
            //only the newest item receives focus on creation and only once.
            isNewestItem: true,
          });
          break;
        case NodeTypes.Module:
          setProgramTreeNodeNewestItemFalse(
            self.modules.find((moduleItem) => moduleItem.isNewestItem),
          );
          // create Module
          self.modules.push({
            id: +`${self.modules.length}${getTmpId()}`,
            created_at: +new Date(),
            program_id: self.id,
            course_id: course_id ? course_id : 0,
            code: "",
            name: "",
            display_name: "",
            description: null,
            display_order: 0,
            isNewItem: true,
            isNewestItem: true,
          });
          break;
        case NodeTypes.Activity:
          setProgramTreeNodeNewestItemFalse(
            self.activities.find((activityItem) => activityItem.isNewestItem),
          );
          //create activity placeholder node.
          self.activities.push({
            id: +`${self.activities.length}${getTmpId()}`,
            created_at: +new Date(),
            program_id: self.id,
            course_id: course_id ? course_id : 0,
            module_id: module_id ? module_id : 0,
            code: "",
            name: "",
            display_name: "",
            tot_minutes: 0,
            modality: "",
            collaboration: "",
            grading_points: 0,
            description: null,
            display_order: 0,
            isNewItem: true,
            isNewestItem: true,
          });
          break;
        default:
        //some exception.
      }
    },
    getCurriculumNodeById(
      nodeType: NodeTypes,
      nodeId: number,
      getInstance?: boolean,
    ) {
      const foundNodeInterfaceTypes: IActivity | IModule | ICourse | undefined =
        undefined;
      let foundNode:
        | ActivityInstance
        | ModuleInstance
        | CourseInstance
        | undefined = undefined;
      switch (nodeType) {
        case NodeTypes.Activity:
          foundNode = self.activities.find((node) => {
            return node.id === nodeId;
          });
          break;
        case NodeTypes.Course:
          foundNode = self.courses.find((node) => {
            return node.id === nodeId;
          });
          break;
        case NodeTypes.Module:
          foundNode = self.modules.find((node) => {
            return node.id === nodeId;
          });
          break;
        default:
        //some exception.
      }
      return getInstance
        ? foundNode
        : (foundNode as typeof foundNodeInterfaceTypes);
    },
    getCurriculumNodeMappings(nodeType: NodeTypes, nodeId: number) {
      const foundNode = this.getCurriculumNodeById(nodeType, nodeId);
      const mappingsFound: IMappingListItem[] = [];
      if (foundNode) {
        foundNode.mappings?.forEach((mapping) => {
          if (mapping.delete === false) {
            mappingsFound.push(toJS(mapping));
          }
        });
      }
      //returning a static object, not a mobx object.
      return mappingsFound;
    },
    areMappingsInEdit(nodeType: NodeTypes, nodeId: number) {
      const foundNode = this.getCurriculumNodeById(nodeType, nodeId);
      let areMappingsInEdit = false;
      if (foundNode) {
        areMappingsInEdit =
          foundNode.areMappingsInEdit !== undefined
            ? foundNode.areMappingsInEdit
            : false;
      }
      return areMappingsInEdit;
    },
    getCurriculumNodeIndex(nodeType: NodeTypes, nodeId: number) {
      let nodeIdx = -1;
      switch (nodeType) {
        case NodeTypes.Activity:
          nodeIdx = self.activities.findIndex((node) => {
            return node.id === nodeId;
          });
          break;
        case NodeTypes.Course:
          nodeIdx = self.courses.findIndex((node) => {
            return node.id === nodeId;
          });
          break;
        case NodeTypes.Module:
          nodeIdx = self.modules.findIndex((node) => {
            return node.id === nodeId;
          });
          break;
        default:
        //some exception.
      }
      return nodeIdx;
    },
    deleteCurriculumNode(nodeType: NodeTypes, nodeId: number) {
      const index = this.getCurriculumNodeIndex(nodeType, nodeId);

      //remove item
      switch (nodeType) {
        case NodeTypes.Activity:
          self.activities.splice(index, 1);
          break;
        case NodeTypes.Course:
          self.courses.splice(index, 1);
          break;
        case NodeTypes.Module:
          self.modules.splice(index, 1);
          break;
        default:
        //some exception.
      }
    },
    addCurriculumNodeMapping(
      nodeId: number,
      nodeType: NodeTypes,
      item: IMappingListItem,
    ) {
      let mappings: IMappingListItem[] = [];

      const foundNode = this.getCurriculumNodeById(nodeType, nodeId, true);
      if (foundNode) {
        applySnapshot(foundNode.mappings, [...foundNode.mappings, item]);
        mappings = getSnapshot(foundNode.mappings);
      }
      return toJS(mappings);
    },
    saveCurriculumNodeMappings(
      nodeType: NodeTypes,
      nodeId: number,
      updateData: IMappingListItem[],
    ) {
      let mappings: IMappingListItem[] = [];
      const foundNode = this.getCurriculumNodeById(nodeType, nodeId, true);

      if (foundNode) {
        const deletedMappings = foundNode.mappings.filter(
          (mapping) => mapping.delete,
        );

        applySnapshot(foundNode.mappings, [...deletedMappings, ...updateData]);
        mappings = getSnapshot(foundNode.mappings);
      }
      //console.log('returning.. saved object?', toJS(mappings))
      return toJS(mappings);
    },
    setField<Key extends keyof typeof self>(
      field: Key,
      value: (typeof self)[Key],
    ): void {
      self[field] = value;
    },
    updateData(data: IProgramTreeNode) {
      //console.log('updateData program ran.', data)
      const updateData = data as IUpdateProgramTreeNode;
      const fields = Object.keys(updateData);
      const selfThis = this;
      fields.forEach((fieldName) => {
        selfThis.setField(
          fieldName as keyof IUpdateProgramTreeNode,
          data[fieldName as keyof IUpdateProgramTreeNode],
        );
      });
    },
    handleValidation(
      fieldName: string,
      fieldNodeId: number,
      action: "add" | "remove",
    ) {
      const foundError = self.validationErrors.find((err) => {
        return err.fieldName === fieldName;
      });
      if (!foundError && action === "add") {
        self.validationErrors.push({ fieldName, fieldNodeId });
        //console.log('adding error', self.validationErrors)
      } else {
        const foundError = self.validationErrors.findIndex((err) => {
          return err.fieldName === fieldName;
        });
        if (foundError !== -1 && action === "remove") {
          self.validationErrors.splice(foundError, 1);
          //console.log('removing error', self.validationErrors)
        }
      }
      //console.log(toJS(self.validationErrors), 'called validation handle', 'operation:', action)
    },
    getProgramHeaderPayloadForPost() {
      const programHeaderPayload = {
        program_id: self.id,
        display_name: self.display_name,
        code: self.code,
        name: self.name,
        mapped_list_ids: [],
      };
      return programHeaderPayload;
    },
    transformNodeItemForPost(itemData: any) {
      const item = toJS(itemData);

      if (!item.operation) {
        if (item.activities) {
          return {
            id: item.id,
            operation: "none",
            activities: item.activities,
          };
        } else if (item.modules) {
          return { id: item.id, operation: "none", modules: item.modules };
        } else console.log("shouldn't get here", item);
      } else if (item.operation === "delete")
        return { id: item.id, operation: "delete" };

      delete item.areMappingsInEdit;
      delete item.expanded;
      delete item.isNewItem;
      delete item.isChanged;
      delete item.isNewestItem;
      delete item.move;
      delete item.delete;
      delete item.activeField;
      delete item.activeRow;
      delete item.inEdit;

      if (item.mappings.length > 0) {
        const mappings_static: MappingListPostItem[] = [];
        item.mappings.forEach((mapping: MappingListPostItem) => {
          if (mapping.operation) {
            if (mapping.operation === "create") mapping.id = 0;
            mappings_static.push(toJS(mapping));
          }
        });
        item.mappings = mappings_static;
      }

      return item;
    },
    assignCurriculumNodeOperation(
      node: CoursePostItem | ModulePostItem | ActivityPostItem,
    ) {
      if (node.delete) node.operation = "delete";
      else if (node.isNewItem) node.operation = "create";
      else if (node.isChanged) node.operation = "update";
    },
    assignMappingOperation(mapping: MappingListPostItem) {
      if (mapping.delete) mapping.operation = "delete";
      else if (mapping.isNewItem) mapping.operation = "create";
    },
    getCurriculumNodesForDatabasePost() {
      //gather course, module, activity nodes for post action
      const itemsToPost: AlignEditorPayload = {
        program_id: self.id,
        display_name: self.display_name,
        code: self.code,
        name: self.name,
        available_list_ids: [],
        courses: [],
      };

      const postItems: CoursePostItem[] = [];
      const coursesToUpdate: CoursePostItem[] = self.courses;
      const selfThis = this;

      if (coursesToUpdate.length > 0) {
        coursesToUpdate.forEach((course) => {
          const courseTmp = { ...(course as CoursePostItem) };
          if (course.isNewItem) {
            courseTmp.id = 0;
          }
          this.assignCurriculumNodeOperation(courseTmp);
          let courseModules: ModulePostItem[] = [];

          courseModules = self.modules.filter((module) => {
            return module.course_id === course.id;
          });
          if (courseTmp.modules === undefined) {
            courseTmp.modules = [];
          }
          if (courseModules.length > 0) {
            courseModules.forEach((module) => {
              const moduleTmp = { ...(module as ModulePostItem) };
              if (module.isNewItem) {
                moduleTmp.id = 0;
                moduleTmp.course_id = 0;
              }
              this.assignCurriculumNodeOperation(moduleTmp);

              let moduleActivities: IActivity[] = [];
              moduleActivities = self.activities.filter((activity) => {
                return activity.module_id === module.id;
              });
              if (moduleTmp.activities === undefined) {
                moduleTmp.activities = [];
              }
              if (moduleActivities.length > 0) {
                moduleActivities.forEach((activity) => {
                  const activityTmp = { ...(activity as ActivityPostItem) };
                  if (activityTmp.isNewItem) {
                    activityTmp.id = 0;
                    activityTmp.course_id = 0;
                    activityTmp.module_id = 0;
                  }
                  this.assignCurriculumNodeOperation(activityTmp);

                  activityTmp.mappings?.forEach((mapping) =>
                    selfThis.assignMappingOperation(mapping),
                  );
                  if (areMappingsChanged(activityTmp))
                    activityTmp.operation = "update";

                  if (activityTmp.operation) {
                    moduleTmp.activities?.push(
                      selfThis.transformNodeItemForPost(activityTmp),
                    );
                  }
                });
              }

              moduleTmp.mappings?.forEach((mapping) =>
                selfThis.assignMappingOperation(mapping),
              );
              if (areMappingsChanged(moduleTmp)) {
                moduleTmp.operation = "update";
              }

              if (moduleTmp.operation || moduleTmp.activities.length)
                courseTmp.modules?.push(
                  selfThis.transformNodeItemForPost(moduleTmp),
                );
            });
          }

          courseTmp.mappings?.forEach((mapping) =>
            selfThis.assignMappingOperation(mapping),
          );
          if (areMappingsChanged(courseTmp)) {
            courseTmp.operation = "update";
          }

          if (courseTmp.operation || courseTmp.modules.length)
            postItems.push(selfThis.transformNodeItemForPost(courseTmp));
        });
      }

      //since we need to always pass in the complete hierarchy of nodes, we need to check if any of the nodes are marked marked as new or updated to switch between 0 or the original id

      if (postItems.length > 0) {
        itemsToPost.courses = postItems;
      }

      return itemsToPost as AlignEditorPayload;
    },
  }));

const setProgramTreeNodeNewestItemFalse = (
  item: ActivityInstance | CourseInstance | ModuleInstance | undefined,
) => {
  if (item) item.isNewestItem = false;
};

export interface ActivityPostItem extends IActivity {
  operation?: "create" | "update" | "delete";
}

export interface ModulePostItem extends IModule {
  activities?: IActivity[];
  operation?: "create" | "update" | "delete";
}

export interface CoursePostItem extends ICourse {
  modules?: ModulePostItem[];
  operation?: "create" | "update" | "delete";
}

export interface MappingListPostItem extends IMappingListItem {
  operation?: "create" | "update" | "delete";
}

type AlignEditorPayload = {
  program_id: number;
  display_name?: string;
  code?: string;
  name?: string;
  available_list_ids?: number[];
  courses: CoursePostItem[];
};

export type ProgramTreeNodeInstance = Instance<typeof ProgramTreeNode>;
export interface IProgramTreeNode extends SnapshotIn<typeof ProgramTreeNode> {}
export interface IUpdateProgramTreeNode
  extends Omit<
    IProgramTreeNode,
    | "id"
    | "partner_id"
    | "created_at"
    | "created_at"
    | "created_by_id"
    | "updated_at"
    | "updated_by_id"
    | "mapped_list_ids"
    | "mapped_lists"
    | "available_lists"
    | "courses"
    | "modules"
    | "activities"
  > {}

const areMappingsChanged = (
  item: CoursePostItem | ModulePostItem | ActivityPostItem,
) => {
  const updatedMappingIndex =
    item.mappings?.findIndex(
      (mapping: MappingListPostItem) => mapping.operation,
    ) ?? -1;

  return !item.operation && updatedMappingIndex > -1;
};

export type NodeData = {
  label: string;
  type?: string;
  coverage?: number;
  detail?: ICourse | IModule | IActivity;
  activities?: IActivity[];
  expanded?: boolean;
  expandable?: boolean;
  childMappings?: Map<string, NodeMapping[]>;
  mappings?: NodeMapping[];
  size?: [number, number];
};
export type Position = {
  x: number;
  y: number;
};
export type INode = {
  id: string;
  data: NodeData;
  position: Position;
  targetPosition: string;
  sourcePosition: string;
  type?: string;
  // eslint-disable-next-line @typescript-eslint/ban-types
  style?: any;
  parentId?: string;
};

export type IEdge = {
  id: string;
  source: string;
  target: string;
  animated?: boolean;
  label?: string;
  type?: "";
};

export type NodeMapping = {
  list_id: number;
  listitem_id: number;
};

let programUndoPatchHistory: IJsonPatch[] = [];
let trackPatches = true;
let usedUndoButton = false;
let initialSnapShot: any = [];

const AlignEditor = types
  .model("AlignEditor", {
    programTreeNode: types.array(ProgramTreeNode),
    listManager: types.optional(ListManager, {}),
    drawerLists: types.optional(ListManager, {}),
    colors: types.array(Color),
    storeReady: types.optional(types.boolean, false),
    listManagerReady: types.optional(types.boolean, false),
    drawerListsReady: types.optional(types.boolean, false),
    drawerSelectedListItems: types.optional(types.array(MappingListItem), []),
    currentMappingListId: types.optional(types.number, -1),
    colorsReady: types.optional(types.boolean, false),
    currentProgramId: types.optional(types.number, -1),
    currentOrganizationId: types.optional(types.number, -1),
    isProgramChanged: types.optional(types.boolean, false),
  })
  .views((self) => ({
    getProgram(id?: number) {
      let progId = -1;
      if (!id) {
        progId = self.currentProgramId !== -1 ? self.currentProgramId : progId;
      } else {
        progId = id;
        self.currentProgramId = progId;
      }
      return resolveIdentifier(ProgramTreeNode, self, progId);
    },
    getCurrentOrganizationId() {
      return self.currentOrganizationId;
    },
    get isStoreReady() {
      return self.storeReady;
    },
    isListManagerReady() {
      return self.listManagerReady;
    },
    isColorsReady() {
      return self.colorsReady;
    },
    getNodeColor(nodeType: string) {
      let nodeColor = "";
      self.colors.every((color) => {
        if (nodeType === color.nodeType) {
          nodeColor = color.color;
          return false;
        }
        return true;
      });
      return nodeColor;
    },
    getCurrentColors() {
      return toJS(self.colors);
    },
    get isProgramExpanded() {
      const program = this.getProgram();
      return program ? program.isExpanded : false;
    },
  }))
  .actions((self) => ({
    initProgramTreeNode(data: IProgramTreeNode[]) {
      //console.log('initProgramTreeNode ran.')
      programUndoPatchHistory = [];
      initialSnapShot = [];
      self.programTreeNode = cast([]);
      //console.log(self.programTreeNode.length, initialSnapShot.length, programUndoPatchHistory.length)
      this.setIsProgramChanges(false);
      applySnapshot(self.programTreeNode, data);
      self.currentProgramId = data[0].id;
      self.storeReady = true;
    },
    resetProgramTreeNode() {
      programUndoPatchHistory = [];
      initialSnapShot = [];
      self.programTreeNode = cast([]);
      this.setIsProgramChanges(false);
      self.currentProgramId = -1;
      self.storeReady = false;
    },
    saveProgramChanges(updateData: IProgramTreeNode[]) {
      const program = self.getProgram();
      updateData.forEach((updatedProgram) => {
        program && program.updateData(updatedProgram);
      });
      return this.getCurrentProgram();
    },
    saveProgram(updateData: IProgramTreeNode) {
      updateData.isChanged = true;
      const program = self.getProgram();
      program && program.updateData(updateData);
      return this.getCurrentProgram();
    },
    resetStoreIsReady() {
      self.storeReady = false;
    },
    undoProgramStep() {
      if (programUndoPatchHistory.length === 0) return;
      usedUndoButton = true;
      if (
        programUndoPatchHistory[programUndoPatchHistory.length - 1].op !==
        "remove"
      ) {
        applyPatch(
          self.programTreeNode,
          programUndoPatchHistory[programUndoPatchHistory.length - 1],
        );
        programUndoPatchHistory.pop();
      } else if (
        programUndoPatchHistory[programUndoPatchHistory.length - 1].op ===
        "remove"
      ) {
        programUndoPatchHistory.pop();
        this.undoProgramStep();
      }
    },
    areUndoPatchesAvailable() {
      if (programUndoPatchHistory.length > 1) {
        return true;
      }
      return false;
    },
    getIsProgramChanged() {
      return self.isProgramChanged;
    },
    setIsProgramChanges(isChanges: boolean) {
      self.isProgramChanged = isChanges;
    },
    initListManager(data: IListManagerList) {
      self.listManagerReady = true;
      return self.listManager.initListManagerData(data);
    },
    resetListManager() {
      self.listManagerReady = false;
    },
    listManagerInstance() {
      return self.listManager;
    },
    resetDrawerLists() {
      self.drawerListsReady = false;
      self.drawerLists.resetList();
    },
    initDrawerList(data: IListManagerList) {
      self.drawerListsReady = true;
      self.drawerLists.resetList();
      //console.log('initDrawerList ran.', data)
      self.drawerLists.initListManagerData(data);
    },
    setCurrentMappingListId(id: number) {
      self.currentMappingListId = id;
    },
    getCurrentMappingListId() {
      return self.currentMappingListId;
    },
    resetDrawerSelectedListItems() {
      self.drawerSelectedListItems = cast([]);
    },
    initDrawerSelectionsListData(data: IMappingListItem[]) {
      this.resetDrawerSelectedListItems();
      const nodes: IMappingListItem[] = [];
      data.forEach((item) => {
        nodes.push({
          id: item.id,
          created_at: item.created_at,
          program_id: item.program_id,
          c_item_type: item.c_item_type,
          c_item_id: item.c_item_id,
          list_id: item.list_id,
          listitem_id: item.listitem_id,
          list_name: item.list_name,
          listitem_display_name: item.listitem_display_name,
          isNewItem: item.isNewItem,
        });
      });
      applySnapshot(self.drawerSelectedListItems, nodes);
      return nodes;
    },
    getDrawerSelectedListItems() {
      return self.drawerSelectedListItems;
    },
    resetDrawerListReady() {
      self.drawerListsReady = false;
    },
    deleteDrawerSelectedListItem(listItemId: number) {
      const item = self.drawerSelectedListItems.find((item) => {
        return item.listitem_id === listItemId;
      });
      if (item && item.isNewItem === true) {
        const index = self.drawerSelectedListItems.findIndex((item) => {
          return item.listitem_id === listItemId;
        });
        if (index !== -1) {
          self.drawerSelectedListItems.splice(index, 1);
        }
      } else if (item) {
        //mark for delete as opposed to actually deleting it for items coming from the database.
        item.markForDelete();
      }
    },
    deleteDrawerSelectedListItemFromSelectionsListGrid(itemId: number) {
      const item = self.drawerSelectedListItems.find((item) => {
        return item.listitem_id === itemId;
      });
      if (item && item.isNewItem === true) {
        const index = self.drawerSelectedListItems.findIndex((selectedItem) => {
          return selectedItem.listitem_id === itemId;
        });
        if (index !== -1) {
          const deletedItem = { ...item };
          self.drawerSelectedListItems.splice(index, 1);
          //console.log('deleting item from drawerSelectedListItems', self.drawerSelectedListItems)
          if (deletedItem.listitem_id !== undefined) {
            //uncheck from the available list grid.
            const listItems = self.drawerLists.getListItemsInstance();
            if (listItems.length > 0) {
              const matchedListItem = listItems.find((listItem) => {
                return listItem.id === deletedItem.listitem_id;
              });
              if (matchedListItem) {
                console.log("matchedListItem", matchedListItem);
                matchedListItem.unSelectItem();
              }
            }
          }
        }
      } else if (item) {
        item.markForDelete();
      }
    },
    addDrawerSelectedListItem(
      listItemId: number,
      listItemListId: number,
      c_item_type: string,
      c_item_id: number,
      listitem_display_name: string,
    ) {
      const list = self.drawerLists.list[0];
      const program = self.getProgram();

      const newListItem: IMappingListItem = {
        id: getTmpId(),
        created_at: +new Date(),
        program_id: program && program.id,
        c_item_type: c_item_type,
        c_item_id: c_item_id,
        list_id: listItemListId,
        listitem_id: listItemId,
        list_name: list.name,
        listitem_display_name: listitem_display_name,
        isNewItem: true,
      };
      self.drawerSelectedListItems.push(newListItem);
    },
    listDrawerInstance() {
      return self.drawerLists;
    },
    getDrawerListWithSelections() {
      //finds a node's previous selected mappings and returns an array with those items marked as selected to the ui so they are visually checked on component load.
      const program = self.getProgram();
      const listItems = self.drawerLists.getListItemsInstance();
      if (program) {
        if (self.drawerSelectedListItems.length > 0) {
          //console.log('self.drawerSelectedListItems', self.drawerSelectedListItems, 'listItems', toJS(listItems))
          self.drawerSelectedListItems.forEach((selectedItem) => {
            const matchedListItem = listItems.find((item) => {
              return item.id === selectedItem.listitem_id;
            });
            if (matchedListItem) {
              matchedListItem.selectItem();
            }
          });
        }
      }
      return listItems;
    },
    getDrawerListWithSelectionsTree() {
      // console.time("getDrawerListWithSelectionsTree");
      const program = self.getProgram();
      const listItems = self.drawerLists.getListItemsInstance();
      if (program) {
        if (self.drawerSelectedListItems.length > 0) {
          self.drawerSelectedListItems.forEach((selectedItem) => {
            const matchedListItem = listItems.find((item) => {
              return item.id === selectedItem.listitem_id;
            });
            if (matchedListItem) {
              matchedListItem.selectItem();
            }
          });
        }
      }
      const map = new Map<number, ItemRow>();
      const roots: ItemRow[] = [];

      listItems.forEach((item) => map.set(item.id, { ...item, items: [] }));

      listItems.forEach((item) => {
        if (item.parent_id) {
          const parent = map.get(item.parent_id);
          if (parent) {
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            parent.items?.push(map.get(item.id)!);
          }
        } else {
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          roots.push(map.get(item.id)!);
        }
      });

      // console.timeEnd("getDrawerListWithSelectionsTree");
      return roots;
    },
    getNodeMappedSelectionsList(curriculumNodeId: number, nodeType: NodeTypes) {
      //finds a node's previous selected mappings only.
      const program = self.getProgram();
      let curriculumNode: ICourse | IModule | IActivity | undefined = undefined;
      const selectedMappingsList: IMappingListItem[] = [];

      if (program) {
        curriculumNode = program.getCurriculumNodeById(
          nodeType,
          curriculumNodeId,
          true,
        );
        if (curriculumNode) {
          if (curriculumNode.mappings && curriculumNode.mappings.length > 0) {
            const notDeletedMappings = curriculumNode.mappings.filter(
              (mapping) => {
                return mapping.delete === false;
              },
            );
            if (notDeletedMappings.length > 0) {
              notDeletedMappings.forEach((mapping) => {
                selectedMappingsList.push(toJS(mapping));
              });
            }
          } else {
            console.log("no mappings found.", curriculumNodeId);
          }
        }
      }
      //console.log('selectedMappingsList for initial drawer selections ->', selectedMappingsList)
      this.initDrawerSelectionsListData(selectedMappingsList);
      return selectedMappingsList;
    },
    resetAll() {
      trackPatches = true;
      applySnapshot(self, initialSnapShot[0]);
      self.isProgramChanged = false;
      programUndoPatchHistory = [];
      //programUndoPatchHistory.push({op: 'remove', path: '/0'})
    },
    getCurrentProgramSnapshot() {
      trackPatches = true;
      return getSnapshot(self.programTreeNode);
    },
    getCurrentProgram() {
      return self.programTreeNode as IProgramTreeNode[];
    },
    getCurrentProgramAvailableListIds(): number[] {
      const program = self.getProgram();
      return program?.available_list_ids ?? [];
    },
    toggleExpandProgram(programId: number) {
      self.programTreeNode.every((node) => {
        if (node.id === programId) {
          node.toggleExpand();
          return false;
        }
        return true;
      });
      return this.getCurrentProgram();
    },
    //Courses
    getProgramCourses() {
      let courses: ICourse[] = [];
      const program = self.getProgram();
      if (program) {
        courses = program.courses.filter((course) => {
          return course.program_id === program?.id && course.delete === false;
        });
      }
      return courses;
    },
    toggleExpandCourse(courseId: number) {
      let program: ProgramTreeNodeInstance | undefined = undefined;
      program = self.getProgram();
      program &&
        program.courses.every((course) => {
          if (course.id === courseId) {
            course.toggleExpand();
            return false;
          }
          return true;
        });
      return this.getProgramCourses();
    },
    getCourseNode(nodeId: number) {
      const program = self.getProgram();
      const foundNode =
        program && program.getCurriculumNodeById(NodeTypes.Course, nodeId);
      return foundNode as CourseInstance;
    },
    saveProgramCourseChanges(updateData: ICourse[]) {
      const program = self.getProgram();
      if (program) {
        updateData.forEach((course) => {
          const courseToUpdate = program?.courses.find((node) => {
            return node.id === course.id;
          });
          courseToUpdate && courseToUpdate.updateData(course);
        });
      }
      return this.getProgramCourses();
    },
    saveCourseChanges(updateData: ICourse) {
      updateData.isChanged = true;
      const program = self.getProgram();
      if (program) {
        const courseToUpdate = program?.courses.find((node) => {
          return node.id === updateData.id;
        });
        courseToUpdate && courseToUpdate.updateData(updateData);
      }
      return this.getProgramCourses();
    },
    createProgramCourse(programId: number) {
      //[Todo] revisit this when we have end points for it.
      /*let program = self.getProgram(programId)
        
        if(program){
            let courseTmp = {
                uid: getUniqueId(),
                parent_uid: program.uid,
                code: NodeTypes.Course,
                name: NodeTypes.Course,
                display_name: 'New Course',
                item_label: NodeTypes.Course,
                item_type: NodeTypes.Course,
            }
            program.addCurriculum(courseTmp)
        }*/
    },
    //Modules
    getCourseModules(courseId: number) {
      let modules: IModule[] = [];
      const program = self.getProgram();
      if (program) {
        modules = program.modules.filter((node) => {
          return node.course_id === courseId && node.delete === false;
        });
      }
      return modules;
    },
    toggleExpandModule(courseId: number, moduleId: number) {
      let program: ProgramTreeNodeInstance | undefined = undefined;
      program = self.getProgram();
      program &&
        program.modules.every((node) => {
          if (node.course_id === courseId && node.id === moduleId) {
            node.toggleExpand();
            return false;
          }
          return true;
        });
      return this.getCourseModules(courseId);
    },
    createModule(courseId: number) {
      //[Todo] revisit this when we have end points for it.
      /*let program = self.getProgram()
        if(program){
            let moduleTmp = {
                uid: getUniqueId(),
                parent_uid: courseUid,
                code: NodeTypes.Module,
                name: NodeTypes.Module,
                display_name: 'New Module',
                item_label: NodeTypes.Module,
                item_type: NodeTypes.Module,
            }
            program.addCurriculum(moduleTmp)
        }
        return this.getCourseModules(programId,courseUid)
        */
    },
    saveProgramModuleChanges(courseId: number, updateData: IModule[]) {
      const program = self.getProgram();
      if (program) {
        updateData.forEach((updatedModule) => {
          const moduleToUpdate = program?.modules.find((node) => {
            return node.course_id === courseId && node.id === updatedModule.id;
          });
          moduleToUpdate && moduleToUpdate.updateData(updatedModule);
        });
      }
      return this.getCourseModules(courseId);
    },
    saveModuleChanges(updateData: IModule, courseId: number) {
      updateData.isChanged = true;
      const program = self.getProgram();
      if (program) {
        const moduleToUpdate = program?.modules.find((node) => {
          return node.course_id === courseId && node.id === updateData.id;
        });
        moduleToUpdate && moduleToUpdate.updateData(updateData);
      }
      return this.getCourseModules(courseId);
    },
    getModule(courseId: number, moduleId: number) {
      let module: ModuleInstance | undefined = undefined;
      let program: ProgramTreeNodeInstance | undefined = undefined;
      program = self.getProgram();
      if (program !== undefined) {
        program.modules.every((node) => {
          if (node.course_id === courseId && node.id === moduleId) {
            module = node;
            return false;
          }
          return true;
        });
      }
      if (module !== undefined) {
        return module as ModuleInstance;
      }
      return module;
    },
    //Activities
    getActivity(courseId: number, moduleId: number, activityId: number) {
      let foundActivity: ActivityInstance | undefined = undefined;
      const program = self.getProgram();
      if (program) {
        foundActivity = program.activities.find((activity) => {
          return (
            activity.course_id === courseId &&
            activity.module_id === moduleId &&
            activity.id === activityId
          );
        });
      }
      return foundActivity;
    },
    getModuleActivities(courseId: number, moduleId: number) {
      let activities: IActivity[] = [];
      const program = self.getProgram();
      if (program) {
        activities = program.activities.filter((node) => {
          return (
            node.module_id === moduleId &&
            node.course_id === courseId &&
            node.delete === false
          );
        });
      }
      return activities;
    },
    toggleExpandActivity(
      courseId: number,
      moduleId: number,
      activityId: number,
    ) {
      const program = self.getProgram();
      program &&
        program.activities.every((node) => {
          if (
            node.course_id === courseId &&
            node.module_id === moduleId &&
            node.id === activityId
          ) {
            node.toggleExpand();
            return false;
          }
          return true;
        });
      return this.getModuleActivities(courseId, moduleId);
    },
    saveModuleActivityChanges(
      courseId: number,
      moduleId: number,
      updatedActivities: IActivity[],
    ) {
      const program = self.getProgram();
      if (program) {
        updatedActivities.forEach((updatedActivity) => {
          const activityToUpdate = program?.activities.find((node) => {
            return (
              node.course_id === courseId &&
              node.module_id === moduleId &&
              node.id === updatedActivity.id
            );
          });
          activityToUpdate && activityToUpdate.updateData(updatedActivity);
        });
      }
      return this.getModuleActivities(courseId, moduleId);
    },
    saveActivityChanges(
      updateData: IActivity,
      courseId: number,
      moduleId: number,
    ) {
      updateData.isChanged = true;
      const program = self.getProgram();
      if (program) {
        const activityToUpdate = program?.activities.find((node) => {
          return (
            node.course_id === courseId &&
            node.module_id === moduleId &&
            node.id === updateData.id
          );
        });
        activityToUpdate && activityToUpdate.updateData(updateData);
      }
      return this.getModuleActivities(courseId, moduleId);
    },
    //We are now doing a soft delete instead so marking for delete, can be used in post request to delete.
    deleteCurriculumNode(nodeId: number, nodeType: NodeTypes) {
      const program = self.getProgram();
      let nodeToDelete:
        | CourseInstance
        | ActivityInstance
        | ModuleInstance
        | undefined = undefined;
      if (program) {
        nodeToDelete = program.getCurriculumNodeById(nodeType, nodeId, true);
        if (nodeToDelete) {
          //check for any required values before marking for delete.
          const errorsToRemove = program.validationErrors.filter((err) => {
            return err.fieldNodeId === nodeId;
          });
          if (errorsToRemove.length > 0) {
            errorsToRemove.forEach((err) => {
              const idxForError = program?.validationErrors.findIndex(
                (error) => {
                  return error.fieldNodeId === err.fieldNodeId;
                },
              );
              if (idxForError !== undefined && idxForError !== -1) {
                program?.validationErrors.splice(idxForError, 1);
              }
            });
          }
          if (nodeToDelete.isNewItem)
            program.deleteCurriculumNode(nodeType, nodeId);
          else nodeToDelete.markForDelete();
        }
      }
    },
    deleteCurriculumNodeMapping(
      nodeId: number,
      nodeType: NodeTypes,
      mappingId: number,
    ) {
      const program = self.getProgram();
      let nodeWithMappings:
        | CourseInstance
        | ActivityInstance
        | ModuleInstance
        | undefined = undefined;
      let mappingToDelete: MappingListItemInstance | undefined = undefined;
      if (program) {
        nodeWithMappings = program.getCurriculumNodeById(
          nodeType,
          nodeId,
          true,
        );
        if (nodeWithMappings) {
          mappingToDelete = nodeWithMappings.mappings.find((mapping) => {
            return mapping.id === mappingId;
          });
          if (mappingToDelete?.isNewItem) {
            const mappingIdx = nodeWithMappings.mappings.findIndex(
              (mapping) => {
                return mapping.id === mappingId;
              },
            );
            if (mappingIdx !== undefined && mappingIdx !== -1) {
              nodeWithMappings.mappings.splice(mappingIdx, 1);
            }
          } else {
            mappingToDelete && mappingToDelete.markForDelete();
          }
        }
      }
    },
    //End mappings.
    getFlowNodes(programId: number) {
      const flowNodes: INode[] = [];
      const flowEdges: Edge[] = [];

      const currentProgram: IProgramTreeNode | undefined =
        self.getProgram(programId);
      if (currentProgram) {
        const programChildMappings = new Map<string, NodeMapping[]>();
        const courses = currentProgram.courses;
        courses?.forEach((course) => {
          const courseChildMappings = new Map<string, NodeMapping[]>();
          const modules = this.getCourseModules(course.id);
          modules.forEach((module) => {
            const moduleChildMappings = new Map<string, NodeMapping[]>();
            const activities = this.getModuleActivities(course.id, module.id);
            // activities.forEach((activity) => {
            //   const activityTemplate: INode = {
            //     id: NodeTypes.Activity + "-" + activity.id,
            //     data: {
            //       label: activity.display_name,
            //       type: NodeTypes.Activity,
            //       direction: TreeDirections.Horizontal,
            //       backgroundColor: self.getNodeColor(NodeTypes.Activity),
            //       color: getReadableTextColorForBackground(
            //         self.getNodeColor(NodeTypes.Activity),
            //       ),
            //       detail: activity,
            //     },
            //     type: "custom",
            //     position: { x: 0, y: 0 },
            //     targetPosition: "top",
            //     sourcePosition: "bottom",
            //     parentId: NodeTypes.Module + "-" + module.id + "-activities",
            //   };
            //   const activityEdge: Edge = {
            //     id: `ed-Module-${module.id}-${activity.id}`,
            //     source: NodeTypes.Module + "-" + module.id,
            //     target: NodeTypes.Activity + "-" + activity.id,
            //     animated: false,
            //     type: "smoothstep",
            //   };
            //   flowNodes.push(activityTemplate);
            //   flowEdges.push(activityEdge);

            //   moduleChildMappings.set(
            //     NodeTypes.Activity + "-" + activity.id,
            //     activity.mappings?.map((mapping) => ({
            //       listId: mapping.list_id,
            //       listItemId: mapping.listitem_id,
            //     })) ?? [],
            //   );
            // });

            if (activities.length > 0) {
              const activitiesTemplate: INode = {
                id: NodeTypes.Module + "-" + module.id + "-activities",
                data: {
                  label: "Activities",
                  type: NodeTypes.Activity,
                  activities: activities,
                },
                type: "activities",
                position: { x: 0, y: 0 },
                targetPosition: "top",
                sourcePosition: "bottom",
                parentId: NodeTypes.Module + "-" + module.id,
              };
              const activitiesEdge: Edge = {
                id: `ed-Module-${module.id}-activities`,
                source: NodeTypes.Module + "-" + module.id,
                target: NodeTypes.Module + "-" + module.id + "-activities",
                animated: false,
                type: "smoothstep",
                style: { stroke: "#1A4353" },
              };
              flowNodes.push(activitiesTemplate);
              flowEdges.push(activitiesEdge);

              activities.forEach((activity) => {
                moduleChildMappings.set(
                  NodeTypes.Activity + "-" + activity.id,
                  activity.mappings?.map((mapping) => ({
                    list_id: mapping.list_id,
                    listitem_id: mapping.listitem_id,
                  })) ?? [],
                );
              });
            }

            const moduleMappings: NodeMapping[] =
              module.mappings?.map((mapping) => ({
                list_id: mapping.list_id,
                listitem_id: mapping.listitem_id,
              })) ?? [];

            const moduleTemplate: INode = {
              id: NodeTypes.Module + "-" + module.id,
              data: {
                label: module.display_name,
                type: NodeTypes.Module,
                detail: module,
                expanded: true,
                childMappings: moduleChildMappings,
                mappings: moduleMappings,
              },
              type: "curriculum",
              position: { x: 0, y: 0 },
              targetPosition: "top",
              sourcePosition: "bottom",
            };
            const edgeToModule: Edge = {
              id: `ed-${NodeTypes.Module}-${course.id}-${module.id}`,
              source: NodeTypes.Course + "-" + course.id,
              target: NodeTypes.Module + "-" + module.id,
              animated: false,
              type: "smoothstep",
              style: { stroke: "#1A4353" },
            };
            flowNodes.push(moduleTemplate);
            flowEdges.push(edgeToModule);

            courseChildMappings.set(
              NodeTypes.Module + "-" + module.id,
              moduleMappings,
            );
            for (const [key, value] of moduleChildMappings) {
              courseChildMappings.set(key, value);
            }
          });

          const courseMappings =
            course.mappings?.map((mapping) => ({
              list_id: mapping.list_id,
              listitem_id: mapping.listitem_id,
            })) ?? [];

          const courseNodeTemplate: INode = {
            id: NodeTypes.Course + "-" + course.id,
            data: {
              label: course.display_name ?? "",
              type: NodeTypes.Course,
              detail: course,
              childMappings: courseChildMappings,
              mappings: courseMappings,
            },
            type: "curriculum",
            position: {
              x: 0,
              y: 0,
            },
            targetPosition: "top",
            sourcePosition: "bottom",
          };
          const courseEdge: Edge = {
            id: "ed-" + course.id,
            source: currentProgram ? `${currentProgram.id}` : "",
            target: NodeTypes.Course + "-" + course.id,
            animated: false,
            type: "smoothstep",
            style: { stroke: "#1A4353" },
          };
          flowNodes.push(courseNodeTemplate);
          flowEdges.push(courseEdge);

          programChildMappings.set(
            NodeTypes.Course + "-" + course.id,
            courseMappings,
          );
          for (const [key, value] of courseChildMappings) {
            programChildMappings.set(key, value);
          }

          // const childMappings = Array.from(courseChildMappings.values()).flat();
          // programChildMappings.set(course.id, [
          //   ...courseMappings,
          //   ...childMappings,
          // ]);
        });

        const nodeTemplate: INode = {
          id: `${currentProgram.id}`,
          data: {
            label: currentProgram.display_name,
            type: NodeTypes.Program,
            expanded: true,
            childMappings: programChildMappings,
            detail: currentProgram,
          },
          type: "curriculum",
          position: { x: 0, y: 0 },
          targetPosition: "top",
          sourcePosition: "bottom",
        };
        flowNodes.push(nodeTemplate);
      }

      console.log("node count:", flowNodes.length);
      console.log("edge count:", flowEdges.length);
      return { nodes: flowNodes, edges: flowEdges };
    },
    setColor(colorType: string, colorvalue: string) {
      self.colors.every((color) => {
        if (color.nodeType === colorType) {
          color.updateColor(colorvalue);
          console.log("updating color...");
          return false;
        }
        return true;
      });
      return true;
    },
    initColors(colorData: IColor[]) {
      applySnapshot(self.colors, colorData);
      self.colorsReady = true;
    },
  }));

export type AlignEditorInstance = Instance<typeof AlignEditor>;
export interface IAlignEditor extends SnapshotIn<typeof AlignEditor> {}

export const alignEditorStore = AlignEditor.create();

//console.log(alignEditorStore, 'AlignEditorStore created.. ')
//use for listening to changes and debugging.
onPatch(alignEditorStore.programTreeNode, (patch, undoPatch) => {
  if (trackPatches) {
    if (!usedUndoButton) {
      if (
        undoPatch.path.indexOf("areMappingsInEdit") === -1 &&
        undoPatch.path.indexOf("mapped_list_items") === -1
      ) {
        programUndoPatchHistory.push(undoPatch);
        //console.log('adding undo patch', programUndoPatchHistory)
      }
    } else {
      usedUndoButton = false;
      //console.log('patch not added to undo.')
    }
    // we need to see if there are changes
    let areProgramChanges = false;
    programUndoPatchHistory.every((aPatch, index) => {
      // const isChange = index !== 0 || aPatch.path.indexOf("delete") !== -1;
      if (aPatch.path.indexOf("expanded") === -1) {
        //console.log('are there program changes??', index)
        areProgramChanges = true;
        return false;
      }
      return true;
    });
    alignEditorStore.setIsProgramChanges(areProgramChanges);
    //console.log('program changes...', alignEditorStore.getIsProgramChanged())
  } else {
    programUndoPatchHistory = [];
  }
});

onSnapshot(alignEditorStore, (snapshot) => {
  //capture initial snapshot.
  if (initialSnapShot.length === 0) {
    //console.log('setting initial snapshot')
    initialSnapShot.push(snapshot);
    programUndoPatchHistory = [];
    alignEditorStore.setIsProgramChanges(false);
  }
});

export type AlignEditorStoreInstance = Instance<typeof alignEditorStore>;

const AlignEditorStoreContext = createContext<AlignEditorStoreInstance>(
  {} as AlignEditorStoreInstance,
);

export const Provider = AlignEditorStoreContext.Provider;

export const useStore = () => {
  const store = useContext(AlignEditorStoreContext);
  if (store === null) {
    throw new Error("Store cannot be null, please add a context provider");
  }
  return store as AlignEditorStoreInstance;
};

export type MapStore<T> = (store: AlignEditorStoreInstance) => T;

export const useInject = <T>(mapStore: MapStore<T>) => {
  const store = useStore();
  return mapStore(store);
};
