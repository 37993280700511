import React, {
  createContext,
  useContext,
  useState,
  SetStateAction,
} from "react";

import { observer } from "mobx-react-lite";

import { useStore } from "~/models/AlignEditor";
import { IListManagerListItem } from "~/models/ListManager";

export type ListItemForAPI = Omit<
  IListManagerListItem,
  "move" | "is_selected" | "is_changed" | "delete" | "isNewItem"
>;

type ListEditorContextType = {
  listItems: IListManagerListItem[];
  setListItems: React.Dispatch<SetStateAction<IListManagerListItem[]>>;
  originalListItems: IListManagerListItem[];
  setOriginalListItems: React.Dispatch<SetStateAction<IListManagerListItem[]>>;
  deletedListItems: IListManagerListItem[];
  setDeletedListItems: React.Dispatch<SetStateAction<IListManagerListItem[]>>;
  isListReordered: boolean;
  setIsListReordered: React.Dispatch<SetStateAction<boolean>>;
  getListItemsForPost: () => ListItemForAPI[];
};

export const ListEditorContext = createContext<ListEditorContextType>({
  listItems: [],
  setListItems: () => undefined,
  originalListItems: [],
  setOriginalListItems: () => undefined,
  deletedListItems: [],
  setDeletedListItems: () => undefined,
  isListReordered: false,
  setIsListReordered: () => undefined,
  getListItemsForPost: () => [],
});

type ListEditorProviderProps = {
  children: React.ReactNode;
};

const ListEditorProvider = observer(({ children }: ListEditorProviderProps) => {
  const { listManagerInstance } = useStore();

  const [listItems, setListItems] = useState<IListManagerListItem[]>(
    listManagerInstance().getListItems(),
  );
  const [originalListItems, setOriginalListItems] =
    useState<IListManagerListItem[]>(listItems);

  const [deletedListItems, setDeletedListItems] = useState<
    IListManagerListItem[]
  >([]);

  const [isListReordered, setIsListReordered] = useState<boolean>(false);

  const getListItemsForPost = () => {
    const postItems = listItems
      .map((item, index) => {
        const postItem = transformItemForPost(item);

        if (isListReordered) postItem.display_order = index;

        if (item.isNewItem) {
          postItem.transaction_type = "Create";
          postItem.id = 0;
        } else if (item.isChanged || isListReordered) {
          postItem.transaction_type = "Update";
        }

        return postItem;
      })
      .filter((item) => item.transaction_type !== "");

    const deletedPostItems = deletedListItems.map((item) => {
      const deletedItem = transformItemForPost(item);
      deletedItem.transaction_type = "Delete";
      return deletedItem;
    });

    return [...postItems, ...deletedPostItems];
  };

  return (
    <ListEditorContext.Provider
      value={{
        listItems,
        setListItems,
        originalListItems,
        setOriginalListItems,
        deletedListItems,
        setDeletedListItems,
        isListReordered,
        setIsListReordered,
        getListItemsForPost,
      }}
    >
      {children}
    </ListEditorContext.Provider>
  );
});

export const useListEditorContext = () => {
  const context = useContext(ListEditorContext);

  if (!context) {
    throw new Error(
      "useListEditorContext must be used within a ListEditorProvider",
    );
  }

  return context;
};

const transformItemForPost = (item: IListManagerListItem): ListItemForAPI => {
  /** Destructure the item as the store has frozen the object */
  const {
    isNewItem,
    isChanged,
    is_selected,
    move,
    delete: del,
    ...listItemForAPI
  } = item;

  return listItemForAPI;
};

export default ListEditorProvider;
