import React, { Fragment } from "react";

import { Badge } from "~/components/ui/badge";
import { Separator } from "~/components/ui/separator";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "~/components/ui/tooltip";
import { useExplorerContext } from "~/context/ExplorerProvider";

const FilterBadges = () => {
  const {
    listDetails,
    selectedList,
    selectedListItemParent,
    selectedListItems,
  } = useExplorerContext();

  const listItemParent = listDetails?.items?.find(
    (item) => item.id.toString() === selectedListItemParent,
  );

  const selectedListItemLabels =
    selectedListItems
      ?.map(
        (item) =>
          listDetails?.items?.find((i) => i.id.toString() === item)
            ?.display_name,
      )
      .filter((label) => label !== undefined) ?? [];

  const visibleLabels =
    selectedListItemLabels.length > 2
      ? selectedListItemLabels.slice(0, 2)
      : selectedListItemLabels;
  const hiddenLabels =
    selectedListItemLabels.length > 2 ? selectedListItemLabels.slice(2) : [];

  return selectedList && listDetails ? (
    <Badge
      variant="outline"
      className="h-11 bg-white/80 backdrop-blur-[4px] py-2"
    >
      <div className="flex gap-4 items-center">
        <div className="flex gap-2 items-center">
          <span className="whitespace-pre font-bold text-sm italic">List:</span>
          <Badge variant="filter" className="h-7 text-nowrap">
            {listDetails?.name}
          </Badge>
        </div>
        {selectedListItemParent && listItemParent ? (
          <div className="flex gap-2 items-center">
            <span className="whitespace-pre font-bold text-sm italic">
              List Item Parent:
            </span>
            <Badge variant="filter" className="h-7 text-nowrap">
              {listItemParent?.display_name}
            </Badge>
          </div>
        ) : null}
        {selectedListItems && selectedListItems.length > 0 ? (
          <div className="flex gap-2 items-center">
            <span className="whitespace-pre font-bold text-base italic">
              List Items:
            </span>
            {visibleLabels.map((label) => (
              <Badge key={label} variant="filter" className="h-7 max-w-48">
                <span className="truncate" title={label}>
                  {label}
                </span>
              </Badge>
            ))}
            {hiddenLabels.length > 0 ? (
              <TooltipProvider>
                <Tooltip>
                  <TooltipTrigger>
                    <Badge
                      variant="filter"
                      className="h-7 rounded-full text-nowrap"
                    >
                      +{hiddenLabels.length} more
                    </Badge>
                  </TooltipTrigger>
                  <TooltipContent side="bottom" className="mr-4">
                    <div className="grid gap-2">
                      {hiddenLabels.map((label, index) => (
                        <Fragment key={label}>
                          <span className="max-w-[230px] text-sm font-normal">
                            {label}
                          </span>
                          {index < hiddenLabels.length - 1 && <Separator />}
                        </Fragment>
                      ))}
                    </div>
                  </TooltipContent>
                </Tooltip>
              </TooltipProvider>
            ) : null}
          </div>
        ) : (
          <div className="flex gap-2 items-center">
            <span className="whitespace-pre font-bold italic text-sm">
              List Items:
            </span>{" "}
            <Badge variant="filter" className="h-7 max-w-48">
              <span className="truncate italic" title={"All list items"}>
                All List Items
              </span>
            </Badge>
          </div>
        )}
      </div>
    </Badge>
  ) : null;
};

export default FilterBadges;
