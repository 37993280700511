import React, { useContext, useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { Form, Formik } from "formik";
import { observer } from "mobx-react-lite";
import styled from "styled-components";
import * as Yup from "yup";

import { API_PATHS } from "~/api/ApiPaths";
import AlignDialog, { DialogContext } from "~/components/AlignDialog";
import BreadcrumbBar from "~/components/BreadcrumbBar";
import RegisterFirstPage from "~/components/login/RegisterFirstPage";
import RegisterSecondPage from "~/components/login/RegisterSecondPage";
import Navbar from "~/components/Navbar";
import { colors } from "~/css/shared-styles";
import useAxios from "~/hooks/useAxios";
import alignHero from "~/images/Align-G.png";
import { useStore as useRootStore } from "~/models/Root";

const AppWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  div.overflow-handle {
    display: flex;
    flex: 1;
    overflow: auto;
  }
`;

export const RegisterStyles = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: row;
  width: 100%;
  align-self: center;
  border-radius: 1rem;
  margin: 1rem;
  align-items: center;
  margin-left: 4rem;
  margin-right: 4rem;
  .hero-image {
    display: flex;
    height: 500px;
    max-width: 600px;
    flex: 1;
    padding-left: 2rem;
    padding-right: 2rem;
    background: center / contain no-repeat url(${alignHero});
    border-radius: 20px;
    align-items: center;
    justify-content: center;
    background-size: 90%;
    margin-right: 3rem;
  }
  .register-form {
    .form-wrap {
      display: flex;
      flex-direction: column;
      flex: 1;
      padding: 4.5rem;
      padding-top: 1rem;
      padding-bottom: 1rem;
      height: 100%;
      max-width: 600px;
      justify-content: center;
      background: #e6f6fc;
      border: 1px solid #b3c0c6;
      border-radius: 6px 6px 40px 6px;
      .input.medium {
        margin-bottom: 0;
      }
      .input-with-label {
        margin-bottom: 0.5rem;
      }
      .requirements {
        margin: 0;
        font-size: 12px;
      }
      .error {
        color: red;
      }
      .checkbox-text {
        margin-bottom: 0;
        margin-left: 5px;
      }
      .user-credentials-error {
        display: flex;
        .alert-content {
          div {
            line-height: inherit;
          }
        }
      }
      .button-holder {
        display: flex;
        justify-content: flex-end;
      }
      .button-holder-double {
        margin-top: 20px;
        display: flex;
        justify-content: space-between;
      }
      .policy {
        background: none;
        color: ${colors.linkBlue};
      }
      .strengthBar {
        .strengthBar-text {
          color: black !important;
          text-align: left !important;
          font-weight: bold;
          margin-bottom: 5px !important;
        }
      }
    }
  }
`;

const formSteps: any[] = [
  {
    firstName: Yup.string()
      .min(2, "Too Short!")
      .max(50, "Too Long!")
      .required("First Name Required"),
    lastName: Yup.string()
      .min(2, "Too Short!")
      .max(50, "Too Long!")
      .required("Last Name Required"),
    email: Yup.string().email("Invalid email").required("Email Required"),
    title: Yup.string()
      .min(2, "Too Short!")
      .max(50, "Too Long!")
      .required("Title Required"),
  },
  {
    password: Yup.string()
      .min(8, "Too Short!")
      .max(50, "Too Long!")
      .required("Password Required")
      .matches(/[0-9]/, "Password requires a number")
      .matches(/[a-z]/, "Password requires a lowercase letter")
      .matches(/[A-Z]/, "Password requires an uppercase letter")
      .matches(/[^\w]/, "Password requires a symbol"),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("password"), null], "Both passwords must match")
      .required("Confirm Password Required"),
    acceptedTerms: Yup.boolean()
      .required("Accepted Terms Required")
      .oneOf([true], "You must accept the terms and conditions."),
  },
];

export interface IRegisterForm {
  firstName: string;
  lastName: string;
  email: string;
  title: string;
  password: string;
  confirmPassword: string;
  acceptedTerms: boolean;
}

interface IRegisterUser {
  token: string;
  first_name: string;
  last_name: string;
  title: string;
  password: string;
}

const Register: React.FC = observer(() => {
  const [showNextPage, setShowNextPage] = useState(false);
  const navigate = useNavigate();
  const { setAuthToken, setLoggedIn, isLoggedIn, resetAppUser } =
    useRootStore();
  const { data, payload, requestUrl, fetch, error } = useAxios({
    method: "GET",
    initialValue: null,
  });
  const registrationSet = useRef(false);
  const invitationToken = useRef(useLocation().search.split("=")[1] || "");
  const displayReady = useRef(false);
  const { dialogContent } = useContext(DialogContext);
  const initialLoad = useRef(true);

  const defaultValues: IRegisterForm = {
    firstName: "",
    lastName: "",
    email: "",
    title: "",
    password: "",
    confirmPassword: "",
    acceptedTerms: false,
  };
  const [formValues, setFormValues] = useState<IRegisterForm>(defaultValues);

  useEffect(() => {
    //If user navigates to register page and is logged in, we need to log them out.
    if (initialLoad.current && isLoggedIn) {
      initialLoad.current = false;
      setAuthToken("");
      resetAppUser();
      setLoggedIn(false);
    }
  }, [isLoggedIn, setAuthToken, resetAppUser, setLoggedIn]);

  useEffect(() => {
    if (data) {
      if (data.issuccess) {
        if (data.message === "Registration completed succesfully.") {
          //TODO in the future modal/dialog would be better
          setAuthToken("");
          navigate("/login", { replace: true });
          alert("Your account has been created. Please sign in now. ");
        } else if (!displayReady.current) {
          //set default values for form
          setFormValues({
            firstName: data.entity.first_name,
            lastName: data.entity.last_name,
            email: data.entity.email,
            title: data.entity.title,
            password: "",
            confirmPassword: "",
            acceptedTerms: false,
          });
          displayReady.current = true;
        }
      }
    } else if (!registrationSet.current) {
      //get registration info
      registrationSet.current = true;
      requestUrl.current = `${API_PATHS.GET_REGISTRATION}?token=${invitationToken.current}`;
      fetch();
    } else if (error) {
      //TODO in the future I think a modal/dialog would be nice here
      alert(
        "An error has occured with your registration link. Please contact iDesign for support.",
      );
      setAuthToken("");
      navigate("/login", { replace: true });
    }
  }, [data, error, fetch, navigate, requestUrl, setAuthToken]);

  const register = (props: IRegisterUser) => {
    payload.current = {
      token: props.token,
      first_name: props.first_name,
      last_name: props.last_name,
      title: props.title,
      password: props.password,
    };
    requestUrl.current = `${API_PATHS.REGISTER}`;
    fetch("POST");
  };

  return (
    <AppWrapper>
      <Navbar />
      <div className="overflow-handle">
        <RegisterStyles className="register-wrap">
          <div className="hero-image"></div>
          <div className="register-form">
            {displayReady.current && (
              <div className="form-wrap">
                <BreadcrumbBar showNextPage={showNextPage} />
                <Formik
                  initialValues={formValues}
                  validationSchema={
                    !showNextPage
                      ? Yup.object().shape(formSteps[0])
                      : Yup.object().shape(formSteps[1])
                  }
                  onSubmit={async (values, { setSubmitting }) => {
                    register({
                      token: invitationToken.current,
                      first_name: values.firstName,
                      last_name: values.lastName,
                      title: values.title,
                      password: values.password,
                    });
                  }}
                >
                  <div>
                    <Form>
                      {!showNextPage && (
                        <RegisterFirstPage setShowNextPage={setShowNextPage} />
                      )}
                      {showNextPage && (
                        <RegisterSecondPage setShowNextPage={setShowNextPage} />
                      )}
                    </Form>
                  </div>
                </Formik>
              </div>
            )}
          </div>
        </RegisterStyles>
      </div>
      <AlignDialog children={dialogContent} />
    </AppWrapper>
  );
});

export default Register;
