import { useState, useContext, useMemo, useRef } from "react";

import axios from "axios";

import { AxiosContext } from "~/api/AxiosInstance";
export interface IUseAxios {
  method: string;
  initialValue?: any;
}
const useAxios = (props: IUseAxios) => {
  const { method, initialValue } = props;
  const requestUrl = useRef("");
  const [data, setData] = useState(initialValue);
  const payload = useRef<unknown | null>(null);
  const [error, setError] = useState<any>("");
  const loading = useRef(false);
  const contextInstance = useContext(AxiosContext);
  const instance = useMemo(() => {
    return contextInstance || axios;
  }, [contextInstance]);
  const controllerRef = useRef(new AbortController());
  const authToken = useRef("");

  //making this hook explicit as to when to call the action
  const fetch = async (callMethod = `${method}`) => {
    loading.current = true;
    await instance
      .request({
        signal: controllerRef.current.signal,
        url: requestUrl.current,
        method: callMethod,
        headers: { Authorization: authToken.current },
        data: payload.current,
      })
      .then((response) => {
        if (response.status === 200) {
          loading.current = false;
          setData(response.data);
        }
      })
      .catch((theError) => {
        loading.current = false;
        setError(theError);
        return null;
      });
  };

  //reset the data to the initial value, useful when you need to refetch the data without reloading a component
  const resetFetchedData = () => {
    setData(initialValue);
  };

  const resetAll = () => {
    setData(initialValue);
    setError("");
    loading.current = false;
    payload.current = null;
    requestUrl.current = "";
    controllerRef.current = new AbortController();
    authToken.current = "";
  };

  return {
    loading,
    data,
    error,
    payload,
    fetch,
    requestUrl,
    authToken,
    resetFetchedData,
    setData,
    resetAll,
  };
};

export default useAxios;
