import { CellContext, FilterFn } from "@tanstack/react-table";

export type HighlightRange = [number, number];
export const HIGHLIGHT_RANGE_START = 0;
export const HIGHLIGHT_RANGE_END = 1;

declare module "@tanstack/table-core" {
  interface FilterMeta {
    globalFilterRanges?: HighlightRange[];
    columnFilterRanges?: HighlightRange[];
  }
}

export interface ColumnFilterWithHighlightingConfig {
  /** Search term */
  term: string;
  /** List Parent Item id */
  parentItemId?: number;
}

interface ResolvedColumnFilterWithHighlightingConfig
  extends ColumnFilterWithHighlightingConfig {
  /** Resolved search term */
  resolvedTerm: string;
  /** List Parent Item id */
  parentItemId?: number;
}

function find(value: string, term: string, all = false) {
  let ranges: HighlightRange[] | undefined = undefined;
  let position = 0;
  // eslint-disable-next-line no-constant-condition
  while (true) {
    const startIndex = value.indexOf(term, position);
    if (startIndex < 0) break;
    if (!ranges) ranges = [];
    ranges.push([startIndex, startIndex + term.length]);
    if (!all) break;
    position = startIndex + term.length;
  }
  return ranges;
}

export const columnFilterWithHighlighting: FilterFn<any> = function (
  row,
  columnId,
  filterValue,
  addMeta,
) {
  const filterConfig =
    filterValue as ResolvedColumnFilterWithHighlightingConfig;
  const term = filterConfig.resolvedTerm;
  const value = row.getValue(columnId);
  let valueStr: string;
  if (typeof value === "string") {
    valueStr = value;
    valueStr = valueStr.toLowerCase();
    // Replace all newlines with spaces
    // IMPORTANT number of characters must not be changed by replace here
    // valueStr = valueStr.replace(/\n/g, " ");
  } else {
    return false;
  }

  const parentItemId = filterConfig.parentItemId;
  if (parentItemId) {
    const parentRows = row.getParentRows();
    for (const parentRow of parentRows) {
      if (parentRow.original.id === parentItemId) {
        if (term === "") return true;
        const ranges = find(valueStr, term, true);
        if (ranges) {
          // Store ranges in filter meta for current column
          // addMeta({
          //   ...row.columnFiltersMeta[columnId],
          //   columnFilterRanges: ranges,
          // });
          return true;
        }
      }
    }
    return false;
  }

  if (term === "") return true;
  const ranges = find(valueStr, term, true);
  if (ranges) {
    // Store ranges in filter meta for current column
    // addMeta({
    //   ...row.columnFiltersMeta[columnId],
    //   columnFilterRanges: ranges,
    // });
    return true;
  }
  return false;
};

export const getHighlightRanges = function (
  cellContext: CellContext<any, string>,
) {
  const value = cellContext.cell.getValue();
  let valueStr: string;
  if (typeof value === "string") {
    valueStr = value.toLowerCase();
    valueStr = valueStr.replace(/\n/g, " ");
  } else {
    return [];
  }

  const filterTerm = (
    (cellContext.column.getFilterValue() as ColumnFilterWithHighlightingConfig)
      ?.term ?? ""
  ).toLowerCase();

  if (filterTerm === "") return [];

  const ranges = find(valueStr, filterTerm, true);

  return ranges ?? [];
};

columnFilterWithHighlighting.resolveFilterValue = function (
  filterValue: ColumnFilterWithHighlightingConfig,
): ResolvedColumnFilterWithHighlightingConfig {
  return {
    ...filterValue,
    resolvedTerm: filterValue.term.toLowerCase(),
  };
};

// export function getHighlightRanges(cellContext: CellContext<any, string>) {
//   // Highlight ranges stored in columnFiltersMeta
//   // globalFilterRanges from global filter
//   // columnFilterRanges from individual column filter
//   // Meta may remain even if filter is empty so we have to check if filter is empty
//   const globalFilter = cellContext.table.getState().globalFilter as
//     | GlobalFilterWithHighlightingConfig
//     | undefined;
//   const globalFilterRanges = globalFilter?.term
//     ? cellContext.row.columnFiltersMeta[cellContext.column.id]
//         ?.globalFilterRanges
//     : undefined;
//   const columnFilter = cellContext.column.getFilterValue() as
//     | ColumnFilterWithHighlightingConfig
//     | undefined;
//   const columnFilterRanges = columnFilter?.term
//     ? cellContext.row.columnFiltersMeta[cellContext.column.id]
//         ?.columnFilterRanges
//     : undefined;

//   // Concat all ranges in one array
//   // Sort ranges by start index
//   // eslint-disable-next-line prefer-const
//   let ranges = [
//     ...(globalFilterRanges ?? []),
//     ...(columnFilterRanges ?? []),
//   ].sort(
//     (rangeA, rangeB) =>
//       rangeA[HIGHLIGHT_RANGE_START] - rangeB[HIGHLIGHT_RANGE_START],
//   );

//   // Merge ranges if they overlap
//   let i = 0;
//   let j = 1;
//   while (j < ranges.length) {
//     if (ranges[i]![HIGHLIGHT_RANGE_END] >= ranges[j]![HIGHLIGHT_RANGE_START]) {
//       ranges[j]![HIGHLIGHT_RANGE_START] = ranges[i]![HIGHLIGHT_RANGE_START];
//       ranges[j]![HIGHLIGHT_RANGE_END] = Math.max(
//         ranges[i]![HIGHLIGHT_RANGE_END],
//         ranges[j]![HIGHLIGHT_RANGE_END],
//       );
//       ranges.splice(i, 1);
//     } else {
//       i++;
//       j++;
//     }
//   }

//   if (ranges.length === 0) ranges = calculateRanges(cellContext);
//   return ranges;
// }
