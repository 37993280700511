import React, { useCallback, useEffect, useRef, useState } from "react";

import "@reach/menu-button/styles.css";
import { TooltipContent, TooltipPortal } from "@radix-ui/react-tooltip";
import { TextSearch } from "lucide-react";
import { observer } from "mobx-react-lite";

import { ApiUrls, IData } from "~/api/ApiPaths";
import CreateListForm from "~/components/CreateListForm";
import ListManagerLoader from "~/components/ListManagerLoader";
import ListFilter from "~/components/lists/ListSelector/ListFilter";
import ListFilterTooltip from "~/components/lists/ListSelector/ListFilterTooltip";
import ListSelector from "~/components/lists/ListSelector/ListSelector";
import { PaddingWrap } from "~/components/SharedLayoutComponents";
import { SelectOption } from "~/components/ui/select";
import {
  Tooltip,
  TooltipProvider,
  TooltipTrigger,
} from "~/components/ui/tooltip";
import { Badge } from "~/components/ui-rework/badge";
import { Button } from "~/components/ui-rework/button";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "~/components/ui-rework/dialog";
import {
  Sheet,
  SheetContent,
  SheetHeader,
  SheetTrigger,
  SheetTitle,
} from "~/components/ui-rework/sheet";
import useAxios from "~/hooks/useAxios";
import useOverrides from "~/hooks/useOverrides";
import EmptyStateGraphic from "~/images/empty-state.svg";
import { IAvailableList } from "~/models/ListManager";
import { useStore as useRootStore } from "~/models/Root";

export type Program = {
  id: number;
  value: string;
  label: string;
};

//We still need drop down reset. If partner context selector changes reset our list dropdown and clear list display
const ListManagerRoute: React.FC = observer(() => {
  const apiFetched = useRef(false);
  const { data, error, fetch, requestUrl, authToken, resetFetchedData } =
    useAxios({ method: "GET", initialValue: null });

  const { getAuthToken, getAppUser } = useRootStore();
  const [allLists, setAllLists] = useState<IAvailableList[]>([]);
  const [filteredLists, setFilteredLists] = useState<IAvailableList[]>([]);
  const [menuItems, setMenuItems] = useState<SelectOption[]>([]);
  const [selectedListId, setSelectedListId] = useState<number | null>(null);
  const [appUser] = useState(getAppUser());
  const { PartnerLabel } = useOverrides();
  const partnerLabel = PartnerLabel();
  const newlyCreatedListId = useRef<number | null>(null);
  const [open, setOpen] = useState(false);
  const [filterOpen, setFilterOpen] = useState(false);
  const [uniqueMappedPrograms, setUniqueMappedPrograms] = useState<Program[]>(
    [],
  );
  const [formValues, setFormValues] = useState(null);
  const [numberOfFilters, setNumberOfFilters] = useState(0);

  const filterLists = useCallback(() => {
    const filteredListsSelect: SelectOption[] = [];
    const filteredListsFullData: IAvailableList[] = [];
    const uniqueMappedPrograms: Program[] = [];
    uniqueMappedPrograms.push({
      id: 0,
      label: "No Assigned Program",
      value: "No Assigned Program",
    });
    allLists.forEach((item: IAvailableList) => {
      // 0 at agency level is global for all, then get global/specific partnerId level lists
      if (
        item.partner_id === appUser.selected_partner_id ||
        item.partner_id === 0 ||
        item.agency_id === 0
      ) {
        filteredListsSelect.push({
          value: `${item.id}`,
          label: item.name,
        });
        filteredListsFullData.push({
          id: item.id,
          name: item.name,
          list_type: item.list_type,
          is_global_list: item.is_global_list,
          is_active: item.is_active,
          agency_id: item.agency_id,
          partner_id: item.partner_id,
          program_id: item.program_id,
          course_id: item.course_id,
          created_at: item.created_at,
          created_by_id: item.created_by_id,
          updated_at: item.updated_at,
          updated_by_id: item.updated_by_id,
          description: item.description,
          mapped_programs: item.mapped_programs,
        });
        if (item.mapped_programs) {
          item.mapped_programs.forEach((program: any) => {
            if (!uniqueMappedPrograms.find((p) => p.id === program.id)) {
              uniqueMappedPrograms.push({
                id: program.id,
                label: program.display_name,
                value: program.display_name,
              });
            }
          });
        }
      }
    });
    setMenuItems(filteredListsSelect);
    setFilteredLists(filteredListsFullData);
    setUniqueMappedPrograms(uniqueMappedPrograms);
  }, [allLists, appUser]);

  //if selected partner id changes, trigger useEffect and filter lists for new partner id
  useEffect(() => {
    if (allLists.length > 0) {
      setFilterOpen(false);
      filterLists();
      setSelectedListId(
        newlyCreatedListId.current ? newlyCreatedListId.current : null,
      );
      //newlyCreatedListId.current = null
    }
  }, [appUser.selected_partner_id, allLists, filterLists]);

  useEffect(() => {
    if (newlyCreatedListId.current) {
      newlyCreatedListId.current = null;
    }
  }, [selectedListId]);

  //fetch and set lists
  useEffect(() => {
    const Data = data as IData;
    if (!apiFetched.current && appUser.selected_partner_id !== 0) {
      apiFetched.current = true;
      //Only plugging in agency value, this gives all lists we have access to, then filter on partner
      //This is done so we only have to call Lists once from the db and then filter on the frontend instead
      const agency_id = appUser.agency_id;
      requestUrl.current = `${ApiUrls.lists.getListsList(agency_id, 0)}`;
      authToken.current = getAuthToken();
      fetch();
    }
    if (Data && Data.issuccess && menuItems.length === 0) {
      setAllLists(data.entity);
      filterLists();
    }
  }, [
    data,
    menuItems,
    fetch,
    requestUrl,
    authToken,
    getAuthToken,
    appUser,
    filterLists,
  ]);

  const onCreateSuccess = (newListId?: number) => {
    //console.log('onListCreationSuccess called at listRoute', newListId)
    resetFetchedData();
    apiFetched.current = false;
    setMenuItems([]);
    setOpen(false);
    if (newListId) {
      newlyCreatedListId.current = newListId;
    }
  };

  useEffect(() => {
    if (selectedListId !== null && selectedListId !== -1) {
      setFilterOpen(false);
    }
  }, [selectedListId]);

  return (
    <PaddingWrap className="overflow-hidden bg-app-background-blue !w-full !p-0 flex-grow light">
      <title>Lists - Align App</title>
      {appUser.selected_partner_id === 0 ? (
        <div className="h-full flex flex-row gap-9 px-24 justify-center items-center">
          <img
            alt="List Page Graphic"
            src={EmptyStateGraphic}
            className="w-[632px] h-[632px] pointer-events-none select-none"
          />
          <div className="flex flex-col gap-4 justify-center max-w-fit">
            <h3 className="text-lg font-bold text-idesign-navy-100">
              Select a {partnerLabel}
            </h3>
            <p className="text-base font-normal text-idesign-navy-100 w-[415px]">
              Select a partner to view and edit lists
            </p>
          </div>
        </div>
      ) : (
        <div className="flex flex-grow h-full">
          <>
            <div className="!w-full flex flex-col items-center overflow-y-auto">
              {error === "" &&
                menuItems &&
                menuItems.length !== 0 &&
                (selectedListId === null || selectedListId === -1) && (
                  <>
                    <div className="w-full flex-row my-2 px-5 items-center text-base justify-between">
                      <Sheet>
                        <SheetTrigger asChild>
                          <div className="relative">
                            <TooltipProvider>
                              <Tooltip delayDuration={0}>
                                <div className="flex items-center">
                                  {numberOfFilters > 0 ? (
                                    <TooltipTrigger asChild>
                                      <Button
                                        variant="outline"
                                        onClick={() =>
                                          setFilterOpen(!filterOpen)
                                        }
                                        testid="lists-filter"
                                      >
                                        <TextSearch className="mr-2" />
                                        Filter
                                        <Badge
                                          className="ml-2 bg-ocean-100 text-white hover:bg-ocean-100"
                                          variant="default"
                                        >
                                          {numberOfFilters}
                                        </Badge>
                                      </Button>
                                    </TooltipTrigger>
                                  ) : (
                                    <Button
                                      variant="outline"
                                      onClick={() => setFilterOpen(!filterOpen)}
                                      testid="lists-filter"
                                    >
                                      <TextSearch className="mr-2" />
                                      Filter
                                    </Button>
                                  )}
                                  <TooltipPortal>
                                    <TooltipContent
                                      side="bottom"
                                      align="start"
                                      className="z-50 mt-1 rounded-md border border-ocean-70 shadow-two"
                                    >
                                      <ListFilterTooltip
                                        filterFormValues={formValues}
                                      />
                                    </TooltipContent>
                                  </TooltipPortal>
                                </div>
                              </Tooltip>
                            </TooltipProvider>
                          </div>
                        </SheetTrigger>
                        <SheetContent
                          side="left"
                          className="light w-1/4 min-w-[350px] bg-gradient-one px-0"
                        >
                          <SheetHeader>
                            <SheetTitle className="pl-4">Filter</SheetTitle>
                          </SheetHeader>
                          <ListFilter
                            setNumberOfFilters={setNumberOfFilters}
                            mappedPrograms={uniqueMappedPrograms}
                            setFormValues={setFormValues}
                            formValues={formValues}
                          />
                        </SheetContent>
                      </Sheet>
                      <Dialog open={open} onOpenChange={setOpen}>
                        <DialogTrigger asChild>
                          <Button
                            className="mr-4"
                            variant="primary"
                            testid="lists-create"
                          >
                            <span
                              className="material-icons mr-1 text-[1.2rem]"
                              aria-hidden="true"
                            >
                              note_add
                            </span>
                            Create a List
                          </Button>
                        </DialogTrigger>
                        <DialogContent className="light">
                          <DialogHeader>
                            <DialogTitle>Create a New List</DialogTitle>
                          </DialogHeader>
                          <CreateListForm
                            handleSuccess={(newId?: number) =>
                              onCreateSuccess(newId)
                            }
                          />
                        </DialogContent>
                      </Dialog>
                    </div>
                    <ListSelector
                      lists={filteredLists}
                      setSelectedListId={setSelectedListId}
                      filterFormValues={formValues}
                    />
                  </>
                )}
              {error !== "" && <span>{JSON.stringify(error)}</span>}
              {selectedListId !== null && selectedListId !== -1 && (
                <div className="w-full overflow-auto">
                  <ListManagerLoader
                    listId={selectedListId}
                    setSelectedListId={setSelectedListId}
                  />
                </div>
              )}
            </div>
          </>
        </div>
      )}
    </PaddingWrap>
  );
});

export default ListManagerRoute;
