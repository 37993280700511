import React from "react";

import { Form } from "formik";

import AlignFormikTextInput from "~/components/AlignFormikTextInput";
import { Button } from "~/components/ui/button";

const AddUser: React.FC = () => {
  return (
    <Form className="add-form">
      <h5 className="info-heading">User Information</h5>
      <AlignFormikTextInput
        label="First Name"
        name="firstName"
        placeholder="First Name"
        materialIconName="person_outline"
        iconAlignment="left"
      />
      <AlignFormikTextInput
        label="Last Name"
        name="lastName"
        placeholder="Last Name"
        materialIconName="person_outline"
        iconAlignment="left"
      />
      <AlignFormikTextInput
        label="Email address"
        name="email"
        type="email"
        placeholder="Email address"
        materialIconName="mail_outline"
        iconAlignment="left"
      />
      <div className="button-container">
        <Button
          type="submit"
          className="text-white bg-button-blue hover:bg-align-blue rounded-full"
        >
          Add User
        </Button>
      </div>
    </Form>
  );
};

export default AddUser;
