import React, { useEffect, useRef } from "react";

import { Form, useFormikContext } from "formik";

import AlignFormikTextInput from "~/components/AlignFormikTextInput";
import { Button } from "~/components/ui/button";
import useOverrides from "~/hooks/useOverrides";

interface IAddNewProgram {
  programDetailsData: any | null;
}

const AddNewProgram: React.FC<IAddNewProgram> = (props) => {
  const { programDetailsData } = props;
  const { ProgramLabel } = useOverrides();
  const programLabel = ProgramLabel();
  const resetFromNow = useRef(false);

  const ResetFormHook = () => {
    const { resetForm } = useFormikContext();
    useEffect(() => {
      if (resetFromNow.current) {
        resetFromNow.current = false;
        resetForm();
      }
    }, [resetForm]);
    return null;
  };

  return (
    <Form>
      <h5 style={{ marginBottom: "0.35rem" }} className="info-heading">
        Create a New Program
      </h5>
      <p style={{ margin: 0, marginBottom: "0.85rem" }}>
        You're creating a {programLabel} for {programDetailsData?.short_name}.
        Please enter the {programLabel} information below.
      </p>
      <AlignFormikTextInput
        label={`${programLabel} Code (i.e. MHA)`}
        name="programCode"
        placeholder={`${programLabel} Code`}
        materialIconName="domain"
        iconAlignment="left"
      />
      <AlignFormikTextInput
        label={`${programLabel} Title (i.e. Masters of Health Administration)`}
        name="programTitle"
        placeholder={`${programLabel} Title`}
        materialIconName="domain"
        iconAlignment="left"
      />
      <div className="button-container">
        <Button
          type="submit"
          className="text-white bg-button-blue hover:bg-align-blue rounded-full "
        >
          Add New {programLabel}
        </Button>
      </div>
      <ResetFormHook />
    </Form>
  );
};

export default AddNewProgram;
