import React from "react";

import * as ReportModels from "~/components/Reports/ReportModels";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "~/components/ui/table";
import useOverrides from "~/hooks/useOverrides";

const CourseModuleDetail: React.FC<{
  module: ReportModels.Module;
  list_id: number;
  fromAlignmentGrid: boolean;
  activityIDDisplay?: number[];
}> = ({ module, list_id, fromAlignmentGrid, activityIDDisplay }) => {
  const getActivities = (
    module: ReportModels.Module,
    activityIDDisplay?: number[],
  ) => {
    if (activityIDDisplay && activityIDDisplay.length > 0) {
      const activities = module.activities?.filter((x) =>
        activityIDDisplay.includes(x.id),
      );
      return activities;
    } else {
      return module.activities;
    }
  };
  const activities = getActivities(module, activityIDDisplay);

  const totalMinutes = activities.reduce(
    (acc, curr) => acc + curr.tot_minutes,
    0,
  );
  const totalMinutesDisplay = ReportModels.formatMinutes(totalMinutes);
  const moduleMappings =
    module.mappings?.filter((x) => x.list_id === list_id) || [];
  const activityMappings =
    module.activities?.flatMap((a) =>
      a.mappings.filter((x) => x.list_id === list_id),
    ) || [];
  const mappings = moduleMappings.concat(activityMappings.flat());
  const uniqueMappingNames = Array.from(
    new Set(mappings.map((x) => x.listitem_display_name)),
  );
  const { ActivitiesLabel } = useOverrides();
  const activitiesLabel = ActivitiesLabel();

  interface IKvp {
    key: string;
    value: number;
  }

  const mappingNamesCount: IKvp[] = [];

  uniqueMappingNames.forEach((name) => {
    const count = mappings.filter(
      (x) => x.listitem_display_name === name,
    ).length;
    mappingNamesCount.push({ key: name, value: count });
  });

  return (
    <>
      {!fromAlignmentGrid && (
        <div className="my-2">
          <h4 className="mb-1">Mappings: {mappings.length}</h4>
          <ul className="list-disc list-inside">
            {mappingNamesCount.map((kvp) => (
              <li key={kvp.key} className="text-foreground ml-2">
                <span>{kvp.key}</span>&nbsp;
                <span className="ml-1">
                  {kvp.value > 1 ? `(${kvp.value} mappings)` : ""}
                </span>
              </li>
            ))}
          </ul>
        </div>
      )}
      <div className="light h-full overflow-auto rounded-md border border-idesign-navy-120 bg-white">
        <Table className="bg-white w-full rounded-md overflow-hidden border-collapse border-spacing-0">
          <TableHeader>
            <TableRow
              role="row"
              className="bg-gradient-1 border-b border-idesign-navy-120"
            >
              <TableHead
                colSpan={5}
                role="gridcell"
                className="text-white font-bold whitespace-nowrap"
              >
                Learning {activitiesLabel}
              </TableHead>
            </TableRow>
            <TableRow
              role="row"
              className="bg-gradient-1 border-b border-idesign-navy-120"
            >
              <TableHead className="text-white font-bold whitespace-nowrap">
                Type
              </TableHead>
              <TableHead className="text-white border-x border-idesign-navy-120 font-bold whitespace-nowrap">
                Name
              </TableHead>
              <TableHead className="text-white font-bold whitespace-nowrap">
                Description
              </TableHead>
              <TableHead className="text-white border-x border-idesign-navy-120 font-bold whitespace-nowrap text-center">
                Points
              </TableHead>
              <TableHead className="text-white font-bold whitespace-nowrap text-center">
                Time
              </TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {activities.map((x) => (
              <TableRow
                role="row"
                className={"border-y border-idesign-navy-120 hover:bg-ocean-10"}
                key={x.id}
              >
                <TableCell className="font-bold w-[120px] px-2 py-4">
                  {`${x.activity_type
                    .charAt(0)
                    .toUpperCase()}${x.activity_type.slice(1)}`}
                </TableCell>
                <TableCell className="border-x border-idesign-navy-120">
                  {x.display_name}
                </TableCell>
                <TableCell>{x.description}</TableCell>
                <TableCell className="text-center w-[120px] border-x border-idesign-navy-120">
                  {x.grading_points}
                </TableCell>
                <TableCell className="center w-[120px]">
                  {ReportModels.formatMinutes(x.tot_minutes)}
                </TableCell>
              </TableRow>
            ))}
            <TableRow className="!border-t-8 border-ocean-120">
              <TableCell className="pl-5 py-2 font-bold uppercase border-idesign-navy-120">
                Total
              </TableCell>
              <TableCell className="text-center border-x border-idesign-navy-120"></TableCell>
              <TableCell></TableCell>

              <TableCell className="text-center border-x border-idesign-navy-120">
                {activities.reduce((acc, curr) => acc + curr.grading_points, 0)}
              </TableCell>
              <TableCell className="text-center">
                {totalMinutesDisplay}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </div>
    </>
  );
};

export default CourseModuleDetail;
