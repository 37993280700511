import React, { useEffect, createContext, useRef } from "react";

import axios, { AxiosInstance, AxiosRequestConfig } from "axios";

interface IAxiosRequestConfig extends AxiosRequestConfig {
  baseURL: string;
  method?: string;
  url?: string;
  headers?: object;
  data?: object;
}

interface IAxiosInstanceProvider {
  config: IAxiosRequestConfig;
  requestInterceptors?: [];
  responseInterceptors?: [];
  children: JSX.Element[] | JSX.Element;
}

export const AxiosContext = createContext<AxiosInstance | null>(null);

const AxiosInstanceProvider = (props: IAxiosInstanceProvider) => {
  const { config, requestInterceptors, responseInterceptors, children } = props;
  const instanceRef = useRef(axios.create(config));
  //this prevents a known bug in react that makes useEffect hooks run twice everywhere
  //they say it is only in Dev mode, but regardless, if not corrected here
  // it get very annoying to deal with double renders!
  const runOnce = useRef(true);

  useEffect(() => {
    if (runOnce) {
      runOnce.current = false;
      requestInterceptors &&
        requestInterceptors.forEach((interceptor) => {
          instanceRef.current.interceptors.request.use(interceptor);
        });
      responseInterceptors &&
        responseInterceptors.forEach((interceptor) => {
          instanceRef.current.interceptors.response.use(interceptor);
        });
    }
  }, [requestInterceptors, responseInterceptors]);

  return (
    <AxiosContext.Provider value={instanceRef.current}>
      {children}
    </AxiosContext.Provider>
  );
};

export default AxiosInstanceProvider;
