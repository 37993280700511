import React from "react";

import { Sparkles } from "lucide-react";

import { useAIListItemsSuggestionsQuery } from "~/components/program/mapping/hooks/useAIListItemSuggestionsQuery";
import { MappingAssistantLoading } from "~/components/program/mapping/MappingAssistantLoading";
import { MappingAssistantRecommendations } from "~/components/program/mapping/MappingAssistantRecommendations";
import { Button } from "~/components/ui-rework/button";
import EmptyStateGraphic from "~/images/AI-Mapping-Empty-State.svg";

export const MappingAssistant = () => {
  const { refetch, isFetching, data } = useAIListItemsSuggestionsQuery();

  if (isFetching) return <MappingAssistantLoading />;

  if (data) return <MappingAssistantRecommendations />;

  return (
    <div className="flex flex-1 flex-col items-center light">
      <div className="flex flex-row gap-9 px-4 pt-24 items-center justify-center">
        <img
          className="pointer-events-none select-none w-[632px] h-[632px]"
          alt="Mapping Assistant"
          src={EmptyStateGraphic}
        />
        <div className="flex flex-col gap-4 justify-center">
          <h3 className="text-xl text-idesign-navy-100">
            Run Mapping Assistant
          </h3>
          <p className="text-base text-idesign-navy-100 max-w-64">
            The Mapping Assistant suggests optimal mappings for your selected
            curriculum and list, along with clear reasoning, and allows you to
            effortlessly accept or decline each recommendation.
          </p>
          <Button variant="primary" className="w-fit" onClick={() => refetch()}>
            <div className="flex gap-2 font-normal">
              <Sparkles />
              <span>Run Mapping Assistant</span>
            </div>
          </Button>
        </div>
      </div>
    </div>
  );
};
