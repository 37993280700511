import React from "react";

import { useFormikContext } from "formik";

import AlignFormikTextInput from "~/components/AlignFormikTextInput";
import { Button } from "~/components/ui/button";
import useOverrides from "~/hooks/useOverrides";

interface IPartnerDetails {
  partnerDetailsData: any | null;
  revertFormValues: IPartnerDetailsForm;
  editMode: boolean;
  setEditMode: React.Dispatch<React.SetStateAction<boolean>>;
}

export interface IPartnerDetailsForm {
  displayName: string;
  shortName: string;
}

const PartnerDetails: React.FC<IPartnerDetails> = (props) => {
  const { partnerDetailsData, editMode, setEditMode, revertFormValues } = props;
  const { setFieldValue, submitForm } = useFormikContext();
  const { PartnerLabel } = useOverrides();
  const partnerLabel = PartnerLabel();
  const revertChanges = () => {
    setFieldValue("displayName", revertFormValues.displayName);
    setFieldValue("shortName", revertFormValues.shortName);
  };

  return (
    <div className="ml-2 h-full">
      <AlignFormikTextInput
        label="Display Name"
        name="displayName"
        placeholder="Display Name"
        materialIconName="person_outline"
        iconAlignment="left"
        disabled={editMode ? false : true}
      />
      <AlignFormikTextInput
        label="Short Name"
        name="shortName"
        placeholder="Short Name"
        materialIconName="person_outline"
        iconAlignment="left"
        disabled={editMode ? false : true}
      />
      <span className="font-bold">{partnerLabel} Type:</span>
      <br />
      {partnerDetailsData?.partner_type}
      {editMode && (
        <div
          className="button-container"
          style={{
            display: "flex",
            justifyContent: "flex-end",
            flexDirection: "row",
          }}
        >
          <Button
            type="button"
            variant="destructive"
            className="hover:bg-red-800 rounded-full mt-3 mr-3"
            onClick={() => {
              setEditMode(false);
              revertChanges();
            }}
          >
            Cancel Changes
          </Button>
          <Button
            variant="blue"
            className="mt-3"
            type="submit"
            onClick={() => {
              submitForm();
            }}
            testid="admin-save-changes"
          >
            Save Changes
          </Button>
        </div>
      )}
    </div>
  );
};

export default PartnerDetails;
