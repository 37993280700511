import React, { useEffect, useRef } from "react";

import { Cell, Label, Pie, PieChart, ResponsiveContainer } from "recharts";

import useOverrides from "~/hooks/useOverrides";

const MappingDetailsMiniDashboard: React.FC<{
  numModulesMapped: number;
  numTotalModules: number;
  numUniqueMappings: number;
  totalTime: string;
}> = ({ numModulesMapped, numTotalModules, numUniqueMappings, totalTime }) => {
  const hours = totalTime.split(" ")[0];
  const minutes = totalTime.split(" ")[1];
  const pieLabel = `${numModulesMapped} / ${numTotalModules}`;
  const { ModulesLabel } = useOverrides();
  const modulesLabel = ModulesLabel();
  const data = [
    { value: numModulesMapped },
    { value: numTotalModules - numModulesMapped },
  ];

  const pieChartRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const observer = new MutationObserver((mutationsList, observer) => {
      for (const mutation of mutationsList) {
        if (mutation.type === "childList" && pieChartRef.current) {
          const gElements =
            pieChartRef.current.querySelectorAll('g[tabindex="0"]');
          gElements.forEach((g) => {
            g.removeAttribute("tabindex");
          });
        }
      }
    });
    if (pieChartRef.current) {
      observer.observe(pieChartRef.current, {
        attributes: false,
        childList: true,
        subtree: true,
      });
    }
    return () => observer.disconnect();
  }, []);

  return (
    <>
      <div className="flex mb-4">
        <div className="p-0 pl-9 pr-9 text-center flex-col justify-end text-foreground h-[150px]">
          <div className="h-[25px]" id="spacer"></div>
          <div className="h-[100px]">
            <h3 className="text-4xl">{minutes !== undefined ? hours : "0h"}</h3>
            <h3 className="text-4xl">
              {minutes !== undefined ? minutes : "0m"}
            </h3>
          </div>
          <p className="m-0 whitespace-nowrap h-[25px] font-bold text-foreground">
            Total Time on Task
          </p>
        </div>
        <div className="p-0 pl-9 pr-9 text-center flex-col justify-end border-l border-r border-idesign-navy-120 text-foreground h-[150px]">
          <ResponsiveContainer width="100%" height={125}>
            <PieChart>
              <defs>
                <linearGradient
                  id="pie"
                  x1="104.035"
                  y1="104.202"
                  x2="-0.404552"
                  y2="2.20143"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stopColor="#006185" />
                  <stop offset="1" stopColor="#0288BA" />
                </linearGradient>
              </defs>
              <Pie
                data={data}
                innerRadius={40}
                outerRadius={50}
                fill="#8884d8"
                paddingAngle={0}
                dataKey="value"
              >
                <Label
                  position="center"
                  className="text-xl fill-current"
                  value={pieLabel}
                />
                {data.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={index === 0 ? "url(#pie)" : "#E6EAEC"}
                    stroke="#B3C0C6"
                  />
                ))}
              </Pie>
            </PieChart>
          </ResponsiveContainer>
          <p className="m-0 whitespace-nowrap text-center h-[25px] font-bold text-foreground">
            {modulesLabel} Mapped
          </p>
        </div>
        <div className="p-0 pl-9 pr-9 text-center flex-col justify-end text-black h-[150px]">
          <div className="h-[25px]" id="spacer"></div>
          <h3 className="text-[75px] h-[100px]">{numUniqueMappings}</h3>
          <p className="whitespace-nowrap m-0 text-center h-[25px] font-bold text-foreground">
            Unique Mappings
          </p>
        </div>
      </div>
    </>
  );
};

export default MappingDetailsMiniDashboard;
