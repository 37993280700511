import * as React from "react";

import { VariantProps, cva } from "class-variance-authority";

import { cn } from "~/lib/utils";

const inputVariants = cva(
  cn(
    "flex w-full rounded-md border border-input bg-background text-foreground",
    "ring-offset-select-ring",
    "file:border-0 file:bg-transparent file:text-sm file:font-medium",
    "placeholder:text-muted-foreground",
    "focus:outline-none focus:ring-1 focus:ring-ring focus:ring-offset-4",
    "disabled:cursor-not-allowed disabled:outline-none disabled:placeholder:text-disable-foreground disabled:bg-disable disabled:text-disable-foreground",
  ),
  {
    variants: {
      variant: {
        default: "py-2.5 px-2 rounded-[10px] text-base",
      },
    },
    defaultVariants: {
      variant: "default",
    },
  },
);

type InputIconProps = {
  icon?: React.ReactNode;
  iconPosition?: "left" | "right";
};

export type InputProps = React.InputHTMLAttributes<HTMLInputElement> &
  VariantProps<typeof inputVariants> &
  InputIconProps;

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  (
    { className, type, variant, icon, iconPosition = "left", ...props },
    ref,
  ) => {
    const disabled = props.disabled;

    return (
      <>
        {icon ? (
          <div className="bg-background">
            <div className="relative inline-block w-full">
              {iconPosition !== "right" && (
                <span
                  className={cn(
                    "absolute left-2.5 top-1/2 -translate-y-1/2 transform text-muted-foreground",
                    {
                      "text-disable-foreground cursor-not-allowed z-10":
                        disabled,
                    },
                  )}
                >
                  {icon}
                </span>
              )}
              <input
                type={type}
                className={cn(
                  inputVariants({ variant }),
                  className,
                  iconPosition !== "right" ? "pl-11 pr-2" : "pl-2 pr-11",
                  "bg-transparent relative",
                  { "z-10": !disabled },
                )}
                ref={ref}
                {...props}
              />
              {iconPosition === "right" && (
                <span
                  className={cn(
                    "absolute left-auto right-2.5 top-1/2 -translate-y-1/2 transform text-muted-foreground",
                    {
                      "text-disable-foreground cursor-not-allowed z-10":
                        disabled,
                    },
                  )}
                >
                  {icon}
                </span>
              )}
            </div>
          </div>
        ) : (
          <input
            type={type}
            className={cn(inputVariants({ variant }), className)}
            ref={ref}
            {...props}
          />
        )}
      </>
    );
  },
);
Input.displayName = "Input";

export { Input };
