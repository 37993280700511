import React from "react";

import NodeIcon from "~/components/explorer/node/NodeIcon";
import { Card } from "~/components/ui/card";
import { useExplorerContext } from "~/context/ExplorerProvider";
import { NodeTypes } from "~/models/AlignEditor";

const ExplorerLegend = () => {
  const { getTypeLabel } = useExplorerContext();
  return (
    <Card className="absolute bottom-4 left-4 px-4 py-3 z-10 font-bold backdrop-blur-sm bg-white/70">
      <div className="flex flex-col gap-2">
        <div className="flex items-center gap-2">
          <NodeIcon type={NodeTypes.Program} />
          <span>{getTypeLabel(NodeTypes.Program)}</span>
        </div>
        <div className="flex items-center gap-2">
          <NodeIcon type={NodeTypes.Course} />
          <span>{getTypeLabel(NodeTypes.Course)}</span>
        </div>
        <div className="flex items-center gap-2">
          <NodeIcon type={NodeTypes.Module} />
          <span>{getTypeLabel(NodeTypes.Module)}</span>
        </div>
        <div className="flex items-center gap-2">
          <NodeIcon type={NodeTypes.Activity} />
          <span>{getTypeLabel(NodeTypes.Activity)}</span>
        </div>
      </div>
    </Card>
  );
};

export default ExplorerLegend;
