import React from "react";

import { BookOpen, Grid2X2, LibrarySquare, School } from "lucide-react";

import { cn } from "~/lib/utils";
import { NodeTypes } from "~/models/AlignEditor";

const GetNodeIcon = ({ type }: NodeIconProps) => {
  switch (type) {
    case NodeTypes.Program:
      return <School size="20" />;
    case NodeTypes.Course:
      return <LibrarySquare size="20" />;
    case NodeTypes.Module:
      return <Grid2X2 size="20" />;
    case NodeTypes.Activity:
      return <BookOpen size="20" />;
  }
};

type NodeIconProps = {
  type: NodeTypes;
};

const NodeIcon = ({ type }: NodeIconProps) => {
  return (
    <div
      className={cn(
        "flex justify-center items-center w-[29px] h-[29px] row-start-1 row-end-2 rounded-lg self-center",
        {
          "bg-neutral-100 text-white": type === NodeTypes.Program,
          "bg-info-100 text-white": type === NodeTypes.Course,
          "bg-info-20 text-neutral-100": type === NodeTypes.Module,
          "bg-neutral-10 text-neutral-100": type === NodeTypes.Activity,
        },
      )}
      style={{
        gridArea: "icon",
      }}
    >
      <GetNodeIcon type={type} />
    </div>
  );
};

export default NodeIcon;
