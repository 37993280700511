import React, { useEffect, useState } from "react";

import { ArrowRight } from "lucide-react";

import { ApiUrls } from "~/api/ApiPaths";
import { ListToListTable } from "~/components/Reports/ListToList/ListToListTable";
import { ProgramData } from "~/components/Reports/ReportModels";
import { Button } from "~/components/ui-rework/button";
import { Label } from "~/components/ui-rework/label";
import { Select, SelectOption } from "~/components/ui-rework/select";
import useAxios from "~/hooks/useAxios";
import EmptyStateGraphic from "~/images/List-To-List-Empty-State.svg";
import { useStore } from "~/models/Root";

type ListToListProps = {
  programData: ProgramData | null;
};

export const ListToList = (programData: ListToListProps) => {
  const [fetchSelectData, setFetchSelectData] = useState(true);
  const [loadSelectValues, setLoadSelectValues] = useState(true);
  const [relationshipData, setRelationshipData] = useState<any>(null);
  const [fromSelectData, setFromSelectData] = useState<SelectOption[]>([]);
  const [toSelectData, setToSelectData] = useState<SelectOption[]>([]);
  const [selectedFrom, setSelectedFrom] = useState<string | undefined>("");
  const [selectedTo, setSelectedTo] = useState<string | undefined>("");
  const { getAuthToken } = useStore();
  const program_id = programData?.programData?.id || 0;
  const { data, authToken, error, requestUrl, fetch, resetAll } = useAxios({
    method: "GET",
    initialValue: null,
  });
  const [readyButton, setReadyButton] = useState(false);
  const [selectedRelationship, setSelectedRelationship] = useState<string>("");
  const [loading, setLoading] = useState(false);
  const [listToListDataFinished, setListToListDataFinished] = useState(false);
  const [listToListData, setListToListData] = useState<any>();
  const [toSelectKey, setToSelectKey] = useState(+new Date());
  const [fromSelectKey, setFromSelectKey] = useState(+new Date() + 1);
  const [tableReady, setTableReady] = useState(false);
  const [buttonPressed, setButtonPressed] = useState(false);
  // const [disabledTo, setDisabledTo] = useState(false);
  // const [disabledFrom, setDisabledFrom] = useState(false);

  const resetLists = () => {
    resetAll();
    setListToListDataFinished(false);
    setListToListData(null);
    setTableReady(false);
    setToSelectKey(+new Date());
    setFromSelectKey(+new Date() + 1);
    setReadyButton(false);
    setSelectedFrom(undefined);
    setSelectedTo(undefined);
    setButtonPressed(false);
  };

  useEffect(() => {
    if (data?.issuccess && loadSelectValues) {
      setLoadSelectValues(false);
      const fromSelect: SelectOption[] = [];
      data.entity.forEach((item: any) => {
        if (!fromSelect.some((i) => i.label === item.from_list_name)) {
          fromSelect.push({
            label: item.from_list_name,
            value: item.from_list_id,
          });
        }
      });
      setFromSelectData(fromSelect);
      setRelationshipData(data.entity);
      if (fromSelect.length === 1) {
        handleFromChange(
          "list-selector-from",
          fromSelect[0].value,
          data.entity,
        );
        // setDisabledFrom(true);
      }
    } else if (fetchSelectData) {
      setFetchSelectData(false);
      requestUrl.current = `${ApiUrls.listtolist.getRelationshipsByProgram(
        program_id,
      )}`;
      authToken.current = getAuthToken();
      fetch();
    } else if (error) {
      console.log(error);
    }
  }, [
    data,
    error,
    fetch,
    fetchSelectData,
    getAuthToken,
    loadSelectValues,
    program_id,
    requestUrl,
    authToken,
  ]);

  useEffect(() => {
    if (selectedFrom && selectedTo) {
      setReadyButton(true);
      relationshipData.forEach((item: any) => {
        if (
          item.from_list_id === selectedFrom &&
          item.to_list_id === selectedTo
        ) {
          setSelectedRelationship(item.id);
        }
      });
    }
  }, [selectedFrom, selectedTo]);

  useEffect(() => {
    if (
      data?.message === "List to list data successful" &&
      !listToListDataFinished &&
      buttonPressed
    ) {
      setListToListData(null);
      setButtonPressed(false);
      setListToListDataFinished(true);
      setListToListData(data.entity);
      setTableReady(true);
    } else if (error) {
      console.log(error);
    }
  }, [
    data,
    error,
    fetch,
    getAuthToken,
    listToListDataFinished,
    program_id,
    requestUrl,
    selectedRelationship,
    buttonPressed,
  ]);

  const handleFromChange = (
    _fieldName: string,
    newValue: string,
    relData = relationshipData,
  ) => {
    setSelectedTo(undefined);
    setSelectedFrom(undefined);
    // setDisabledTo(false);
    setToSelectKey(+new Date());
    // setFromSelectKey(+new Date() + 1);
    setReadyButton(false);
    const toSelect: SelectOption[] = [];
    relData.forEach((item: any) => {
      if (
        item.from_list_id === newValue &&
        !toSelect.some((i) => i.label === item.to_list_name)
      ) {
        toSelect.push({
          label: item.to_list_name,
          value: item.to_list_id,
        });
      }
    });
    setToSelectData(toSelect);
    setSelectedFrom(newValue);

    if (toSelect.length === 1) {
      handleToChange("list-selector-to", toSelect[0].value);
      // setDisabledTo(true);
    }
  };

  const handleToChange = (_fieldName: string, newValue: string) => {
    setSelectedTo(newValue);
  };

  return (
    <>
      {!tableReady ? (
        <div className="h-full justify-center flex flex-1 flex-col items-center light">
          <div className="flex flex-row gap-12 px-20 justify-center">
            {!loading ? (
              <>
                <div className="w-[632px] h-[632px] flex items-center justify-center">
                  <img
                    className="pointer-events-none select-none"
                    alt="List-to-List Graphic"
                    src={EmptyStateGraphic}
                  />
                </div>

                <div className="flex flex-col gap-6 justify-center max-w-fit">
                  <div className="flex flex-col gap-4">
                    <h3 className="text-xl text-idesign-navy-100">
                      Select Lists
                    </h3>
                    <p className="text-base text-idesign-navy-100 w-[416px]">
                      Select two lists to view alignment between items, such as
                      a course learning outcome to an accreditation standard.
                      Your selection for the first list will determine which
                      options are available for the second list.
                    </p>
                  </div>
                  <div className="py-2.5">
                    <Label>List 1</Label>
                    <Select
                      key={fromSelectKey}
                      fieldName="list-selector-from"
                      placeholder={
                        fromSelectData.length
                          ? "Select a list..."
                          : "No list to list relationships available"
                      }
                      options={fromSelectData}
                      handleChange={handleFromChange}
                      testid="list-to-list-from-select"
                      value={selectedFrom}
                      disabled={!fromSelectData.length || loading}
                    />
                  </div>
                  <div className="py-2.5">
                    <Label>List 2</Label>
                    <Select
                      key={toSelectKey}
                      fieldName="list-selector-to"
                      placeholder={
                        selectedFrom
                          ? "Select a list..."
                          : !fromSelectData.length
                          ? "No list to list relationships available"
                          : "Select a list from the top first..."
                      }
                      options={toSelectData}
                      handleChange={handleToChange}
                      testid="list-to-list-to-select"
                      value={selectedTo}
                      disabled={!selectedFrom || loading}
                    />
                  </div>
                  <Button
                    variant="primary"
                    className={`w-fit ${readyButton ? "bg-gradient-1" : ""}`}
                    disabled={!readyButton}
                    onClick={() => {
                      setLoading(true);
                      setButtonPressed(true);
                      requestUrl.current = `${ApiUrls.listtolist.getRelationshipDetails(
                        program_id,
                        selectedRelationship,
                      )}`;
                      authToken.current = getAuthToken();
                      fetch();
                    }}
                  >
                    <div className="flex gap-2 font-normal">
                      <span>View list relationships</span>
                      <ArrowRight className="w-6 h-6" />
                    </div>
                  </Button>
                </div>
              </>
            ) : (
              <div className="flex flex-col items-center p-6">
                <div className="flex justify-center items-center gap-6 pb-10">
                  <span
                    style={{ animationDelay: "0ms" }}
                    className="animate-blink h-4 w-4 rounded-full bg-ocean-100"
                  />
                  <span
                    style={{ animationDelay: "150ms" }}
                    className="animate-blink h-4 w-4 rounded-full bg-ocean-100"
                  />
                  <span
                    style={{ animationDelay: "300ms" }}
                    className="animate-blink h-4 w-4 rounded-full bg-ocean-100"
                  />
                  <span
                    style={{ animationDelay: "450ms" }}
                    className="animate-blink h-4 w-4 rounded-full bg-ocean-100"
                  />
                </div>
                <Label className="mb-6" type="bold">
                  Results are on the way
                </Label>
                <p className="center">
                  Studio is fetching the mapping relationships
                  <br />
                  Note that the longer list will appear on the y-axis
                </p>
              </div>
            )}
          </div>
        </div>
      ) : null}
      {listToListDataFinished && listToListData ? (
        <ListToListTable
          relationshipData={listToListData}
          setLoading={setLoading}
          resetLists={resetLists}
        />
      ) : null}
    </>
  );
};
