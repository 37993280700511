import React from "react";

import classNames from "classnames";

type ButtonSize = "small" | "medium";
type ButtonSizesType = {
  [key in ButtonSize]: {
    textSize: string;
    iconSize: string;
  };
};
const ButtonSizes: ButtonSizesType = {
  small: {
    textSize: "text-[14px]",
    iconSize: "text-[20px]",
  },
  medium: {
    textSize: "text-[16px]",
    iconSize: "text-[20px]",
  },
} as const;

type AlignButtonProps = {
  alignRight?: boolean;
  leftIcon?: string;
  rightIcon?: string;
  label?: string;
  onClick?: (event?: React.MouseEvent<HTMLButtonElement>) => void;
  style?: React.CSSProperties;
  type?: "button" | "submit" | "reset" | undefined;
  disable?: boolean;
  className?: string;
  size?: ButtonSize;
  testid?: string;
};

/**
 * @description A button that is styled using the iDesign webflow styles, use this whenever you need to use a button which is also accessible.
 * @param alignRight:boolean optional Used to make the whole button align right, may need to have a flex parent
 * @param leftIcon:string optional Pass the name of a material design icon here and it will be used and displayed within the button.
 * @param rightIcon:string optional Pass the name of a material design icon here and it will be used and displayed within the button.
 * @param label:string optional The button label
 * @param onClick:(e:Synthetic event)=>void optional handler triggered then button is clicked
 * @param style:React.CSSProperties optional
 * @param type:string optional either 'button'|'submit'|'reset'| undefined defining the button type.
 * @param iconAlignment:icon goes on left side of label or right side
 * @param disable:disable the button
 * @param className:string optional
 * @returns styled button instance component
 */

const AlignButton = ({
  alignRight,
  leftIcon,
  rightIcon,
  label,
  onClick,
  style, //TODO: remove this prop. Passing style to a design-system component is an anti-pattern.
  type,
  disable = false,
  className = "", //TODO: remove this prop. Passing className to a design-system component is an anti-pattern.
  size = "small",
  testid,
}: AlignButtonProps) => {
  const { textSize: fontSize, iconSize } = ButtonSizes[size];

  return (
    <button
      onClick={(event) => {
        onClick && !disable && onClick(event);
      }}
      className={classNames(`flex w-fit min-w-[5rem] ${className}`, {
        "ml-auto": alignRight,
        "max-w-full button small align-outline": className === "",
      })}
      style={style}
      type={type}
      data-testid={testid}
    >
      <div
        className={classNames("w-full flex justify-center items-center", {
          "opacity-50 cursor-not-allowed": disable,
        })}
      >
        {leftIcon && (
          <div
            className={`material-icons pr-1.5 ${iconSize}`}
            aria-hidden="true"
          >
            {leftIcon}
          </div>
        )}
        {label && (
          <div className={`font-medium ${fontSize}`} aria-hidden="true">
            {label}
          </div>
        )}
        {rightIcon && (
          <div
            className={`material-icons pl-1.5 ${iconSize}`}
            aria-hidden="true"
          >
            {rightIcon}
          </div>
        )}
      </div>
    </button>
  );
};

export default AlignButton;
