/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React from "react";

import { CellContext } from "@tanstack/react-table";
import { BookText, LibrarySquare } from "lucide-react";

import {
  HIGHLIGHT_RANGE_END,
  HIGHLIGHT_RANGE_START,
  HighlightRange,
  getHighlightRanges,
} from "~/components/program/mapping/utils/filtersWithHighlighting";
import { Label } from "~/components/ui/label";
import { cn } from "~/lib/utils";
import { ItemRow } from "~/models/AlignEditor";

function splitNewlines(value: string) {
  return value
    .split("\n")
    .map((line, index) => [
      index > 0 ? <br key={`br-${index}`} /> : undefined,
      line,
    ]);
}

function splitHighlights(
  value: string,
  ranges: HighlightRange[],
  ignoreNewlines = false,
) {
  const handleRange = ignoreNewlines ? (range: string) => range : splitNewlines;
  const lastRange = value.slice(
    ranges.length > 0 ? ranges[ranges.length - 1]![HIGHLIGHT_RANGE_END] : 0,
  );
  return [
    ...ranges.map((range, index, array) => {
      const spanRange = value.slice(
        index > 0 ? array[index - 1]![HIGHLIGHT_RANGE_END] : 0,
        range[HIGHLIGHT_RANGE_START],
      );
      const markRange = value.slice(
        range[HIGHLIGHT_RANGE_START],
        range[HIGHLIGHT_RANGE_END],
      );
      return [
        <span
          key={`span-${range[HIGHLIGHT_RANGE_START]}-${range[HIGHLIGHT_RANGE_END]}`}
        >
          {handleRange(spanRange)}
        </span>,
        <mark
          key={`mark-${range[HIGHLIGHT_RANGE_START]}-${range[HIGHLIGHT_RANGE_END]}`}
          className="bg-cerulean-30"
        >
          {handleRange(markRange)}
        </mark>,
      ];
    }),
    <span key="span-last">{handleRange(lastRange)}</span>,
  ];
}

export const HighlightableCell = (props: CellContext<ItemRow, string>) => {
  const { row } = props;
  return (
    <div
      style={{
        // Since rows are flattened by default,
        // we can use the row.depth property
        // and paddingLeft to visually indicate the depth
        // of the row
        paddingLeft: `${row.depth * 1.5}rem`,
      }}
    >
      <div className="flex items-center gap-2 leading-[17px] text-idesign-navy-100">
        {!row.original.parent_id && (row.original.items?.length ?? 0) > 0 ? (
          <LibrarySquare className="stroke-idesign-navy-100" size="18" />
        ) : (
          <BookText className="stroke-idesign-navy-100" size="18" />
        )}
        <Label
          htmlFor={`mapping-checkbox-${row.original.id}`}
          className={cn("m-0", {
            "cursor-pointer": row.original.is_mappable,
            "font-bold": row.original.is_heading,
          })}
        >
          {splitHighlights(props.cell.getValue(), getHighlightRanges(props))}
        </Label>
      </div>
    </div>
  );
};
