import styled from "styled-components";

interface IHorizontalPaddingWrap {
  hPadding?: string | number;
  vPadding?: string | number;
}

export const PaddingWrap = styled.div<IHorizontalPaddingWrap>`
  padding-left: ${(props) => (props.hPadding ? props.hPadding : `1rem`)};
  padding-right: ${(props) => (props.hPadding ? props.hPadding : `1rem`)};
  padding-top: ${(props) => (props.vPadding ? props.vPadding : `1rem`)};
  padding-bottom: ${(props) => (props.vPadding ? props.vPadding : `1rem`)};
  display: flex;
  flex-direction: column;
`;
