import * as React from "react";

import { Slot } from "@radix-ui/react-slot";
import { cva, type VariantProps } from "class-variance-authority";

import { cn } from "~/lib/utils";

const buttonVariants = cva(
  cn(
    "inline-flex items-center justify-center whitespace-nowrap rounded-md text-sm font-medium ring-offset-background transition-colors",
    "focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2",
    "disabled:pointer-events-none disabled:opacity-50",
  ),
  {
    variants: {
      variant: {
        grey: cn(
          "bg-button-grey hover:bg-neutral-20 text-neutral-100 outline outline-1 outline-neutral-40",
          "transition-colors duration-200 ease-in-out",
        ),
        blue: cn(
          "bg-button-blue hover:bg-[#0197cf] text-[#ffffff] outline outline-1 outline-neutral-40",
          "transition-colors duration-200 ease-in-out",
        ),
        darkblue: cn(
          "bg-button-dark-blue hover:bg-[#325766] text-[#ffffff] outline outline-1 outline-neutral-40",
          "transition-colors duration-200 ease-in-out",
        ),
        default: "bg-primary text-primary-foreground hover:bg-primary/90",
        destructive:
          "bg-destructive text-destructive-foreground hover:bg-error-50",
        outline:
          "border border-input bg-background hover:bg-accent hover:text-accent-foreground",
        secondary:
          "bg-secondary text-secondary-foreground hover:bg-secondary/80",
        ghost: "hover:bg-accent hover:text-accent-foreground",
        link: "text-primary underline-offset-4 hover:underline",
        draggable: "hover: text-accent-foreground",
        explorer: "bg-neutral-90 text-primary-foreground hover:bg-neutral-80",
      },
      size: {
        default: "h-10 px-2 py-2 rounded-3xl",
        xs: "h-6 px-2 rounded-3xl",
        sm: "h-9 px-2 rounded-3xl",
        lg: "h-11 px-8 rounded-3xl",
        icon: "h-10 w-10",
        icon_xs: "h-5 w-5",
      },
    },
    defaultVariants: {
      variant: "default",
      size: "default",
    },
  },
);

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  asChild?: boolean;
  testid?: string;
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    { className, variant, size, asChild = false, testid = null, ...props },
    ref,
  ) => {
    const Comp = asChild ? Slot : "button";

    return (
      <Comp
        className={cn(buttonVariants({ variant, size, className }))}
        ref={ref}
        data-testid={testid}
        {...props}
      />
    );
  },
);
Button.displayName = "Button";

export { Button, buttonVariants };
