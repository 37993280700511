import React, { useEffect, useState } from "react";

import { useSortable } from "@dnd-kit/sortable";
import { CellContext, RowData } from "@tanstack/react-table";
import { GripVertical } from "lucide-react";

import { Button } from "~/components/ui/button";
import { Checkbox } from "~/components/ui/checkbox";
import { Input } from "~/components/ui/input";
import { SelectMultiple } from "~/components/ui/select-multiple";
import {
  IListManagerListItem,
  Mapping_Restrictions,
} from "~/models/ListManager";

declare module "@tanstack/react-table" {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  interface TableMeta<TData extends RowData> {
    updateData: (rowIndex: number, columnId: string, value: unknown) => void;
  }
}

const RowDragHandleCell = ({ rowId }: { rowId: string }) => {
  const { attributes, listeners } = useSortable({
    id: rowId,
  });
  return (
    <div className="h-full w-full flex items-center justify-center">
      <Button {...attributes} {...listeners} size="icon_xs" variant="draggable">
        <GripVertical size="20" />
      </Button>
    </div>
  );
};

const InputCell = ({
  getValue,
  row,
  column: { id },
  table,
}: CellContext<IListManagerListItem, unknown>) => {
  const initialValue = getValue();
  const [value, setValue] = useState(initialValue);

  const onBlur = () => {
    table.options.meta?.updateData(row.index, id, value);
  };

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  return (
    <div>
      <Input
        value={(value as string) ?? ""}
        onChange={(e) => setValue(e.target.value)}
        onBlur={onBlur}
        className="w-full"
        variant="sm"
      />
    </div>
  );
};

const mappingRestrictionsOptions = Mapping_Restrictions.map((restriction) => ({
  label: restriction,
  value: restriction,
}));

const MappingRestrictionsCell = ({
  getValue,
  row: { index },
  column: { id },
  table,
}: CellContext<IListManagerListItem, unknown>) => {
  const initialValue = getValue() as string[] | undefined;
  const [value, setValue] = useState(initialValue ?? []);

  const handleValuesChange = (newValue: string[]) => {
    setValue(newValue);
    table.options.meta?.updateData(index, id, newValue);
  };

  useEffect(() => {
    setValue(initialValue ?? []);
  }, [initialValue]);

  return (
    <div>
      <SelectMultiple
        clearable
        onValuesChange={handleValuesChange}
        options={mappingRestrictionsOptions}
        values={value}
      />
    </div>
  );
};

const CheckboxCell = ({
  getValue,
  row,
  column: { id },
  table,
}: CellContext<IListManagerListItem, unknown>) => {
  const initialValue = (getValue() as boolean) ?? false;
  const [value, setValue] = useState(initialValue);

  const handleClick = () => {
    table.options.meta?.updateData(row.index, id, !value);
    setValue(!value);
  };

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  return (
    <div className="flex items-center space-x-2">
      <Checkbox
        id={`${row.original.id}-${id}-checkbox`}
        checked={value}
        onClick={handleClick}
      />
      <label
        htmlFor={`${row.original.id}-${id}-checkbox`}
        className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70 my-0"
      >
        {value ? "Yes" : "No"}
      </label>
    </div>
  );
};

export { RowDragHandleCell, InputCell, MappingRestrictionsCell, CheckboxCell };
