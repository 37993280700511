import React, { ReactNode, useEffect } from "react";

import { observer } from "mobx-react-lite";

import MappingListLoader from "~/components/program/mapping/MappingListLoader";
import {
  Sheet,
  SheetContent,
  SheetTrigger,
} from "~/components/ui-rework/sheet";
import MappingSheetProvider from "~/context/MappingSheetProvider";
import { useProgramEditorContext } from "~/context/ProgramEditorProvider";
import { NodeTypes, useStore } from "~/models/AlignEditor";

type MappingSheetProps = {
  nodeId: number;
  nodeType: NodeTypes;
  children: ReactNode;
  updateMappingsDisplay: () => void;
};

const MappingSheet = observer(
  ({
    nodeId,
    nodeType,
    children,
    updateMappingsDisplay,
  }: MappingSheetProps) => {
    const { resetDrawerLists, setCurrentMappingListId } = useStore();
    const { isEditingMappings, setIsEditingMappings, setNodeId, setNodeType } =
      useProgramEditorContext();

    const handleOpenChange = (open: boolean) => {
      if (open) {
        resetDrawerLists();
        setCurrentMappingListId(-1);
        setIsEditingMappings(true);
        setNodeId && setNodeId(nodeId);
        setNodeType && setNodeType(nodeType);
      } else {
        setIsEditingMappings(false);
      }
    };

    useEffect(() => {
      if (!isEditingMappings) {
        updateMappingsDisplay();
      }
    }, [isEditingMappings, updateMappingsDisplay]);

    return (
      <Sheet onOpenChange={handleOpenChange}>
        <SheetTrigger asChild>{children}</SheetTrigger>
        <SheetContent className="w-[65%] min-w-[686px]" side="right">
          <MappingSheetProvider nodeId={nodeId} nodeType={nodeType}>
            <MappingListLoader />
          </MappingSheetProvider>
        </SheetContent>
      </Sheet>
    );
  },
);

export default MappingSheet;
