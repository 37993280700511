import React, { useEffect, useState } from "react";

import { Icon as SelectPrimitiveIcon } from "@radix-ui/react-select";
import { cva, VariantProps } from "class-variance-authority";
import { ChevronDown } from "lucide-react";

import {
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectRoot,
  SelectTrigger,
  SelectValue,
} from "~/components/ui-rework/select";
import { cn } from "~/lib/utils";

const dropdownLabelSizes = {
  s: "text-[9px] px-[13.5px] py-[4.5px]",
  m: "text-xs px-[18px] py-1.5",
};

const dropdownLabelVariants = cva(
  cn(
    "bg-dropdown-label text-dropdown-label-foreground rounded-t-[10px] mb-0 w-min",
    "aria-disabled:bg-disable aria-disabled:text-disable-foreground aria-disabled:cursor-not-allowed",
  ),
  {
    variants: {
      size: dropdownLabelSizes,
    },
    defaultVariants: {
      size: "m",
    },
  },
);

const dropdownStrokes = {
  default: "stroke-dropdown-stroke",
  outline: "stroke-dropdown-outline-stroke",
  ghost: "stroke-dropdown-ghost-stroke",
};

const dropdownSizes = {
  s: "px-3 py-1.5 rounded-[12px] rounded-tl-none rounded-br-none text-xs leading-[14px]",
  m: "h-11 px-4 py-2 rounded-xl rounded-tl-none rounded-br-none",
};

const dropdownTypes = {
  default: cn(
    "border-none bg-dropdown text-dropdown-foreground data-[placeholder]:text-dropdown-foreground",
    "disabled:bg-disable disabled:text-disable-foreground disabled:opacity-100",
    "active:bg-dropdown-active hover:bg-dropdown-active focus:bg-dropdown-active data-[state=open]:bg-dropdown-active",
    "hover:shadow-lg focus:shadow-lg shadow-y-2 disabled:shadow-none",
  ),

  outline: cn(
    "border border-input text-dropdown-outline-foreground data-[placeholder]:text-dropdown-outline-foreground",
    "active:bg-dropdown-outline-active hover:bg-dropdown-outline-active",
    "hover:shadow-lg focus:shadow-lg data-[state=open]:shadow-lg shadow-y-2 disabled:shadow-none",
    "focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-0",
    "data-[state=open]:outline-none data-[state=open]:ring-2 data-[state=open]:ring-ring data-[state=open]:ring-offset-0",
  ),
  ghost: cn(
    "border-none bg-transparent rounded-full",
    "text-dropdown-ghost-foreground data-[placeholder]:text-dropdown-ghost-foreground ",
    "active:bg-dropdown-ghost-active active:text-dropdown-ghost-active-foreground data-[placeholder]:active:text-dropdown-ghost-active-foreground",
    "hover:bg-dropdown-ghost-active hover:text-dropdown-ghost-active-foreground data-[placeholder]:hover:text-dropdown-ghost-active-foreground",
    "shadow-none hover:shadow-none focus:shadow-none",
    "disabled:bg-transparent disabled:text-disable-foreground",
    "focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-0",
    "data-[state=open]:outline-none data-[state=open]:ring-2 data-[state=open]:ring-ring data-[state=open]:ring-offset-0",
  ),
};

const dropdownVariants = cva("", {
  variants: {
    size: dropdownSizes,
    variant: dropdownTypes,
  },
  defaultVariants: {
    size: "m",
    variant: "default",
  },
});

type DropdownProps = {
  disabled?: boolean;
  icon?: React.ReactNode;
  label?: string;
  options: { label: string; value: string }[];
  placeholder?: string;
  testid?: string;
  value?: string;
  variant?: keyof typeof dropdownTypes;
  handleChange: (newValue: string) => void;
} & VariantProps<typeof dropdownVariants>;

export const Dropdown = ({
  disabled,
  icon,
  label,
  options,
  testid,
  placeholder,
  value,
  handleChange,
  size = "m",
  variant = "default",
}: DropdownProps) => {
  const [defaultValue, setDefaultValue] = useState(value);

  useEffect(() => {
    if (defaultValue !== value) {
      setDefaultValue(value);
    }
  }, [value, defaultValue]);

  const hasLabel = label && label.length > 0;

  const gap = size === "s" ? "gap-1.5" : "gap-2";
  const separatorHeight = size === "s" ? "h-[18px]" : "h-[24px]";
  const svgLineHeight = size === "s" ? 18 : 24;
  const dropdownStroke = dropdownStrokes[variant];

  return (
    <div className="flex flex-col gap-0" data-testid={testid}>
      <SelectRoot
        value={defaultValue}
        defaultValue={defaultValue}
        onValueChange={handleChange}
        disabled={disabled}
      >
        {hasLabel && (
          <label
            aria-disabled={disabled}
            className={dropdownLabelVariants({ size })}
          >
            {label}
          </label>
        )}
        <SelectTrigger
          className={cn(
            dropdownVariants({ size, variant }),
            `w-full justify-between ${gap}`,
            {
              "*:stroke-disable-foreground *:text-disable-foreground": disabled,
            },
          )}
          disabled={disabled}
          noIcon
        >
          <div className={`flex items-center ${gap} w-max`}>
            {icon}
            <SelectValue
              placeholder={placeholder ? placeholder : "Placeholder"}
            />
          </div>
          <div className={`flex h-full items-center ${gap}`}>
            {variant !== "ghost" && (
              <svg
                width="1px"
                className={cn(separatorHeight, dropdownStroke, {
                  "stroke-disable-foreground": disabled,
                })}
              >
                <line x1="0" y1="0" x2="0" y2={svgLineHeight} strokeWidth="1" />
              </svg>
            )}
            <SelectPrimitiveIcon asChild>
              <ChevronDown
                size={16}
                className={cn(dropdownStroke, {
                  "stroke-disable-foreground": disabled,
                })}
              />
            </SelectPrimitiveIcon>
          </div>
        </SelectTrigger>
        <SelectContent className="min-w-[312px]">
          <SelectGroup>
            {options.map((option, index) => (
              <SelectItem value={option.value} key={index}>
                {option.label}
              </SelectItem>
            ))}
          </SelectGroup>
        </SelectContent>
      </SelectRoot>
    </div>
  );
};
