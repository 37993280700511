import { types, Instance, SnapshotIn } from "mobx-state-tree";

export enum ROLES {
  ADMIN = "admin",
  TEST = "test",
  LEARNING_ARCHITECT = "learningarchitect",
}

export enum ROLES_READABLE {
  admin = "Administrator",
  test = "Tester",
  learningarchitect = "Learning Architect",
}

const User = types.model("User", {
  id: types.string,
  email: types.string,
  firstName: types.string,
  lastName: types.string,
  firstLast: types.string,
  password: types.string, // Why is this here?  We shouldn't be storing passwords in the client!
  organizationId: types.number,
  agencyId: types.number,
  partnerId: types.number,
  accountStatus: types.string,
  userType: types.string,
  roles: types.optional(types.array(types.string), []),
  move: types.optional(types.boolean, false),
});
export type UserInstance = Instance<typeof User>;
export type IUser = SnapshotIn<typeof User>;

export enum PERMISSION {
  MANAGE_USERS = "ManageUsers",
  MANAGE_GLOBAL_LISTS = "ManageGlobalLists",
  MANAGE_PARTNERS = "ManagePartners",
  MANAGE_PROGRAMS = "ManagePrograms",
  MANAGE_CURRICULUM = "ManageCurriculum",
  MANAGE_CURRICULUM_MAPPINGS = "ManageCurriculumMappings",
  MANAGE_AGENCY_SETTINGS = "ManageAgencySettings",
  MANAGE_PARTNER_SETTINGS = "ManagePartnerSettings",
  MANAGE_PROGRAM_SETTINGS = "ManageProgramSettings",
  LIST_AGENCIES = "ListAgencies",
  LIST_PARTNERS = "ListPartners",
  LIST_PROGRAMS = "ListPrograms",
  VIEW_LISTS = "ViewLists",
  VIEW_GLOBAL_LISTS = "ViewGlobalLists",
  MANAGE_PARTNER_LISTS = "ManagePartnerLists",
  VIEW_PROGRAM_DETAILS = "ViewProgramDetails",
  MANAGE_PROGRAM_LISTS = "ManageProgramLists",
}

//this is the shape of the data coming back from the database when querying for Users
const UserItem = types.model("UserItem", {
  id: types.string,
  name: types.string,
  first_name: types.string,
  last_name: types.string,
  agency_id: types.number,
  title: types.string,
  email: types.string,
  user_type: types.string,
  partner_id: types.number,
  account_status: types.string,
});
export type UserItemInstance = Instance<typeof UserItem>;
export type IUserItem = SnapshotIn<typeof UserItem>;

const Partner = types.model("Partner", {
  id: types.number,
  display_name: types.string,
  partner_type: types.string,
  short_name: types.string,
  move: types.optional(types.boolean, false),
  delete: types.optional(types.boolean, false),
});
export type PartnerInstance = Instance<typeof Partner>;
export type IPartner = SnapshotIn<typeof Partner>;

//this is the shape of the data coming back from the database when querying for Partners
const PartnerItem = types.model("PartnerItem", {
  short_name: types.string,
  name: types.string,
  partner_type: types.string,
  id: types.number,
});
export type PartnerItemInstance = Instance<typeof PartnerItem>;
export type IPartnerItem = SnapshotIn<typeof PartnerItem>;

//this is the shape of the data coming back from the database when querying for User's Roles
const UserRoles = types.model("UserRoles", {
  id: types.optional(types.number, 0),
  role_name: types.string,
  agency_name: types.string,
  partner_name: types.string,
  program_name: types.string,
  move: types.optional(types.boolean, false),
  delete: types.optional(types.boolean, false),
});
export type UserRolesInstance = Instance<typeof UserRoles>;
export type IUserRoles = SnapshotIn<typeof UserRoles>;

//this is the shape of the data sent to the database when creating a new Program
const ProgramItem = types.model("ProgramItem", {
  id: types.integer,
  code: types.string,
  partner_id: types.string,
  display_name: types.string,
  name: types.string,
  available_list_ids: types.array(types.number),
});
export type ProgramItemInstance = Instance<typeof ProgramItem>;
export type IProgramItem = SnapshotIn<typeof ProgramItem>;

//this is the shape of the data when retrieving a Program
const ProgramData = types.model("ProgramItem", {
  id: types.integer,
  partner_id: types.integer,
  display_name: types.string,
  code: types.string,
  name: types.string,
  total_credits: types.integer,
  total_tot_minutes: types.integer,
  move: types.optional(types.boolean, false),
  delete: types.optional(types.boolean, false),
});
export type ProgramDataInstance = Instance<typeof ProgramData>;
export type IProgramData = SnapshotIn<typeof ProgramData>;

//this is the shape of the data when drilling down to users sessions activity
const SessionDetails = types.model("ISessionDetails", {
  activity_type: types.string,
  created_at: types.number,
  details: types.string,
  id: types.number,
  relative_uri: types.maybeNull(types.string),
  session_id: types.number,
  move: types.optional(types.boolean, false),
});
export type SessionDetailsInstance = Instance<typeof SessionDetails>;
export type ISessionDetails = SnapshotIn<typeof SessionDetails>;
