import { useEffect, useState } from "react";

import { ApiUrls } from "~/api/ApiPaths";
import { useToast } from "~/components/ui/use-toast";
import useAxios from "~/hooks/useAxios";
import { useStore as useAlignEditorStore } from "~/models/AlignEditor";
import { useStore as useRootStore } from "~/models/Root";

const useProgramEditorAPI = () => {
  const { getProgram } = useAlignEditorStore();
  const { getAppUser } = useRootStore();
  const [pending, setPending] = useState(false);
  const [isPersisted, setIsPersisted] = useState(false);
  const { toast } = useToast();

  const { data, error, requestUrl, fetch, authToken, payload, resetAll } =
    useAxios({ method: "POST", initialValue: null });

  const postProgramUpdates = async () => {
    const program = getProgram();
    resetAll();
    payload.current = program?.getCurriculumNodesForDatabasePost();
    requestUrl.current = ApiUrls.programs.updateCurriculumItems;
    authToken.current = getAppUser()?.authtoken;
    fetch("POST");
    setPending(true);
  };

  useEffect(() => {
    if (data?.issuccess && !isPersisted) {
      setIsPersisted(true);
      toast({
        variant: "success",
        title: "Success!",
        description: "Program saved successfully.",
      });
    } else if (error) {
      console.error(error);
      toast({
        variant: "destructive",
        title: "Uh oh! Something went wrong",
        description: "There was a problem with your request.",
      });
    }
    setPending(false);
  }, [data, error, setIsPersisted, isPersisted, toast]);

  return {
    isPersisted,
    setIsPersisted,
    postProgramUpdates,
    pending,
  };
};

export default useProgramEditorAPI;
