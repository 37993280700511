import React, { useEffect, useState } from "react";

import CourseModule, { CompModel } from "~/components/Reports/CourseModule";
import MappingDetailsMiniDashboard from "~/components/Reports/MappingDetailsMiniDashboard";
import * as ReportModels from "~/components/Reports/ReportModels";

function calculateTotalModulesMapped(
  course: ReportModels.Course,
  list: ReportModels.List,
  listItemId: number,
  modules?: ReportModels.Module | undefined,
): number[] {
  const Model = new CompModel(course, list?.id);
  let moduleCount = 0;
  let uniqueMappingCount = 0;
  Model.modules.forEach((x) => {
    const moduleMappings =
      x.mappings?.filter(
        (x) => x.list_id === list.id && x.listitem_id === listItemId,
      ) || [];
    const activityMappings =
      x.activities?.flatMap((a) =>
        a.mappings.filter(
          (b) => b.list_id === list.id && b.listitem_id === listItemId,
        ),
      ) || [];
    const mappings = moduleMappings.concat(activityMappings.flat());
    if (mappings.length > 0) {
      moduleCount++;
      uniqueMappingCount += mappings.length;
    }
  });
  return [moduleCount, uniqueMappingCount];
}

const AlignmentMappingDetails: React.FC<{
  course: ReportModels.Course;
  mappings: ReportModels.MappingItem[];
  list: ReportModels.List;
}> = ({ course, mappings, list }) => {
  const [mappedActivities, setMappedActivities] = useState<
    ReportModels.Activity[]
  >([]);
  const [totalTime, setTotalTime] = useState(0);
  const formattedTime = ReportModels.formatMinutes(totalTime);
  const [listItemId] = useState(mappings[0].listitem_id);
  const caculatedModuleMappings = calculateTotalModulesMapped(
    course,
    list,
    listItemId,
  );
  const numModulesMapped = caculatedModuleMappings[0];
  const numUniqueMappings = caculatedModuleMappings[1];

  useEffect(() => {
    if (!mappedActivities || mappedActivities.length === 0) {
      const activities = course.modules.flatMap((module) => {
        const moduleActivities = module.activities.filter((activity) => {
          return mappings.some((mapping) => {
            return (
              mapping.c_item_type === "Activity" &&
              mapping.c_item_id === activity.id
            );
          });
        });
        return moduleActivities;
      });

      setMappedActivities(activities);
    }
  }, [mappedActivities, course, mappings]);

  useEffect(() => {
    if (mappedActivities && mappedActivities.length > 0) {
      let totalTime = 0;
      mappedActivities.forEach((activity) => {
        let listItemFound = false;
        activity.mappings.forEach((mapping) => {
          if (mapping.listitem_id === listItemId) {
            listItemFound = true;
          }
        });
        if (listItemFound) {
          totalTime += activity.tot_minutes;
        }
      });
      setTotalTime(totalTime);
    }
  }, [mappedActivities, listItemId]);

  return (
    <>
      <div className="flex justify-between items-center mb-4">
        <div className="flex flex-col justify-center items-start">
          <div className="mb-2">
            <b>List:</b> {list.name}
          </div>
          <div>
            <b>List Item:</b> {mappings[0].listitem_display_name}
          </div>
        </div>
        <MappingDetailsMiniDashboard
          numModulesMapped={numModulesMapped}
          numTotalModules={course.modules.length}
          numUniqueMappings={numUniqueMappings}
          totalTime={formattedTime}
        />
      </div>

      <CourseModule
        course={course}
        list={list}
        expandable={true}
        listItemId={listItemId}
      />
    </>
  );
};

export default AlignmentMappingDetails;
