import React from "react";

import { useField } from "formik";
import styled from "styled-components";

import { getTmpId } from "~/models/AlignEditor";

const AlignTextInputStyles = styled.div`
  .inline-error {
    display: flex;
    margin-left: 0.5rem;
    .body-text {
      margin-bottom: 0;
      line-height: unset;
    }
  }
  margin-bottom: 0.2rem;
`;

interface AlignFormikTextInputInterface {
  label: string;
  id?: string;
  name?: string;
  type?: string;
  placeholder?: string;
  materialIconName?: string;
  iconAlignment?: "left" | "right" | undefined;
  isReadOnly?: boolean;
  disabled?: boolean;
}

const AlignFormikTextInput: React.FC<AlignFormikTextInputInterface> = (
  props,
) => {
  const [field, meta] = useField(props as any);
  const fieldId = (props.name ? props.name + getTmpId() : props.id) || "";
  const { iconAlignment, materialIconName, isReadOnly, disabled, ...rest } =
    props;
  const iconElementAlignment = !iconAlignment
    ? ""
    : iconAlignment === "left"
    ? "icon-input-left"
    : "icon-input-right";
  const iconFieldAlignment = !iconAlignment
    ? ""
    : iconAlignment === "left"
    ? "icon-left"
    : "icon-right";
  const readOnly = isReadOnly || false;
  return (
    <AlignTextInputStyles
      className={`input-with-label ${
        materialIconName ? "input-icon-wrapper" : null
      }`}
    >
      <label htmlFor={fieldId} className="input-title">
        {props.label}
      </label>
      <input
        className={`input-text-field input medium ${iconFieldAlignment} w-input`}
        id={fieldId}
        {...field}
        {...rest}
        readOnly={readOnly}
        disabled={disabled}
        aria-describedby={fieldId + "-error"}
      />
      {materialIconName && (
        <div
          className={`material-icons ${iconElementAlignment}`}
          style={{ top: `3.375rem` }}
          aria-hidden="true"
        >
          {materialIconName}
        </div>
      )}
      {meta.touched && meta.error ? (
        <div className="inline-error">
          <div
            className="material-icons icon-left icon-list-item error"
            aria-hidden="true"
          >
            error
          </div>
          <div className="alert-content">
            <div className="body-text s" id={fieldId + "-error"}>
              {meta.error}
            </div>
          </div>
        </div>
      ) : null}
    </AlignTextInputStyles>
  );
};

export default AlignFormikTextInput;
