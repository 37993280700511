import React from "react";

import AlignmentGridSection from "~/components/Reports/AlignmentGridSection";
import * as ReportModels from "~/components/Reports/ReportModels";
import { ScrollArea } from "~/components/ui/scroll-area";
import { Table, TableHead, TableHeader, TableRow } from "~/components/ui/table";
import { Checkbox } from "~/components/ui-rework/checkbox";
import { Label } from "~/components/ui-rework/label";
import { cn } from "~/lib/utils";

const AlignmentGrid: React.FC<{
  programData: ReportModels.ProgramData | null;
  targetList: ReportModels.List | null;
  forPrint: boolean | false;
}> = ({ programData, targetList, forPrint }) => {
  const [showNumbers, setShowNumbers] = React.useState(false);
  const checkboxChange = (event: any) => {
    setShowNumbers(event);
  };

  return (
    <div className="light h-full w-full overflow-hidden rounded-md  border-idesign-navy-120">
      <ScrollArea
        className="h-full w-full"
        horizontal
        vertical
        thumbClassName="z-50 bg-gradient-1"
      >
        <Table
          className="w-full rounded-md border-collapse border-spacing-0 bg-gradient-1"
          sticky
          role="treegrid"
        >
          <colgroup>
            <col style={{ minWidth: "300px" }} />
            {programData?.courses.map((course, idx) => (
              <col key={idx} style={{ width: "0px" }} />
            ))}
          </colgroup>
          <TableHeader className="sticky top-0 z-10">
            <TableRow
              role="row"
              className="bg-gradient-1 border-b border-idesign-navy-120 border-x"
            >
              <TableHead
                role="gridcell"
                className="sticky left-0 z-20 bg-ocean-140 border-r border-idesign-navy-120"
              >
                <div className="flex items-center">
                  <Checkbox
                    id="showNumbers"
                    checked={showNumbers}
                    onCheckedChange={checkboxChange}
                    className="dark"
                  />
                  <Label
                    htmlFor="showNumbers"
                    type="bold"
                    className="whitespace-nowrap px-2 m-0 cursor-pointer dark"
                  >
                    Show Numbers
                  </Label>
                </div>
              </TableHead>
              {programData?.courses.map((course, idx) => (
                <TableHead
                  key={idx}
                  role="gridcell"
                  className={cn(
                    "text-white font-bold border-x sticky top-0 border-idesign-navy-120 whitespace-nowrap text-center",
                    {
                      "border-l-0": idx === 0,
                    },
                  )}
                >
                  {course.code}
                </TableHead>
              ))}
            </TableRow>
          </TableHeader>
          {forPrint && programData && targetList != null ? (
            <AlignmentGridSection
              list={targetList}
              programData={programData}
              forPrint={forPrint}
              showNumbers={showNumbers}
              last={false}
            />
          ) : (
            programData?.mapped_list_ids.map((list_id, idx) => {
              const findListResult = programData.mapped_lists.find(
                (x) => x.id === list_id,
              );
              if (findListResult === undefined) {
                return null;
              }
              return (
                <AlignmentGridSection
                  key={idx}
                  list={findListResult}
                  programData={programData}
                  forPrint={forPrint}
                  showNumbers={showNumbers}
                  last={
                    idx === programData.mapped_list_ids.length - 1
                      ? true
                      : false
                  }
                />
              );
            })
          )}
        </Table>
      </ScrollArea>
    </div>
  );
};

export default AlignmentGrid;
