import React, { Fragment, useMemo, useState } from "react";

import {
  ColumnDef,
  ExpandedState,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { ChevronDown, ChevronUp, FilePenLineIcon } from "lucide-react";
import { observer } from "mobx-react-lite";

import CourseDialog from "~/components/program/dialogs/CourseDialog";
import { DeleteCurriculumNode } from "~/components/program/dialogs/DeleteNodeDialog";
import MappingTable from "~/components/program/tables/MappingTable";
import ModuleTable from "~/components/program/tables/ModuleTable";
import {
  Table,
  TableHeader,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
} from "~/components/ui/table";
import { Button } from "~/components/ui-rework/button";
import { cn } from "~/lib/utils";
import {
  useStore,
  ICourse,
  NodeTypes,
  CourseEditable,
} from "~/models/AlignEditor";

type CourseTableProps = {
  className?: string;
};

const CourseTable = observer(({ className }: CourseTableProps) => {
  const columns = useMemo<ColumnDef<ICourse>[]>(
    () => [
      {
        id: "expand",
        header: "",
        cell: ({ row }) => (
          <div className="flex">
            <button
              {...{
                onClick: () => row.toggleExpanded(!row.getIsExpanded()),
                style: { cursor: "pointer" },
                "data-testid": "course-expand",
              }}
            >
              {row.getIsExpanded() ? (
                <ChevronUp size="16px" />
              ) : (
                <ChevronDown size="16px" />
              )}
            </button>
          </div>
        ),
      },
      {
        id: "edit",
        header: "",
        cell: ({ row }) => (
          <div className="flex">
            <CourseDialog
              course={row.original}
              onSubmit={(data) => handleEditCourse(data, row.original)}
            >
              <button
                data-testid="program-edit-course"
                title="Edit Course"
                aria-label="Edit Course"
              >
                <FilePenLineIcon className="stroke-ocean-120" size="16px" />
              </button>
            </CourseDialog>
          </div>
        ),
      },
      {
        id: "code",
        header: "Code",
        cell: ({ row }) => <div>{row.original.code}</div>,
      },
      {
        id: "name",
        header: "Name",
        cell: ({ row }) => <div>{row.original.name}</div>,
      },
      {
        id: "display_name",
        header: "Display Name",
        cell: ({ row }) => <div>{row.original.display_name}</div>,
      },
      {
        id: "level",
        header: () => <div className="text-center">Level</div>,
        cell: ({ row }) => (
          <div className="text-center">{row.original.level}</div>
        ),
      },
      {
        id: "credits",
        header: () => <div className="text-end">Credits</div>,
        cell: ({ row }) => (
          <div className="text-end">
            {row.original.credits ? row.original.credits : ""}
          </div>
        ),
      },
      {
        id: "delete",
        header: "",
        cell: ({ row }) => (
          <DeleteCurriculumNode
            id={row.original.id}
            type={NodeTypes.Course}
            display_name={
              row.original.display_name ??
              row.original.name ??
              row.original.code ??
              "Course"
            }
            onDelete={handleDeleteCourse}
          />
        ),
      },
    ],
    [], // eslint-disable-line react-hooks/exhaustive-deps
  );

  const {
    getProgram,
    getProgramCourses,
    saveCourseChanges,
    deleteCurriculumNode,
  } = useStore();

  const [data, setData] = useState<ICourse[]>(getProgramCourses());

  const handleAddCourse = (course: CourseEditable) => {
    getProgram()?.addCourse(course);
    setData(getProgramCourses());
  };

  const handleEditCourse = (
    courseUpdates: CourseEditable,
    courseOriginal: ICourse,
  ) => {
    const course = { ...courseOriginal, ...courseUpdates };
    setData(saveCourseChanges(course));
  };

  const handleDeleteCourse = (courseId: number) => {
    deleteCurriculumNode(courseId, NodeTypes.Course);
    setData(getProgramCourses());
  };

  const [expanded, setExpanded] = useState<ExpandedState>({});

  const table = useReactTable({
    data,
    columns,
    state: {
      expanded,
    },
    onExpandedChange: setExpanded,
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <>
      <div className="bg-gradient-1 text-white font-bold flex gap-2 items-center w-full px-3 py-2 border-t border-l border-idesign-navy-120">
        <span className="text-base">Courses</span>
        <CourseDialog onSubmit={handleAddCourse}>
          <Button
            testid="program-add-course"
            className="dark text-sm py-1 px-2 font-normal"
            variant="outline"
          >
            Add Course
          </Button>
        </CourseDialog>
      </div>
      {data.length > 0 && (
        <Table className="table-fixed border-t border-l border-idesign-navy-120">
          <colgroup>
            <col style={{ width: "33px" }} />
            <col style={{ width: "33px" }} />
            <col style={{ width: "120px" }} />
            <col style={{ width: "33%" }} />
            <col style={{ width: "66%" }} />
            <col style={{ width: "100px" }} />
            <col style={{ width: "68px" }} />
            <col style={{ width: "33px" }} />
          </colgroup>
          <TableHeader className="bg-gradient-1">
            {table.getHeaderGroups().map((headerGroup) => (
              <TableRow
                key={headerGroup.id}
                className={cn("hover:bg-transparent")}
              >
                {headerGroup.headers.map((header) => {
                  return (
                    <TableHead
                      className={cn(
                        "text-white font-bold border-x border-idesign-navy-120 p-2 py-4",
                        "last:border-r-0 first:border-x-0 [&:nth-child(2)]:border-x-0",
                      )}
                      key={header.id}
                    >
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                            header.column.columnDef.header,
                            header.getContext(),
                          )}
                    </TableHead>
                  );
                })}
              </TableRow>
            ))}
          </TableHeader>
          <TableBody>
            {table.getRowModel().rows?.length ? (
              table.getRowModel().rows.map((row) => (
                <Fragment key={row.id}>
                  <TableRow
                    key={row.id + "_original"}
                    className={cn(
                      "hover:bg-gray-200 border-b-0",
                      "[&_td:last-child]:border-r-0 [&_td:first-child]:border-l-0",
                      {
                        "bg-muted": row.index % 2 !== 0,
                        "bg-white": row.index % 2 === 0,
                      },
                    )}
                  >
                    {row.getVisibleCells().map((cell) => (
                      <TableCell
                        key={cell.id}
                        className="p-2 border-x border-idesign-navy-120"
                      >
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext(),
                        )}
                      </TableCell>
                    ))}
                  </TableRow>
                  {row.getIsExpanded() ? (
                    <tr
                      key={row.id + "_expanded"}
                      className={cn({
                        "bg-muted": row.index % 2 !== 0,
                        "bg-white": row.index % 2 === 0,
                      })}
                    >
                      <TableCell
                        colSpan={columns.length}
                        className="pl-8 pr-0 py-0"
                      >
                        <MappingTable
                          nodeId={row.original.id}
                          nodeType={NodeTypes.Course}
                        />
                        <ModuleTable
                          className={cn({
                            "border-b-0":
                              row.index === table.getRowModel().rows.length - 1,
                          })}
                          courseId={row.original.id}
                        />
                      </TableCell>
                    </tr>
                  ) : null}
                </Fragment>
              ))
            ) : (
              <TableRow>
                <TableCell
                  colSpan={columns.length}
                  className="h-24 text-center"
                >
                  No results.
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      )}
    </>
  );
});

export default CourseTable;
