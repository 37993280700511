import React, { useEffect, useState } from "react";

import { CheckCircle2, Loader } from "lucide-react";

import { useDebounce } from "~/hooks/useDebounce";

type AutosaveIndicatorProps = {
  lastSaved: Date | null;
  setLastSaved: React.Dispatch<React.SetStateAction<Date | null>>;
  init: React.MutableRefObject<boolean>;
};

export const FilterAutosaveIndicator = ({ lastSaved, setLastSaved, init}: AutosaveIndicatorProps) => {

  const [animate, setAnimate] = useState(false);

  const debouncedLastSaved = useDebounce(lastSaved, 250);

  useEffect(() => {
    if (debouncedLastSaved && lastSaved !== null) {
      init.current = false;
      setAnimate(true);
      
      const timer = setTimeout(() => {
        setAnimate(false);
        setLastSaved(null);
      }, 300);

      return () => {
        clearTimeout(timer);
      };
    }
  }, [debouncedLastSaved]);

  if (init.current) return null;

  return (
    <div>
      <div className="flex gap-2.5 items-center justify-center text-idesign-navy-70 stroke-idesign-navy-70">
        {animate ? (
          <>
            <Loader className="animate-spin" />
            <span>Applying...</span>
          </>
        ) : (
          <>
            <CheckCircle2 />
            <span>Filters applied</span>
          </>
        )}
      </div>
    </div>
  );
};
